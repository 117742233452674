/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState } from 'react';
import { UseFormSetValue, UseFormRegister } from 'react-hook-form';
import { Grid, IconButton, InputAdornment, TextField, OutlinedTextFieldProps } from "@mui/material";
import { Controller, ControllerProps, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import FxMaterialSelect from '../FxSelect/FxMaterialSelect';
import { ReactComponent as UsFlagIcon } from '../../../assets/svg/us-flag-icon.svg';
import { ReactComponent as IndiaFlagIcon } from '../../../assets/svg/india-flag-icon.svg';

Logger.debug("FxPhoneEdit.tsx", "Fx Phone Edit initializing")

/**
 * Component used to edit phone number
 * @param props :props data
 * @returns
 */
type TFieldValues = Record<string, string | number>;

type FxPhoneEditType = ControllerProps & OutlinedTextFieldProps;

interface FxPhoneEditProps extends Partial<FxPhoneEditType> {
    name: string;
    className?: string;
    isEditable?: boolean;
    disableFlag?: boolean;
    clearErrors?: UseFormClearErrors<TFieldValues>;
    setError?: UseFormSetError<TFieldValues>;
    value?: string;
    helperText?: string;
    inputRef?: any;
    setValue: UseFormSetValue<TFieldValues>;
    register: UseFormRegister<TFieldValues>;
    format?: string;
    readOnly?:boolean;
    defaultCountryCode?: any;
    suffixIcon?: any;
    suffix?: any;
}

const FxPhoneEdit: React.FC<FxPhoneEditProps> = (props) => {
  const [flagSelected, setFlagSelected] = useState((props?.defaultCountryCode !== undefined && props?.defaultCountryCode !== 'undefined') ? props?.defaultCountryCode : '1');

  // Method handles the change event of flag
  const handleFlagChange = (event: any) => {
    try {
      setFlagSelected(event.target.value)
    }
    catch (e) { 
      Logger.error("FxPhoneEdit.tsx", "handleFlagChange", "error", e);
    }
  }

  // Method handles the onchange event which transforms the value to the phone number format
  const handleOnChange = (event: any) => {
    try {
      let val = event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      val = !val[2] ? val[1] : '(' + val[1] + ') ' + val[2] + (val[3] ? '-' + val[3] : '');
      if (val.length === 14 || val.length === 0) {
          props.clearErrors && props.clearErrors(event.target.name);
      } else {
          props.setError && props.setError(event.target.name, {
          type: "manual",
          message: "Invalid phone number",
        });
      }
      props.setValue(event.target.name, val);
    }
    catch (e) { 
      Logger.error("FxPhoneEdit.tsx", "handleOnChange", "error", e);
    }
  }
  // Method handles the key down change event
  const handleKeyDownChange = (event: any) => {
    try {
      event.persist();
      if (event.key === 'Backspace') {
        let val = event.target.value;
        if (val && val.length > 0 && val[val.length - 1] === '-') {
          setTimeout(() => {
            val = val.substring(0, val.length - 1);
            props.setValue(event.target.name, val);
          }, 1);
        }
      }
    }
    catch (e) { 
      Logger.error("FxPhoneEdit.tsx", "handleKeyDownChange", "error", e);
    }
  }
  const flagData: any = [
    { label: < UsFlagIcon />, value: '1' },
    { label: < IndiaFlagIcon />, value: '91' },
  ]

  const regText=  {...props.register(props.name) }
  /**
   * Method transforms the default value
   * @param value : default value
   * @returns : transformed value
   */
  const transformToPhoneNumberFormat = (value: any) => {
    try{
      let val =value?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      val = !val[2] ? val[1] : '(' + val[1] + ') ' + val[2] + (val[3] ? '-' + val[3] : '');
      return val;
    }
    catch(e){
      Logger.error("FxPhoneEdit.tsx", "transformToPhoneNumberFormat", "error", e);
    }
  }

  return (
    <Grid item container xs={12} className={"fx-phone-edit "}>
      <div className={ props.className}>
      <Grid item className={"fx-phone-edit-country "}>
        <FxMaterialSelect register={{...props.register(props.name+'-select') }} value={flagSelected} onChange={handleFlagChange} 
          id={props.id} name={props.name+'-select'} data={flagData} setValue={props.setValue} control={props.control} 
            readOnly={props.isEditable === false ? true : props.disableFlag ? props.disableFlag : false}
          />
      </Grid>
      <Grid item  xs>
        <Controller
          name={props.name}
          control={props.control ? props.control : undefined}
          rules={props.rules ? props.rules : { required: false }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              {...regText}
              variant={"outlined"}
              id={props.id}
              name={props.name}
              InputLabelProps={{
                shrink: true,
              }}
              label={props.label ? props.label : ''}
              disabled={props.isEditable === false ? true : false}
              defaultValue={props.defaultValue? transformToPhoneNumberFormat(props.defaultValue):''}
              className="fx-input-edit"
              type={'text'}
              value={(props.value === '' ? props.value : (props.value ? props.value : value))}
              onChange={handleOnChange}
              onKeyDown={handleKeyDownChange}
              inputRef={props.inputRef}
              error={error ? error as unknown as boolean : false}
              helperText={props.helperText ? props.helperText : ''}
              inputProps={props.inputProps ? props.inputProps : undefined}
              InputProps={
                flagSelected && props.suffixIcon
                  ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        {'+' + flagSelected}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {props.suffix}
                        <IconButton size="large">
                          {<props.suffixIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),

                  }
                  :
                  props.suffixIcon
                    ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          {props.suffix}
                          <IconButton size="large">
                            {<props.suffixIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    } :
                    flagSelected
                      ? {
                        startAdornment: (
                          <InputAdornment position="start">
                            {'+' + flagSelected}
                          </InputAdornment>
                        )

                      } :
                      {}
              }

            />
          )}
        />
      </Grid>
      </div>
    </Grid>
  );
}



export default FxPhoneEdit;
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useMemo, useState } from "react";
import { IconButton, InputAdornment, TextField, OutlinedTextFieldProps } from "@mui/material";
import { UseFormRegisterReturn, UseFormSetValue, Controller, ControllerProps } from 'react-hook-form';
import clsx from "clsx";
import moment from "moment";
import { Logger } from "../../../libs/utils/logger";
import { Visibility, VisibilityOff } from '@mui/icons-material';

/**
 * Component used for edit text
 */
Logger.debug("FxTextEdit.tsx", "Fx Text Edit initializing")

type FxTextEditType = ControllerProps & OutlinedTextFieldProps;

type TFieldValues = Record<string, string | number>;

interface FxTextEditProps extends Partial<FxTextEditType> {
    name: string;
    maxLength?: number;
    min?: number | string;
    setValue?: UseFormSetValue<TFieldValues>;
    onChange?: (event: any, data?: any) => void;
    register?: UseFormRegisterReturn;
    isEditable?: boolean;
    valuePattern?: any;
    suffix?: string;
    showDecimal?: boolean;
    passwordIcon?: boolean;
    max?: number;
    suffixIcon?: React.ElementType;
    prefixIcon?: React.ElementType;
    notAllowPastDate?: boolean;
    notAllowFutureDate?: boolean;
    readOnly?: boolean;
    formatDate?: string;
    onWheel? : React.WheelEventHandler<HTMLDivElement> | undefined
}


export const FxTextEdit: React.FC<FxTextEditProps> =
  (props) => {
    let className= (props.className ? props.className : 'fx-input-edit')
    if (props.type === "date"){
      className = clsx(className,'fx-date-edit' )
    }
    const [inputType, setInputType] = useState(setType(props));

    const finalDefaultValue = useMemo(() => {
      if(!props.defaultValue || inputType !== "number" || !props.showDecimal) {
        return props.defaultValue;
      }
      return parseFloat(props.defaultValue).toFixed(2);
    }, [props.defaultValue, props.showDecimal, inputType]);

    function onFocus() {
      if (props.type === "date"  && props.label) setInputType("date")
    }
    function onBlur (e:any) {
      if(props.onBlur){
        props.onBlur(e.target.value)
      }
      const date = moment(e.target.value);
      //if valid date input given do not change the mod
      if (props.type === "date"  && props.label && !date.isValid()) setInputType("text")
      //put .00 format for amount
      if(e.target.value && inputType === "number" && props.showDecimal && props.setValue){
        props.setValue(props.name,parseFloat(e.target.value).toFixed(2))
      }
    }


     /**
     * stop changing value if type is number
     * @param event 
     */
     const handleWheel = (event: any) => {
      if (event.target.type === 'number') {
        event.target.blur();
        return;
      }
      if (typeof props.onWheel !== 'undefined') {
        props.onWheel(event)
      }

    };
    
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword = (event:any) => {
      setShowPassword(!showPassword)
    };
      const inputProps = () => {
          const propsObj: any = {};
          if (props.prefixIcon) {
              propsObj.startAdornment = <InputAdornment position="start">
                  <IconButton size="large">
                      {<props.prefixIcon />}
                  </IconButton>
                  {props.prefix}
              </InputAdornment>
          }
          if (props.prefix) {
              propsObj.startAdornment = <InputAdornment position="start">
                  {props.prefix}
              </InputAdornment>
          }

          if (props.max) {
              propsObj.max = props.max
          }
          if (props.notAllowFutureDate) {
              propsObj.max = moment(new Date()).format('YYYY-MM-DD')
          }
          if (props.notAllowPastDate) {
              propsObj.min = moment(new Date()).format(props.type === 'month' ? 'YYYY-MM' : 'YYYY-MM-DD')
          }
          if (props.suffixIcon) {
              propsObj.endAdornment = <InputAdornment position="end">
                  <props.suffixIcon></props.suffixIcon>
              </InputAdornment>
          }
          if (props.suffix) {
              propsObj.endAdornment = <InputAdornment position="end">
                  {props.suffix}
              </InputAdornment>
          }
          if (props.type === 'password' && props.passwordIcon) {
              propsObj.endAdornment = <InputAdornment position="end">
                  <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="large">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
              </InputAdornment>
          }
          if (props.min) {
              propsObj.min = props.min
          }
          if (props.maxLength) {
              propsObj.maxLength = props.maxLength
          }
          return propsObj;
      }

    return (
      <Controller
        name={props.name}
        control={props.control ? props.control : undefined}
        rules={props.rules ? props.rules : { required: false }}
        defaultValue={finalDefaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            {...props.register}
            variant={"outlined"}
            id={props.id}
            name={props.name}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={props.isEditable === false ? true : false}
            placeholder={props.placeholder ? props.placeholder : ""}
            className={className}
            type={showPassword === true ? 'text' : inputType}
            onFocus ={onFocus}
            onBlur ={onBlur}
            multiline={props?.rows? true : false}
            rows={props?.rows ? props?.rows : ''}
            label={props.label ? props.label : ''}
            value={(props.value === '' ? props.value : (props.value ? props.value : value))}
            onChange={props.onChange ? props.onChange : onChange}
            onKeyDown={props.onKeyDown && props.onKeyDown}
            onBeforeInput={(e: any) =>{props?.valuePattern?.test(e?.data) && e.preventDefault()}}
            inputRef={props.inputRef}
            error={error ? error as unknown as boolean : false}
            helperText={props.helperText ? props.helperText : ''}
            inputProps={props.inputProps ? props.inputProps : undefined}
            InputProps={inputProps()}
            onWheel={handleWheel}
          />

        )}

      />
    )
  }

  //For date type need to show place holder. date type will come during on focus event
  function setType(props:any){
    if(!props.type) return
    else{
      //do not change type if already have a data
      if(props.type === "date" && props.label && !props.defaultValue ){
        return "text"
      }
      else{
        return props.type
      }
    }
  }

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react';
import { Grid, CircularProgress, Divider } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { clean, renderStateError } from '../../Utils/CommonBaseClass';
import { useForm } from 'react-hook-form';
import { AUTH_STRINGS } from '../../../constants/strings';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';
import countries from '../../../libs/utils/country/country.json';
import usa_state from '../../Utils/usa_states.json';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import currencies from '../../Utils/currencies.json';
import FxMaterialMultipleSelect from '../../Input/FxSelect/FXMaterialMultipleSelect';
import { FxSwiftCode } from '../../Input/FxSwiftCode/FxSwiftCode';

const httpClient = HttpClient.getClient();
interface InputErrType {
    type: string;
    message: string;
}


Logger.debug("EditInternationalExternalaccountCard.tsx", "update external account initializing");
/**
 * Component: EditInternationalExternalaccountCard
 * Usage: Update International External Account
 */
export const EditInternationalExternalaccountCard: React.FC<any> = React.memo((props) => {
    // account purpose data
    const accountPurposeData = [
        { label: 'Consumer', value: 'CONSUMER' },
        { label: 'Corporate', value: 'CORPORATE' }
    ];

    // account type data
    const accountTypeData = [
        { label: 'Checking', value: 'CHECKING' },
        { label: 'Savings', value: 'SAVINGS' }
    ];

    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const { register, formState: { errors }, handleSubmit, setValue, control, watch } = useForm();
    const params = useParams<any>();
    const edit_eia_data = props?.eiadetails?.data;
    const eiaid = params.id;
    const contactId = params.contactId;
    let defaultCurrencies: any;

    // To add default currencies in required format
    if (edit_eia_data) {
        const selectAll = [];
        if(edit_eia_data?.acceptedCurrency?.length === currencies.length-1){
            selectAll.push({label: 'Select All', value: 'Select All'})
        }
        defaultCurrencies = edit_eia_data?.acceptedCurrency?.map((ele: any) => {
            return { label: ele, value: ele }
        });

        defaultCurrencies = [...selectAll, ...defaultCurrencies];
        setValue('swiftCode', edit_eia_data.swiftCode);
    }

    const [routingCodeDetails, setRoutingCodeDetails] = React.useState<any>();

    /**
     * reset the component on unmount.so that when redirect back page it will call the api again
     */
    useEffect(() => {
        if(!edit_eia_data){
            history.push(`/payee/id/${contactId}/internationalExternalAccount/view/${eiaid}`)
        }
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Edit International External Account **' + edit_eia_data?.accountNumberLast4, backButton: `/payee/id/${contactId}/internationalExternalAccount/view/${eiaid}` } } });
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [isloading, setIsloading] = React.useState(false);
    const submitButton = 'Save Changes';

    const history = useHistory();

    const renderNameError = (err: InputErrType): string => {
        if (err.type === 'maxLength') {
            return AUTH_STRINGS.ERRORS.NAME_INVALID_LENGTH;
        }
        return err.message;
    };

    /**
     * Method to redirect to previous page on clicking cancel button
     */
    const handleClose = () => {
        history.push(`/payee/id/${contactId}/internationalExternalAccount/view/${eiaid}`, { contact: { id: props?.eiadetails?.contactId } });
    };


    /**
     * Method to handle on submit request
     * @param data : form data
     */
    async function onSubmit(data: any) {
        let req: any = {};
        let status: any;
        req = updateEIARequest(data);
        const successMessage = 'International External Account Updated Successfully!';
        status = await updateEIA(clean(req));
        status = processAPIResponse(status);
        if (status.status) {
            FxSnackBar.show({
                autoHideDuration: 1000,
                severity: 'success',
                text: successMessage,
            });
            setTimeout(() => {
                history.push(`/payee/id/${contactId}/internationalExternalAccount/view/${eiaid}`, { contact: { id: props?.eiadetails?.contactId } });
            }, 1000)
        }
        else {
            FxSnackBar.show({
                text: status.message,
            });
        }
        setIsloading(false);

    }

    /**
     * Method to get the eia request
     * @param data
     * @returns
     */
    function updateEIARequest(data: any) {
        const object: any = {};
        const internationalExternalaccountRequest: any = {
            holderName: data.holderName,
            id: eiaid,
            purpose : data.purpose ? data.purpose : "",
            "holderAddress": {
                state: data.internationalCountry !== "US" ? data?.nonUnitedState ? data?.nonUnitedState: null : data?.unitedState ? data?.unitedState: null,
                country: data.internationalCountry,
                city: data.internationalCity,
                zip: data.internationalZip ? data.internationalZip : null,
                addressLine1: data.internationalAddressLine1,
                addressLine2: data.internationalAddressLine2,
            }
        }

        object.internationalExternalAccount = [internationalExternalaccountRequest];
        return object;
    }

    /**
     * Method to call api for update internationalExternalaccount
     * @param paylaoddata : request payload
     */
    async function updateEIA(paylaoddata: any) {
        try {
            return await httpClient.post(getCustomerUrl('contact/id/' + props?.eiadetails?.contactId, false), paylaoddata);
        } catch (err) {
            Logger.error("EditInternationalExternalaccountCard.tsx", "error", err);
            return err;
        }
    }

    return (
        <Grid container id="edit-payee-eia-main-grid" xs={12} className='fx-form-grid' >
            <Grid id="edit-payee-eia-first-grid" item xs={12} sm={8}>
                <Grid id="edit-payee-eia-sub-grid" container spacing={1} >

                    <Grid id="edit-payee-eia-second-grid" item xs={12}>
                    <div className="fx-form-edit-profile flex column">
                            {edit_eia_data && <><form id="edit-payee-eia-form" onSubmit={handleSubmit(onSubmit)}>
                                <FxCard id="edit-payee-eia-form-card" className="fx-theme-passport">
                                    <FxCardHeader id="edit-payee-eia-form-card-header">

                                    </FxCardHeader>
                                    <FxCardBody id="edit-payee-eia-form-card-body" className="fx-info-card fx-margin-top-reducer" >
                                        <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                            <>
                                                <Grid item xs={12} sm={6}>
                                                    <FxMaterialSelect
                                                        register={{ ...register("holderType") }}
                                                        control={control}
                                                        className={
                                                            errors.holderType
                                                                ? "border-error-input fx-input-edit"
                                                                : "fx-input-edit"
                                                        }
                                                        id="create-payee-iea-form-card-account-purpose"
                                                        data={accountPurposeData}
                                                        name="holderType"
                                                        readOnly={true}
                                                        label="Holder Type"
                                                        setValue={setValue}
                                                        value={ edit_eia_data?.holderType?.toUpperCase() }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FxTextEdit register={{ ...register("holderName") }} className={errors.holderName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="payee-eia-edit-holdername-textbox" label="Holder Name*" name="holderName" variant="outlined" defaultValue={edit_eia_data?.holderName ? edit_eia_data?.holderName : ''} />
                                                    <div className={'error-message'} >
                                                        {errors.holderName && renderNameError(errors.holderName)}
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <fieldset className="fx-container-create-schdule-summery">
                                                        <legend>Holder Address Details</legend>
                                                        <Grid item container xs={12} sm={12} spacing={2}>
                                                            <Grid item xs={12} sm={12}>
                                                                <FxTextEdit register={{ ...register('internationalAddressLine1') }} control={control} className={errors['internationalAddressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} id={'payee-eia-add-addressline1-textbox'} label="Address Line 1" name={'internationalAddressLine1'} defaultValue={edit_eia_data?.holderAddress?.addressLine1 ? edit_eia_data?.holderAddress?.addressLine1 : ''} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12}>
                                                                <FxTextEdit register={{ ...register('internationalAddressLine2') }} control={control} className={errors['internationalAddressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} id={'payee-eia-add-addressline2-textbox'} label="Address Line 2" name={'internationalAddressLine2'} defaultValue={edit_eia_data?.holderAddress?.addressLine2 ? edit_eia_data?.holderAddress?.addressLine2 : ''} />
                                                            </Grid>

                                                            <Grid item xs={12} sm={6}>
                                                                <FxTextEdit register={{ ...register("internationalCity") }} control={control} rules={{ required: true }} className={errors.internationalCity ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-iea-add-city-textbox" name="internationalCity" label="City*" defaultValue={edit_eia_data?.holderAddress?.city ? edit_eia_data?.holderAddress?.city : ''} />
                                                            </Grid>
                                                            {watch('internationalCountry') === "US" ? <Grid item xs={12} sm={6}>
                                                                <FxMaterialSelect register={{ ...register("unitedState") }} rules={{ required: false }} className={errors.unitedState ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-eia-add-state-textbox" name="unitedState" data={usa_state} label="State" value={edit_eia_data?.holderAddress?.state ? edit_eia_data?.holderAddress?.state : ''}
                                                                    setValue={setValue} />
                                                            </Grid> : <Grid item xs={12} sm={6}>
                                                                <FxTextEdit register={{ ...register("nonUnitedState") }} control={control} rules={{ required: false , maxLength: 3}} className={errors.nonUnitedState ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-eia-add-city-textbox" name="nonUnitedState" label="State" defaultValue={edit_eia_data?.holderAddress?.state ? edit_eia_data?.holderAddress?.state : ''} />
                                                                <div className={'error-message'}>{errors.nonUnitedState && renderStateError(errors.nonUnitedState)}</div>
                                                            </Grid>}
                                                            <Grid item xs={12} sm={6}>
                                                                <FxMaterialSelect register={{ ...register("internationalCountry") }} rules={{ required: true }} className={errors.internationalCountry ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-eia-add-country-textbox" name="internationalCountry" data={countries} label="Country*" value={edit_eia_data?.holderAddress?.country ? edit_eia_data?.holderAddress?.country : ''}
                                                                    setValue={setValue} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <FxTextEdit register={{ ...register("internationalZip") }} control={control} rules={{ required: false }} className={errors.internationalZip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-eia-add-zip-textbox" name="internationalZip" label="Zip Code" defaultValue={edit_eia_data?.holderAddress?.zip ? edit_eia_data?.holderAddress?.zip : ''} />
                                                            </Grid>
                                                        </Grid>
                                                    </fieldset>
                                                </Grid>
                                                <>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxLabelView id="customer-signup-individual-info-label-primary-payee-iea-bank" className="" >BANK ACCOUNT INFORMATION</FxLabelView>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} container spacing={2}>
                                                        <>
                                                            <Grid item container xs={12} sm={12} spacing={2}>
                                                                <Grid item xs={12} sm={6}>
                                                                    <FxMaterialSelect
                                                                        register={{ ...register("type") }}
                                                                        className={
                                                                            errors.type
                                                                                ? "border-error-input fx-input-edit"
                                                                                : "fx-input-edit"
                                                                        }
                                                                        rules={{ required: true }}
                                                                        control={control}
                                                                        id="create-payee-iea-form-card-account-type"
                                                                        name="type"
                                                                        data={accountTypeData}
                                                                        value={edit_eia_data?.type?.toUpperCase()}
                                                                        label="Account Type*"
                                                                        setValue={setValue}
                                                                        readOnly={true}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} className="fx-contact-user-card">
                                                                    <FxMaterialMultipleSelect name='acceptedCurrency'
                                                                        selectAllLabel={'Select All'}
                                                                        control={control}
                                                                        isEdit={props.isEdit ? true : false}
                                                                        id="iea-contact-form-acceptedcurrency"
                                                                        data={currencies}
                                                                        rules={{ required: true }}
                                                                        limitTags={4}
                                                                        readOnly={ true}
                                                                        label='Accepted Currency *'
                                                                        value={defaultCurrencies}
                                                                        canSearch={false}
                                                                        register={{ ...register("acceptedCurrency") }}
                                                                        setValue={setValue} />
                                                                </Grid>
                                                                <Grid item xs={12} sm={12}>
                                                                    <FxSwiftCode register={{ ...register("swiftCode") }} setValue={setValue} className={errors.swiftCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-eia-add-swift-number-textbox" label="Swift Code*" name="swiftCode" variant="outlined"
                                                                        defaultValue={edit_eia_data?.swiftCode || ""}
                                                                        isEditable={false} onSwiftCodeChange={(data: any)=> {setRoutingCodeDetails(data); edit_eia_data?.swiftCode && setValue('swiftCode', edit_eia_data.swiftCode)}} />
                                                                </Grid>
                                                                {routingCodeDetails?.internationalCodeCharacterLength !== 0 && routingCodeDetails?.internationalCodeLabel &&<Grid item xs={12} sm={12}>
                                                                        <FxTextEdit register={{ ...register("internationalRoutingCode") }} className={errors.internationalRoutingCode ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control}
                                                                            rules={{ required: false }}  id="iea-contact-add-routingcode-number-textbox"
                                                                            label={routingCodeDetails?.internationalCodeLabel || "International Routing Code"}
                                                                            name="internationalRoutingCode" variant="outlined" isEditable={false}
                                                                            defaultValue={edit_eia_data?.internationalRoutingCode ? edit_eia_data?.internationalRoutingCode : ''}
                                                                             />
                                                                    </Grid>}
                                                                <Grid item xs={12} sm={12}>
                                                                    <FxTextEdit register={{ ...register("accountNumber") }} className={errors.accountNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-eia-add-account-number-textbox" label="International Bank Account Number*" name="accountNumber" variant="outlined" defaultValue={edit_eia_data?.accountNumberLast4 ? '***' + edit_eia_data.accountNumberLast4 : ''} isEditable={false} />
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    </Grid>
                                                </>
                                                <Grid item xs={12} sm={12}>
                                                    <FxTextEdit register={{ ...register("purpose") }} className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="payee-eia-edit-purpose-textbox" label="Purpose" name="purpose" variant="outlined" setValue={setValue}
                                                        defaultValue={edit_eia_data?.purpose ? edit_eia_data?.purpose : ''} />
                                                </Grid>

                                            </>
                                            <Grid item xs={12}>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </FxCardBody>
                                    <FxCardFooter id="edit-payee-eia-form-card-footer" className="fx-footer">
                                        <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                            <FxButton variant="contained"
                                                className="fx-button fx-button-cancel"
                                                id="payee-eia-edit-cancel"
                                                onClick={handleClose}>
                                                Cancel
                                            </FxButton>
                                            <span className="fx-padding-right-16" />
                                            <FxButton
                                                disableRipple={false}
                                                className="fx-button fx-button-theme"
                                                id={"payee-edit-eia-submit-button"}
                                                type="submit"
                                            >
                                                {isloading ? (
                                                    <CircularProgress
                                                        size={20}
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                ) : (
                                                    submitButton
                                                )}
                                            </FxButton>
                                        </Grid>
                                    </FxCardFooter>
                                </FxCard>

                            </form></>}
                        </div>
                    </Grid >
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container spacing={1}>
                    <Grid item xs={12} ></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
});
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { Divider, Grid, Typography } from '@mui/material';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { IFilterColumns, IFilterFields, IDataGridProps } from '../../../types/common.interfaces';
import { Filter } from '../../Data/Filter';
import { FxDivider } from '../../Data/FxDivider';
import { Logger } from '../../../libs/utils/logger';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { FxButton } from '../../Action/FxButton';
import { FxPaymentModeStatus } from '../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { commaSeperatedValueFromArray, updateComponentParams } from '../../Utils/CommonBaseClass';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import FxTextView from '../../Input/FxText/FxTextView';
import { useDispatch } from 'react-redux';
import { FxGridList } from '../../Data/FxGridList';
import { FaPhone } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { useIsMobileOrTabletScreen, useIsMobileScreen } from '../../../libs/utils/utils';

/**
 * Component: UserList
 * Usage: To display users list
 */

export const AuthorisedUserList: React.FC<any> = React.memo(() => {
  const dispatch = useDispatch();
  const src = {
    url: "/authorizedUser/list",
    method: "POST",
    data: {
      "pageNumber": 1,
      "pageSize": 25,
      "sortOptions": {
        "sortBy": "lastUpdatedOn",
        "sortOrder": "desc"
      },
      "criteria": {
        "filters": []
      }
    }
  }

  const column: IFilterColumns = {
    "title": "Select Columns to Show",
    "columns": [
      {
        "label": "ID",
        "enable": true,
        "fieldName": "id",
        "type": "fri://fieldtype/default",
        "default": true,
        "style": { "width": "7%" }
    },
      {
        "label": "Name",
        "enable": true,
        "fieldName": "fullName",
        "type": "fri://fieldtype/custom",
        "default": true,
      },
      {
        "label": "Email",
        "enable": true,
        "fieldName": "email",
        "type": "fri://fieldtype/default",
        "default": true,
      },
      {
        "label": "Phone",
        "enable": true,
        "fieldName": "mobilePhone",
        "type": "fri://fieldtype/phonenumber",
        "default": true,
      },
      {
        "label": "Status",
        "enable": true,
        "fieldName": "status",
        "type": "fri://fieldtype/status",
        "default": true,
        "style": { "width": "9%" }
      },
      {
        "label": "Last Updated On",
        "enable": true,
        "fieldName": "lastUpdatedOn",
        "type": "fri://fieldtype/date",
        "default": true
      },
      {
        "label": "Last Updated By",
        "enable": true,
        "fieldName": "lastUpdatedBy",
        "type": "fri://fieldtype/default",
        "default": true
      }

    ]
  };

  /**
  * useeffect to load title while loading page
  */
  useEffect(() => {
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Authorized Users' } } });
  }, [])


  /**
    * useEffect to reset the filter while unmount the page
    */
  useEffect(() => () => {
    updateComponentParams(dispatch, 'users-card-filter', { resetFilter: true });
  }, [dispatch]);

  const history = useHistory();
  /**
   * Method to redirect to user details page
   * @param event :event object - unused
   * @param data : user data
   */
  function redirectDetailsPage(event: any, data: any) {
    try
    {
    const url = data.url;
    const parts = url.split('/');
    const idValue = parts[parts.length-1];
    history.push(`/authorizedusers/view/${idValue}`);
    }
    catch(e){
      Logger.error('AuthorisedUserList.tsx','error',e)
    }
  }

  const datagridProps: IDataGridProps = {
    id: "users-card-users-list",
    column: column,
    pagination: true,
    pageNumber: 1,
    pageSize: 25,
  };

  const filterFields: IFilterFields = {
    "fields": [
      {
        "label": "First Name",
        "fieldName": "firstName",
        "type": "fri://fieldtype/textfield",
        "operator":"eq"
      },
      {
        "label": "Last Name",
        "fieldName": "lastName",
        "type": "fri://fieldtype/textfield",
        "operator":"eq"
      },
      {
        "label": "Email",
        "fieldName": "email",
        "type": "fri://fieldtype/textfield",
        "operator":"eq"
      },
      {
        "label": "Phone",
        "fieldName": "mobilePhone",
        "type": "fri://fieldtype/phoneeditwithoutcountrycode",
        "operator":"eq"
      },
    ]
  }
  /**
   * Method to handle add user
   */
  const handleAddUser = () => {
    history.push("/authorizedusers/add")
  };

  const isMobileOrTabScreen = useIsMobileOrTabletScreen();
  const isMobileScreen = useIsMobileScreen();

  /**
   * Method to transform data for list columns
   * */
  function transformUserList(data: any) {
    try {
      return data?.map((item: any) => {
        const modes = getModes(item);
        return {
          ...item,
          createdByUser: item.createdBy.username,
          lastUpdatedBy: item?.lastUpdatedBy?.username,
          status: 'ACTIVE',
          lastUpdatedInfo: <><FxDateTimeView  tooltip={true} value={item['lastUpdatedOn']} format={"MMM DD, yyyy"}></FxDateTimeView> By <FxTextView id={'fullName'} value={`${item['lastUpdatedBy']['username'] }`} tooltip={true}></FxTextView></>,
          fullName: <><span>{` ${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`} &nbsp;</span><FxPaymentModeStatus tooltip={true} id={'recurring-method'} noColor={true} value={modes} fill={false} className="fx-status"></FxPaymentModeStatus> </>,
          userTypeText: item.userType === 'API_USER' ? 'API User' : 'Portal User'
        }
      });
    }
    catch (e) {
      Logger.error("UserList.tsx", "error", e);
      return false;
    }
  }

  /**
      * @param data : create user grid mobile
      */
  const createUserGridItem = (item: any) => (
    <Grid item container xs={12} className='fx-grid-list-content'>
      <Grid item container xs={12} alignItems='center'>
        <Grid item container xs={12}>
          <Grid item xs={7} sm={6}>
            <Grid>
              <Typography variant='h3'> {` ${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`}</Typography> 
            </Grid>
          </Grid>
          <Grid item xs={5} sm={6}>
            <Grid>
              <Typography variant='h4'>ID: {item?.id}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} >
          <Grid item xs={7} sm={6}>
            <Grid>
            <Typography variant='h4' display='flex' alignItems='center'>{item?.email && <FiMail/>}&nbsp;  {isMobileScreen ?<span className={'fx-text-ellipsis'}>{item?.email}</span>  : item?.email}  </Typography>
            </Grid>
          </Grid>
          <Grid item xs={5} sm={6}>
            <Grid>
              <Typography variant='h4' display='flex' alignItems='center'>{item?.mobilePhone && <FaPhone/>}&nbsp; {item?.mobilePhone} </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item className='fx-grid-list-method'>
            <FxPaymentModeStatus tooltip={true} id={'recurring-method'} noColor={true} value={getModes(item)} fill={false} className="fx-status"></FxPaymentModeStatus>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          {item.modes && (
            <Grid item>
              <FxPaymentModeStatus
                tooltip={true}
                id={'user-method'}
                noColor={true}
                value={item.modes}
                fill={false}
                className="fx-status"
              ></FxPaymentModeStatus>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
  /**
    * @param data : Devider
    */
  const createUserDivider = () => (
    <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  );
  /**
  * @param data : User List Mobile
  * @returns : transformed data
  */
  const userListGridTransformerMobile = (data: any) => {
    const transformedData = data?.map((item: any) => ({
      description: createUserGridItem(item),
      divider: createUserDivider(),
      url: item?.url
    }));

    return transformedData || [];
    };

      const gridColumn: any = {
        "title": "Select Columns to Show",
        "columns": [
          {
            "enable": true,
            "fieldName": "description",
            "type": "fri://fieldtype/custom",
            "xs": "12",
            "alignItems": 'center'
          },
          {
            "enable": true,
            "fieldName": "divider",
            "type": "fri://fieldtype/custom",
            "xs": "12",
          }
    
        ]
      }
  /**
   * Setting the Beneficialowner and auth signatury
   * @param data
   * @returns
   */
  function getModes(data: any) {
    const methods:any =[];
    if (data?.isBeneficialOwner === true) {
      methods.push('BENF. OWNER');
    }
    if (data?.actAsAuthorizedSignatory === true) {
      methods.push('AUTH SIGNATORY');
    }
    return commaSeperatedValueFromArray(methods);

  }


  const permission = {
    list: {
      entity: "Authorized User",
      name: "User",
      operation: "View"
    },
    create: {
      entity: "Authorized User",
      name: "User",
      operation: "Create"
    }
  }

  return (

    <Grid id="users-first-grid" item xs={12}>
      <Grid container xs={12} >
        <FxCard id="users-card" className="fx-theme-passport">
          <FxCardHeader id="users-card-header" className="fx-card-header">
              <Grid id="users-card-header-filter-grid" item container xs={12} justifyContent={'flex-end'}  className="fx-inline-flex fx-action-block" spacing={2}>
                <Grid item>
                  <Filter id="users-card-filter" permissions={permission.list} field={filterFields} dataGridProps={datagridProps} />
                </Grid>
                <Grid item className="fx-action-block-divider">
                  <FxDivider />
                </Grid>
                <Grid item>
                  <FxButton permissions={permission.create} id="users-card-add-new-button" variant="contained" className="fx-button fx-button-theme fx-svg-theme" onClick={handleAddUser} startIcon={<AddIcon />} >ADD AUTH USER</FxButton>
                </Grid>
              </Grid>
          </FxCardHeader>

          <FxCardBody id="user-list-Card-Body" className="fx-card-body-no-border-no-shadow">
            <Grid id="users-card-body-grid" item xs={12}>
              {/* list content*/}
             {!isMobileOrTabScreen? <DataGrid id="users-card-users-list" permissions={permission.list} tableClass="fx-table-passport" rowClick={redirectDetailsPage} dataTransformer={transformUserList} source={src} column={column} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
          
            :<FxGridList id="users-card-users-list" source={src} permissions={permission.list} dataTransformer={userListGridTransformerMobile} column={gridColumn} rowClick={redirectDetailsPage} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />}
              </Grid>
          </FxCardBody>
        </FxCard>
      </Grid>

    </Grid>
  )
})

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/**
 * Component: CoOwnerList
 * Usage: For Co-Owner listing
 */
import React, { useEffect } from 'react'
import { Divider, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { ViewProfile } from '../Index/Passport/ViewProfile';
import { Method, commaSeperatedValueFromArray, updateComponentParams } from '../../Utils/CommonBaseClass';
import { IFilterColumns } from '../../../types/common.interfaces';
import { Logger } from '../../../libs/utils/logger';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { ReactComponent as confirmIcon } from '../../../assets/svg/set-as-primary-confirmation.svg';
import { FxButton } from '../../Action/FxButton';
import { FxPaymentModeStatus } from '../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import { getKey } from '../../../libs/utils/storageManager';
import { FxGridList } from '../../Data/FxGridList';
import { getCustomerUrl, useIsMobileScreen } from '../../../libs/utils/utils';
import { ReactComponent as ThreeDotMenu } from '../../../assets/svg/threedot-menu-icon.svg';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { FxConfirmationBox } from '../../Data/FxConfirmationBox';
import { FxStatus } from '../../Input/FxStatus/FxStatus';

interface ICoownerListProps {
    id: string;
    key: string;
    anchorEl: any;
    ownerId?: any
    data?: any;
    owner?:any;
    actionOptions?: any;
}
export const CoOwnerList: React.FC<ICoownerListProps> = React.memo((props) => {
    const dispatch = useDispatch();
    ({ props } = RegisterComponent(props));
    const open = Boolean(props?.anchorEl);
    const history = useHistory();
 
    useEffect(()=>{
        updateComponentParams(dispatch, 'confirm-set-as-primary', { open: false })
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Owners' } } });
        return ()=>{
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            updateComponentParams(dispatch, props?.id, { anchorEl: null, ownerId: null,owner:null});
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch])
    const src = {
        url: getCustomerUrl("/owner/list", true),
        method: "POST",
        data: {
            "pageNumber": 1,
            "pageSize": 25,
            "criteria": {
                "filters": [

                ]
            }
        }
    }

    // Contact list columns
    const column: IFilterColumns = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "label": "Name",
                "enable": true,
                "fieldName": "name",
                "type": "fri://fieldtype/custom",
                "default": true,
            },
            {
                "label": "Email",
                "enable": true,
                "fieldName": "email",
                "type": "fri://fieldtype/default",
                "default": true
            },
            {
                "label": "Phone",
                "enable": true,
                "fieldName": "mobilePhone",
                "type": "fri://fieldtype/default",
                "default": true
            },
            {
                "label": "Ssn",
                "enable": true,
                "fieldName": "ssn",
                "type": "fri://fieldtype/default",
                "default": true
            },
            {
                "label": "ITIN",
                "enable": true,
                "fieldName": "itin",
                "type": "fri://fieldtype/default",
                "default": true
            },
            {
                "label": " ",
                "enable": true,
                "fieldName": "actions",
                "type": "fri://fieldtype/custom",
                "default": true,
                "style": { "width": "4rem" }
            },
        ]
    };

    const gridColumn: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "enable": true,
                "fieldName": "description",
                "type": "fri://fieldtype/custom",
                "xs": "12",
                "alignItems": 'center'
            },
            {
                "enable": true,
                "fieldName": "divider",
                "type": "fri://fieldtype/custom",
                "xs": "12",
            }
        ]
    }

    /**
     * Handles redirect page to co owner add
     */

    function redirectToCreatePage() {
        history.push('/co-owner/add');
    }


    const isSmallScreen = useIsMobileScreen();
    /**
     * Method to transform data
     * @param data : contact data
     */
    function transformOwnerList(data: any) {
        try {
            const newData: any = [];

            // eslint-disable-next-line array-callback-return
            data?.map((item: any, i: number) => {
                const modes = getModes(item);
                newData[i] = { ...data[i] }
                if (item) {
                    newData[i].name = getFullName(item, modes);
                    newData[i].email = item?.email;
                    newData[i].phone = item?.mobilePhone;
                    newData[i].ssn = item?.last4ssn ?'***' + item?.last4ssn : '';
                    newData[i].itin = item?.taxIdentification?.id || item?.taxIdentification?.idLast4 ? '***' + item?.taxIdentification?.idLast4 : '';
                    newData[i].lastUpdated = item?.lastUpdatedBy?.username
                    newData[i].actions = getOwnerActions(item)
                }
            });
            return newData;
        }
        catch (e) {
            Logger.error("CoOwnerList.tsx->transformOwnerList", "error", e);
            return false;
        }
    }
    /**
  * Setting the Beneficialowner and auth signatury
  * @param data
  * @returns
  */
    function getModes(data: any) {
        const methods: any = [];
        if (data?.isPrimaryOwner === true) {
            methods.push('PRIMARY');
        }

        return commaSeperatedValueFromArray(methods);

    }
    /**
     * method to fet the full name
     * @param item 
     * @param modes 
     * @returns 
     */
    function getFullName(item: any, modes: any) {
        return <><span>{` ${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`} &nbsp;</span><FxPaymentModeStatus tooltip={true} id={'co-owner-fullname'} noColor={true} value={modes} fill={false} className="fx-status"></FxPaymentModeStatus> </>
    }


    /**
          *  Handling the click event
          *  */
    const handleClick = (event: any, item: any) => {
      const actionOptions = [{
        id: 'owner-view-option',
        title: 'View'
      },
        {
          id: 'owner-primary-option',
          title: 'Set as Primary Owner'
        }
      ].filter((option: any) => !(option.id === 'owner-primary-option' && item?.isPrimaryOwner));
        updateComponentParams(dispatch, props?.id, { 'ownerId': item?.id, 'anchorEl': event.currentTarget, owner: item, actionOptions})

    };
    /**
     * Method handles the change event of the menu click
    * @param event
     */
    const handleChange = (event: string) => {
        try {

            const eventHandlers: Record<string, () => void> = {
                'View': () => history.push(`/co-owner/details/${props?.ownerId}`),
                'Set as Primary Owner': () => {
                    openSetAsPrimary(true); updateComponentParams(dispatch, props?.id, { 'anchorEl': null, ownerId: props?.ownerId, actionOptions: null})
                },
            };

            const eventHandler = eventHandlers[event];
            eventHandler?.(); // Execute the corresponding event handler if it exists

        } catch (error) {
            Logger.error("CoOwnerList.tsx -> handleChange", "error", error);
        }
    };

    /**
      * close the delete popup
      */
    const handleClose = () => {
        updateComponentParams(dispatch, props?.id, { 'anchorEl': null })
    };
    /**
         * Method handles used to get Co-Owner action.
         * @param item
         */
    const getOwnerActions = (item: any) => {
      return (<Grid id="filter-Grid" className="fx-view-action" >
            {
                <>
                    <FxButton
                        aria-label="more"
                        id={props.id + '_' + item?.id + '-button'}
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        className="fx-button fx-button-dotted fx-button-dotted-icons"
                        onClick={(event: any) => { handleClick(event, item) }}
                    >
                        <ThreeDotMenu />
                    </FxButton>
                    {<Menu
                        id="confirm-modal-info-menu"
                        MenuListProps={{
                            'aria-labelledby': 'confirm-modal-info-menu',
                        }}
                        anchorEl={props.anchorEl}
                        open={Boolean(props.anchorEl)}
                        onClose={handleClose}

                        className="fx-menu-dotted fx-list-action-menu"
                    >
                      {props.actionOptions?.map((option: any) => (
                        <MenuItem id={option.id} key={option.id} onClick={() => {handleChange(option.title)}}>
                          <div>{option.title}</div>
                        </MenuItem>
                      ))
                      }
                    </Menu>}
                </>}
        </Grid>)
    }
   /**
    * Method for mobile list
    * @param item 
    * @param modes 
    * @returns 
    */
    const getOwnerListMobile = (item: any, modes: any) => (

        <Grid item container xs={12} className='fx-grid-list-content'>
            <Grid item container xs={12} alignItems='center'>
                <Grid item container xs={12}>
                    <Grid item xs={7} sm={6}>
                        <Grid className='fx-info-label-value'>
                            {getFullName(item, modes)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={7} sm={6}>
                        <Grid className='fx-info-label-value'>
                            <Typography> {item?.email}</Typography>
                        </Grid>
                    </Grid>
                   {item?.last4ssn && <Grid item xs={7} sm={6}>
                        <Grid className='fx-info-label-value'>
                            <Typography> {`***${item?.last4ssn}`}</Typography>
                        </Grid>
                    </Grid>}
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={5} sm={6}>
                        <Grid>
                            {item?.status && <FxStatus id="invest-instruction-status" value={item?.status} fill={true} className="fx-status"></FxStatus>}
                        </Grid>
                    </Grid>
                    <Grid item xs={5} sm={6}>
                        <Grid>
                            <Typography> {item?.name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={1} alignItems='center'>
                        {getOwnerActions(item)}
                    </Grid>

                </Grid>

            </Grid>
        </Grid>
    );

    /**
    * Divider
    */
    const createOwnerListDivider = () => (
        <Grid item container xs={12} alignItems={'center'} className='fx-divider-grid-list'>
            <Grid item xs>
                <Divider />
            </Grid>
        </Grid>
    );

    /**
     * Method to transform data for mobile screen
    * @param data: Contact list
    * @returns :Grid Transformed data
    */
    const transactionHistoryGridTransformer = (data: any) => {
        const transformedData = data?.map((item: any) => ({
            description: getOwnerListMobile(item, getModes(item)),
            divider: createOwnerListDivider(),
            url: item?.url
        }));

        return transformedData || [];
    };
    /**
     * Method for set as primary owner
     * @param open 
     */
    function openSetAsPrimary(open: boolean) {
        updateComponentParams(dispatch, 'confirm-set-as-primary', { open: open })
    }

    const isAddDisabled = getKey("accountStatus") === 'true';

    return (

        <Grid id="co-owner-grid" item xs={12}>
            <Grid container className="fx-top-header" id="co-owner-top-grid" item justifyContent="space-between" alignItems="center" xs={12} >
                <Grid item><h3 >Owners</h3></Grid>
                <ViewProfile />
            </Grid>
            <Grid container xs={12}>
                <FxCard id="co-owner-card" className="fx-theme-passport">
                    <FxCardHeader id="co-owner-card-header" className="fx-card-header">
                        <Grid id="co-owner-card-header-first-grid" item container justifyContent="space-between" xs={12} >
                            <Grid id="co-owner-card-header-second-grid">
                                <Grid item>
                                  <h3 className={'fx-margin-y-0'}>Owners</h3>
                                </Grid>
                            </Grid>
                          <Grid id="co-owner-filter-grid" item container spacing={2} className="fx-action-block fx-inline-flex">
                            <Grid item>
                              <FxButton id="co-owner-add-button" disabled={isAddDisabled} className={isAddDisabled ? 'fx-button fx-button-disabled fx-svg-theme' :'fx-button fx-button-theme fx-svg-theme'} variant="contained"  onClick={redirectToCreatePage} startIcon={<AddIcon />} >Add New</FxButton>
                            </Grid>
                          </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="co-owner-card-body" className="fx-card-body-no-border-no-shadow">
                        <Grid id="co-owner-card-body-grid" item xs={12}>
                            {
                                !isSmallScreen ? <DataGrid id="co-owner-card-list"  tableClass="fx-table-passport" dataTransformer={transformOwnerList} source={src} column={column} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} /> :
                                    <FxGridList id="co-owner-card-list" source={src}  dataTransformer={transactionHistoryGridTransformer} column={gridColumn} pagination={true} pageNumber={1} pageSize={25} resultPerPage={25} />
                            }

                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid>
          <FxConfirmationBox resetFormId={'co-owner-card-list'}
                             id={'confirm-set-as-primary'}
                             open={open}
                             ownerFullName={`${props.owner?.firstName ? props.owner.firstName : ''} ${props.owner?.lastName ? props.owner.lastName : ''}`}
                             confirmationMessage={`Are you sure you want to make ${props?.owner?.firstName} ${props?.owner?.lastName} a Primary Owner?`}
                             ConfirmIcon={confirmIcon}
                             description={'Note: All the communication related to Passport Account will be sent to the email of the Primary Owner.'}
                             apiUrl={{
                               url: 'customer/id/' + getKey('customerId'),
                               method: Method.POST,
                               payload: {
                                 owners: [{
                                   id: props?.ownerId,
                                   isPrimaryOwner: true,
                                   portalAccess: { grantAccess: true },
                                   email: props?.owner?.email
                                 }]
                               }
                             }}
                             successText="The owner is set as primary"/>
        </Grid>
    )
})

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { Logger } from '../../../libs/utils/logger';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { updateComponentData, viewSsnItinNode } from '../../Utils/CommonBaseClass';

/**
 * Component used to view customer basic Infromations
 */
Logger.debug("CustomerInfoCard.tsx", "Customer profile initializing")

export const CustomerInfoCard: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        let userInfo: any = {};
        let ssnItinNode = [{}];

        /**
         * Getting data from context and setting values in variables
         */
        if (context && context.data && context.data.body) {
            userInfo = { ...context.data.body };
            userInfo.last4ssn = userInfo.last4ssn && "***" + userInfo.last4ssn;
            userInfo.fullName = (userInfo.firstName ? userInfo.firstName : '') + ' ' + (userInfo.middleName ? userInfo.middleName : '') + ' ' + (userInfo.lastName ? userInfo.lastName : '');
            ssnItinNode = viewSsnItinNode('itin', userInfo?.taxIdentification?.idLast4 || userInfo?.taxIdentification?.id, true)
            userInfo.userType = userInfo.userType ? userInfo.userType : 'Portal User';
            userInfo.createdInfo = userInfo['createdBy']['username'] + '\nOn ' + moment(userInfo['createdOn']).format('MM/DD/YYYY');
            userInfo.updatedInfo = userInfo['lastUpdatedBy']['username'] + '\nOn ' + moment(userInfo['lastUpdatedOn']).format('MM/DD/YYYY');
            if(userInfo?.mailingAddress?.isPrimary === true){
                userInfo.authAddressFull = userInfo?.mailingAddress?.addressLine1 + ', ' + (userInfo?.mailingAddress?.addressLine2 ? userInfo?.mailingAddress?.addressLine2 + ', ' : '') + userInfo?.mailingAddress?.city + ', ' + userInfo?.mailingAddress?.state + ', ' + userInfo?.mailingAddress?.zip;
            }
            userInfo['itin'] = userInfo?.taxIdentification?.id || userInfo?.taxIdentification?.idLast4 ? '***' + userInfo?.taxIdentification?.idLast4 : '';
        }
       
        const customer_left_section = [
            {
                label: 'Name',
                value: 'fullName'
            },
            {
                label: 'Username',
                value: 'userName'
            },
            {
                label: 'DOB',
                value: 'dob',
                type: 'fri://fieldtype/date',
              
            },
            {
                label: 'Primary Address',
                value: 'authAddressFull'
            }
        ]
        const customer_right_section = [
            {
                label: 'Status',
                value: 'status',
                type: 'fri://fieldtype/status'
            },
            ...ssnItinNode,
            {
                label: 'User Type',
                value: 'userType'
            },
        ]
        const audit_fields_left_section = [
            {
                label: 'Created',
                value: 'createdInfo'
            }
        ]
        const audit_fields_right_section = [
            {
                label: 'Last Updated',
                value: 'updatedInfo'
            }
        ]

        const dispatch = useDispatch()
        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'User Profile',backButton:'/dashboard' } }});
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        /**
         * Useeffect hook updates the data to the 'communication-preference-data' state when the context?.data?.body changes
         */
        useEffect(() => {
            if(context?.data?.body){
                updateComponentData(dispatch, 'communication-preference-data', context?.data?.body )
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[context?.data?.body])


        //check api call completed or not
        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
            <Grid id="customer-profile-user-information-grid" item xs={12}>
                <FxCard id="customer-profile-user-information-card" className="fx-theme-passport">
                    <FxCardHeader id={'customer-profile-user-information-card-header'} >
                        <Grid item container justifyContent="space-between" xs={12} >
                            <Typography id="customer-profile-user-card-title" variant="h4" >
                                User Information
                            </Typography>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="customer-profile-user-information-card-body" className="fx-info-card fx-margin-top-reducer" >
                        <Grid container item xs={12} direction="row" >
                            {dataLoadStatus ?
                                (context?.data?.body && <Grid item xs={12}>
                                <FxInfoCard id="customer-profile-user-information-card-customer-one-section-info-card" title="BASIC INFORMATION" leftcolumn={customer_left_section} rightcolumn={customer_right_section} data={userInfo} ></FxInfoCard>
                                <FxInfoCard id="customer-profile-user-information-card-customer-three-section-info-card" title="AUDIT FIELDS" leftcolumn={audit_fields_left_section} rightcolumn={audit_fields_right_section} data={userInfo} ></FxInfoCard>
                                </Grid>)
                                    :
                                <FxSkeltonList height="25rem" />
                            }
                        </Grid>
                    </FxCardBody>
                </FxCard>
            </Grid >
        )
    })

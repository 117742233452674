/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react"; // we need this to make JSX compile
import { Logger } from "../../../libs/utils/logger";
import { ISource } from '../../../types/common.interfaces';
import { useForm } from "react-hook-form";
import { FxTextEdit } from "../../Input/FxText/FxTextEdit";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { accountLabelTransformation } from "../Schedules/ScheduleGlobalFunctions";
import FxSnackBar from "../../Utils/fx-snack-bar";
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxButton } from '../../Action/FxButton';
import FxCardHeader from '../../Container/FxCardHeader';
import { FxCriteraForm } from "../Contacts/FxCriteriaForm";
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { FxSkeltonList } from "../Cards/FxSkelton";
import HttpClient from "../../../libs/utils/httpClient";
import { CircularProgress, Divider, Grid } from "@mui/material";

Logger.debug(
    "CreateApprovalPolicy.tsx",
    "create approval policy"
);

const acc_src: ISource = {
    url: "account/list",
    method: "POST",   
    data: {

        "pageSize": 25,
        "pageNumber": 1,
        "sortOptions": {
            "sortOrder": "DESC",
            "sortBy": "createdOn"
        },
        "criteria": {
            "filters": [
                {
                    "key": "status",
                    "operator": "nin",
                    "values": [
                        "INACTIVE"
                    ]
                }
            ]
        }
    }
};
/**
 * This component handles the creation of approval policy 
 */
export const CreateApprovalPolicy: React.FC<any> = React.memo((props) => {
    let context: any;
    ({ context, props } = RegisterComponent(props));
    const dispatch = useDispatch();
    const history = useHistory();
    const httpClient = HttpClient.getClient();
    const [isDataLoaded, setIsDataLoaded] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(props.source ? true : false);
    const [isloading, setIsloading] = useState(false);
    const { register, formState: { errors }, handleSubmit, setValue, control } = useForm();
    let policyId:any;

    const [forms, setForms] = useState([
        {
          id: 1,
          criteria: undefined,       
          isEdit:true
        },
    ]);

    const [authorisedUserList, setAuthorizedUserList] = React.useState([])
    const [methodFilterList, setMethodFilterList] = useState([]);

    const getAuthorizedUserName = (id: any) => {
        const authUser : any = authorisedUserList?.filter((item:any) => item.id === id)[0]
        return `${authUser?.firstName} ${authUser?.lastName} (ID: ${authUser.id})`;
    }

    useEffect(()=> {
        if (context?.data?.body && !isDataLoaded && authorisedUserList.length>0) {
            setValue('destination',context?.data?.body?.account?.id)
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: context?.data?.body?.policyName, backButton: '/approvalpolicy/view/'+context?.data?.body?.id } } });
            setIsDataLoaded(true);
            setIsEdit(true);
            const updatedForms =context?.data?.body?.policyDetails?.map((item:any,index:any)=>{
                return {
                    id: index + 1,
                    data : {  
                        applicableToMethod: item?.criteria?.values[0],
                        policy: item?.condition?.map((con:any)=>{
                            return {
                                condition: con.filter.operator.toUpperCase(),
                                amount: con.filter.values[0],
                                fromAmount: con.filter.values[0],
                                toAmount: con.filter.values[1],
                                approver: con.approver.authorizedUser.map((authUser:any)=>{
                                    return {
                                        label:getAuthorizedUserName(authUser.id),
                                        value:authUser.id
                                    }
                                })
                            }
                        })
                    },
                    criteria : {  
                        applicableToMethod: item?.criteria?.values[0],
                        policy: item?.condition?.map((con:any)=>{
                            return {
                                condition: con.filter.operator.toUpperCase(),
                                amount: con.filter.values[0],
                                fromAmount: con.filter.values[0],
                                toAmount: con.filter.values[1],
                                approver: con.approver.authorizedUser.map((authUser:any)=>{
                                    return {
                                        label:getAuthorizedUserName(authUser.id),
                                        value:authUser.id
                                    }
                                })
                            }
                        })
                    }
                }
            })
            setForms(updatedForms);
        }
    },[context?.data?.body, authorisedUserList]);

    /**
     * Function to be called before loading the component
     */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'create-approval-destination' } });
        if(!props?.source){
            dispatch({
                type: "DATA_UPDATE_COMPONENT_REDUCER",
                payload: {
                    id: "page-title-details",
                    data: { title: "Approval Policy", backButton: "/approvalpolicy" },
                },
            });
        }
        getAuthorisedUserList();
    }, []);

    /**
     * Method to get Authorized Users List
     */
    const getAuthorisedUserList = async () => {
        try {
            const payloadData = {
                "pageNumber": 1,
                "pageSize": 25,
                "sortOptions": {
                  "sortBy": "lastUpdatedOn",
                  "sortOrder": "desc"
                },
                "criteria": {
                  "filters": []
                }
            }
            await httpClient.post(getCustomerUrl('/authorizedUser/list'),payloadData).then(response => {
                setAuthorizedUserList(response?.data?.resources)
            });
        } catch (e) { 
            Logger.error('CreateApprovalPolicy.tsx','error',e);
        }
    }

    /**
     * Method to call api  for create user
     * */
    async function createApprovalPolicy(paylaoddata: any) {
        try {
            const data: any = await httpClient.post(getCustomerUrl('approvalPolicy',false), paylaoddata)

            return data;
        } catch (err) {
            Logger.error("CreateApprovalPolicy.tsx", "error", err);
            return err;
        }
    }
    
    /**
     * Method to call api for edit approval policy
     * */
    async function updateApprovalPolicy(paylaoddata: any) {
        try {
            const data: any = await httpClient.post(getCustomerUrl('approvalPolicy/id/' + context?.data?.body?.id,false), paylaoddata)
            return data;
        } catch (err) {
            Logger.error("CreateUser.tsx", "error", err);
            return err;
        }
    }

    /**
     * Function to create request for Approval Policy
     * @param forms any
     */
    const policyDetailsRequest = (forms: any) => {
        let policyDetails = []
        policyDetails =  forms && forms?.map((policy: any)=> {
            const req = {
                "criteria": {
                    "operator": "eq",
                    "key": "method",
                    "values": [ policy?.criteria?.applicableToMethod || '' ]
                },
                "condition": criteriaRequest(policy?.criteria?.policy)
            }
            return req;
        })
        return policyDetails;
    }

    /**
     * Function to create criteria request for Approval Policy
     * @param forms any
     */
    const criteriaRequest = (forms: any) =>{
        let criteriaReq = [];

        criteriaReq =  forms?.map((criteria: any)=> {
             const req = {
                "filter": {
                    "operator":criteria?.condition,
                    "key": "amount",
                    "values": criteria?.condition === 'IN' ? [criteria?.fromAmount, criteria?.toAmount] : [ criteria?.amount || '' ]
                },
                "approver": {
                    "authorizedUser": criteria?.approver?.map((item:any)=>{
                        return {
                            "id": item.value
                        }
                    })
                }
             }
             return req;
        });

        return criteriaReq;
    }

    /**
     * Async function handles the on submit of form
     * @param data : form request
     */
    async function handleSavePolicy(data: any) {
        
        let createReq: any = {};
        let updateReq: any = {};
        let status: any;

        setIsloading(true);
        createReq = {
            "name": data.name,
            "account": {
                "id": data.destination
            },
            "policyDetails": policyDetailsRequest(forms)
        }
        updateReq = {
            "name": data.name,
            "policyDetails": policyDetailsRequest(forms)
        }

        if (context?.data?.body) {
            status = await updateApprovalPolicy(updateReq);
        } else {
            status = await createApprovalPolicy(createReq);
        }
        if (status?.headers?.url) {
            policyId = (status.headers.url).split('/').pop();
        }
        setIsloading(false);
        status = processAPIResponse(status)

        if (status.status) {
            // api success
            if (context?.data?.body) {
                history.push('/approvalPolicy/view/' + context.data.body.id)
            } else {
                setTimeout(() => {
                    history.push("/thanks/manage/approvalpolicy/" + policyId);
                }, 400)
            }
        }
        else {
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
        }
    }

    /**
     * Function to handle Close Button of Create/Edit Screen 
     */
    const handleClose = () => {
        if (context?.data?.body) {
            history.push("/approvalpolicy/view/" + context.data.body.id);
        } else {
            history.push("/approvalpolicy");
        }
    };

     /**
     * Function to add new criteria in Approval Policy
     */
    const handleAddField = () => {
        const newForm = {
            id: forms.length + 1,
            criteria: undefined ,
            isEdit:true
        };
    
        setForms([...forms, newForm]);
        getMethods(forms);
    };

     /**
     * Function for cloning an existing criteria 
     */
    const handleCriteriaclone = (data:any) => {
        
        const updatedfilteredData = data?.policy?.map((item:any)=>{
            return {
                ...item,
                approver: undefined
            }
        })
        const newForm = {
            id: forms.length + 1,
            criteria: {
                ...data,
                policy:updatedfilteredData,
                applicableToMethod : undefined
            }, 
            isEdit:false
        };
        setForms([...forms, newForm]);
    };

    /**
     * Function for delete a criteria
     */
    const handleCriteriaDelete = (id:any) => {
        let updatedForms = forms.filter((form) => form.id !== id);
        updatedForms = updatedForms.map(form=>{
            if(form.id>id){
                return {
                    ...form,
                    id: form.id -1,
                }
            }else{
                return form
            }
        })
        setForms(updatedForms); 
    }

    /**
     * Function for edit a criteria
     */
    const handleCriteriaEdit = (id:any) => {
        const updatedForms = forms.map(form=>{
            if(form.id === id){
                return {
                    ...form,                   
                    isEdit: true
                }
            }else{
                return form
            }
        })
        setForms(updatedForms); 
        getMethods(updatedForms); 
    }

    /**
     * Function to add criteria details     
     */
    const handleAddForm = (data:any,id:any) => {
        const updatedForms = forms.map((form) => {
            if (form.id === id) {
                return {
                    ...form,
                    criteria: data,
                    isEdit: false
                }
            } else {
                return form;
            }
        });
        setForms(updatedForms);
        getMethods(updatedForms);
    };

      /**
     * Function to cancel criteria details     
     */
      const handleCancel = (id:any) => {
        const updatedForms = forms.map((form) => {
            if (form.id === id) {
                return {
                    ...form,                   
                    isEdit: false
                }
            } else {
                return form;
            }
        });
        setForms(updatedForms);        
    };

    /**
     * Function transforms data for source account
     * @param data : data
     * @returns : transformed data
     */
    function dataDestinationTransformation(data: any) {
        return data?.filter((option: any) => option.status === 'ACTIVE' && option.type !== 'DEPOSIT')
            .map((item:any) => ({ value: item.id, label: accountLabelTransformation(item) }));
    }
    
    /**
     * Method to get already saved Criteria Methods
     * @param updatedForms 
     */
    const getMethods = (updatedForms:any)=> {
        const methods:any = updatedForms.map((form:any)=> {
            return !form.isEdit && form?.criteria?.applicableToMethod;
        })
        setMethodFilterList(methods);
    }

    return (
        <Grid item xs={12} className="fx-create-approval-policy">
            <FxCard className="fx-schedule-card-general fx-theme-passport fx-no-box-shadow">
                {!isDataLoaded && isEdit && <FxSkeltonList height="40rem" />}
                {(isDataLoaded || !isEdit) && authorisedUserList.length>0 && <div className="fx-form-edit-profile flex column">
                    <form id="create-approval">
                        <FxCardBody className="fx-info-card">
                            <Grid container direction="row" justifyContent="space-between" className="flex column fx-container-create-schdule" >
                                <Grid item xs className='fx-container-create-schdule-middle-scroll fx-container-create-schdule-left-layout'>
                                    <FxCard id="create-approval-form-card">
                                        <FxCardHeader id="create-approval-form-card-header" >
                                            Basic Info
                                        </FxCardHeader>
                                        <FxCardBody id="create-approval-form-card-body" className="fx-info-card" >
                                            <Grid container spacing={2} className="fx-form-edit-profile" >
                                                <Grid container item direction="row" spacing={2} className="fx-form-edit-profile" >
                                                    <Grid item xs={6}>
                                                        <FxTextEdit 
                                                            register={{ ...register("name") }} 
                                                            className={errors.name ? "border-error-input fx-input-edit" : "fx-input-edit"} 
                                                            control={control} 
                                                            rules={{ required: true }} 
                                                            id="create-approval-form-card-first-name-textbox" 
                                                            label="Name*" 
                                                            name="name" 
                                                            variant="outlined" 
                                                            defaultValue={context?.data?.body?.policyName ? context.data.body.policyName : ''} 
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FxMaterialSelect
                                                            control={control}
                                                            rules={{ required: true }}
                                                            register={{ ...register("destination") }} 
                                                            id="create-approval-destination"
                                                            name="destination"
                                                            dataTransformation={dataDestinationTransformation} 
                                                            source={acc_src}
                                                            label="Account*"
                                                            setValue={setValue} 
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container item direction="row" spacing={2} className="fx-form-edit-profile" >
                                                    <Grid item xs={12} sm={12}>
                                                        {forms.map((form) => (
                                                            <Grid item xs={12} sm={12} paddingBottom={'2rem'}>
                                                                <FxCriteraForm
                                                                    methodsFilter={methodFilterList}
                                                                    authorisedUserList={authorisedUserList}
                                                                    id={form.id}
                                                                    isEdit={isEdit}
                                                                    form={form}   
                                                                    formCount={forms.length}                                                                 
                                                                    onSubmit={handleAddForm}                                                                    
                                                                    handleClone={handleCriteriaclone}
                                                                    handleDelete={handleCriteriaDelete}
                                                                    handleEdit={handleCriteriaEdit}  
                                                                    handleCancel={handleCancel}                                                                 
                                                                />
                                                            </Grid>
                                                        ))}
                                                        { forms.length <6 && <FxButton
                                                            disableRipple={false}
                                                            className="fx-button fx-button-theme"
                                                            id="add-criteria"
                                                            startIcon={<AddIcon />}
                                                            onClick={handleAddField}
                                                        >
                                                            Add New
                                                        </FxButton>}
                                                    </Grid>
                                                </Grid>
                                            </Grid>    
                                        </FxCardBody>
                                        <FxCardFooter id="create-approval-form-card-footer" className="fx-footer">
                                            
                                            <Grid container direction="row" justifyContent="flex-end" className="" paddingTop={'2rem'}>
                                                <Divider/>
                                                <FxButton variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="create-approval-form-card-cancel-button"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </FxButton>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme"
                                                    id={"create-approval-policy-btn"}
                                                    onClick={handleSubmit((data) => handleSavePolicy(data))}
                                                >
                                                    {isloading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    ) : (
                                                        'SAVE POLICY'
                                                    )}
                                                    
                                                </FxButton>
                                            </Grid>
                                        </FxCardFooter>
                                    </FxCard>
                                </Grid>
                            </Grid>
                        </FxCardBody>

                        <Grid
                            container
                            xs={12}
                            id={`form-card-footer`}
                            className='fx-card-footer-mobile'
                            justifyContent='space-around'
                        >

                        </Grid>
                    </form>
                </div>}
            </FxCard>
        </Grid>
    );
});

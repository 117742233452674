/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useParams } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { toTitleCase } from "../../../libs/utils/string";
import { FxDelete } from '../../Data/FxDelete';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FxButton } from '../../Action/FxButton';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getEAOrAddressDetailsFromContact } from '../../Utils/CommonBaseClass';
import { FxRoutingCodeLabel } from '../../Input/FxSwiftCode/FxRoutingCodeLabel';


Logger.debug("InternationalEADetailsCard.tsx", "International External Account details initializing");
/**
 * Component: EIADetailsCard
 * Usage: To display international external account information
 */

export const InternationalEADetailsCard: React.FC<any> = React.memo((props) => {
    //#region Varables Declarations
    const history = useHistory();
    const dispatch = useDispatch();
    let context: any;
    ({ context, props } = RegisterComponent(props));
    let contact_data: any;
    let international_external_account_data: any;
    const params = useParams<any>();
    const contactId = params?.contactId;
    const id = params.id
    const edit: boolean = true;

    const componentstate = useSelector((state: any) => {
        if (state.data['eia-details-info-card']) {
            return state.data['eia-details-info-card']
        } else {
            return { config: { params: {} } };
        }
    });

    if (context && context?.data && context?.data?.body) {
        contact_data = { ...context.data.body };
        if (contact_data?.internationalExternalAccount) {
            international_external_account_data = { ...getEAOrAddressDetailsFromContact(contact_data, "EIA", id, false) };
        }
        if (international_external_account_data?.accountNumberLast4) {
            international_external_account_data.accountNumber = '** ' + international_external_account_data?.accountNumberLast4;
            international_external_account_data.accountNumberTitle = '** ' + international_external_account_data?.accountNumberLast4
        }

        international_external_account_data.holderType = international_external_account_data?.holderType ? toTitleCase(international_external_account_data?.holderType) : '';
        international_external_account_data.type = international_external_account_data?.type ? toTitleCase(international_external_account_data?.type) : '';
    }

    /**
     * Method to rest the component on unmount.so that when redirect back page it will call the api again
     */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'International External Account ' + (international_external_account_data && international_external_account_data?.accountNumberTitle ? international_external_account_data?.accountNumberTitle : ''), backButton: '/payee/view/' + componentstate?.data?.body?.id } } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [international_external_account_data])

    /**
     * Based on country recevied in  edit_eia_data the routinglabel will be shown
     */
    const swiftCode = useMemo(() => {
        if (international_external_account_data?.swiftCode) {
            return international_external_account_data?.swiftCode;
        }
    }, [international_external_account_data?.swiftCode]);

    const international_external_basic_section_left_one = [
        {
            label: 'Account Type',
            value: 'type'
        },
        {
            label: 'Holder Type',
            value: 'holderType'
        }
    ];


    const international_external_basic_section_right_one = [
        {
            label: 'Account Holder Name',
            value: 'holderName'
        },
        {
            label: 'Account Holder Address',
            value: 'holderAddress',
            type: 'fri://fieldtype/address'
        }

    ];

    const international_external_account_section_left_one = [
        {
            label: 'Account Status',
            value: 'status',
            type: 'fri://fieldtype/status'
        },
        {
            label: 'International Bank Account Number',
            value: 'accountNumberLast4',
            type: 'fri://fieldtype/accountnumber'
        },
        {
            label: 'Swift Code',
            value: 'swiftCode'
        },
        {
            label: (swiftCode && <FxRoutingCodeLabel defaultValue={swiftCode} />) || '',
            value: 'internationalRoutingCode'
        },
    ];


    const international_external_account_section_right_one = [
        {
            label: 'Accepted Currency',
            value: 'acceptedCurrency',
            type: 'fri://fieldtype/acceptedCurrency'
        },
        {
            label: 'Purpose',
            value: 'purpose'
        },

    ];

    const editPermission = {
        entity: "Other Entities",
        name: "International External Account",
        operation: "EDIT"
    }

    const deletePermission = {
        entity: "Other Entities",
        name: "International External Account",
        operation: "DELETE"
    }

    return (
        <Grid id="international-external-account-info-Grid " className="fx-margin-bottom-16" item xs={12}>
            <FxCard id="international-external-account-info-info-card" className="fx-theme-passport">
                <FxCardHeader id={'dinternational-external-account-info-card#card-header'}>
                    <Grid id="international-external-account-Grid2" item container justifyContent="flex-end" xs={12} >
                        <Grid id="filter-Grid" item container className="fx-view-action fx-inline-flex" >
                            {edit && <Grid item> <FxButton id="payee-edit-eia-button" permissions={editPermission} variant="contained" className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme" startIcon={<EditIcon />} onClick={() => history.push(`/payee/id/${contactId}/internationalExternalAccount/edit/${id}`, { contact: { id: contactId, name: contact_data?.name, eaDetails: { accnumber: international_external_account_data?.accountNumberTitle, data: international_external_account_data } } })}>Edit</FxButton></Grid>}
                            <Grid item><FxDelete id={international_external_account_data?.id} permissions={deletePermission} buttonId={'payee-ea'} entity={'/contact/id/' + contactId + '/internationalExternalAccount'} buttonTitle="Delete" header={"Are you sure want to delete this International External Account?"} description="You can only take this action if there are no transactions scheduled on this International External account. You cannot undo this action." successEntity="International External Account"
                                redirectUrl={'/payee/view/' + contactId} /></Grid>
                        </Grid>
                    </Grid>
                </FxCardHeader>
                <FxCardBody id="international-external-account-info-info-card#card-body" className="fx-info-card fx-margin-top-reducer" >
                    <Grid container item xs={12} direction="row" >
                        {international_external_account_data && <><Grid item xs={12} direction="column">
                            <FxInfoCard id={"section-info-card-international-external-account-basic-info-one" + international_external_account_data['id']} title="BASIC INFORMATION" leftcolumn={international_external_basic_section_left_one} rightcolumn={international_external_basic_section_right_one} data={international_external_account_data} />
                        </Grid>
                        </>}
                    </Grid>
                    <Grid container item xs={12} direction="row" >
                        {international_external_account_data && <><Grid item xs={12} direction="column">
                            <FxInfoCard id={"section-info-card-international-external-account-basic-info-one" + international_external_account_data['id']} title="ACCOUNT INFORMATION" leftcolumn={international_external_account_section_left_one} rightcolumn={international_external_account_section_right_one} data={international_external_account_data} />
                        </Grid>
                        </>}
                    </Grid>
                </FxCardBody>
            </FxCard>
        </Grid >

    )
})

/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Label, Legend } from 'recharts';
import FxNoGridDataImage from '../../Data/FxNoGridDataImage';

type IFxPieChart = {
    id: string;
    data: any;
    colors: any;
    label: any;
    labelColor?:any
}
/**
 * Componet used to draw piechart
 */
export const FxPieChart: React.FC<IFxPieChart> = React.memo((props) => {

    const { data, colors, label } = props
    if (label.value === 0) {
        return (
          <Grid item container flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
              <FxNoGridDataImage />
              <Typography className="fx-no-data"> No data to show </Typography>
          </Grid>
        )}
    return (
        <ResponsiveContainer width="100%" height={190} className={'fx-rechart-piechart'}>
            <PieChart>
                <Pie
                    data={data}
                    innerRadius={"65%"}
                    outerRadius={"100%"}
                    paddingAngle={4} // Adjust the gap angle here
                    dataKey="value"
                    minAngle={20} 
                >
                    {data.map((entry: any, index: any) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colors[index % colors?.length]}
                            className={entry.name.replace(/\s+/g, '')}
                        />
                    ))}
                    <Label
                        content={<CustomLabel labelText={label?.text} value={label?.value} labelColor={label?.labelColor} />}
                        position="center"
                    />
                </Pie>
                <Legend content={<CustomizedLegend />} verticalAlign="middle" align="right" layout="vertical" />
            </PieChart>
        </ResponsiveContainer>
    );
});

const CustomizedLegend = (props: any) => {
    const { payload } = props;
    const labels: any = {
      ACH: 'ACH',
      CHECK: 'Check',
      WIRE: 'Wire',
      INTERNATIONAL_WIRE: 'Itn Wire',
      VIRTUAL_CARD: 'Virtual Card',
      BOOK: 'Book'
    }

  return (
        <ul className="LegendList">
            {payload?.map((entry: any, index: any) => (
                <li key={`item-${index}`}>
                    <Grid item container xs={12} direction={'row'}>
                        <Grid item container xs={9} className="BulletLabel" alignItems={'center'}>
                            <Grid item >
                                <Bullet backgroundColor={entry.payload.fill} size="12px" />
                            </Grid>
                            <Grid>
                                <Typography variant='h3' className="BulletLabelText">&nbsp;{labels[entry.value] || entry.value}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs={3} justifyContent={'flex-end'}>
                            <Typography variant='h4' style={{ marginLeft: "30px" }}>{entry.payload.value}</Typography>
                        </Grid>
                    </Grid>
                </li>
            ))}
        </ul>
    );
};

const CustomLabel = ({ viewBox, labelText, value ,labelColor}: any) => {
    const { cx, cy } = viewBox;
    return (
        <g>
            <text
                x={cx}
                y={cy + 10}
                className="recharts-text recharts-label label-text"
                textAnchor="middle"
                dominantBaseline="central"
                alignmentBaseline="middle"
            >
                {labelText}
            </text>
            <text
                x={cx}
                y={cy - 10}
                className="recharts-text recharts-label label-value"
                textAnchor="middle"
                dominantBaseline="central"
                alignmentBaseline="middle"
                fill={labelColor?labelColor:"#0088FE"}
            >
                {value}
            </text>
        </g>
    );
};

const Bullet = ({ backgroundColor, size }: any) => {
    return (
        <div className="CircleBullet" style={{ backgroundColor, width: size, height: size ,borderRadius:"0.2rem" }}></div>
    );
};

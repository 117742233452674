/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { Button, ButtonGroup, ButtonGroupProps } from "@mui/material";
import { Logger } from "../../libs/utils/logger";

Logger.debug("FxButtonGroup.tsx", "Fx Button Group initializing")

export interface IOption {
    id: string;
    value: string;
    label: string;
    selected?: boolean
}

interface FxButtonGroupProps extends ButtonGroupProps {
    options: IOption[]
}

/**
 * fx button group
 * @param props FxButtonGroupProps
 */
export const FxButtonGroup: React.FC<FxButtonGroupProps> =
    (props) => {

        const [options, setOptions] = useState<any>(props.options);

        // Method handles the click event of the button
        const handleClick = (event: any, item: any) => {
            try{
                const buttonOptions: any = [...options];
                buttonOptions.map((button: any)=>{
                    if(button.value === item.value){
                        button.selected = true;
                    }
                    else{
                        button.selected = false;
                    }
                })
                // update the state with new item selected
                setOptions(buttonOptions);
                if(props.onClick){
                    props.onClick(item);
                }
            }
            catch(e){
                Logger.error("FxButtonGroup.tsx", "handleClick", "error", e);
            }
        }
        
        return (
            <ButtonGroup variant="contained" aria-label="outlined primary button group"  className="fx-button-group" id={props.id}>
                {options?.map((item: any)=>{
                    return <Button id={props.id+'-'+item.id} onClick={(e: any) => handleClick(e, item)} className = {"fx-button " + (item.selected ? "fx-button-group-sel":"fx-button-group-def")} >{item.label}</Button>
                })}
            </ButtonGroup>
        )
    }
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * AuthenticationVerificationModal.tsx
 * This component handels the otp verify and select channel modal 
 */
import React, { useEffect, useMemo, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import FxCard from "../../Container/FxCard";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { useForm } from "react-hook-form";
import { FxOtpInput } from "../../Input/FxOtpInput/FxOtpInput";
import { FxResendOtp } from "../../Data/FxResendOtp";
import FxSnackBar from "../../Utils/fx-snack-bar";
import { FxLink } from "../../Action/FxLink";
import moment from "moment";
import FxLocker, { IFxLocker } from "../../Action/FxLocker";
import { ReactComponent as VerificationIcon } from '../../../assets/svg/otp-verification-icon.svg';
import { ReactComponent as UserProfileIcon } from '../../../assets/svg/user-profile-icon.svg';
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { Method, callAPI, clean, convertEmailAddress, convertPhoneNumber, customErrorMessage, updateComponentParams } from "../../Utils/CommonBaseClass";
import { processAPIResponse } from "../../../libs/utils/utils";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import FxRadioEdit from "../../Input/FxRadio/FxRadioEdit";
import { getKey } from "../../../libs/utils/storageManager";
import { GenerateJwtToken } from "../../Utils/GenerateJwtToken";
import HttpClient from "../../../libs/utils/httpClient";
import DEFAULT_CONFIG from '../../../configs/default.json'
import { FxButton } from "../../Action/FxButton";

// import jwt from 'jsonwebtoken';

interface InputErrType {
  type: string;
  message: string;
}

interface IAuthenticationVerificationModal {
  id?: string;
  metadata?: any;
  onClose?: any;
  resendStatus?: boolean;
  screen?: any;
  subTitle?: any;
}

declare global {
  interface Window {
    JWT_MFA_SIGN_KEY: any;
  }
}

export const AuthenticationVerificationModal: React.FC<IAuthenticationVerificationModal> = React.memo(
  (props) => {
    ({ props } = RegisterComponent(props));

    const lockerRef = useRef<IFxLocker>(null);
    const {
      control,
      register,
      handleSubmit,
      formState: { errors, isSubmitting },
      watch,
      setError,
      clearErrors,
      setValue
    } = useForm();
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
      updateComponentParams(dispatch, props.id, { screen: 'OTP_VERIFY', subTitle: '' })
    },[dispatch,props.id])

    /**
     *  Method returns the error message
     * @param err : error object
     * @returns 
     */
    const renderCodeError = (err: InputErrType): string => {
      if (err.type === "required") {
        return "Please enter the OTP";
      }
      return err.message;
    };
    /**
    * Method handles the resending of the otp
    */
    const handleResendCode = async (mechanism?: any) => {
      updateComponentParams(dispatch, props.id, { resendStatus: true })
      lockerRef.current?.lock();
      const url = props.metadata?.resendOtpUrl;
      const req = { sessionId: props.metadata?.initiationDetails?.sessionId, mechanism: mechanism }
      const response = await callAPI(url, Method.POST, clean(req))
      const status = processAPIResponse(response)
      if (status.status) {
        // otp send success
        FxSnackBar.show({
          severity: 'success',
          text: 'OTP sent successfully',
        });
      }
      else {
        //otp send failed
        FxSnackBar.show({
          text: status.message,
        });
      }
      setTimeout(() => {
        updateComponentParams(dispatch, props.id, { resendStatus: false })
      }, 30000);

    }


    /**
     * Method handles the closing of the modal
     */
    const onModalClose = () => {
      props.onClose && props.onClose();
    }
    /**
     * Method creates the jwt token
     * @param otp : otp
     * @param sessionId : sessiong id
     * @returns : jwt token
     */
    const createJwtToken = (otp: any, sessionId: any) => {
      const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp format
      const expiryTime = currentTime + DEFAULT_CONFIG['JWT_TOKEN_EXPIRY_TIME']*1; // Set expiration time to 5 minute from now

      const payload = {
          iss: 'CustomerPortal',
          otp: otp,
          sessionId: sessionId,
          exp: expiryTime
      };

      const secretKey = window.JWT_MFA_SIGN_KEY;
      const jwtToken = GenerateJwtToken(payload, secretKey);
      return jwtToken;
    }
    /**
     * Method handles the api call with jwt token in the header
     * @param url : api url
     * @param req : api request
     * @param token : jwt token
     * @returns 
     */
    const handlePostApiCall = async (url: any, req: any, token: any) => {
      try {
      const httpClientCustomHeader = HttpClient.newClientCustomHeaders({'X-Security-Token': token });
      const data = await httpClientCustomHeader.post(url, req).then(response => {
        return response;
      })
      .catch((error) => {
          return { ...error };
      })
  return data;
    } catch (err) {
      return err;
  }
    }

    /**
     * Method handles the api call with jwt token in the header
     * @param url : api url
     * @param req : api request
     * @param token : jwt token
     * @returns 
     */
    const handleGetApiCall = async (url: any, token: any) => {
      try {
        const httpClientCustomHeader = HttpClient.newClientCustomHeaders({'X-Security-Token': token });
        const data = await httpClientCustomHeader.get(url).then(response => {
          return response;
        })
        .catch((error) => {
            return { ...error };
        })
        return data;
      } catch (err) {
        return err;
      }
    }

    /**
     * Method handles the form submission
     * @param data : form request
     */
    const handleContinue = async () => {  
      if(props.screen === 'CHANNEL_SELECTION'){
        const channel = watch('channel') === 'SMS'? `phone ${convertPhoneNumber(getKey('phone'))}`: `email ${convertEmailAddress(getKey('email'))}`;
        const subTitle = `A verification code has been sent to your ${channel}. Please enter the code to complete verification.`;
        updateComponentParams(dispatch, props.id, { screen: 'OTP_VERIFY', subTitle });
        handleResendCode(watch('channel'));
        setValue('otp','')
        return;
      }   
      if(!watch('otp')){
        setError("otp", {
          type: "required",
          message: "Please enter the OTP",
      });
        return;
      }
      clearErrors('otp')
      const url = props.metadata?.url;
      const req = props.metadata?.req;
      let redirectUrl = props.metadata?.redirectUrl;
      const successMessage = props.metadata?.successMessage
      const otp = watch('otp');
      const sessionId = props.metadata?.initiationDetails?.sessionId;
      const token = createJwtToken(otp,sessionId)
      const response = props.metadata?.method === 'GET' ?  await handleGetApiCall(url, token) : await handlePostApiCall(url, req, token);
        if (props.metadata?.redirectUrlFromHeader && response?.headers?.url) {
          redirectUrl = `${redirectUrl}${(response.headers.url).split('/').pop()}`;
        }
        
        const status = processAPIResponse(response)
        if (status.status) {
          //api success
          if(props.metadata?.postSuccessEvent){
            props.metadata?.postSuccessEvent(response)
          }

          successMessage && FxSnackBar.show({
            autoHideDuration: 1000,
            severity: 'success',
            text: successMessage,
          });
          onModalClose();
          redirectUrl && history.push(redirectUrl);
        }
        else {
          //api  failed
          if(status.errorCode !== 'EC-BL-0102'){
            onModalClose();
          }
          FxSnackBar.show({
            text: customErrorMessage(status),
          });
          
        }
      
    }
    /**
     * Method handles the opening of the channel selection screen
     */
    const handleChooseChannel = () => {
      setValue('channel','SMS')
      updateComponentParams(dispatch, props.id, { screen: 'CHANNEL_SELECTION' })
    }

    const textMessageData=[
      { label: 'Text Message', value: "SMS" },
    ]
    const emailData=[
      { label: 'Email', value: "EMAIL" },
    ]

    /**
     * Method handles the channel change event
     * @param event : event parameter
     */
    const handleChannelOnChange = (event: any) => {
      setValue('channel',event.target.value)
    }
   /**
   * useMemo hook to compute the 'fetchSubTitle' based on 'props.subTitle' and 'props.metadata?.subTitle'.
   * It returns the first non-null value among 'props.subTitle', 'props.metadata?.subTitle', or null.
   * @returns {string | null} - The computed 'fetchSubTitle' value.
   */
    const fetchSubTitle = useMemo(() => {
      return props.subTitle || props.metadata?.subTitle || null;
    }, [props.subTitle, props.metadata?.subTitle]);
    
   


    return (
      <Grid container id="authentication-verification-modal-main-grid" xs={12} className="fx-form-grid fx-verification-modal">
        <form id="markas-paid-form" onSubmit={handleSubmit(handleContinue)}>
          <FxCard>
            <FxCardHeader id="authentication-verification-modal-header" className="fx-card-header fx-modal-header">
              <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                <Grid item container xs={12} justifyContent="center">
                  {props.screen === 'OTP_VERIFY' && <VerificationIcon />}
                  {props.screen === 'CHANNEL_SELECTION' && <UserProfileIcon />}
                </Grid>
                <Grid item xs={12}>
                {props.screen === 'OTP_VERIFY' && <Typography id="authentication-verification-modal-title" align="center" className="fx-verification-modal-title" >
                    {'Enter verification code'}
                  </Typography>}
                  {props.screen === 'CHANNEL_SELECTION' && <Typography id="channel-selection-modal-title" align="center" className="fx-verification-modal-title" >
                    {"Let's make sure it's you"}
                  </Typography>}
                </Grid>
              </Grid>
            </FxCardHeader>
            <FxCardBody id="authentication-verification-modal-body" className="fx-info-card fx-delete-info" >
              <Grid container justifyContent="center" item xs={12} className="fx-delete-description">
                {props.screen === 'OTP_VERIFY' && <Typography id="authentication-verification-modal-sub-title-grid-one" align="center" className="fx-verification-modal-sub-title" >
                  {fetchSubTitle}
                </Typography>}
                {props.screen === 'CHANNEL_SELECTION' && <Typography id="channel-selection-modal-sub-title-grid-one" align="center" className="fx-verification-modal-sub-title" >
                  {props.metadata?.channelTitle}
                </Typography>}
              </Grid>
              <Grid item xs={12} sm={12}>&nbsp;</Grid>
              {props.screen === 'OTP_VERIFY' && <><FxOtpInput register={{ ...register("otp") }} className={errors.otp ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="verify-code" name="otp" />
              {errors.otp && (
                <div className={"error-message"}>
                  {renderCodeError(errors.otp)}
                </div>)}
              <Grid item xs={12}>&nbsp;</Grid>
              <Grid container justifyContent="flex-end" alignItems="center">
                <FxLocker
                  initiallyLocked
                  ref={lockerRef}
                  renderLocked={(remainedTime) => (
                    <Grid container justifyContent="flex-end" >
                      <Grid item>
                        Resend OTP in &nbsp;
                      </Grid>
                      <Grid item className='login-page-content-card-content-remained'>
                        {`${moment.utc(remainedTime * 1000).format('mm:ss')}s`}
                      </Grid>
                    </Grid>
                  )}>
                  <Grid>
                    Didn't get it?
                  </Grid>

                  <Grid item justifyContent="center">
                    <FxResendOtp id="resend-code" resendStatus={props.resendStatus} className={"fx-mfa-selection-resndbtn"} onClick={(e) => handleResendCode()} buttonName="RESEND" />
                  </Grid>
                </FxLocker>
              </Grid></>}
              {props.screen === 'CHANNEL_SELECTION' && <><Grid item xs={12} sm={12} className="fx-verification-modal-channel-title-grid">
              <FxRadioEdit register={{ ...register("phone") }} control={control} className={errors.phone ? "border-error-input fx-input-edit" : "fx-input-edit"} data={textMessageData} id="authentication-type-modal-sms-radio" name="phone" row="vertical" setValue={setValue} onChange={handleChannelOnChange} defaultValue={watch('channel')}/>
              <Typography className="fx-verification-modal-channel-sub-title">{convertPhoneNumber(getKey('phone'))}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className="fx-verification-modal-channel-title-grid">
              <FxRadioEdit register={{ ...register("email") }} control={control} className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"} data={emailData} id="authentication-type-modal-email-radio" name="email"  setValue={setValue} onChange={handleChannelOnChange} defaultValue={watch('channel')}/>
              <Typography className="fx-verification-modal-channel-sub-title">{convertEmailAddress(getKey('email'))}</Typography>
            </Grid></>}
              <Grid className="fx-verification-modal-divider"></Grid>
            </FxCardBody>
            <FxCardFooter id="authentication-verification-modal-Footer" className="fx-footer">
              <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                <FxButton variant="contained"
                  className="fx-button fx-button-cancel"
                  id={"authentication-verification-modal-cancel-button"}
                  onClick={onModalClose}>
                  CANCEL
                </FxButton>
                <span className="fx-padding-right-16" />
                <FxButton
                  type="submit"
                  disableRipple={false}
                  className="fx-button fx-button-theme"
                  id={"authentication-verification-modal-continue-button"}
                  isSubmitting={isSubmitting}
                >CONTINUE
                </FxButton>
              </Grid>
              {props.screen === 'OTP_VERIFY' && <Grid item>
           <Typography className="fx-footer-title fx-verification-modal-trouble-label">Having Trouble?</Typography> 
           <Typography variant="h5" ><FxLink id="authentication-verification-modal-link" className="fx-footer-link fx-verification-modal-trouble-link" onClick={handleChooseChannel}>Choose another option</FxLink></Typography>
          </Grid>}
            </FxCardFooter>
          </FxCard>
        </form>
      </Grid>
    );
  });

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { IssuedDebitCards } from './AuthorisedUserDebitCards';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { AssignedRoles } from './AssignedRoles';
import { checkPermission } from "../../Utils/CommonBaseClass";
import { FxSkeltonList } from '../Cards/FxSkelton';
import { AuthorisedUserInfoCard } from './AuthorisedUserInfoCard';

/**
 * Component: UserDetails
 * Usage: To display user cards
 */
export const AuthorisedUserDetails: React.FC<any> = React.memo((props) => {
        const dispatch = useDispatch()
        // user details API
        let userFullName: any;

        let context: any;
        let user_info: any = {};
        ({ context, props } = RegisterComponent(props));

        /**
         * set user information
         */

        if (context && context.data && context.data.body) {
            user_info = context.data.body;
            userFullName = (user_info.firstName ? user_info.firstName : '') + ' ' + (user_info.middleName ? user_info.middleName : '') + ' ' + (user_info.lastName ? user_info.lastName : '');
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: userFullName, backButton: '/authorizedusers' } } });
        }

        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        }, [])

         /**
        * function to check permission 
        */
         function checkViewPermission(data: any) {
            const permissionObject: any = {
                'authuser_role_view': {
                    entity: "Role",
                    name: "Role",
                    operation: "View"
                },
                'authuser_debitCard_view': {
                    entity: "Accounts",
                    name: "Debit Card",
                    operation: "View"
                }
            }
             return checkPermission(permissionObject[data])
        }

        //check api call completed or not
        let contextState = context?.data_state;
        let dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        return (
            <Grid item xs={12} container className="fx-container">
                <Grid container xs={12} sm={8} className="fx-container-left">
                    {dataLoadStatus ?
                    (context?.data?.body && <AuthorisedUserInfoCard id="user-details-info-card" data={user_info}></AuthorisedUserInfoCard>)
                        :
                        <div className={'fx-margin-bottom-2 fx-width-100'}>
                            <FxSkeltonList height="20rem" />
                        </div>
                    }
                    {dataLoadStatus ?
                        (context?.data?.body && checkViewPermission("authuser_role_view") && <AssignedRoles id="assigned-role-card" data={user_info}/>)
                        :
                        <div className={'fx-margin-bottom-2 fx-width-100'}>
                            <FxSkeltonList height="15rem" />
                        </div>
                    }
                    {dataLoadStatus ?
                        (context?.data?.body && checkViewPermission("authuser_debitCard_view") && <IssuedDebitCards id="company-profile-beneficial-owners-comp-card" data={user_info}/>)
                        :
                        <div className={'fx-margin-bottom-2 fx-width-100'}>
                            <FxSkeltonList height="15rem" />
                        </div>
                    }
                </Grid>
            </Grid>
        )
    })

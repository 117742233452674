/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

export interface IFxLocker {
    lock(): void;
}

type PropsType = {
    initiallyLocked?: boolean;
    children?: React.ReactNode;
    seconds?: number;
    renderLocked:(remainedSeconds: number) => React.ReactNode;
    custom?: number;
}

/**
 * Component for hiding something for certain time
 */
const FxLocker = forwardRef<IFxLocker, PropsType>(({initiallyLocked,custom, seconds = custom? custom: 59, renderLocked, children}, ref) => {
    const intervalRef = useRef<NodeJS.Timer>();
    const [remainedTime, setRemainedTime] = useState<number>();

    const _lock = useCallback(() => {
        clearInterval(intervalRef.current);
        setRemainedTime(seconds);
        intervalRef.current = setInterval(() => setRemainedTime(value => (value || 1) - 1), 1000);
    }, [seconds]);

    useImperativeHandle<IFxLocker, any>(ref, () => ({
        lock: _lock,
    }));

    useEffect(() => {
        if(!initiallyLocked) {
            return;
        }
        _lock();
        return () => {
            clearInterval(intervalRef.current);
        }
    }, [_lock, initiallyLocked]);

    useEffect(() => {
        if(remainedTime === 0) {
            clearInterval(intervalRef.current);
        }
    }, [remainedTime]);

    return (
        <>
            {remainedTime ? renderLocked(remainedTime)
                : children
            }
        </>
    );
});

export default FxLocker;

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useMemo } from 'react'; // we need this to make JSX compile
import { Grid, Paper, TableContainer } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ContactDetails } from './ContactDetails';
import { checkPermission } from '../../Utils/CommonBaseClass';
import { FxAccessDenied } from '../Index/Passport/FxAccessDenied';

/**
 * This component loads the contact details page
 */

export const ContactDetailsHome: React.FC = (props:any) => {
        const params = useParams<any>();
        const id = params.id;
        /**
         * Default params for contact details page
         */
        const objectParams:any={
            openFxSnackBar:false,
            deleteContactOpen:false,
            anchorEl:null,
            fxSnackbarProps:{
                severity: "" as any,
                text: ""
            }
        }

        const viewPermission = {
            entity:"Other Entities",
            name:"Contact",
            operation:"VIEW"
          }

        /**
        * to set permission
        */
        const permission = useMemo(() => {
            return  checkPermission(viewPermission);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

         return (
            <Grid item container xs={12}>
                <Grid item xs={12} sm={12} >
                   { permission ?
                        <ContactDetails id={"-contact-details-component-"+id} {...objectParams} ></ContactDetails>
                        : <Paper>
                            <TableContainer className={"fx-grid-container"}>
                                <FxAccessDenied id={props?.id + "-access-denied"} title={props?.accessDeniedTitle ? props?.accessDeniedTitle : 'You are not authorized to access this page'} />
                            </TableContainer>
                        </Paper>
                    }
                </Grid>
            </Grid>

        )
    }

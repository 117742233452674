/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { UseFormRegisterReturn, UseFormSetValue, ControllerProps, UseFormClearErrors, Controller } from 'react-hook-form';
import { FormControl, InputLabel, MenuItem, Select, SelectProps, MenuProps } from "@mui/material";
import clsx from "clsx";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { ReactComponent as Selection } from '../../../assets/svg/select-arrow.svg';
import { ISource } from '../../../types/common.interfaces';
import { useDispatch } from "react-redux";

/**
 * Component used for material selection
 */

type FxMaterialSelectType = ControllerProps & SelectProps;

type TFieldValues = Record<string, string | number>;

export interface ISelectData {
    label: number | string | React.ReactElement;
    value: string | number;
    operator?: string
}

interface FxMaterialSelectProps extends Partial<FxMaterialSelectType> {
    name: string;
    helperText?: string;
    value?: string | number;
    setValue?: UseFormSetValue<TFieldValues>;
    clearError?:UseFormClearErrors<TFieldValues>;
    onChange?: (event: any) => void;
    setParam?: (event: any) => void;
    onFocus?: (event: any) => void;
    register?: UseFormRegisterReturn;
    data?: ISelectData[];
    dataTransformation?: (data: any) => any;
    noResources?: any;
    source?: ISource;
    prefix?: any;
    hidePlaceholderInOptions?: boolean;
}

const FxMaterialSelect: React.FC<FxMaterialSelectProps> = React.memo(
    (props) => {

        let context: any;
        const dispatch = useDispatch();
        ({ context, props } = RegisterComponent(props));
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            // eslint-disable
        }, [])
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const FxMenuProps: Partial<MenuProps> = {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    // width: 250,
                    overflow: "Auto",
                    overflowX: 'hidden',
                },
            },
        };

        /**
         * Method used to maipulate data from api 
         * @returns :selected options
         */
        function dataTransformation() {
            if (props.dataTransformation && context?.data?.body?.resources) {
                return props.dataTransformation(context.data.body.resources);
            }
            else if (props.dataTransformation && context?.data?.body && props.noResources) {
                return props.dataTransformation(context.data.body);
            }
            else if (props.dataTransformation && context?.data?.body?.settings) {
                return props.dataTransformation(context.data.body.settings);
            }
        }

        const selectOptions:any = props.source ? dataTransformation() : props.data ? props.data : [];

      return (<>
            <FormControl variant="outlined" className={clsx("fx-input-edit fx-select-input", props.className)}>
                <InputLabel
                    ref={ref => { }}
                    htmlFor={props.id}
                    id={'label-material-' + props.id}
                    shrink
                >
                    {props.label}
                </InputLabel>
                <Controller
                    name={props.name}
                    control={props.control}
                    rules={props.rules}
                    defaultValue={props.value}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return (
                            <Select
                                {...props.register}
                                id={props.id + '-select'}
                                labelId={props.id + '-select'}
                                readOnly={props.readOnly ? props.readOnly : false}
                                value={value}
                                onChange={(e: any) => {
                                    onChange(e.target.value);
                                    props.onChange?.(e);
                                }}
                                onFocus={props?.onFocus}
                                MenuProps={FxMenuProps}
                                label={props.label}
                                IconComponent={Selection}
                                className={props.readOnly === true ? 'fx-disabled' : 'fx-select-box-icon'}
                                error={!!error}
                                renderValue={(selected) => {
                                  if (props.placeholder && !selected) {
                                    if(props.prefix) {
                                      return <p className={'fx-placeholder'}><span className={'fx-select-prefix'}>{props.prefix}</span><span>{props.placeholder}</span></p>;
                                    }
                                    return <p className={'fx-placeholder'}>{props.placeholder}</p>;
                                  } else {
                                    if(props.prefix) {
                                      return <p className={'fx-selected-view'}><span className={'fx-select-prefix'}>{props.prefix}</span><span>{selectOptions?.find((item: any)=>item?.value === selected)?.label}</span></p>;
                                    } else {
                                      return selectOptions?.find((item: any)=>item?.value === selected)?.label;
                                    }
                                  }
                                }}
                                inputProps={{
                                    name: props.name,
                                    id: props.id + '-input',
                                    readOnly : props.readOnly
                                }}
                                displayEmpty
                            >
                                {props.placeholder && !props.hidePlaceholderInOptions && <MenuItem disabled value="">
                                    <>{props.placeholder}</>
                                </MenuItem>}
                                {selectOptions?.map((option: any) => {
                                    return  <MenuItem className="fx-select-box" value={option.value} id={props.id + '-' + option.value} disabled={option.disabled}>
                                      <span className={'fx-option'}>
                                        <span className={'fx-option-prefix'}>
                                        {option.prefix}
                                        </span>
                                        {option.label}
                                      </span>
                                    </MenuItem>
                                })}

                            </Select>)
                    }}
                />
            </FormControl>
        </>
        )
    })

export default FxMaterialSelect;







/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import { Grid, Typography } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import { ReactComponent as ContactUsIcon } from "../../../assets/svg/contact-us-icon.svg";
import { FxCopyToClipboard } from "../../Data/FxCopyToClipboard";
import DEFAULT_CONFIG from '../../../configs/default.json'

Logger.debug(
  "ContactUsModal.tsx",
  "Contact Us Modal initializing"
);
/**
 * @author Ritish
 * This component handles the Contact Us Modal 
 */
export const ContactUsModal: React.FC<any> = React.memo(() => {

  const contact_data = [
    { title: "Business Banking: ", contact: DEFAULT_CONFIG['CONTACT_INFORMATION']['BUSINESS_BANKING_CONTACT']},
    { title: "Consumer Banking: ", contact: DEFAULT_CONFIG['CONTACT_INFORMATION']['CONSUMER_BANKING_CONTACT']},
    { title: "Email: ", contact: DEFAULT_CONFIG['CONTACT_INFORMATION']['CONTACT_US_EMAIL'] },
  ];

  return (
    <Grid>
      <FxCard className="fx-replace-debit-card-modal">
        <FxCardHeader id="contact-us-modal-header" className="fx-card-header">
          <Grid
            container
            direction="row"
            spacing={1}
            className="fx-modal-form flex column"
            alignItems="center"
          >
            <Grid item container xs={12} justifyContent="center">
              <ContactUsIcon />
            </Grid>
            <Grid item xs={12}>
              <Typography
                id="fx-contact-us-modal-modal-title"
                align="center"
                className="fx-replace-debit-card-modal-title"
              >
                Contact us
              </Typography>
            </Grid>
          </Grid>
        </FxCardHeader>
        <FxCardBody id="contact-us-modal-modal-body" className="fx-info-card">
          <Grid item xs={12} className="fx-modal-form flex column">
            <Typography
              id="title-delete-modal"
              variant="h3"
              textAlign={"center"}
              className="fx-contact-us-main-text"
            >
              For Priority Passport Customer Support, please contact us at:
            </Typography>
          </Grid>
          <Grid
            container
            className="fx-modal-form flex column fx-contact-us-form-body"
          >
            {contact_data.map((item: any, index: any) => {
              return (
                <Grid
                  id={"fx-contact-us-items-" + index}
                  className={"fx-contact-us-form-body-items"}
                >
                  <Grid>
                    <Typography>{item.title}</Typography>
                  </Grid>
                  <Grid>
                    <Typography className="fx-contact-us-form-body-numbers">
                      {item.contact}
                    </Typography>
                  </Grid>
                  <Grid>
                    <FxCopyToClipboard
                      hideSnackbar={true}
                      id="passport-customer-token-copy-to-clipboard"
                      value={item.contact}
                      message="Copied to clipboard"
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          <Grid className="fx-contact-us-note-text">
            <Typography id="title-contact-us-modal-moddsal">
              Hours of Operation: 
              <span className="fx-contact-us-note-text-colored"> &nbsp;
               {DEFAULT_CONFIG['CONTACT_INFORMATION']['OPERATION_HOURS']}
              </span> 
            </Typography>
          </Grid>
        </FxCardBody>
      </FxCard>
    </Grid>
  );
});


/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint react-hooks/exhaustive-deps: "off" */
/**
 * Component used to display co-owner address list
*/
import React, { useEffect } from 'react'; // we need this to make JSX compile
import {Grid, Typography } from '@mui/material';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useDispatch } from 'react-redux';
import FxCardFooter from '../../Container/FxCardFooter';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { FxGridList } from '../../Data/FxGridList';
import { useIsMobileScreen } from '../../../libs/utils/utils';
import { FxDivider } from '../../Data/FxDivider';
const column: any = {
    "title": "Select Columns to Show",
    "columns": [
        {
            "label": "ID",
            "enable": true,
            "fieldName": "id",
            "type": "fri://fieldtype/default",
            "default": true
        },
        {
            "label": "Address",
            "enable": true,
            "fieldName": "address",
            "type": "fri://fieldtype/default",
            "default": true
        }
    ]
}

const gridColumn: any = {
    "title": "Select Columns to Show",
    "columns": [
        {
            "enable": true,
            "fieldName": "description",
            "type": "fri://fieldtype/custom",
            "xs": "12",
            "alignItems": 'center'
        },
        {
            "enable": true,
            "fieldName": "divider",
            "type": "fri://fieldtype/custom",
            "xs": "12",
        }

    ]
}

/**
* Method to transform data
* @param data
* @returns transformed data object for mobile view
*/
const newDataMobile = (data: any) => {
    var length = data?.length - 1;
    const transformedData = data?.map((item: any, index: any) => (
        length === index ? {
            description: (
                <Grid item container xs={12} className='fx-grid-list-content'>
                    {coOwenerAddressDetails(item)}
                </Grid>
            )
        }
            :
            {
                description: (
                    <Grid item container xs={12} className='fx-grid-list-content'>
                        {coOwenerAddressDetails(item)}
                    </Grid>
                ), divider: <FxDivider />
            }));
    return transformedData || [];
};


/**
 * Grid lay out for mobile
 * @param item 
 * @returns 
 */
const coOwenerAddressDetails = (item: any) => (

    <> <Grid item container xs={12} alignItems='center'>
        <Grid item container xs={12}>
            <Typography variant='h4'>ID:{item?.id}</Typography>
        </Grid>
        <Grid item container xs={12}>
            <Typography variant='h4'>{item?.address}</Typography>
        </Grid>

    </Grid>
    </>
);


export const CoOwnerCustomerAddress: React.FC<any> = React.memo(
    (props: any) => {
        const isMobile = useIsMobileScreen()
        let address_data: any = [];
        let newData: any = [];

        if (props?.data) {
            address_data = [...props?.data]
        }

        /**
         * mapping used to format values from API and to load data in datagrid
         */
        newData = address_data.map((item: any) => {
            const addressLine2 = item.addressLine2 ? item.addressLine2 + ', ' : '';
            return {
                id: item.id,
                address: item.addressLine1 + ', ' + addressLine2 + item.city + ', ' + item.state + ', ' + item.zip
            }
        })
        const dispatch = useDispatch()
        useEffect(() =>  {
            return ()=>{
                dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            } 
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        //check api call completed or not
        const dataLoadStatus = DATA_STATE.STATUS_DONE;
        return (
            <>
                {<Grid id="co-owneraddress-Grid" item xs={12}>
                    <FxCard id="address-card" className=" fx-theme-passport">
                        <FxCardHeader id={'address-card#card-header'} >
                            <Grid id="address-Grid" item container justifyContent="space-between" xs={12} >
                                <Typography id="title-co-owneraddress-info" variant="h4" >Mailing Address</Typography>
                            </Grid>
                            <div id="div-grow-title-co-owneraddress-info" className="fx-flex-grow" />
                        </FxCardHeader>
                        <FxCardBody id="co-owner-address#card-body" className="fx-card-body-no-border-no-shadow">
                            {
                                dataLoadStatus ? (
                                    !isMobile ? (
                                        (
                                            <DataGrid id="co-owner-mailing-address-grid" tableClass="fx-table-passport" data={newData} column={column} />
                                        )
                                    ) : (
                                        <FxGridList id="co-owner-mailing-address-grid" data={newDataMobile(newData)} column={gridColumn} />)
                                ) : (
                                    <FxSkeltonList height="8rem" />
                                )
                            }
                        </FxCardBody>
                        <FxCardFooter id="co-owner-mailing-address-Footer" className="fx-footer">
                            <Grid></Grid>
                        </FxCardFooter>
                    </FxCard>
                </Grid>}
            </>
        )
    })

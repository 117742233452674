/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { InternationalEADetailsCard } from './InternationalEADetailsCard';

Logger.debug("InternationalExternalAccountDetailsCard.tsx", "EA initializing");
/**
 * Component: International External Account Details Card
 * Usage: To display International External Account Details
 */

export const InternationalExternalAccountDetailsCard: React.FC<any> = React.memo((props) => {

    //#region Variable Declaration
    const dispatch = useDispatch();
    const location: any = useLocation();
    const componentstatePayee = useSelector((state: any) => {
        if (state.data['payee-details-info-card']) {
            return state.data['payee-details-info-card']
        } else {
            return { config: { params: {} } };
        }
    });
    const params = useParams<any>();
    const compContactId = componentstatePayee?.source?.url?.split("/")[3] ? componentstatePayee?.source?.url?.split("/")[3] : '';
    const contactId = params ? params?.contactId : compContactId;
    const name = location.state?.contact?.name;
    const eia_source = {
        url: "/contact/id/" + contactId,
        method: "GET"
    };

    /**
    * Method to rest the component on unmount.so that when redirect back page it will call the api again
    */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                <Grid container xs={12} sm={8} className="fx-container-left" >
                    <InternationalEADetailsCard id="eia-details-info-card" source={eia_source} contactDetails={name} />
                </Grid>
            </Grid>
        </>
    )
})
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * SetAsPrimary
 * This component handles the set as primary owner
 */
import React from 'react'; // we need this to make JSX compile
import { Grid, IconButton, Typography } from '@mui/material';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { useForm } from 'react-hook-form';
import { setKey } from '../../../libs/utils/storageManager';
import { callAPI, updateComponentParams } from '../../Utils/CommonBaseClass';
import { processAPIResponse } from '../../../libs/utils/utils';
import FxSnackBar from '../../Utils/fx-snack-bar';
import FxCard from '../../Container/FxCard';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxButton } from '../../Action/FxButton';
import { useDispatch } from 'react-redux';
import { Logger } from '../../../libs/utils/logger';

Logger.debug("FxConfirmationBox.tsx", "FxConfirmationBox modal initializing")

interface IFxConfirmationBoxPropery {
    id: any;
    open: boolean;
    confirmationMessage: any;
    apiUrl?: ApiUrl;
    submitButtonText?: string
    ConfirmIcon?: any;
    description?: any;
    resetFormId?: string;
    successText: string;
    class?: string;
    metadata?: any;
}
interface ApiUrl {
    url: string;
    method: any;
    payload?: any
}
export const SetAsPrimary: React.FC<IFxConfirmationBoxPropery> = React.memo(
    (props) => {
        ({ props } = RegisterComponent(props));
        const { formState: { isSubmitting }, handleSubmit } = useForm();
        const dispatch = useDispatch();
        const handleClose = () => {
            updateComponentParams(dispatch, props?.metadata?.id, { open: false })
        };
        /**
         * onsubmit function for redeem form
         * @param data 
         */
        async function onSubmit(data: any) {
            let status: any;
            status = await callAPI(props?.metadata?.apiUrl?.url, props?.metadata?.apiUrl?.method, props?.metadata?.apiUrl?.payload)
            status = processAPIResponse(status);

            if (status.status) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: props?.metadata?.successText
                });
                if (props?.metadata?.resetFormId) {
                    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props?.metadata?.resetFormId } });
                }
                handleClose();
                setKey("PrimaryOwnerFullName", props.metadata?.ownerFullName);
            } else {
                //api  failed
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    text: status.message,
                });
            }
        };
        return (
            <Grid container className={"fx-container"}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FxCard >
                        <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                            <Grid item container justifyContent="center" xs={12}>
                                <IconButton
                                    id='fx-set-as-primary-modal-icon'
                                    className="fx-modal-confirm-modal-icon"
                                    size="large">
                                    {props?.metadata?.ConfirmIcon && <props.metadata.ConfirmIcon />}
                                </IconButton>
                            </Grid>
                            <Grid container justifyContent="center" item xs={12} className="fx-confirm-modal-title">
                                <Typography id="title-set-as-primary" variant="h3" >
                                    {props?.metadata?.confirmationMessage}
                                </Typography>
                            </Grid>
                            <Grid container justifyContent="center" item xs={12} className="fx-confirm-modal-description">
                                <Typography id="title-set-as-primary-grid-two" variant="h5" >
                                    {props?.metadata?.description}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* </FxCardBody> */}
                        <FxCardFooter id="fx-set-as-primary-Footer" className="fx-footer">
                            <Grid container direction="row" justifyContent="center" className="fx-modal-footer">
                                <Grid container columnSpacing={2} justifyContent='end'>
                                    <Grid item>
                                        <FxButton
                                            className="fx-button fx-button-cancel"
                                            id="fx-set-as-primary-cancel-button"
                                            onClick={handleClose}
                                        >
                                            CANCEL
                                        </FxButton>
                                    </Grid>
                                    <Grid item>
                                        <FxButton
                                            className="fx-button fx-button-theme"
                                            id="fx-set-as-primary-submit-button"
                                            type="submit"
                                            isSubmitting={isSubmitting}
                                        >
                                            {props?.metadata?.submitButtonText || 'Confirm'}
                                        </FxButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FxCardFooter>
                    </FxCard>
                </form>
            </Grid>
        );
    })
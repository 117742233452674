
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid, ListItemIcon } from '@mui/material';
import { useLocation } from 'react-router';
import { FxLink } from '../../Action/FxLink';
import { getSelectedPath } from '../Passport';
import { useIsMobileOrTabletScreen, useIsMobileScreen } from '../../../libs/utils/utils';
import { ReactComponent as CloseIconLeft } from '../../../assets/svg/leftmenu-close.svg';
import { ReactComponent as CloseIconRight } from '../../../assets/svg/rightmenu-close.svg';
import { LogoMenu } from '../../Utils/Logo';

//Icons
import { ReactComponent as DashboardIcon } from '../../../assets/svg/leftmenu-dashboard.svg';
import { ReactComponent as LeadsIcon } from '../../../assets/svg/leads-icon.svg';
import { ReactComponent as CustomerIcon } from '../../../assets/svg/partner-customer-icon.svg';
import { ReactComponent as LogoutIconMobile } from '../../../assets/svg/logout-icon-mobile.svg';
import { checkPermission } from '../../Utils/CommonBaseClass';
import { portalSecondTitle, swtichPortalMenu } from '../../Utils/ThemeCheckerPartner';
import UIFeatureImpl from '../../../libs/services/uiFeatures';

/**
 * This Component handles the Partner drawer expand state
 */
export const PartnerDrawerExpand: React.FC<any> = React.memo((props) => {
    const location = useLocation();
    const isSmallScreen = useIsMobileScreen();
    const isMobileOrTabScreen = useIsMobileOrTabletScreen();
    const uiFeat = UIFeatureImpl.getInstance();
    const individualCustomer = uiFeat.getIndividualCustomerFeature().available

    return (<>
        <Grid item container justifyContent="space-between" className="fx-logo-passport-menu-icon">
            <Grid item xs container alignItems={'center'}>
                <FxLink redirect={props?.selectedPage === 'LEADS' ? "/leads-home" : "/dashboard"}><LogoMenu /></FxLink>
            </Grid>
            
            <Grid item xs={2} container alignContent='center' justifyContent='center' className="fx-logo-passport-menu-icon-close" id="close-expand-menu">
                {isSmallScreen ? <CloseIconRight /> : <CloseIconLeft />}
            </Grid>
        </Grid>
        {portalSecondTitle(props)}
            {/* #FFFFFF */}
        <Grid container className="fx-menu-passport">
            <Grid item xs={12}>
                <ul>
                    {checkPermission({ entity: "Partner Portal", name: "Lead Portal", operation: "View" }) && <li className={getSelectedPath(location) === 1 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-dashboard" redirect={"/leads-home"}><ListItemIcon> <DashboardIcon /></ListItemIcon>Dashboard</FxLink></Grid></li>}
                    {checkPermission({ entity: "Partner Portal", name: "Lead Portal", operation: "List" }) && <li className={getSelectedPath(location) === 2 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-leads" redirect={"/leads"}><ListItemIcon> <LeadsIcon /></ListItemIcon>Leads</FxLink></Grid></li>}
                    {checkPermission({ entity: "Partner Portal", name: "Customer", operation: "List" }) &&<li className={getSelectedPath(location) === 3 ? 'fx-menu-passport-sel' : ''}><Grid item container alignItems='center'><FxLink id="menu-left-customers" redirect={"/customers"}><ListItemIcon> <CustomerIcon /></ListItemIcon>Customers</FxLink></Grid></li>}
                </ul>
            </Grid>
            <Grid item xs={12} className={`${isMobileOrTabScreen || individualCustomer ? 'fx-menu-passport-bottommenu' : 'fx-sidenav-bottommenu'}`}>
                    {swtichPortalMenu(props)}
                {isSmallScreen && <>
                    <Grid item>
                        {uiFeat.getSSOUserFeatures().available && uiFeat.getSSOUserFeatures().button}
                    </Grid>
                    <Grid item xs={12} className={'fx-menu-left-logout'}>
                        <FxLink id="menu-left-logout" redirect="/logout">
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Grid item xs={!isSmallScreen ? 2 : 1} container alignItems={'center'}><LogoutIconMobile /></Grid>
                                <Grid item xs={!isSmallScreen ? 10 : 11}><div className="fx-menu-passport-bottommenu-smalltext fx-menu-passport-profile-space">Logout</div></Grid>
                            </Grid>
                        </FxLink>
                    </Grid>
                </>}
            </Grid>
        </Grid>
    </>
    )
})
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'; // we need this to make JSX
import { Grid } from '@mui/material';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive'
import moment from 'moment';
import { Logger } from '../../../../libs/utils/logger';
import HttpClient from '../../../../libs/utils/httpClient';
import { IFileInfo } from '../../../Data/FxAgreementLinkCard';
import { getLastFourDigits } from '../../../Utils/CommonBaseClass';
import { convertToPhoneRequestFormat, convertToPhoneDisplayFormat, updateComponentData, fetchDataFromState, updateComponentParams, callAPI, Method } from '../../../Utils/CommonBaseClass';
import { AccountIdentifierSignup } from '../Common/AccountIdentifierSignup';
import { ReviewAndSubmitSignup } from '../Common/ReviewAndSubmitSignup';
import { getCustomerUrl, processAPIResponse, pushGTM } from '../../../../libs/utils/utils';
import { getKey, removeCookieCustom, setKey } from '../../../../libs/utils/storageManager';
import { RegisterComponent } from '../../../../libs/saga/dataSaga';
import FxSnackBar from '../../../Utils/fx-snack-bar';
import { useDispatch, useSelector } from 'react-redux';
import { ICardData } from '../Common/ReviewInfoCard';
import { JointTenancyIdentificationSignUp } from './JointTenancyIdentificationSignUp';
import { JointTenancyCoOwnerCard } from './JointTenancyCoOwner/JointTenancyCoOwnerCard';
import { JointTenancySignUpLeftBar } from './JointTenancySignUpLeftBar';
const httpClient = HttpClient.getClient();

/**
 * Component: Signup Joint 
 * Usage: User signup page
 */

Logger.debug("JointTenancySignUp.tsx", "SignUp initializing")

export const JointTenancySignUp: React.FC<any> = React.memo(
    (props) => {
        let context : any;
        ({ context, props } = RegisterComponent(props));
        const { isLoading, step, paymentIdDetailData, paymentIdDetailReq, isUpdateContactRequest, initialIndData } = props;
        const history = useHistory();
        const dispatch = useDispatch();
        const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' })

        
        /**
         * UseEffect hook resets the parameters
         */
        useEffect(() => {
          updateComponentParams(dispatch, props.id, { isLoading: false, step: 1, formReq: undefined, primaryOwnerData: undefined, paymentIdDetailData: undefined, paymentIdDetailReq: undefined,  isUpdateContactRequest: false,  initialIndData: false, beneficialCoOwnerData: undefined });
          return () => {
              dispatch({ type: 'DATA_COMPONENT_RESET', payload: { id: props.id } });
            }
        },[dispatch,props.id,])


        /**
         * Method fetches data from the state
         */
        const jointOwnerState = useSelector((state: any) =>
            fetchDataFromState(state, 'customer-kyc-joint-owners-data')
        );

        /**
        checking customer info is present on the customer get api or not.If data is present then calling method 'setInitialData' to prepolutae the information
         */
        useEffect(() => {
            if(context?.data?.body){
                setInitialData();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[context?.data?.body])
        /**
         * Deep copy function to create a copy of the object and its nested properties
         * @param obj : object
         * @returns 
         */
        function deepCopy(obj: any) {
            if (typeof obj !== 'object' || obj === null) {
                return obj; // Return the value if it's not an object
            }

            const newObj: any = Array.isArray(obj) ? [] : {}; // Determine if it's an array or object

            for (const key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                newObj[key] = deepCopy(obj[key]); // Recursively copy nested properties
                }
            }

            return newObj;
            }
        /**
         * method used to set data on form while page loading
         */
        const setInitialData= ()=> {
            const primaryOwnerData = getCustomerDetailsDataFromApi(context?.data?.body);
            const beneficialOwnerData = getBODetailsDataFromApi(context?.data?.body);
            const formReqCopy = signUpJointTenancy({ details: primaryOwnerData })
            const jointOwnerStateCopy = deepCopy(jointOwnerState?.data);
            let primaryOwnerObj;
            if(jointOwnerStateCopy){
                if(primaryOwnerData){
                    const ssn=jointOwnerStateCopy['primaryOwnerData']['ssn'];
                    const itin=jointOwnerStateCopy['primaryOwnerData']['itin'];
                    primaryOwnerData.ssn = primaryOwnerData?.ssn ? primaryOwnerData?.ssn : ssn;
                    primaryOwnerData.itin = primaryOwnerData?.itin ? primaryOwnerData?.itin : itin;
                    jointOwnerStateCopy['primaryOwnerData'] = primaryOwnerData;
                }

                if(beneficialOwnerData){
                    jointOwnerStateCopy['beneficialCoOwnerData'] = beneficialOwnerData;
                }
            }
            else{
                primaryOwnerObj = { primaryOwnerData: primaryOwnerData, beneficialCoOwnerData: beneficialOwnerData  }
            }

          updateComponentData(dispatch, 'customer-kyc-joint-owners-data', { ...jointOwnerStateCopy || primaryOwnerObj })
          updateComponentParams(dispatch, props.id, { primaryOwnerData: primaryOwnerData, initialIndData: true, formReq: formReqCopy, beneficialCoOwnerData: beneficialOwnerData  })

        }
               
        /**
         * method used to convert customer get  api response in form data format
         * @param data :data from api response
         * @returns
         */
        const getBODetailsDataFromApi = (data: any) => {   
            const coOwner:any = data.owners.filter((x:any)=> !x.isPrimaryOwner);         

            return coOwner.length ? coOwner.map((data: any) => {
                 let _mailingAddress: any = data.mailingAddress;
                 _mailingAddress = _mailingAddress ? _mailingAddress[0] : {};
                   return {
                         "id":data.id?data.id:data.id,
                         "firstName": data.firstName ? data.firstName : '',
                         "lastName": data.lastName ? data.lastName : '',
                         "middleName": data['middleName'] ? data['middleName'] : '',
                         "email": data.email ? data.email : '',                         
                         "dob": data.dob ? data.dob : '',
                         "ssn": data?.ssn || data?.last4ssn || '',
                         "itin": data?.taxIdentification?.id || data?.taxIdentification?.idLast4 || '', 
                         "address_id": _mailingAddress.id ? _mailingAddress.id : '',
                         "addressLine1": _mailingAddress.addressLine1 ? _mailingAddress.addressLine1 : '',
                         "addressLine2": _mailingAddress.addressLine2 ? _mailingAddress.addressLine2 : '',
                         "city": _mailingAddress.city ? _mailingAddress.city : '',
                         "state": _mailingAddress.state ? _mailingAddress.state : '',
                         "zip": _mailingAddress.zip ? _mailingAddress.zip : '',
                         "mobilePhone": data.mobilePhone ? convertToPhoneDisplayFormat(data.mobilePhone) : ''
                        }
             }) : []
     }


        /**
         * This method used to set customer details as the format of form data
         */
        function getCustomerDetailsDataFromApi(data: any) {

            const customer = data.owners.find((x:any)=>x.isPrimaryOwner);            

            let _mailingAddress: any = customer.mailingAddress;
            _mailingAddress = _mailingAddress ? _mailingAddress[0] : {};
            return  {
                "id":customer['id'],
                "email": customer['email'],
                "mobilePhone": convertToPhoneDisplayFormat(customer['mobilePhone']),
                "firstName": customer['firstName'],
                "lastName": customer['lastName'],
                "middleName": customer['middleName'] ? customer['middleName'] : '',
                "dob": customer['dob'],
                "ssn": customer?.ssn || customer?.last4ssn || '',
                "itin": customer?.taxIdentification?.id || customer?.taxIdentification?.idLast4 || '', 
                "address_id": _mailingAddress.id ? _mailingAddress.id : '',
                "addressLine1": _mailingAddress['addressLine1'],
                "addressLine2": _mailingAddress['addressLine2'] ? _mailingAddress['addressLine2'] : '',
                "city": _mailingAddress['city'],
                "state": _mailingAddress['state'],
                "zip": _mailingAddress['zip'],
                "mobilePhone-select": "+91"
            }
        }

        // Method handles the onsubmit event of the customer details form
        const getCustomerDetails = async (data: any, callback:any) => {
            const jointOwnerStateCopy = {...jointOwnerState?.data}
            jointOwnerStateCopy['primaryOwnerData'] = data;
            const formReqCopy = signUpJointTenancy({ details: data })

            const req ={...formReqCopy.customer};
            const url:any="customer/id/" + getKey('customerId');
            await callAPI(url, Method.POST, req)
            callback()
                if(getKey('isPPIEnabled') === 'TRUE'){
                    const paymentIdDetailDataCopy = {...paymentIdDetailData}
                    paymentIdDetailDataCopy['phone'] = data?.mobilePhone;
                    jointOwnerStateCopy['primaryOwnerData']['phone'] = data?.mobilePhone;
                    updateComponentParams(dispatch, props.id, { primaryOwnerData: data, step: 2, paymentIdDetailData: paymentIdDetailDataCopy, formReq: formReqCopy })
                }
                else{
                    updateComponentParams(dispatch, props.id, { primaryOwnerData: data, step: 3, formReq: formReqCopy })
                }
    
                updateComponentData(dispatch, 'customer-kyc-joint-owners-data', { ...jointOwnerStateCopy })
        }

        /**
         * Method for creating the Joint tenancy request
         * @param data 
         * @returns 
         */
        function signUpJointTenancy(data: any) {
            const details: any = data.details ? data.details : {};
            const address: any = {
                addressLine1: details['addressLine1'],
                addressLine2: details['addressLine2'] ? details['addressLine2'] : '',
                city: details['city'],
                state: details['state'],
                zip: details['zip'],
                isPrimary: true
            };
            if(details.addressLine2 === undefined || details.addressLine2 === ''){
                delete address['addressLine2']
            }
            if(details['address_id']){
                address['id'] = details['address_id'];
            }

            const primaryOwners:any =  {
                "id": details.id,
                "isPrimaryOwner":true,
                "portalAccess": { "grantAccess": true },
                "firstName": details.firstName,
                "middleName": details.middleName,
                "lastName": details.lastName,             
                "dob": moment(details.dob).format('MM/DD/YYYY'),
                "email": details.email,
                "mobilePhone": convertToPhoneRequestFormat(details.mobilePhone),
                "mailingAddress": [address]
            };
            if (details?.ssn?.length > 10){
                primaryOwners['ssn'] = details.ssn;
            } else if (details?.itin?.length > 10){
                primaryOwners['taxIdentification'] = {
                    type: "ITIN",
                    id: details.itin
                };
            }            

            const req:any = {
                customer: {
                    "type": "JOINT_TENANCY",
                    "owners": [primaryOwners],
                }
            }

            if(details.middleName === undefined || details.middleName === ''){
                delete req['customer']['owners'][0]['middleName']
            }
            
            return req;
        }

        // Method handles the onsubmit event of the review & submit page
        const handleReviewJointTenancySubmit = (req:any) => {           
            const customerType ={
                customer:{
                    type: "JOINT_TENANCY",
                }
            };

            const request = {...customerType, ...req};


          const linkedDocuments = req.fileData?.map((file: IFileInfo) => {
            return {
              purpose: 'AUTHORIZATION',
              document: {
                type: file?.fileType,
                name: file?.fileType === 'RATE_CARD' ? 'RATE_CARD.pdf' : 'KYC_AGREEMENT.pdf',
                base64encodedContent: file?.base64
              }
            }
          })

          let accountData: any = {
                "isCustomerOwned": true,
                "linkedDocument": linkedDocuments
            }
            if(paymentIdDetailReq?.nickname){
                accountData = {
                    ...accountData,
                    "nickName" : paymentIdDetailReq?.nickname
                }
            }

            request['account'] = accountData
            reviewJointTenancySubmitRequest(request)
        }

        async function reviewJointTenancySubmitRequest(req:any){
            let status:any = false;
            if(isUpdateContactRequest){
                status = true;
            }

            if(!status){
                status = await transformRequest(req)
            }

           if(status  && getKey('isPPIEnabled') === 'TRUE'){
                const payload = {
                    ppi:paymentIdDetailReq.ppi?.trim()
                };

                let ppIdStatus = await addPaymentId(payload);
                ppIdStatus = processAPIResponse(ppIdStatus)
                if(ppIdStatus.status){
                    setTimeout(() => {
                        history.push('/')
                    }, 1000);
                }
                else{
                    //api  failed
                    FxSnackBar.show({
                        text: ppIdStatus.message,
                    });
                }
                updateComponentParams(dispatch, props.id, { isLoading: false })
           }
        }

        // Method transforms the request from various pages for api call
        async function transformRequest(req: any) {
            updateComponentParams(dispatch, props.id, { isLoading: true })
            delete req['privacyPolicyAccepted'];
            delete req['fileData'];
            delete req['accountNickname'];
            let status: any;
            status = await updateCustomer(req);
            status = processAPIResponse(status)
            if (status.status) {
                removeCookieCustom('customerKycData')
                // configuring gtm for kyc successful submit
                    const gtmData ={
                        event:"userKycSuccessTrigger",
                        "userEmail": getKey('email'),
                        "userType": "JOINT_TENANCY"
                    }
                    pushGTM(gtmData)
                setKey('kycPending','true');
                if(getKey('isPPIEnabled') === 'TRUE'){
                    updateComponentParams(dispatch, props.id, { isUpdateContactRequest: true })
                    return true;
                }
                else{
                    updateComponentParams(dispatch, props.id, { isLoading: false, isUpdateContactRequest: true })
                    setTimeout(() => {
                        history.push('/')
                    }, 1000);
                }
            }
            else {
                //api  failed
                updateComponentParams(dispatch, props.id, { isLoading: false })
                FxSnackBar.show({
                    text: status.message,
                });
                return false;
            }
        }

        /**
        * API call function
        * @param req :request
        * @returns
        */
        async function updateCustomer(req: any) {
            try {
                const data: any = await httpClient.post(getCustomerUrl('/onboard',false), req).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                return err;
            }
        }

        /**
         * API call function for create ppi
         * @param req :request
         * @returns
         */
        async function addPaymentId(req: any) {
            try {
                const url = "/ppi"
                const data: any = await httpClient.post(getCustomerUrl(url,false), req).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                return err;
            }
        }

        // Method handles the going back from review screen to payment ppi screen
        const handleReview = (step?: number) => {
          if(step){
            updateComponentParams(dispatch, props.id, { step })
          } else {
            let _step: any;
            if(getKey('isPPIEnabled') === 'TRUE'){
              _step = 3;
            }
            else{
              _step = 2;
            }
            updateComponentParams(dispatch, props.id, { step: _step })
          }
        }

        // Method handles the click event of menu bar
        const handleMenuClick = (step: any) => {
            updateComponentParams(dispatch, props.id, { step })

        }

         // Method handles the click event of the beneficial owner page to move to the next screen
         const handleNextScreen = async (callback:any) => {
            let benOwnerReq = jointOwnerState?.data?.beneficialCoOwnerReq ? [...jointOwnerState?.data?.beneficialCoOwnerReq] : [];

            if(benOwnerReq.length === 0){
                updateComponentParams(dispatch, props.id, { step: 3 });

                return;
            }

            const updatedBenOwnerReq = benOwnerReq.filter(obj =>obj && Object.keys(obj).length !== 0).map((item: any) => {
                const { address_id, ...rest } = item || {};
                return rest;
              });

            benOwnerReq = updatedBenOwnerReq; 
            
            /**
             *  To update beneficial owners by removing 'ssn' property for items with SSN length less than 10.
             * @param {object} req - The request object containing beneficial owner information.
             * @returns {Array} - An array of updated beneficial owners.
             */
            const updatedBeneficialOwners = (benOwnerReq || []).map((item: any) => {
                if ((item?.ssn && item.ssn.length < 10) || item.ssn === '') { 
                    // Create a new object without the 'ssn' property
                    const { ssn, ...updatedItem } = item;
                    return updatedItem;
                }
                if ((item?.itin && item.itin.length < 10) || item.itin === '') {
                    // Create a new object without the 'itin' property
                    const { itin, ...updatedItem } = item;
                    return updatedItem;
                } else if (item?.itin) {
                    const { itin, ...updatedItem } = item;
                    updatedItem['taxIdentification'] = {
                        id: itin,
                        type:'ITIN'
                    }
                    return updatedItem;  
                }
                return item;
            });

            benOwnerReq = updatedBeneficialOwners;            
           
            const req:any = {
                type: "JOINT_TENANCY",
                owners: [...benOwnerReq], 
            }

            let url:any="customer/id/" + getKey('customerId');
            let status = await callAPI(url, Method.POST, req)
            status = processAPIResponse(status)
            callback()
            if (status.status) {  
                
                resetCoOwnerDetails();
                updateComponentParams(dispatch, props.id, { step: 3 })
            }
            else {
                //api  failed
                FxSnackBar.show({
                    text: status.message,
                });
            }
        }

        // Method to refersh customer details
        const resetCoOwnerDetails=()=>{                  
            const dataCopy = {...jointOwnerState?.data}            
            dataCopy['beneficialCoOwnerReq'] = [];
            updateComponentData(dispatch, 'customer-kyc-joint-owners-data', { ...dataCopy  })
            
            dispatch({type: 'DATA_API_PARAM_CHANGED', payload: {id: `joint-tenancy-user-signup`, source: { url: `/`,method: "GET"}} });  
        }

         // Method handles the going back from review screen to joint owner details screen
         const handlePaymentId = () => {
            updateComponentParams(dispatch, props.id, { step: 2 })

        }

         // Method handles the going back from review screen to joint owner details screen
         const handleBeneficialCoOwner = () => {
            updateComponentParams(dispatch, props.id, { step: 1 })

        }

        // Method handles to going for review screen to joint owner details screen
        const handlePaymentDetailsSubmit = (req: any) => {
            const stepCopy: number = step;
            const newStep = stepCopy + 1;
            updateComponentParams(dispatch, props.id, { step: newStep, paymentIdDetailReq: req })
        }

         /**
         * update request and data after remove owner
         * @param index :index
         * @param data :data
         */
         const updatedBusinessOwnerData= (index:any,data:any) =>{
            const reqArray = jointOwnerState?.data?.beneficialCoOwnerReq?.length > 0? [...jointOwnerState?.data?.beneficialCoOwnerReq]: []
            if(index){
                reqArray.splice(index, 1);
            }
           
            const dataCopy = {...jointOwnerState?.data}
            dataCopy['beneficialCoOwnerData'] = data;
            dataCopy['beneficialCoOwnerReq'] = reqArray;
            updateComponentData(dispatch, 'customer-kyc-joint-owners-data', { ...dataCopy  })
        }

        // Method handles the onsubmit event of the beneficial owner page
        const handleBeneficialOwnerSubmit = (data: any, req: any, action: any, index: any) => {
            const benOwnerData = [...jointOwnerState?.data?.beneficialCoOwnerData]
            const benOwnerReq = jointOwnerState?.data?.beneficialCoOwnerReq?.length > 0? [...jointOwnerState?.data?.beneficialCoOwnerReq]: []
            let reqArray: any;
            let dataArray: any;
            
            if (action === 'add') {
                reqArray = [...benOwnerReq];
                reqArray[benOwnerData.length] = req;
                data.actionIndex = reqArray.length-1;
                dataArray = [...benOwnerData, data];
            }
            else {
                if (index !== undefined) {
                    reqArray = [...benOwnerReq];
                    dataArray = [...benOwnerData];
                    reqArray[index] = req;
                    data.actionIndex = index;
                    dataArray[index] = data;
                }
            }
            const dataCopy = {...jointOwnerState?.data}           
            dataCopy['beneficialCoOwnerData'] = dataArray;
            dataCopy['beneficialCoOwnerReq'] = reqArray;
            updateComponentData(dispatch, 'customer-kyc-joint-owners-data', { ...dataCopy  })

        }


      const primaryOwnerData = jointOwnerState?.data?.primaryOwnerData || {};
      const coOwnerData = jointOwnerState?.data?.beneficialCoOwnerData || [];



      // Primary owner details for the review screen
         const ssn = getLastFourDigits(primaryOwnerData.ssn);
         const itin = getLastFourDigits(primaryOwnerData.itin);
      const primaryOwnerInfo: ICardData = {
        header: 'Primary Owner',
        items: [{
          name: `${primaryOwnerData.firstName ?? ''} ${primaryOwnerData.lastName ?? ''}`,
          data: {
            ein: {
              title: 'EIN:',
              value: primaryOwnerData.ein || ''
            },
            ...(itin ? { itin: { title: 'ITIN', value: `${itin ? '*' + itin : ''}` } } :
            { ssn: { title: 'SSN', value: `${ssn ? '*' + ssn : ''}` } }),
            dob: {
                title: 'DOB',
                value: `${primaryOwnerData.dob ? primaryOwnerData.dob : ''}`
            },
            address: {
              value: `${primaryOwnerData.addressLine1 || ''} ${primaryOwnerData.addressLine2 || ''}${primaryOwnerData.city ? ', ' + primaryOwnerData.city : ''}${primaryOwnerData.state ? ', ' + primaryOwnerData.state : ''}${primaryOwnerData.zip ? ', ZIP - ' + primaryOwnerData.zip : ''}`
            },
            email: {
              value: primaryOwnerData.email || ''
            }
          }
        }]
      }

      // co-owner details for the review screen
      const coOwnerInfo: ICardData = {
        header: 'Co-Owner',
        items: coOwnerData.map((coOwner: any, index: number) => {
          const ssn = getLastFourDigits(coOwner.ssn);
          const itin = getLastFourDigits(coOwner.itin);
          return {
            name: `${coOwner.firstName ?? ''} ${coOwner.lastName ?? ''}`,
            index: index + 1,
            data: {
              email: {
                value: coOwner.email || ''
              },
              ...(itin ? { itin: { title: 'ITIN', value: `${itin ? '*' + itin : ''}` } } :
              { ssn: { title: 'SSN', value: `${ssn ? '*' + ssn : ''}` } }),
              dob: {
                title: 'DOB',
                value: coOwner.dob || ''
              },
              address: {
                value: `${coOwner.addressLine1 || ''} ${coOwner.addressLine2 || ''}${coOwner.city ? ', ' + coOwner.city : ''}${coOwner.state ? ', ' + coOwner.state : ''}${coOwner.zip ? ', ZIP - ' + coOwner.zip : ''}`
              }
            }
          }
        })
      }


      // Account identifier details for the review screen
      const { mobilePhone, email } = jointOwnerState?.data?.primaryOwnerData || {};
      const { value, ppi } = paymentIdDetailReq?.paymentType || {};


      const accountIdentifierData = {
        'Phone': {
          title: 'Phone:',
          value: mobilePhone || ''
        },
        'Email': {
          title: 'Email:',
          value: email || ''
        },
        'Custom': {
          title: 'PPI ID:',
          value: ppi || ''
        }
      }


      const accountIdentifierInfo: ICardData = {
        header: 'Account Identifier',
        items: [{
          name: paymentIdDetailReq?.nickname || '',
          data: {
            ppi: accountIdentifierData[value as keyof typeof accountIdentifierData] || {},
          }
        }]
      }


      // Review details for the review screen
      const reviewDetails = [
        { info: primaryOwnerInfo, step: 1 },
        { info: coOwnerInfo, step: 2 },
        { info: accountIdentifierInfo, step: 3 }
      ]

        return (
            <Grid item xs={12} container direction="row"  className='fx-form-grid fx-join-tenancy-co-owner'>
                {!isSmallScreen && <Grid item xs={3} sm={4} md={3} container direction="row" alignContent="flex-start"    className ="fx-login-promo-parent">
                    <JointTenancySignUpLeftBar activeMenu={step} onClick={handleMenuClick} data={jointOwnerState?.data?.primaryOwnerData} />
                </Grid>}
                {isSmallScreen && <Grid item xs={12} container direction="row" alignContent="flex-start" sm={12}   className ="fx-login-promo-parent">
                    <JointTenancySignUpLeftBar activeMenu={step} onClick={handleMenuClick} data={jointOwnerState?.data?.primaryOwnerData} />
                </Grid>}
                {step === 1 && initialIndData && <Grid item xs={12} sm={isSmallScreen?undefined:8} md={isSmallScreen?undefined:9} container direction="row">
                    <JointTenancyIdentificationSignUp id='fx-signup-join-tenancy-details' onSubmit={getCustomerDetails} data={jointOwnerState?.data?.primaryOwnerData} />
                </Grid >}
                {/* {mainMenu === 2 && initialBOData && <BusinessOwnerCard businessDetails={businessState?.data?.['businessDetailsData']} updatedData={updatedBusinessOwnerData} data={businessState?.data?.beneficialOwnerData} onClick={handleNextScreen} goBackClick={handleGoBack} onSubmit={handleBeneficialOwnerSubmit}/>}
                */}
                {step === 2 &&  <Grid item xs={12} sm={isSmallScreen?undefined:8} md={isSmallScreen?undefined:9} container direction="row" alignContent="flex-start">
                    <JointTenancyCoOwnerCard id='fx-signup-join-tenancy-co-owner-details' primaryOwnerDetails={jointOwnerState?.data?.primaryOwnerData} updatedData={updatedBusinessOwnerData} onSubmit={handleBeneficialOwnerSubmit} data={jointOwnerState?.data?.beneficialCoOwnerData} onClick = {handleNextScreen} goBackClick={handleBeneficialCoOwner} />
                </Grid >}
                {step === 3 && (getKey('isPPIEnabled') === 'TRUE') &&  <Grid item xs={12} sm={isSmallScreen?undefined:8} md={isSmallScreen?undefined:9} container direction="row" alignContent="flex-start">
                    <AccountIdentifierSignup details={jointOwnerState?.data?.primaryOwnerData} onSubmit={handlePaymentDetailsSubmit} data={paymentIdDetailReq} goBackClick={handlePaymentId} />
                </Grid >}

                {step === 4 && <Grid item xs={12} sm={isSmallScreen?undefined:8} md={isSmallScreen?undefined:9} container direction="row" alignContent="flex-start">
                    <ReviewAndSubmitSignup reviewDetails={reviewDetails} goBackClick={handleReview} onSubmit={handleReviewJointTenancySubmit} loading={isLoading} />
                </Grid >}
            </Grid >
        );
});



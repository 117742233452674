/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as BlackCard } from '../../../assets/svg/black.svg';
import { ReactComponent as GreyCard } from '../../../assets/svg/grey.svg';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { Logger } from '../../../libs/utils/logger';
import { getKey } from '../../../libs/utils/storageManager';
import { FxButton } from '../../Action/FxButton';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import FxCardHeader from '../../Container/FxCardHeader';
import { DataGrid } from '../../Data/DataGrid';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import { FxPaymentModeStatus } from '../../Input/FxPaymentModeStatus/FxPaymentModeStatus';
import FxTextView from '../../Input/FxText/FxTextView';
import { checkPermission, maskAccountNumber } from '../../Utils/CommonBaseClass';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../Data/FxGridList';
import moment from 'moment';
import { IFilterColumns } from '../../../types/common.interfaces';

interface RouteParams {
    id: string
}

/**
 * Component used to display customer address list
 */
 Logger.debug("EmployeeDebitCards.tsx", "issued debit cards list initializing")


export const IssuedDebitCards: React.FC<any> = React.memo(
    (props: any) => {
        const isMobile = useMediaQuery({ query: '(max-width: 999px)' });
        ({ props } = RegisterComponent(props));
    
        const params = useParams<RouteParams>();
        const id = params.id
        
        //to get debit card list
        function debitCardList(){
            return {
                url: "account/id/" + getKey("selectedAccount")?.id + "/debitCard/list",
                method: "POST",
                data: {
                    "pageNumber": 1,
                    "pageSize": 25,
                    "criteria": {
                        "filters": [
                            {
                                "operator": "in",
                                "key": "holderId",
                                "values": [id]
                            }
                        ]
                    }
                }
            }
        }
            
        const column: IFilterColumns = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "Card",
                    "enable": true,
                    "fieldName": "cardData",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "38%" }
                },
                {
                    "label": "Account",
                    "enable": true,
                    "fieldName": 'account',
                    "type": "fri://fieldtype/default",
                    "default": true,
                    "style": { "width": "20%" }
                },
                {
                    "label": "Status",
                    "enable": true,
                    "fieldName": 'status',
                    'type': 'fri://fieldtype/status',
                    "default": true,
                    "style": { "width": "15%" }
                },
                {
                    "label": "Issued",
                    "enable": true,
                    "fieldName": "createdInfo",
                    "type": "fri://fieldtype/custom",
                    "default": true
                }
            ]
        }
        /**
        * Method to transform data
        * @param data
        */
       
        function transformList(data: any) {
            try {

                const newData: any = [];

                data?.forEach((item: any, i: number) => {
                    newData[i] = { ...data[i] }
                    if (item) {
                        
                        newData[i].cardData = <Grid container xs={12} className='card-details' alignItems='center'>{item?.cardType && item?.cardType ==='VIRTUAL' ? (<BlackCard/>) : (<GreyCard/>)}
                            <div className='fx-margin-left-12'>{item?.cardLast4?"*"+item.cardLast4:'*009'}</div>&nbsp;
                            <FxTextView id={'cardHolderName'} 
                            value={`${item['cardHolder']['name'] }`} 
                            tooltip={true}>
                            </FxTextView>&nbsp;
                            <Typography>
                            <FxPaymentModeStatus tooltip={true} id={'recurring-method'} 
                            value={item?.cardType?item.cardType:'Physical'} noColor={true} fill={false} 
                            className="fx-status"></FxPaymentModeStatus>
                            </Typography>
                            </Grid> 

                        newData[i].issued = item?.lastUpdatedOn + '' + item?.lastUpdatedBy?.username
                        newData[i].account = (getKey("selectedAccount")?.purpose ? getKey("selectedAccount")?.purpose + ' '  : '') + maskAccountNumber(item?.accountNumber, false);
                        newData[i].createdInfo = <><FxDateTimeView tooltip={true} value={item['createdOn']} format={"MMM DD, yyyy"}></FxDateTimeView>
                            By <FxTextView id={'userName'} value={`${item['createdBy']['username'] }`} tooltip={true}></FxTextView></>
                    }
                });

                return newData;
            }
            catch (e) {
                Logger.error("ManageContactList.tsx", "error", e);
                return false;
            }
        }
        const history = useHistory()
        const dispatch = useDispatch()
        //rest the component on unmount.so that when redirect back page it will call the api again
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        }, [])
       
         /**
        * function to check permission 
        */
         function checkViewPermission(data: any) {
            const permissionObject: any = {
                'authuser_debitCard_issue': {
                    entity: "Accounts",
                    name: "Debit Card",
                    operation: "Issue"
                }
            }
             return checkPermission(permissionObject[data])
        }
        
        const gridColumn: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "divider",
                    "type": "fri://fieldtype/divider",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "header",
                    "type": "fri://fieldtype/header",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "description",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                    "alignItems": 'center'
                }

            ]
        }

        /**
        * Method to transform data
        * @param data
        * @returns transformed data object for mobile view
        */
        const docListGridTransformerMobile =(data:any)=>{
            const newData: any = [];
            let cur_date: any = '';
            data?.forEach((item: any, i: number) => {
                newData[i] = { ...data[i] }
                let title: any
                if (cur_date === '' || cur_date !== moment(new Date(item?.createdOn)).format('MMM DD, YYYY')) {
                    cur_date = moment(new Date(item?.createdOn)).format('MMM DD, YYYY')
                    title = moment(new Date(item?.createdOn)).format('MMM DD, YYYY')
                }
                if (!title) {
                    newData[i].divider = true
                }
                if (title) {
                    newData[i].header = <Grid item className='fx-grid-list fx-padding-top-header'> <Typography variant='h6'>{title}</Typography></Grid>
                }
                newData[i].description = 
                <Grid spacing={2} item container xs={12} alignItems='center'>
                    <Grid item container xs={9}>
                        <Grid container xs={12} className='card-details' alignItems='center'>{item?.cardType && item?.cardType ==='VIRTUAL' ? (<BlackCard/>) : (<GreyCard/>)}
                            <div className='fx-margin-left-12'>{item?.cardLast4?"*"+item.cardLast4:'*009'}</div>&nbsp;
                            <FxTextView id={'cardHolderName'} 
                            value={`${item['cardHolder']['name'] }`} 
                            tooltip={true}>
                            </FxTextView>&nbsp;
                            <Typography>
                            <FxPaymentModeStatus tooltip={true} id={'recurring-method'} 
                            value={item?.cardType?item.cardType:'Physical'} noColor={true} fill={false} 
                            className="fx-status"></FxPaymentModeStatus>
                            </Typography>
                        </Grid> 
                    </Grid>
                    <Grid item container xs={3}>
                        <Typography>
                            <FxStatus tooltip={true}  id={'status'}
                            value={item?.status?item.status:''}  fill={true} 
                            className="fx-status"></FxStatus>
                        </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                        {(getKey("selectedAccount")?.purpose ? getKey("selectedAccount")?.purpose + ' '  : '') + maskAccountNumber(item?.accountNumber, false)}
                    </Grid>
                    <Grid item container xs={12}>
                        <FxTextView id={'userName'} value={`${item['createdBy']['username'] }`} tooltip={true}></FxTextView>
                    </Grid>
                    
                </Grid>
            });
            return newData;
        }

        return (
            <Grid className="fx-container" item xs={12}>
                <Grid container xs={12} className="fx-layout-list">
                    <FxCard className=" fx-theme-passport">
                        <FxCardHeader className="fx-card-header">
                            <Grid item container justifyContent="space-between" className="fx-inline-flex" xs={12} >
                                <Typography id="title-customer-address-info" variant="h4" >Issued Debit Cards</Typography>
                                {checkViewPermission("authuser_debitCard_issue") && (getKey("selectedAccount")?.status !== 'INACTIVE' && getKey("selectedAccount")?.status !== 'CLOSURE_INITIATED' && getKey("selectedAccount")?.status !== 'CLOSED') && <FxButton
                                    disableRipple={false}
                                    className="fx-button fx-button-passport-border fx-svg-theme"
                                    id="profile-add-mailing-address"
                                    onClick={() => history.push('/debit-card/physical/'+id)}
                                    startIcon={<PlusIcon />}
                                > {isMobile ? '':'Issue Debit Card'}
                                </FxButton>}
                                
                            </Grid>
                            <div id="div-grow-title-customer-address-info" className="fx-flex-grow" />
                        </FxCardHeader>
                        <FxCardBody id="customer-address#card-body" className="fx-card-body-no-border-no-shadow">
                            {!isMobile ?(props?.data &&<DataGrid id="customer-profile-mailing-address-grid" tableClass="fx-table-passport" source={debitCardList()} column={column} dataTransformer={transformList} />):
                            (props?.data &&<FxGridList id="transaction-list-schedule" source={debitCardList()}  dataTransformer={docListGridTransformerMobile} column={gridColumn} /> )}
                        </FxCardBody>
                        <FxCardFooter id="customer-profile-mailing-address-Footer" className="fx-footer">
                            <Grid></Grid>
                        </FxCardFooter>
                    </FxCard>
                </Grid>
            </Grid>
        )
    })

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { DataGrid } from '../../Data/DataGrid';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { Logger } from '../../../libs/utils/logger';
import { FxDateTimeView } from '../../Input/FxDate/FxDateView';
import { FxButton } from '../../Action/FxButton';
import FxTextView from '../../Input/FxText/FxTextView';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../Data/FxGridList';
import moment from 'moment';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import FxModalGeneric from '../../Utils/FxModalGeneric';
import { EditAssignedRoles } from './EditAssignedRoles';
import { IFilterColumns } from '../../../types/common.interfaces';

/**
 * Component used to display assigned role list
 */
Logger.debug("AssignedRole.tsx", "assigned roles list initializing")


export const AssignedRoles: React.FC<any> = React.memo(
    (props: any) => {
        const isMobile = useMediaQuery({ query: '(max-width: 999px)' });
        ({ props } = RegisterComponent(props));
        const dispatch = useDispatch();
        const [userdata, setUserData] = useState([]);

        useEffect(()=>{
          if (props?.data?.portalAccess?.role) {
            setUserData(transormList(props.data.portalAccess.role));
          }
        },[props?.data?.portalAccess?.role])


      const onEditClick = () => {
          updateComponentParams(dispatch, props.id, {modalProps: { open: true, roles: props.data?.portalAccess?.role, userId: props.data?.id, portalAccess: props.data?.portalAccess?.grantAccess }});
        }

      /**
       * Function to handle the closing of the modal.
       */
      const handleCloseModal = () => {
        updateComponentParams(dispatch, props.id, { modalProps: { open: false } });
      };


        const column: IFilterColumns = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "Roles",
                    "enable": true,
                    "fieldName": "name",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "Assigned",
                    "enable": true,
                    "fieldName": "assignedInfo",
                    "type": "fri://fieldtype/custom",
                    "default": true
                }
            ]
        }

        const gridColumn: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "enable": true,
                    "fieldName": "divider",
                    "type": "fri://fieldtype/divider",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "header",
                    "type": "fri://fieldtype/header",
                    "xs": "12"
                },
                {
                    "enable": true,
                    "fieldName": "description",
                    "type": "fri://fieldtype/custom",
                    "xs": "12",
                    "alignItems": 'center'
                }

            ]
        }

        /**
        * Method to transform data
        * @param data
        * @returns transformed data object
        */

        function transormList(data: any) {
            try {

                const newData: any = [];
                if (data) {
                    data?.forEach((item: any, i: number) => {
                        newData[i] = { ...data[i] }
                        if (item) {
                            newData[i].assignedInfo = 
                           <Grid container xs={12} >
                             <Grid>
                                <FxDateTimeView tooltip={true} value={item['assignedOn']} format={"MMM DD, yyyy"}></FxDateTimeView>
                             </Grid>
                                <Grid>
                                    <span className='fx-padding-left-right-16'>by</span>
                                </Grid>
                                <Grid>
                                    <FxTextView id={'userName'} value={`${item['assignedBy']['username']}`} tooltip={true}></FxTextView>
                               </Grid>
                           </Grid>
                                newData[i].status = item?.assignedBy?.status;
                        }
                    });
                }


                return newData;
            }
            catch (e) {
                Logger.error("AssignedRole.tsx", "error", e);
                return false;
            }
        }

        const editPermission = {
            entity: "Authorized User",
            name: "User",
            operation: 'Edit'
        }

        /**
        * Method to transform data
        * @param item
        * @returns transformed data object for mobile view
        */
        const docListGridTransformerMobile =(data:any)=>{
            const newData: any = [];
            let cur_date: string = '';
            data?.forEach((item: any, i: number) => {
                newData[i] = { ...data[i] }
                let title: any
                if (cur_date === '' || cur_date !== moment(new Date(item?.assignedOn)).format('MMM DD, YYYY')) {
                    cur_date = moment(new Date(item?.assignedOn)).format('MMM DD, YYYY')
                    title = moment(new Date(item?.assignedOn)).format('MMM DD, YYYY')
                }
                if (!title) {
                    newData[i].divider = true
                }
                if (title) {
                    newData[i].header = <Grid item className='fx-grid-list fx-padding-top-header'> <Typography variant='h6'>{title}</Typography></Grid>
                }
                newData[i].description = <Grid item container xs={12} className='fx-grid-list-content'>
                    <Grid item container xs={10} alignItems='center' alignContent={'center'}>
                        <Grid item container xs={12}>
                            <Grid item>
                                <Grid><Typography variant='h4'>Role: {item?.name}</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item>
                                <Grid><Typography variant='h4'>{item?.assignedBy?.username}</Typography></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            });
            return newData;
        }

        return (
            <Grid id="assign-role-Grid" item xs={12}>
              <FxModalGeneric
                id="edit-assigned-roles-modal"
                open={props.modalProps?.open}
                onClose={handleCloseModal}
                componentProps={props.modalProps}
                className={'fx-modal-small2medium'}
                component={EditAssignedRoles}
                title={'Edit Assigned Roles'}
              >
              </FxModalGeneric>
                <Grid container xs={12} >
                    <FxCard id="assign-role-card" className=" fx-theme-passport">
                        <FxCardHeader id={'assign-role-card#card-header'} >
                            <Grid id="assign-Grid" item container justifyContent="space-between" xs={12} >
                                <Typography id="title-assign-role-info" variant="h4" >Assigned Roles</Typography>
                              <Grid>
                                <FxButton id="edit-user-button" permissions={editPermission} variant="contained"
                                          className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme"
                                          startIcon={<EditIcon />}
                                          onClick={onEditClick}
                                  >
                                  {isMobile ? '' : 'Edit'}
                                </FxButton>
                              </Grid>
                            </Grid>
                            <div id="div-grow-title-assign-role-info" className="fx-flex-grow" />
                        </FxCardHeader>
                        <FxCardBody id="assign-role#card-body" className="fx-card-body-no-border-no-shadow">
                            {!isMobile?<DataGrid id="roles-grid" tableClass="fx-table-passport" data={userdata} column={column}/>
                            :<FxGridList id="roles-grid" data={docListGridTransformerMobile(userdata)} column={gridColumn} /> }
                        </FxCardBody>
                        <FxCardFooter id="assign-role-Footer" className="fx-footer">
                            <Grid></Grid>
                        </FxCardFooter>
                    </FxCard>
                </Grid>
            </Grid>
        )
    })

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { TextField, Grid, OutlinedTextFieldProps } from "@mui/material";
import clsx from "clsx";
import { Controller, ControllerProps, UseFormSetValue, UseFormClearErrors, UseFormRegisterReturn, UseFormSetError } from 'react-hook-form';
import { Logger } from "../../../libs/utils/logger";
import { ReactComponent as AmexIcon } from '../../../assets/svg/cards/amex.svg';
import { ReactComponent as JcbIcon } from '../../../assets/svg/cards/jcb.svg';
import { ReactComponent as DiscoverIcon } from '../../../assets/svg/cards/discover.svg';
import { ReactComponent as DinersClubIcon } from '../../../assets/svg/cards/dinersclub.svg';
import { ReactComponent as MasterCardIcon } from '../../../assets/svg/cards/mastercard.svg';
import { ReactComponent as VisaIcon } from '../../../assets/svg/cards/visa.svg';

Logger.debug("FxCardNumberEdit.tsx", "Fx Card Number Edit initializing")

/**
 * Component used for edit card number
 */

type TFieldValues = Record<string, string>;

type FxCardNumberEditType = Partial<ControllerProps> & OutlinedTextFieldProps;

interface FxCardNumberEditProps extends FxCardNumberEditType {
  name: string;
  isEditable: boolean;
  maxLength?: number;
  clearErrors: UseFormClearErrors<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
  setError: UseFormSetError<TFieldValues>;
  register: UseFormRegisterReturn;
}

export const FxCardNumberEdit: React.FC<FxCardNumberEditProps> =
  (props) => {

    //store cc formatted value
    const [ccFormattedValue, setCCFormattedValue] = useState();

    //identified card
    const [ccCompany, setCCCompany] = useState<any>();

    // Define the cards we support. You may add additional card types as follows.
    //  Name:         As in the selection box of the form - must be same as user's
    //  Length:       List of possible valid lengths of the card number for the card
    //  prefixes:     List of possible prefixes for the card
    //  checkdigit:   Boolean to say whether there is a check digit
    const getCardTypes = () => [
      { name: 'VISA', length: [13, 16], prefixes: ['4'], icon: <VisaIcon /> },
      { name: 'MASTERCARD', length: [16], prefixes: ['51', '52', '53', '54', '55'], icon: <MasterCardIcon /> },
      { name: 'DinersClub', length: [14, 16], prefixes: ['36', '38', '54', '55'], icon: <DinersClubIcon /> },
      { name: 'AMERICAN_EXPRESS', length: [15], prefixes: ['34', '37'], icon: <AmexIcon /> },
      { name: 'DISCOVER', length: [16], prefixes: ['6011', '622', '64', '65'], icon: <DiscoverIcon /> },
      { name: 'JCB', length: [16], prefixes: ['35'], icon: <JcbIcon /> },
    ];

    /**
     * Check if the number contains only numeric value  
      and is of between 13 to 19 digits
     * @param number 
     */
    const validateCardNumber = function (number: any) {
      const regex = new RegExp("^[0-9]{13,19}$");
      if (!regex.test(number)) {
        return false;
      }

      return luhnCheck(number);
    }


    /**
     * 
     * @param val 
     */
    const luhnCheck = function (val: any) {
      let checksum = 0; // running checksum total
      let j = 1; // takes value of 1 or 2

      // Process each digit one by one starting from the last
      for (let i = val.length - 1; i >= 0; i--) {
        let calc = 0;
        // Extract the next digit and multiply by 1 or 2 on alternative digits.
        calc = Number(val.charAt(i)) * j;

        // If the result is in two digits add 1 to the checksum total
        if (calc > 9) {
          checksum = checksum + 1;
          calc = calc - 10;
        }

        // Add the units element to the checksum total
        checksum = checksum + calc;

        // Switch the value of j
        if (j === 1) {
          j = 2;
        } else {
          j = 1;
        }
      }

      //Check if it is divisible by 10 or not.
      return (checksum % 10) === 0;
    }



    /**
     * get the formatted CC number
     * @param cardNumber 
     */
    const checkCCNumber = (cardNumber: string) => {
      cardNumber = cardNumber.replace(/\s/g, '');

      if (!cardNumber) {
        return { success: false, message: 'No card number provided' };
      }


      //if match found draw icon
      validateCardNumberPrefix(cardNumber);

      if (!validateCardNumber(cardNumber)) {
        //before return check prefix is matching or not.
        return { success: false, message: 'Invalid card number format' };
      }

      const card = findCardType(cardNumber);
      if (!card) {
        return { success: false, message: 'Unknown card type' };
      }

      setCCCompany(card);

      // You can also add additional checks here if needed

      return { success: true, message: 'Card number is valid', type: card.name };
    };

    /**
     * find card type
     * @param cardNumber 
     * @returns 
     */
    const findCardType = (cardNumber: string) => {
      const cards = getCardTypes();
      for (const cardType of cards) {
        for (const prefix of cardType.prefixes) {
          const prefixArray = prefix.split(',');
          for (const pre of prefixArray) {
            if (cardNumber.startsWith(pre) && cardType.length.includes(cardNumber.length)) {
              return cardType;
            }
          }
        }
      }

      return null;
    };

    /**
     * check a prefix against the card number matches
     * @param number 
     * @returns 
     */
    const validateCardNumberPrefix = (number: string) => {
      const cards = getCardTypes();
      for (const cardType of cards) {
        for (const prefix of cardType.prefixes) {
          const prefixArray = prefix.split(',');
          for (const pre of prefixArray) {
            if (number.startsWith(pre)) {
              setCCCompany(cardType);
              return true;
            }
          }
        }
      }
      setCCCompany(null);
      return false;
    };


    //cc on change
    function ccOnChange(e: any) {
      let val = e.target.value;
      if (val) {
        val = val.replace(/\s/g, "");
        const ccVal = checkCCNumber(val);
        if (!ccVal.success) {
          // props.setError(props.name, { type: 'custom', message: ccval.message });
        }
        else {
          props.clearErrors(props.name)
        }
        setCCFormattedValue(val.match(new RegExp('.{1,4}', 'g'))?.join(" "));
        const finalVal = { ...ccVal, value: val }
        props.setValue(props.name, JSON.stringify(finalVal))
      }

      else {
        setCCFormattedValue(val)
        setCCCompany(null)
      }
    }

    return (
      <Grid item container className="fx-cc-grid" justifyContent="space-between">
        <TextField
          id={props.id + "-input"}
          name={props.name + "-input"}
          label={props.label ? props.label : ''}
          onChange={props.onChange ? props.onChange : ccOnChange}
          className={clsx(props.className ? props.className : 'fx-input-edit')}
          variant={"outlined"}
          defaultValue={props?.defaultValue}
          disabled={!props?.isEditable}
          value={ccFormattedValue}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={
            (props.maxLength) ? {
              inputProps: { maxLength: props.maxLength }
            } :
              {}
          }
        />
        <Controller
          name={props.name}
          control={props.control ? props.control : undefined}
          rules={props.rules ? props.rules : { required: false }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              {...props.register}
              id={props.id}
              name={props.name}
              disabled={props.isEditable === false ? true : false}
              defaultValue={props.defaultValue}
              // placeholder={props.placeholder ? props.placeholder : ""}
              type={'hidden'}
              value={value}
              variant={'standard'}
              onChange={props.onChange ? props.onChange : onChange}
              inputRef={props.inputRef}
              error={error ? error as unknown as boolean : false}
              helperText={props.helperText ? props.helperText : ''}
              inputProps={props.inputProps ? props.inputProps : undefined}
            />
          )}
        />
        <div className="fx-cc-grid-issuer">{ccCompany && ccCompany.icon}</div>

      </Grid>
    )
  }
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'; // we need this to make JSX
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { AUTH_STRINGS } from '../../../constants/strings';
import { setUserLoggedIn, setKey, getKey } from '../../../libs/utils/storageManager';
import { useHistory } from 'react-router';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import { AccountOpeningTermsCard } from './AccountOpeningTermsCard';
import FxCheckEdit from '../../Input/FxCheck/FxCheckEdit';
import { useMediaQuery } from 'react-responsive'
import { FxButton } from '../../Action/FxButton';
import Copyright from './Copyright';
import { FxPdfReader } from '../SignUp/Business/FxPdfReader';
import { checkPermission, getRedirectInfo, isPartner, updateComponentParams } from '../../Utils/CommonBaseClass';
import { useDispatch, useSelector } from 'react-redux';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { Logo } from '../../Utils/Logo';
import { checkIsPartner } from '../../Utils/ThemeCheckerPartner';



Logger.debug("LoginTerms.tsx", "Login Terms initializing");
/**
 * Component: LoginTerms
 * Usage: To display login terms and conditions
 */

interface InputErrType {
    type: string;
    message: string;
}

const LoginTerms = (): JSX.Element => {
    const { register, formState: { errors,isSubmitting }, handleSubmit, setValue, control } = useForm();
    const history = useHistory();
    const dispatch = useDispatch()
    const [btnDisabled, setBtnDisabled] = React.useState(true);
    const [fileInfo, setFileInfo] = React.useState<any>();
    //check if user is on the mobile device
    const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
    const iSPartner = isPartner()
    const httpClient = HttpClient.getClient();

    /**
     * UseEffect to call partner details
     */
    useEffect(()=>{
        if(iSPartner){
            getPartnerDetails()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[iSPartner])

    /**
     * Method to call partner get API 
     */
    const getPartnerDetails=async ()=>{
        const data: any = await httpClient.get(`partner/id/${getKey('partnerId')}`)
        setKey('partnerStatus',data?.data?.status)
    }
  
    
    const accountList = useSelector((state: any) => state.data['customer-basic-info']?.params?.accountList || null);

    /**
     * Method to handle form submit event
     * @param data : form data
     */
    async function onSubmit(data: any) {
        const newpayload: any = { ...data }
        newpayload.firstName = getKey('firstName');
        newpayload.lastName = getKey('lastName');
        newpayload.userType = "CUSTOMER";
        newpayload.email = getKey('email');
        newpayload.privacyPolicyAccepted = true;
        if(fileInfo !== undefined){
            newpayload.linkedDocument =  [
                {
                    "purpose": "END_USER_LICENSE_AGREEMENT",
                    "document": {
                        "name":"End_user_license_agreement.pdf",
                        "type": "END_USER_LICENSE_AGREEMENT",
                        "base64encodedContent": fileInfo
                    }
                }
            ]
        }
       /**
        * When login as co owner user has to accept he account agreement
        */
        if(getKey('userType')==='JOINT_TENANCY'  && getKey('accountAgreementAccepted') === false && accountList?.resources?.length>0 ){
            const payload:any={
                account:accountList?.resources[0],
                newPayload:newpayload
            }
            dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'co-owner-aggrement-details' } });
            updateComponentParams(dispatch, 'co-owner-aggrement-details', { 'ownerPayload': payload })
            history.push('/co-owner/welcome')
        }else
         if (iSPartner && getKey('userType')==='BUSINESS' && getKey('partnerStatus') === 'INACTIVE') {
            setUserLoggedIn();
            dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'partner-aggrement-details' } });
            updateComponentParams(dispatch, 'partner-aggrement-details', { 'newpayload': newpayload })
            history.push('/partner/welcome')
        } else{
            const status = await httpClient.post('user/acceptPrivacyPolicy', newpayload).then(response => {
                setUserLoggedIn();
                setKey("showTerms","FALSE")
                return response
            }).catch(errorss => {
                setKey("showTerms","FALSE")
                return errorss.response
            })
            if (status) {
                updateComponentParams(dispatch, 'layout-passport', { 'showTerms': 'NO' })
                //auth success
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: 'Logged in successfully',
                });
                if(checkIsPartner() && (checkPermission({ entity: "Partner Portal", name: "Lead Portal", operation: "View" }))){
                    history.push(getRedirectInfo(accountList, dispatch ,'/leads-home'))
                }
                else{
                    history.push(getRedirectInfo(accountList, dispatch ,'/dashboard'))
                }
            }
            else {
                //auth failed
                FxSnackBar.show({
                    text: 'Please try again',
                });
            }
        }        
    }


    const renderError = (err: InputErrType): string => {
        if (err.type === 'required') {
            return AUTH_STRINGS.ERRORS.AGREE_PLEASE_ENTER;
        }
        return err.message;
    };
    /**
     * Method to handle form change events
     * @param event :event object
     */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        if (event.target.checked === true) {
            setBtnDisabled(false)
        }
        else {
            setBtnDisabled(true)
        }
    }

    const privacyPolicyData = [
        { label: "By clicking continue, you accept the terms of the “PASSPORT” end user license agreement. ", value: 'yes' }
    ];



    return (
        <Grid item xs={12} container direction="row">
            <Grid item xs={12} container direction="row" alignContent="flex-start" sm={isSmallScreen ? undefined : 12}  >
                <div className="login-page">
                    <div className="login-page-content fx-terms-condition">
                        <div className="login-logo">
                            {/* logo */}
                            <Logo showFull />
                        </div>
                        <Grid id="email-forgot-label" container direction="row" item className="fx-login-text fx-login-text-media" justifyContent="center" alignItems="center">Please read and agree to the terms of “PASSPORT" end user license agreement</Grid>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="login-page-content-card ">
                                {/* terms component */}
                                <div className="flex column login-page-content-card-content login-terms-div">
                                <FxPdfReader className='fx-hidden' url='/assets/pdf/account_opening_terms.pdf' onFileInfoReady={setFileInfo}></FxPdfReader>
                                <AccountOpeningTermsCard></AccountOpeningTermsCard>
                                </div>
                                <div className="flex column login-page-content-card-content">
                                    <br />
                                    <div className="login-terms-footer">
                                        <FxCheckEdit register={{ ...register("privacyPolicyAccepted") }} rules={{ required: "Please click Agree" }} control={control} onChange={(e: any) => { handleChange(e); }} id="privacy-policy-accepted" name="privacyPolicyAccepted" data={privacyPolicyData} className="fx-privacy-text" row="vertical" setValue={setValue} />
                                    </div>
                                    <div className={'error-message'}>
                                        {errors.privacyPolicyAccepted && renderError(errors.privacyPolicyAccepted)}
                                    </div>
                                </div>
                                <div className="flex login-buttonarea-terms">
                                    <FxButton
                                        disableRipple={false}
                                        className={btnDisabled ? "fx-button fx-button-disabled" : "fx-button login-signin"}
                                        id="login-terms-signin"
                                        type="submit"
                                        disabled={btnDisabled}
                                        isSubmitting={isSubmitting}
                                    >Continue</FxButton>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Grid item xs={12} className="login-page-bottomstatic">
                <Copyright />
            </Grid>
                </div>
            </Grid>
            {/* footer */}

        </Grid>

    );
};

export default LoginTerms;

/* eslint-disable */
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { TextField } from '@mui/material';
import clsx from 'clsx';
import { Controller, ControllerProps, UseFormSetError, UseFormSetValue, UseFormClearErrors, UseFormRegisterReturn } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { ISource } from '../../../types/common.interfaces';
const httpClient = HttpClient.getClient();

/***
 * Component used to do AutoComplete Actions in fields
 */
Logger.debug("FxAutoComplete.tsx", "Fx Auto Complete initializing")

type TFieldValues = Record<string, string>;

interface FxAutoCompleteProps extends Partial<ControllerProps> {
    id?: string;
    name: string;
    placeholder?: string;
    className?: string;
    pageSize?: number;
    setError: UseFormSetError<TFieldValues>;
    setValue: UseFormSetValue<TFieldValues>;
    clearError: UseFormClearErrors<TFieldValues>;
    register: UseFormRegisterReturn;
    isEditable?: boolean;
    helperText?: string;
    lens?: boolean;
    source: ISource;
    dataTransformer: (data: any) => any;
    onChange?: (event: any) => any;
    label?: string;
    filterOptions?: any[];
    setParam?: (event: any) => void;
    searchBy: string;
    defaultOperator?: string;
    defaultOptions?: any;
    inputRef?: any;
    resetField: any;
    error?: any;
    options?: any;
    value?: any;
}

export const FxAutoComplete: React.FC<FxAutoCompleteProps> =
  (props) => {

    ({ props } = RegisterComponent(props));

    const [value, setValue] = useState(props.value ? props.value : '');

    /**
     * Function for handle the onChange event
     * @param obj :event data
     */
    const handleOptionsChange = (obj: any) => {
      if (props.setValue && obj?.value) {
        props.setValue(props.name, obj?.value)
      }
      if (props.resetField && !obj?.value) {
        props.resetField(props.name)
      }
      if (props.setError && !obj?.value) {
        props.setError(props.name,{})
      }
      if (props.clearError && obj?.value) {
        props.clearError(props.name)
      }
      if (props.setParam) {
        props.setParam(obj)
      }
      setValue(obj)
    }
    
    /**
     * Method used to call api for data filtering
     * @param value :value
     * @returns data object
     */
    async function getSelectedData(value: any) {
      try {
        if (props.source?.method === 'POST') {
          let request: any = {}
          let operator: any;

          props.filterOptions?.forEach((item: any) => {
            if (item.value === props.searchBy) {
              operator = item.operator;
            }
          });
          if (!props.filterOptions && props.defaultOperator) {
            operator = props.defaultOperator;
          }
          const filters = props.source?.data?.criteria?.filters ? [...props.source?.data?.criteria?.filters] : [];
          const searchedFilter: any = {
            "operator": operator,
            "key": props.searchBy,
            "values": [value]
          };
          filters.push(searchedFilter);
          request = {
            "pageNumber": 1,
            // expiry:3600,
            "pageSize": props.pageSize ? props.pageSize : 10,
            "criteria": {
              "filters": filters
            }
          };

           //some api doesn't require customer/id prefix
          const newHttpClient = props.source.baseUrl ?   httpClient : httpClient

          const data: any = await newHttpClient.post(getCustomerUrl(props.source.url,props.source.baseUrl), request).then(response => {
            
            if (response.data) {
              return response.data
            }

          })
          let transformData: any = [];
          if (props.dataTransformer) {
            transformData = props.dataTransformer(data.resources)
          }
          return transformData;
        }

      } catch (err) {
        Logger.error("FxAutoComplete.tsx", "error", err);
        return false;
      }
    }

    // function for load the options by on key pressing
    const promiseOptions = async (inputValue: any, callback: any) => {
      if (String(inputValue).length >= 3) {
        const selectedData = await getSelectedData(inputValue);
        callback(selectedData ? selectedData : []);

      }
    };

    const SelectComponent = AsyncSelect;
    const _className = "fx-auto-def fx-select-def fx-input-edit";

    return (<>
      <Controller
        name={props.name}
        control={props.control ? props.control : undefined}
        rules={props.rules ? props.rules : { required: false }}
        render={({ field: { onChange, ref }, fieldState: { error } }) => (
          <SelectComponent
            {...props.register}
            id={"sel-" + props.id + "-autocomplete"}
            isMulti={false}
            onChange={handleOptionsChange}
            value={value && value}
            isClearable
            defaultOptions={props?.defaultOptions ? props.defaultOptions : []}
              //@ts-ignore
            loadOptions={promiseOptions}
            className={props.className ? clsx(_className, props.className) : _className}
            isDisabled={props.isEditable === false ? true : false}
            error={error ? error : false}
            helperText={props.helperText ? props.helperText : ''}
            placeholder={props.placeholder ? props.placeholder : 'Search...'}
            formatCreateLabel={() => undefined}
            styles={{
              menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
              control: (styles:any) => ({
                ...styles,
                borderColor: props.error ? 'red' : styles.borderColor
              }), valueContainer: (base:any) => ({ ...base, paddingLeft: props.lens ? 24 : 2 })
            }}
            classNames={{
              option: ({ isDisabled, isFocused, isSelected }) =>
                  isSelected ? 'fx-theme-select' :''
            }}
            inputRef={props.inputRef ? props.inputRef : ''}
            noOptionsMessage={({inputValue}) => !inputValue ? null : "No record found"} 

            instanceId={props.id}
          />
        )}
      />
      <TextField
        className="fx-hidden"
        id={"react-select-" + props.id + "-autocomplete-input"}
        name={props.name}
        value={value && value.value ? value.value : ""}
        inputRef={props.inputRef ? props.inputRef : ''}
        type={'hidden'} />
    </>
    );
  }


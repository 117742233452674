/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { FormGroup, Checkbox, FormControlLabel } from '@mui/material';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Controller, ControllerProps, UseFormSetValue } from 'react-hook-form';
import { Logger } from '../../../libs/utils/logger';

Logger.debug("FxCheckEdit.tsx", "Fx Check Edit initializing")

/**
 * Component used to display checkbox
 */

type TFieldValues = Record<string, string>;

interface FxCheckEditProps extends Partial<ControllerProps> {
    id: string;
    name: string;
    onChange?: (event: any, name?: any) => void;
    className?: string;
    data: any;
    disabled?: boolean;
    row?: string;
    setValue?: UseFormSetValue<TFieldValues>;
    register?: UseFormRegisterReturn;
    label?: string;
    updateOptions?:boolean;
}

/*
    * Component Function for Checkbox component
    * @param  {FxCheckEditProps} props with accepted values
*/
const FxCheckEdit: React.FC<FxCheckEditProps> =
  (props) => {
    const [options, setOptions] = useState(props.data ? props.data : []);

    useEffect(()=>{
      if(props?.updateOptions) {
        setOptions(props.data);
      }
    },[props?.data, props?.updateOptions]);

    /**
     * Function for handle the onChange event
     * @param event :event data
     */
    function handleChange(event: any) {

      const checkOptions: any = [...options];
      const checkArray: any = [];
      const updatedCheckOptions = checkOptions.map((item: any) => {
        if (item.value === event.target.value) {
          return { ...item, checked: event.target.checked };
        }
        return item;
      });
      
      updatedCheckOptions.forEach((item: any) => {
        if (item.checked) {
          checkArray.push(item.value);
        }
      });
      setOptions(updatedCheckOptions);
      if (props.setValue) {
        props.setValue(props.name, checkArray)
      }
      if (props.onChange) {
        props.onChange(event);
      }
    }

    return (
      <div>
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules ? props.rules : { required: false }}
          render={({ field: { onChange, value } }) => (
            <FormGroup row={props.row === 'vertical' ? false : true} className={props.className ? props.className : 'fx-group-checkbox' }>
              {options?.map((option: any, i: number) => {
                return <FormControlLabel name={props.name} id={'check-label-' + props.id} control={<Checkbox onChange={handleChange} id={props.id + '-' + option.value} value={option.value} />} label={option.label} checked={option.checked} disabled={props.disabled ? props.disabled : option.disabled ? option.disabled : false} />
              })}
            </FormGroup>
          )}
        />
      </div>
    );
  }
export default FxCheckEdit;

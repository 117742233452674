/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import HttpClient from "../../../libs/utils/httpClient";
import { Logger } from "../../../libs/utils/logger";
import FxMaterialSelect from "../../Input/FxSelect/FxMaterialSelect";
import { removeUnderScoreConvertTitleCase, updateComponentParams } from "../../Utils/CommonBaseClass";
import FxCard from "../../Container/FxCard";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCardBody from "../../Container/FxCardBody";
import FxCardFooter from "../../Container/FxCardFooter";
import { FxButton } from "../../Action/FxButton";
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/transaction-edit.svg';
import { ReactComponent as ArrowPermsRight } from '../../../assets/svg/arrowPermsRight.svg';
import { ReactComponent as ArrowPermsUp } from '../../../assets/svg/arrowPermsUp.svg';
import { getCustomerUrl } from "../../../libs/utils/utils";
import CriteriaForm from "./CriteriaForm";
const httpClient = HttpClient.getClient();

Logger.debug(
  "FxCriteriaForm.tsx",
  "FxCriteriaForm"
);

/**
 * Component: FxCriteriaForm
 * Usage: create criteria setcion for approval policies
 */
export const FxCriteraForm: React.FC<any> = React.memo((props) => {
  ({ props } = RegisterComponent(props));
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
    reset,
    getValues,
  } = useForm();

  const TransactionSendModes = [
    { label: 'ACH', value: "ACH" },
    { label: 'WIRE', value: "WIRE"},
    { label: 'INTERNATIONAL WIRE', value: "INTERNATIONAL_WIRE"},
    { label: 'CHECK', value: "CHECK"},
    { label: 'BOOK', value: "BOOK"},
    { label: 'VIRTUAL CARD', value: "VIRTUAL_CARD"}
  ];

  const { criteria, isEdit } = props.form; 
  const [approverList, setApproverList] = React.useState<any>([]);
  const [applicableMethodsData, setApplicableMethodsData] = useState(TransactionSendModes);
    /**
    * reset the component
    * */
    useEffect(() => {
        dispatch({
        type: "DATA_COMPONENT_RESET",
        payload: { id: "criteria-form-form" },
        });
        updateComponentParams(dispatch, props?.id, {});
    }, []);

    /**
     * create a Form Field Array
     */
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'policy',
        shouldUnregister: true
    });

    /**
     * To initialize the Field Array
     */
    useEffect(() => {
        if(criteria){
          handleReset();
        }else{
            append({});
        }
    }, []);

    /**
     * To initialize the Methods
     */
    useEffect(()=>{
      if(props?.methodsFilter.length>0){
        getTransactionSendMethods()
      }
    },[props?.methodsFilter])

    /**
     * Method to add Field
     */
    function handleAppend() {
        append({});
    }

    /**
     * Method to remove Field
     */
    function handleReset() {
      let policy = criteria?.policy;
      const applicableToMethod = criteria?.applicableToMethod || '';
      if(!policy){
        policy = [{condition: '', amount: '',fromAmount:'',toAmount:'', approvedBy: 'User', approver: []}];
      }    

      const formValues = {applicableToMethod, policy};
      reset(formValues);      
    }

    /**
     * Method to cancel Field
     */
    function handleCancel() {
      props.handleCancel(props.id)        
    }

    /**
     * Method to transform approver data as dropdown options
     * @param data : approver name data
     */
    function approverTransformer(data: any) {
        try {
        setApproverList(data?.map((item: any) => {
            return {
            label: `${item.firstName} ${item.lastName} (ID: ${item.id})`,
            value: item.id,
            };
        }));
        } catch (e) {
          Logger.error('FxCriterialForm.tsx','error',e)
        }
    }

    /**
     * Method to handle on submit request
     * @param data : form data
     */
    const handleAddCriteria = (data:any) => {
        if (props.onSubmit) {
            props.onSubmit(data,props.id)
        }
    }

    const operatorData = [
        { label: "Greater Than", value: "GT" },
        { label: "Less Than", value: "LT" },
        { label: "Range", value: "IN" }
    ];

    const getTransactionSendMethods = () => {
        const methods = TransactionSendModes.filter((item) =>{
          if(!props.methodsFilter.includes(item.value)){
            return item
          } else {
            return null
          }
        })
        setApplicableMethodsData(methods);
    }

    const getOperator = (op:any) => {
        const operator:any = operatorData.filter((item)=> item.value === op)[0]
        return operator.label
    }

    /**
     * Method to handle row click
     * @param event 
     */
    const handleArrowClick = () => {
        updateComponentParams(dispatch, props?.id, { "expand": !props.expand });
    }

    const methodPermissions : any = {
        "ACH" : "CUSTOMER_APPROVE_SEND_ACH_TRANSACTION",
        "CHECK" : "CUSTOMER_APPROVE_SEND_CHECK_TRANSACTION",
        "WIRE" : "CUSTOMER_APPROVE_SEND_WIRE_TRANSACTION",
        "VIRTUAL_CARD" : "CUSTOMER_APPROVE_SEND_VIRTUAL_CARD_TRANSACTION",
        "INTERNATIONAL_WIRE" : "CUSTOMER_APPROVE_SEND_INTERNATIONAL_WIRE_TRANSACTION",
        "BOOK" : "CUSTOMER_APPROVE_SEND_BOOK_TRANSACTION"
    }

    /**
     * Method to handle on change of Applicable Method
     * @param event 
     */
    const handleMethodChange = (event:any) => {
        getAuthorisedUserList(methodPermissions[event.target.value]);
    }

    /**
     * Method to get Authorized user list
     * @param filter
     */
    const getAuthorisedUserList = async (filter:any) => {
        try {
            const payloadData = {
                "pageNumber": 1,
                "pageSize": 25,
                "sortOptions": {
                "sortBy": "lastUpdatedOn",
                "sortOrder": "desc"
                },
                "criteria": {
                "filters": [
                    {
                        "key": "permission",
                        "operator": "eq",
                        "values": [filter]
                    }    
                ]
                }
            }
            await httpClient.post(getCustomerUrl('/authorizedUser/list'),payloadData).then(response => {
                approverTransformer(response?.data?.resources);
            });
        } catch (e) { 
          Logger.error('FxCriteriaForm.tsx','error',e);
        }
    }

    const onEditApplicableForm=()=>{
      handleReset()
      props?.handleEdit(props?.id);         
    }

  return (
      <fieldset className="fx-container-create-schdule-summery fx-container-criteria-summery">
        { !isEdit ? <>
            <Accordion expanded={props.expand === true} className={'fx-criteria-accordion'}>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="fx-criteria-accordion-summary"
            >
                <Grid container item alignItems={'center'}  spacing={1.5} className="fx-form-edit-profile" >
                  <Grid item xs={'auto'} container alignItems={'center'}>
                    {!props.expand && <ArrowPermsRight  onClick={handleArrowClick} /> }
                    {props.expand &&<ArrowPermsUp  onClick={handleArrowClick}/> }
                  </Grid>
                  <Grid item>
                    <Typography variant="h3" className="fx-summary-title">Applicable to Send Money Via {removeUnderScoreConvertTitleCase(criteria?.applicableToMethod)}</Typography>
                  </Grid>
                  <Grid item container xs={'auto'} spacing={0.5} alignItems={'center'}>
                    <Grid item>
                      <span className="fx-count-container">{criteria?.policy.length}</span>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" className="fx-condition">Conditions</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>  
              <AccordionDetails>
                  <Grid container direction="column" spacing={2} marginLeft='3rem'>
                    {criteria?.policy.map((item:any)=>
                      <Grid container item direction="column" className="fx-criteria-accordion-detail" >
                        <Grid item xs>
                          { item?.condition === 'IN' ?
                            <Typography variant="h6">{getOperator(item?.condition) + ' : $' + item?.fromAmount + ' to ' + item?.toAmount}</Typography>:
                            <Typography variant="h6">{getOperator(item?.condition) + ' $' + item?.amount}</Typography>
                          }
                        </Grid>
                        <Grid container item alignItems={'center'} marginTop={0.5}>
                          <Grid item className="fx-criteria-accordion-detail-label" marginRight={2}>
                            <Typography variant="body1">Approved By any User</Typography>
                          </Grid>
                          {item?.approver?.map((app:any)=>(
                            <Grid item className="fx-criteria-accordion-detail-approver-chip" marginRight={1}>
                                <Typography variant="body1">{app.label}</Typography>
                            </Grid>
                            ))}
                        </Grid>
                      </Grid> 
                    )}  
                  </Grid>
                </AccordionDetails>
            </Accordion>
          <Grid container item direction='row' spacing={props.expand === true ? 2 : 0} marginLeft='4.7rem'>
            <Grid marginRight={2}>
            <FxButton
              disableRipple={false}
              id={"edit-criteria-btn"}
              className="fx-button fx-button-theme fx-button-passport-border"
              startIcon={<EditIcon />}
              onClick={onEditApplicableForm}
            >
              EDIT
            </FxButton>
            </Grid> 
            {props?.formCount > 1 && <Grid marginRight={2}>
              <FxButton
                disableRipple={false}
                id={"clone-criteria-btn"}
                variant="contained" 
                className="fx-button fx-button-action"
                startIcon={<DeleteIcon />}
                onClick={()=>props?.handleDelete(props?.id)}
              >
                DELETE
              </FxButton>
            </Grid>}
          </Grid>
        </> :
        <FxCard>
          <FxCardHeader id="create-approval-form-card-header">
            <Grid item container xs={12} alignItems={'center'}>
              <Grid item xs={'auto'} className={'fx-card-header'}>Applicable To</Grid>
              <Grid item xs={4} marginLeft='1.08rem' paddingBottom={'1.375rem'}>
                <FxMaterialSelect
                  name={"applicableToMethod"}
                  control={control}
                  rules={{ required: true }}
                  register={{ ...register("applicableToMethod") }}
                  id="applicable-to-send-method"
                  value={getValues('applicableToMethod') ? getValues('applicableToMethod'):''}
                  placeholder="Select"
                  data={applicableMethodsData}
                  setValue={setValue}
                  onChange={handleMethodChange}
                  className={errors?.applicableToMethod && "border-error-input"}
                />
              </Grid>
            </Grid>
          </FxCardHeader>
           <FxCardBody id="create-approval-form-card-body" className="fx-info-card" >
                <Grid container item direction="row" spacing={2} className="fx-form-edit-profile" >
                    <Grid item xs>
                        <fieldset className="fx-container-create-schdule-summery fx-condition-summary">
                            {fields.map((field, index) => {
                                return (
                                    <CriteriaForm
                                        applicableMethod={getValues('applicableToMethod')}
                                        data ={criteria}
                                        approverList ={approverList}
                                        control={control}
                                        index={index}
                                        value={field}
                                        setValue={setValue}
                                        register={register}
                                        errors={errors}
                                        handleAppend={handleAppend}
                                        handleRemove={()=>remove(index)}
                                        isAddButtonDisabled={fields.length === 3 || fields.length !== index + 1} fieldLength={fields.length}
                                        getValues={getValues}
                                        update={update}
                                    />
                                )
                            })}
                        </fieldset>
                    </Grid>
                </Grid>
            </FxCardBody>
          <FxCardFooter id="create-approval-form-card-footer" className={props?.isEdit ? `fx-footer fx-footer-approval-form` :'fx-footer'}>
            {props?.isEdit ?
              <Grid direction="row" justifyContent="start" className="fx-modal-footer" paddingTop='1.75rem'>
                {/* <Grid item>  */}
                  <FxButton
                    disableRipple={false}
                    className="fx-button fx-button-theme"
                    id={"create-approval-form-card-"}
                    onClick={handleSubmit((data) => handleAddCriteria(data))}
                  >
                    SAVE CHANGES
                  </FxButton>
                {/* </Grid>     */}
                
                <span className="fx-padding-right-16" />
                <FxButton variant="contained"
                  className="fx-button fx-button-cancel"
                  id="create-approval-form-card-cancel-button"
                  onClick={handleCancel}
                >
                  Cancel
                </FxButton>
              </Grid> :
              <Grid item direction="row" justifyContent="flex-end" className="fx-modal-footer" paddingTop='1.75rem'>
                <FxButton variant="contained"
                  className="fx-button fx-button-cancel"
                  id="create-approval-form-card-cancel-button"
                  onClick={handleReset}
                >
                  RESET
                </FxButton>
                <span className="fx-padding-right-16" />
                <FxButton
                  disableRipple={false}
                  className="fx-button fx-button-theme"
                  id={"create-approval-form-card-"}
                  onClick={handleSubmit((data) => handleAddCriteria(data))}
                >
                  CONTINUE
                </FxButton>
              </Grid>
          }            
          </FxCardFooter>
        </FxCard>}
      </fieldset>
  );
});

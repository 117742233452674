/*
*@sarath
Fx Button component
extends from the Material button and
enhances for the airbase requirements
//do not try to call material button directly
*/
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from "react";
import { Button, CircularProgress, ButtonProps, Tooltip } from "@mui/material";
import { Logger } from "../../libs/utils/logger";
import { checkPermission } from "../Utils/CommonBaseClass";
import clsx from "clsx";

Logger.debug("FxButton.tsx", "Fx Button initializing")

/*
button interface extends common button properties for specific properties
extending the properties for the divider function
*/
/*
  this component support both standard attributes as well as the
  attributes setting from the JSON like config.
*/

export interface FxButtonProps extends ButtonProps {
  permissions?: any,
  isSubmitting? : any,
  multiplePermission?: boolean,
  /**
   * Message for showing as tooltip when button action is not allowed
   * By passing this message the button will be not clickable
   * Will not work with disabled = true
   */
  forbiddenMessage?: string;
}

//FX Button Component
export const FxButton: React.FC<FxButtonProps> = props => {
  Logger.info("FxButton.tsx", "buttonprops", props);

   /**
   * to set permission
   */
  const permission = useMemo(() => {
    let flag: any = false;
      if(props.multiplePermission){
        props.permissions?.map((item:any)=>{
          if(!flag){
            flag = checkPermission(item)
          }
          if(flag===true){
            return flag
          }
        })
      }
      else{
        flag = checkPermission(props.permissions)
      }
      return flag
  }, []);

  const {onClick, forbiddenMessage, className} = props;
  const handleClick = useCallback((e: any) => {
    if(forbiddenMessage) {
      return;
    }
    onClick?.(e);
  }, [onClick, forbiddenMessage]);

  return (
    <Tooltip
      title={forbiddenMessage}
      PopperProps={{className: 'fx-tooltip-popper-forbidden'}}
    >
      {permission ? <Button
        {...props}
        type={forbiddenMessage ? 'button' : props.type}
        className={clsx(className, forbiddenMessage ? 'fx-forbidden' : '')}
        disabled={props?.disabled || props?.isSubmitting}
        onClick={handleClick}
      >
        {props?.isSubmitting && (
          <CircularProgress
            size={20}
            className={'fx-button-circular-progress'}
          />
        )}
        {props.children}
      </Button> : <></>}
    </Tooltip>
  );
};

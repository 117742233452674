/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Checkbox, Grid } from '@mui/material';
import { ReactComponent as FastFundingCongratsIcon } from '../../assets/svg/fast-funding-congratulation.svg';
import { FxDialogCard } from '../Utils/FxDialogCard';
import UIFeatureImpl from '../../libs/services/uiFeatures';

interface IFxFastFundingCard {
    onButtonClick?: (event: any) => void;
    disabled?:any;
    className?:any;
}


export const FxFastFundingCard: React.FC<IFxFastFundingCard> = React.memo(({ onButtonClick}) => {

    const [btnDisabled, setBtnDisabled] = React.useState(true);
     
    const uiFeat = UIFeatureImpl.getInstance();

    
     /**
     * Method to handle form change events
     * @param event :event object
     */
     const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any) => {
        setBtnDisabled(!event.target.checked)
    }
    return (
        <Grid xs={12} className={'fx-faster-funding-card'}>
            <FxDialogCard
                messageContent={
                    <div>
                        <Checkbox className={'fx-faster-funding-card-checkbox'} onChange={handleChange}/>
                        {uiFeat.getFasterFunding().innerText}
                    </div>
                }
                Icon={FastFundingCongratsIcon}
                title={'Funding via Passport'}
                buttonText={'Enable Faster funding now!'}
                disabled={btnDisabled}
                onButtonClick={onButtonClick}
            />
        </Grid>
    )
})
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
/**
 * file contains common global level methods
 */

import React from 'react';
import moment from 'moment';
import { ReactComponent as CircleIcon } from '../../assets/svg/link-circle.svg';
import { AUTH_STRINGS } from '../../constants/strings';
import { getLocalStorage } from '../../libs/utils/cacheHandler';
import HttpClient from '../../libs/utils/httpClient';
import { getKey, getSessionStorageCustom, setKey, setSessionStorageCustom } from '../../libs/utils/storageManager';
import usa_state from '../Utils/usa_states.json';
import countries from '../../libs/utils/country/country.json';
import _ from "lodash";
import { Logger } from '../../libs/utils/logger';
import { FxInfoTooltip } from './FxInfoTooltip';
import { ReactComponent as DarkInfoIcon } from '../../assets/svg/darkgrey-info-icon.svg';
import { createTransactionUrl } from '../Page/Schedules/ScheduleGlobalFunctions';
import UIFeatureImpl, { applyTransactionFeatures } from '../../libs/services/uiFeatures';

const httpClient = HttpClient.getClient();
const uiFeat = UIFeatureImpl.getInstance();

 interface InputErrType {
    type: string;
    message: string;
}
/**
 * mask the account number
 * @param value
 * @param unMask
 */
export function maskAccountNumber(value: any, unMask: boolean) {
    if (value) {
        if (unMask) {
            return value;
        }
        value = value.toString();
        if (value.length >= 3 && value.charAt(0) !== '#') {
            return '***' + value.substring(value.length - 4, value.length);
        } else {
            return value;
        }
    } else {
        return '';
    }
}

/**
 *  account number last 4 digits
 * @param value
 * @param unMask
 */
export function maskAccountNumberCircle(value: any, unMask: boolean) {
    if (value) {
        if (unMask) {
            return value;
        }
        value = value?.toString();
        if (value.length >= 3 && value.charAt(0) !== '#') {
            return <><CircleIcon/> <CircleIcon/>&nbsp;{value.substring(value.length - 4, value.length)} </>
        } else {
            return value;
        }
    } else {
        return '';
    }
}


/**
 * Format date
 * @param date
 */

export function formatDate(date: any) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function formatMonth(date: any) {
    const d = new Date(date);
    const month = "0" + (date.getMonth() + 1);
    return (d.getFullYear() + "-" + (month.slice(-2)));
}

export function isNotUndefined(input: any) {
    return input !== undefined;
}

export function isNotNull(input: any) {
    return isNotUndefined(input) && input !== null;
}
/**
 * fins the expiry date
 * @param month
 * @param year
 */
export function getExpiryDate(month: string, year: any) {
    let eMonth = month;
    let eYear = year;
    if (month && month.toString().length === 1) {
        eMonth = '0' + month;
    }
    if (year && year.toString().length === 2) {
        eYear = '20' + year;
    }
   const expiry = eMonth + '/' + eYear;
    return expiry
}

/**
 * format the address
 * @param format
 * @param data
 */
export function addressFormatter(format: string, data: any) {
    let out = ''
    out += isNotNull(data['addressLine1']) && data['addressLine1'] !== ',' ? data['addressLine1'] + ', ' : ''
    out += isNotNull(data['addressLine2']) && data['addressLine2'] !== ',' && data['addressLine2'] !== '' ? data['addressLine2'] + ', ' : ''

    if (format === '2l') {
        out += '\n';
    }

    out += isNotNull(data['city']) && data['city'] !== ',' ? data['city'] + ', ' : ''
    out += isNotNull(data['state']) && data['state'] !== ',' ? data['state'].toUpperCase() : ''

    out += isNotNull(data['zip']) && data['zip'] !== ',' ? ' ' + data['zip'] : ''
    if (out === '') {
        return '-';
    }
    return out.replace(/u2013/g, '-');
}

export function clean(obj: any) {
    for (const propName in obj) {
        if ((obj[propName] === null || obj[propName] === undefined || obj[propName] === "") ) {
            delete obj[propName];
        }
    }
    return obj
}
// Function removes the unwanted items belongs to the deleteArray and returns object
export function deleteUnwantedRequest(obj: any, deleteArray: any) {
    for (const propName in obj) {
        if(deleteArray.includes(propName)){
            delete obj[propName];
        }
    }
    return obj
}

/**
 * convert to title case
 * @param str
 */
export function toTitleCase(str: any) {
    try{
        return !isEmpty(str) && str.replace(
            /\w\S*/g,
            function (txt: any) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }
    catch(e){
        Logger.error("toTitleCase", "error", e);
    }
}

/**
 * define bread crumb links
 * @param data
 */
export function breadCrumbGlobal(data: any) {
    if (data === 'send-check') {
        return [{ title: "Send Money to", link: '/send' }, { title: "My Account", link: '/transaction/send/add/check' }]
    } else if (data === 'my-account-ach') {
        return [{ title: "Collect Money from", link: '/collect' }, { title: "My Account", link: '/transaction/collect/add/my-account-ach' }]
    } else if (data === 'payer-ach') {
        return [{ title: "Collect Money from", link: '/collect' }, { title: 'My Payer', link: '/transaction/collect/add/payer-ach' }]
    } else if (data === 'payer-card') {
        return [{ title: "Collect Money from", link: '/collect' }, { title: 'My Payer', link: '/transaction/collect/add/payer-card' }]
    } else if (data === 'send-ach') {
        return [{ title: "Send Money to", link: '/send' }, { title: "My Account", link: '/transaction/send/add/ach' }]
    } else if (data === 'send-wire') {
        return [{ title: "Send Money to", link: '/send' }, { title: "My Account", link: '/transaction/send/add/wire' }]
    } else if (data === 'send-other-ach') {
        return [{ title: "Send Money to", link: '/send' }, { title: "My Account", link: '/transaction/send/other/add/ach' }]
    } else if (data === 'send-other-wire') {
        return [{ title: "Send Money to", link: '/send' }, { title: "My Account", link: '/transaction/send/other/add/wire' }]
    } else if (data === 'send-other-check') {
        return [{ title: "Send Money to", link: '/send' }, { title: "My Account", link: '/transaction/send/other/add/check' }]
    }
    else if (data === 'send-contact-ach') {
        return [{ title: "Send Money to", link: '/send' }, { title: "My Contact", link: '/transaction/send/contact/add/ach' }]
    }
    else if (data === 'send-contact-wire') {
        return [{ title: "Send Money to", link: '/send' }, { title: "My Contact", link: '/transaction/send/contact/add/wire' }]
    }
    else if (data === 'send-contact-check') {
        return [{ title: "Send Money to", link: '/send' }, { title: "My Contact", link: '/transaction/send/contact/add/check' }]
    }
}

/**
 * download the file
 * @param data
 * @param type
 * @param fileName
 */
export const downloadFileCommonClass = (data: any, type: any,fileName:any) => {
    let extension: any;
    let mime: any;
    if (type === 'CSV') {
        extension = 'csv';
        mime = 'text/csv';
    }
    const link = document.createElement('a');
    link.download = fileName + '.' + extension;
    const blob:any = new Blob(
         [data],
         {type: mime});
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
}

// function for setting the minimum age limit
export function ageLimit(limit: any){
    return moment(new Date().setFullYear(new Date().getFullYear()-limit)).format("MM/DD/YYYY");
}

export function commaSeperatedValueFromArray(data: Array<string>) {
   return data.join(',');
}
/**
 * mask the phone number
 * @param value
 */
export function maskPhoneNumber(value: any) {
    if (value !== null) {
        value = value.toString();
        if (value.length >= 3 && value.charAt(0) !== '#') {
            return '**' + value.substring(value.length - 4, value.length);
        } else {
            return value;
        }
    } else {
        return '-';
    }
}

/**
 * mask the email
 * @param value
 */
export function maskEmail(value: any) {
    if (value !== null) {
       value = value.toString();
       if(value.includes('@')){
            let prefix = value.split('@')[0];
            prefix = prefix[0] + new Array(prefix.length - 1).join('*') + prefix[prefix.length - 1];
            value = prefix+'@'+value.split('@')[1];
            return value;
       }
    } else {
        return '-';
    }
}

/**
 * convert to upper case
 * @param value
 */
export function convertToCapitalLetters(value: any) {
    return value.toUpperCase();
}

/**
 * convert to lower case
 * @param value
 */
export function convertToSmallLetters(value: any) {
    return value.toLowerCase();
}

/**
  * zip validation constant
  * */
export const setZipValidationOptions = (mandatory: boolean,regex?:any) => ({
            required: mandatory,
            //AUTH_STRINGS.ERRORS.CUSTOMER_ZIP_PLEASE_ENTER
            pattern: {
                value:regex || /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/,
                message: AUTH_STRINGS.ERRORS.ZIP_INVALID_REGEXP,
            },
});

/**
  * Routing number validations
  */
export const setRoutingNumberValidation = (mandatory: boolean) => ({
    required: mandatory,
    minLength: 9,
    maxLength: 9,
    pattern: {
        value: /^[0-9-_&,.]*$/,
        message: AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_REGEXP,
    },
});

/**
 * wire routing number validation constant
 */
export const setWireRoutingNumberValidation =  (mandatory: boolean) => ({
    required: mandatory,
    minLength: 9,
    maxLength: 9,
    pattern: {
        value: /^[0-9-_&,.]*$/,
        message: AUTH_STRINGS.ERRORS.WIRE_ROUTING_NUMBER_INVALID_REGEXP,
    },
});

/**
  *  Method for rendering error
  * */
export const renderError = (err: InputErrType): string => {
            return err.message;
};

// Method for formatting zip in the correct format
export const handleZipChange = (event: any,setValue: any,setError: any,clearErrors: any) => {
    try{
                let val = event.target.value.replace(/\D/g, '');
                if(val.length>5){
                    val = val.replace(/^(\d{5})/, '$1-');
                    val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
                }
                if (val.length === 5 || val.length === 10 || val.length === 0) {
                    clearErrors(event.target.name);
                }
                else {
                    setError(event.target.name, {
                        type: "manual",
                        message: "ZIP code must contain either 5 or 9 digits - in the format XXXXX OR XXXXX-XXXX.",
                    });
                }
                setValue(event.target.name, val);
    }
    catch(e){
        Logger.error("CommonBaseClass.tsx", "handleZipChange error", e)
    }
}

/**
  *  Method for validating address length
  * */ 
export const handleAddressChange = (event: any,setValue: any,setError: any,clearErrors: any) => {
    try{
        const { name, value } = event.target;
        const minlength = 4;
        const maxLength = 40;
        if(value.length >= minlength && value.length <= maxLength){
            clearErrors(name, value)
        }else{
            setError(name, {
                type: "manual",
                message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
            });
        }
        setValue(name, value)
    }
    catch(e){
        Logger.error("CommonBaseClass.tsx", "handleAddressChange error", e)   
    }
}

/**
* Method to validate Secondary Identification Id
*/
export const handleSecIdChange = (event: any,setValue: any,setError: any,clearErrors: any,type: any) => {
    try{
        const { name, value } = event.target;
        const minLength = type === 'ALIEN_REGISTRATION_CARD' ? 7 : 6
        const maxLength = 10
        if(type === 'DRIVER_LICENSE'){
            clearErrors(name)
        }else if(value.length>=minLength && value.length <maxLength){
            clearErrors(name)
        }else{
            setError(event.target.name, {
                type: "manual",
                message: type === 'ALIEN_REGISTRATION_CARD' ? AUTH_STRINGS.ERRORS.SECONDARY_ID_ALIEN_REG_INVALID_LENGTH : AUTH_STRINGS.ERRORS.SECONDARY_ID_PASSPORT_INVALID_LENGTH,
            });
        }
        setValue(name, value)
    }
    catch(e){
        Logger.error("CommonBaseClass.tsx", "handleSecIdChange error", e)
    }
}

/**
 * Method to validate Secondary Identification Id on Submitting Form
 */
export const secondaryIdentificationIdValidation = (secondaryIdType:any) =>{
    if(secondaryIdType && secondaryIdType !== 'DRIVER_LICENSE'){
        let minimumLength = 0;
        const maxLength = 9;
        let errorMessage = '';
        if(secondaryIdType === 'PASSPORT'){
            minimumLength =6;
            errorMessage = AUTH_STRINGS.ERRORS.SECONDARY_ID_PASSPORT_INVALID_LENGTH;
        }else if(secondaryIdType === 'ALIEN_REGISTRATION_CARD'){
            minimumLength =7;
            errorMessage = AUTH_STRINGS.ERRORS.SECONDARY_ID_ALIEN_REG_INVALID_LENGTH;
        }
        return {
            required: true,
            minLength: {
                value: minimumLength,  
                message: errorMessage
            },
            maxLength: {
                value: maxLength,
                message: errorMessage,
            },
        }
    }
    return {
        required: true,
    }
};

// Method handles the keydown event for removing - after formatting of zip, ssn, ein, phone number
export const handleKeyDownChange = (event: any, setValue: any) => {
        try{
            event.persist();
            if (event.key === 'Backspace') {
                let val = event.target.value;
                if (val && val.length > 0 && val[val.length - 1] === '-') {
                    setTimeout(() => {
                        val = val.substring(0, val.length - 1);
                            setValue(event.target.name, val);
                    }, 1);
                }
            }
        }
        catch(e){
            Logger.error("CommonBaseClass.tsx", "handleKeyDownChange error", e)
        }

}

// Phone validation constant
export const setPhoneValidationOptions = (mandatory: boolean) => ({
    required: mandatory,
    minLength: 14,
    maxLength: 14,
    pattern: {
        value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g,
        message: AUTH_STRINGS.ERRORS.PHONE_INVALID_REGEXP,
    },
});

//Method to render phone component error
export const renderPhoneError = (err: InputErrType): any => {
    if (err.type === 'minLength') {
        return AUTH_STRINGS.ERRORS.PHONE_INVALID_REGEXP;
    }
    else if (err.type === 'maxLength') {
        return AUTH_STRINGS.ERRORS.PHONE_INVALID_REGEXP;
    }
    else if (err.type === 'manual') {
        return AUTH_STRINGS.ERRORS.PHONE_INVALID_REGEXP;
    }
    else if (err.type === 'required'){
        return err.message;
    }

};

// Transforming to phone request format
export const convertToPhoneRequestFormat = (value: any) => {
    try{
        value = value.replace('(','');
        value = value.replace(') ','-');
        return value;
    }
    catch(e){
        Logger.error("CommonBaseClass.tsx", "convertToPhoneRequestFormat error", e) 
    }
};

// Transforming to phone display format
export const convertToPhoneDisplayFormat = (value: any) => {
    try{
        // 123-123-1234 to (123) 123-1234
       const temp = value.split('-');
       value = '('+temp[0]+') '+temp[1]+'-'+temp[2]
       return value;
    }
    catch(e){
        Logger.error("CommonBaseClass.tsx", "convertToPhoneDisplayFormat error", e) 
    }
};

//Method to render phone component error for showing required error
export const renderPhoneDisplayError = (err: InputErrType): any => {
            if (err.type === 'minLength') {
                return AUTH_STRINGS.ERRORS.PHONE_INVALID_REGEXP;
            }
            else if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.PHONE_INVALID_REGEXP;
            }
            else if (err.type === 'manual') {
                return AUTH_STRINGS.ERRORS.PHONE_INVALID_REGEXP;
            }
            else if (err.type === 'required'){
                return AUTH_STRINGS.ERRORS.REQUIRED;
            }

};

// Ssn validation constant
export const setSsnValidationOptions = (mandatory: boolean) => ({
    required: mandatory,
    maxLength: 11,
    pattern: {
        value: /^\d{3}-?\d{2}-?\d{4}$/,
        message: AUTH_STRINGS.ERRORS.SSN_INVALID_REGEXP,
    },
});

// Function to set validation options for the ITIN field
// Takes a boolean parameter 'mandatory' to determine if the field is required
export const setItinValidationOptions = (mandatory: boolean) => ({
    required: mandatory,
    maxLength: 11,

    // Regex pattern to validate the ITIN format
    // ITIN must start with 9, followed by specific ranges of digits for the middle section
    pattern: {
        value: /9\d{2}-(5[0-9]|6[0-5]|7[0-9]|8[0-8]|9[0-2]|9[4-9])-\d{4}/,
        message: AUTH_STRINGS.ERRORS.ITIN_INVALID_REGXP,
    },
})

// Email validation constant
export const setEmailValidationOptions = (mandatory: boolean) => ({
    required: mandatory,
    maxLength: 250,
    pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i,
        message: AUTH_STRINGS.ERRORS.EMAIL_INVALID_REGEXP,
    },
});

// ein validation constant
export const setEinValidationOptions = (mandatory: boolean) => ({
    required: mandatory,
    maxLength: 11,
    pattern: {
        value: /^\d{2}-?\d{7}$/,
        message: AUTH_STRINGS.ERRORS.EIN_INVALID_REGEXP,
    },
});

// website validation constant
export const setWebsiteValidation = (mandatory: boolean) => ({
    required: mandatory,
    pattern: {
        value: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
        message: AUTH_STRINGS.ERRORS.URL_INVALID_REGEXP,
    },
});

// Method for formatting phone in the correct format
export const handlePhoneChange = (event: any, setValue: any, setError: any, clearErrors: any) => {
    try {
        let val = event.target.value.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{3})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        if (val.length === 12 || val.length === 0) {
            clearErrors(event.target.name);
        } else {
            setError(event.target.name, {
                type: "manual",
                message: "Invalid phone number",
            });
        }
        setValue(event.target.name, val);
    } catch (e) {
        Logger.error("CommonBaseClass.tsx", "handlePhoneChange error", e) 
    }
}

// Method for formatting ssn in the correct format
export const handleSSNChange = (event: any, setValue: any, setError: any, clearErrors: any) => {
    try {
        let val = event.target.value.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{2})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        if (val.length === 11) {
            clearErrors(event.target.name);
        } else {
            setError(event.target.name, {
                type: "manual",
                message: "Invalid SSN",
            });
        }
        setValue(event.target.name, val)
    } catch (e) {
        Logger.error("CommonBaseClass.tsx", "handleSSNChange error", e)
    }
}

// Method for formatting ein in the correct format
export const handleEINChange = (event: any, setValue: any, setError: any, clearErrors: any) => {
    try {
        let val = event.target.value.replace(/\D/g, '');
        val = val.replace(/^(\d{2})/, '$1-');
        val = val.replace(/(\d)-(\d{7}).*/, '$1-$2');
        if (val.length === 10) {
            clearErrors(event.target.name);
        } else {
            setError(event.target.name, {
                type: "manual",
                message: "Invalid EIN",
            });
        }
        setValue(event.target.name, val)
    } catch (e) {
        Logger.error("CommonBaseClass.tsx", "handleEINChange error", e)
    }
}

//Method to add errormessage dynamic
export const renderOwnershipPercentageError = (err: InputErrType): any => {
    if (err.type === 'min') {
        return AUTH_STRINGS.ERRORS.MIN_OWNERSHIP_PERCENTAGE;
    }
    else if (err.type === 'max') {
        return AUTH_STRINGS.ERRORS.MAX_OWNERSHIP_PERCENTAGE;
    }
    else if (err.type === 'required'){
        return 'Ownership Percentage is required for every beneficial owner.'
    }

};

// Method to add Name length error
export const renderNameError = (err: InputErrType): string => {
    if (err.type === 'maxLength') {
        return AUTH_STRINGS.ERRORS.NAME_INVALID_LENGTH;
    }
    return err.message;
};

// Method to HOLDER Name length error
export const renderHolderNameError = (err: InputErrType): string => {
    if (err.type === 'maxLength') {
        return AUTH_STRINGS.ERRORS.HOLDER_NAME_INVALID_LENGTH;
    }
    return err.message;
};


/**
 * Handle the address line 1 error
 * @param err input error
 * @returns error message
 */
export const renderAddressLine1Error = (err: InputErrType): string => {
  if (err.type === "maxLength") {
    return AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_INVALID_LENGTH;
  }
  return err.message;
};

/**
 * Method to render the address line 2 error
 * @param err input message
 * @returns output error message
 */
export const renderAddressLine2Error = (err: InputErrType): string => {
  if (err.type === "maxLength") {
    return AUTH_STRINGS.ERRORS.ADDRESS_LINE_2_INVALID_LENGTH;
  }
  return err.message;
};

/**
 * Handle the address line 1 error
 * @param err input error
 * @returns error message
 */
export const renderEAAddressLine1Error = (err: InputErrType): string => {
    if (err.type === "maxLength") {
      return AUTH_STRINGS.ERRORS.EA_ADDRESS_LINE_1_INVALID_LENGTH;
    }
    return err.message;
  };

  /**
   * Method to render the address line 2 error
   * @param err input message
   * @returns output error message
   */
  export const renderEAAddressLine2Error = (err: InputErrType): string => {
    if (err.type === "maxLength") {
      return AUTH_STRINGS.ERRORS.EA_ADDRESS_LINE_2_INVALID_LENGTH;
    }
    return err.message;
  };

/**
 * Handling the error for city
 * @param err : input error message
 * @returns message
 */
export const renderCityNameError = (err: InputErrType): string => {
  if (err.type === "minLength") {
    return AUTH_STRINGS.ERRORS.CITY_NAME_INVALID_LENGTH;
  }
  else if (err.type === "maxLength") {
    return AUTH_STRINGS.ERRORS.CITY_NAME_MAX_LENGTH;
  }
  return err.message;
};

/**
 * CVV validation
 */
export const setCVVValidation = (mandatory: boolean) => ({
  required: mandatory,
  minLength: 3,
  maxLength: 4
});

/**
 * card number validation
 */
export const setCardNumberValidation = (mandatory: boolean) => ({
  required: mandatory,
  minLength: 13,
  maxLength: 227
});

/**
 * city name validation
 */
export const setCityNameValidation  = (mandatory: boolean) => ({
  required: mandatory,
  minLength: 2,
  maxLength: 25
});

/**
 *  Address line 1 validation
 * */
export const setAddressLine1Validation = (mandatory: boolean) => ({
  required: mandatory,
  minLength: {
    value: 4,
    message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
  },
  maxLength: {
    value: 40,
    message: AUTH_STRINGS.ERRORS.ADDRESS_LINE_1_LENGTH_VALIDATION
  }
});


/**
 * address line 2 validation
 */
export const setAddressLine2Validation = (mandatory: boolean) => ({
  required: mandatory,
  maxLength: 128,
});


/**
 * Handle the card number error
 * @param err input error
 * @returns error message
 */
export const renderCardNumberError = (err: InputErrType): string => {
  if (err.type === "minLength") {
    return AUTH_STRINGS.ERRORS.CARD_NUMBER_INVALID_LENGTH;
  } else if (err.type === "maxLength") {
    return AUTH_STRINGS.ERRORS.CARD_NUMBER_INVALID_LENGTH;
  }
  return err.message;
};

/**
 * Function to get the Ea details form the contact response
 * @param contactData //contact get api response
 * @param fetchArrayType // EA or ADDRESS
 * @param id // id of EA or Address looking for
 * @param IsFetchArray // if true it will return the Array(EA/ADDRESS)
 * @param isBindToDropDown // if true it will bind the EA/ADDRESS to a dropdown
 * @returns
 */
export function getEAOrAddressDetailsFromContact(contactData: any, fetchArrayType: string, id?: any, IsFetchArray?: boolean, isBindToDropDown?: boolean) {
        if (!isBindToDropDown) {
            if (fetchArrayType === 'EA') {
                return IsFetchArray ? contactData?.externalAccount : contactData?.externalAccount?.find((element: any) => element.id?.toString() === id?.toString())
            }
            else if (fetchArrayType === 'EIA') {
                return IsFetchArray ? contactData?.internationalExternalAccount : contactData?.internationalExternalAccount?.find((element: any) => element.id?.toString() === id?.toString())
            }
            else if (fetchArrayType.toLocaleUpperCase() === "ADDRESS") {
                return IsFetchArray ? contactData?.mailingAddress : contactData?.mailingAddress?.find((element: any) => element.id?.toString() === id?.toString())
            }
        }
        else {
            const arrayDropdown:any = [];
            let destinationArray:any = fetchArrayType.toLocaleUpperCase() === "EA" ? contactData?.externalAccount : fetchArrayType.toLocaleUpperCase() === "EIA" ? contactData?.internationalExternalAccount : contactData?.mailingAddress
            if(!Array.isArray(destinationArray)) {
                destinationArray = [destinationArray]
            }
            destinationArray?.forEach((item: any) => {
                if (item) {
                    const label = fetchArrayType.toLocaleUpperCase() === "EA" ?item?.bankInfo?.name + ' **' + item?.accountNumberLast4: fetchArrayType.toLocaleUpperCase() === "EIA" ? item['holderName'] + ', ' + item['type'] + '(*' + item['accountNumberLast4'] + ')': addressFormatter('',item)
                    arrayDropdown.push({ 'label': label, 'value': item?.id })
                 }
            })
            return arrayDropdown;
        }
  }

  /**
   * For getting the redirect link - using in Send money to contact
   * @param data
   * @returns
   */

export function redirectForm(data: any) {
    let link = '';
    switch (data) {
        case 'send-payee-ach':
            link = '/transaction/send/contact/add/ach';
            break;
        case 'send-payee-wire':
            link = '/transaction/send/contact/add/wire';
            break;
        case 'send-payee-check':
            link = '/transaction/send/contact/add/check';
            break;
        default:
            link = '/payees';
            break;

    }
    return link;
}
/**
 * For getting the payee information when redirect back to schedule page
 * @param data
 * @returns
 */
export function setPayeeInformation(data: any) {
    const destinationArray = [];
    const label = data?.name + '(ID: ' + data?.id + ')';
    destinationArray.push({ 'label': label, 'value': data?.id })
    return destinationArray;
}

/**
   * Method handles the transformation of the number with commas
   * @param x : number
   * @returns : number with commas
   */
export function numberWithCommas(x: any) {
    const y = (Math.round(Math.abs(x) * 100) / 100).toFixed(2)
    return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

/**
 * status format for string
 * @param value
 */
export function convertToUpperAndTitleCase(value: any) {
    if (value ) {
        if (value.includes('_')) {
            const val = value.split('_');
            return val[0].toUpperCase()+' ' +toTitleCase(val[1]);
        } else {
            return value;
        }
    } else {
        return '';
    }
}

/**
 * format to title case if it has underscore
 * @param value
 */
export function removeUnderScoreConvertTitleCase(value: any) {
    if (value ) {
        if (value.includes('_')) {
            let val = value.replace(/_/g, ' ');
            val = toTitleCase(val)
            return val;
        } else {
            return value;
        }
    } else {
        return '';
    }
}

/**
 * Method handles the state value
 * @returns : state with name
 * @param stateCode
 */
export function stateValueFormatter(stateCode: any) {
   let state: any;
   usa_state?.forEach((data: any) => {
       if (data?.value === stateCode) {
           state = data?.label
       }
   })
   return state
}

/**
* Method transforms the request for recurring
* @param data : send data
* @param recurringData : recurring data
* @returns : transformed request
*/
export const transformRecurringRequestGlobal = (data: any, recurringData: any) => {
    try {
        const req: any = {};
        if (recurringData?.repeatEveryFormData?.repeatEverySelect === 'CALENDAR_DAY' || recurringData?.repeatEveryFormData?.repeatEverySelect === 'BUSINESS_DAY') {
            req['transactionDetail'] = {
                frequency: recurringData ? recurringData?.repeatEveryFormData?.repeatEverySelect : data?.frequency,
                interval: recurringData ? recurringData?.repeatEveryFormData?.repeatEvery : data?.interval
            }
            if (recurringData?.endFormData?.endRecurring === 'AFTER') {
                req['transactionDetail']['endDate'] = recurringData?.endFormData?.afterDate;
                req['transactionDetail']['occurrence'] = null;
            } else if (recurringData?.endFormData?.endRecurring === 'THE') {
                req['transactionDetail']['occurrence'] = recurringData?.endFormData?.transactions;
                req['transactionDetail']['endDate'] = null;
            } else {
                req['transactionDetail']['endDate'] = null;
                req['transactionDetail']['occurrence'] = null;
            }
        } else if (recurringData?.repeatEveryFormData?.repeatEverySelect === 'WEEK') {
            req['transactionDetail'] = {
                frequency: recurringData?.repeatEveryFormData?.repeatEverySelect,
                interval: recurringData?.repeatEveryFormData?.repeatEvery,
                dayOfWeek: recurringData?.weekFormData
            }
            if (recurringData?.endFormData?.endRecurring === 'AFTER') {
                req['transactionDetail']['endDate'] = recurringData?.endFormData?.afterDate;
                req['transactionDetail']['occurrence'] = null;
            } else if (recurringData?.endFormData?.endRecurring === 'THE') {
                req['transactionDetail']['occurrence'] = recurringData?.endFormData?.transactions;
                req['transactionDetail']['endDate'] = null;
            } else {
                req['transactionDetail']['endDate'] = null;
                req['transactionDetail']['occurrence'] = null;
            }
        } else if (recurringData?.repeatEveryFormData?.repeatEverySelect === 'MONTH') {
            req['transactionDetail'] = {
                frequency: recurringData?.repeatEveryFormData?.repeatEverySelect,
                interval: recurringData?.repeatEveryFormData?.repeatEvery,
            }
            if (recurringData?.endFormData?.endRecurring === 'AFTER') {
                req['transactionDetail']['endDate'] = recurringData?.endFormData?.afterDate;
                req['transactionDetail']['occurrence'] = null;
            } else if (recurringData?.endFormData?.endRecurring === 'THE') {
                req['transactionDetail']['occurrence'] = recurringData?.endFormData?.transactions;
                req['transactionDetail']['endDate'] = null;
            } else {
                req['transactionDetail']['endDate'] = null;
                req['transactionDetail']['occurrence'] = null;
            }
            const onFormArray: any = [];
            if (recurringData?.onDayStatus) {
                recurringData?.onDayFormData?.forEach((item: any) => {
                    if (item.type === 'WEEK') {
                        item.week?.forEach((weekItem: any) => {
                            if (item.order && weekItem) {
                                onFormArray.push(item.order + '_' + weekItem)
                            }

                        })
                    } else {
                        if (item['order'] && item['type']) {
                            onFormArray.push(item.order + '_' + item.type)
                        }
                    }
                })
                req['transactionDetail']['dayOfMonth'] = onFormArray;
            }
            if (recurringData?.onDateStatus) {
                recurringData?.onDateFormData?.forEach((item: any) => {
                    if (item * 1) {
                        onFormArray.push(item * 1)
                    }

                })
                req['transactionDetail']['dayOfMonth'] = onFormArray;
            }
            if (!recurringData?.onDateStatus && !recurringData?.onDayStatus) {
                req['transactionDetail']['dayOfMonth'] = [];
            }
        } else if (recurringData?.repeatEveryFormData?.repeatEverySelect === 'YEAR') {
            req['transactionDetail'] = {
                frequency: recurringData?.repeatEveryFormData?.repeatEverySelect,
                interval: recurringData?.repeatEveryFormData?.repeatEvery,
            }
            if (recurringData?.endFormData?.endRecurring === 'AFTER') {
                req['transactionDetail']['endDate'] = recurringData?.endFormData?.afterDate;
                req['transactionDetail']['occurrence'] = null;
            } else if (recurringData?.endFormData?.endRecurring === 'THE') {
                req['transactionDetail']['occurrence'] = recurringData?.endFormData?.transactions;
                req['transactionDetail']['endDate'] = null;
            } else {
                req['transactionDetail']['endDate'] = null;
                req['transactionDetail']['occurrence'] = null;
            }
            const onFormArray: any = [];
            if (recurringData?.onDayStatus) {
                recurringData?.onDayFormData?.forEach((item: any) => {

                    if (item.type === 'WEEK') {
                        item.week?.forEach((weekItem: any) => {
                            if (item.order && weekItem) {
                                onFormArray.push(item.order + '_' + weekItem)
                            }
                        })
                    } else {
                        if (item['order'] && item['type']) {
                            onFormArray.push(item.order + '_' + item.type)
                        }
                    }


                })
                req['transactionDetail']['dayOfMonth'] = onFormArray;
                req['transactionDetail']['monthOfYear'] = recurringData?.yearFormData;
            }
            if (recurringData?.onDateStatus) {
                recurringData?.onDateFormData?.forEach((item: any) => {
                    if (item * 1) {
                        onFormArray.push(item * 1)
                    }
                })
                req['transactionDetail']['dayOfMonth'] = onFormArray;
                req['transactionDetail']['monthOfYear'] = recurringData?.yearFormData;
            }
            if (!recurringData?.onDateStatus && !recurringData?.onDayStatus) {
                req['transactionDetail']['dayOfMonth'] = [];
            }
        }
        return req;

    } catch (e) {
        Logger.error("CommonBaseClass.tsx", "transformRecurringRequestGlobal error", e)
    }
}
/**
* Method fetches label from an array by passing id
* @param data : any array of data
* @param id : id to be selected
* @returns : label belongs to the item containing the selected id
*/
export function fetchLabelFromId(data: any, id: any) {
    try {
        let label: any;
        data?.forEach((item: any) => {
            if (item.value?.toString() === id.toString()) {
                label = item.label;
            }
        })
        return label;
    } catch (e) {
        Logger.error("CommonBaseClass.tsx", "fetchLabelFromId error", e)
     }
}
/**
* Method fetches an item on the array based on the checkedNode value and returns the returnedNode value from the selected item
* @param data : any array of data
* @param value : value to be checked
* @param checkedNode : node name for the value to be checked
* @param returnedNode : node name for the value to be returned
* @returns : value based on the returnedNode
*/
export function fetchItemFromArray(data: any, value: any, checkedNode: any, returnedNode: any) {
    try {
        let label: any;
        data?.forEach((item: any) => {
            if (item[checkedNode] === value) {
                label = item[returnedNode];
            }
        })
        return label;
    } catch (e) {
        Logger.error("CommonBaseClass.tsx", "fetchItemFromArray error", e)
    }
}

/**
 * format to title case and append 's'
 * @param value : value to be formatted
*  @returns : returns the formatted value
 */
export function convertToTitleCaseAndAppendWithS(value: any) {
    try {
        if (value) {
            if (value.includes('_')) {
                let val = value.replace(/_/g, ' ');
                val = toTitleCase(val)
                return val + 's';
            } else {
                let val = toTitleCase(value)
                return val + 's';
            }
        } else {
            return '';
        }
    } catch (e) {
        Logger.error("CommonBaseClass.tsx", "convertToTitleCaseAndAppendWithS error", e) 
    }
}
/**
 * format to title case and show first n characters for an array
 * @param value: value to be formatted
 * @param n: number of characters to be displayed
*  @returns : returns the formatted value
 */
export function convertToTitleCaseAndShowFirstNCharactersForAnArray(value: any, n: number) {
    try {
        if (value) {
            const newArray: any = [];
            value?.forEach((item: any) => {
                if (item) {
                    let val = toTitleCase(item);
                    if (val.substring(0, n)) {
                        val = val.substring(0, n);
                        newArray.push(val)
                    }
                }
            })
            return newArray.join(', ');
        } else {
            return '';
        }
    } catch (e) {
        Logger.error("CommonBaseClass.tsx", "convertToTitleCaseAndShowFirstNCharactersForAnArray error", e) 
    }
}
/**
 * converts to on day format in recurring
 * @param data: data to be formatted
*  @returns : returns the formatted value
 */
export function convertToOnDayFormat(data: any) {
    try {
        if (data) {
            const value = data?.onDayFormData;
            let newString = '';
            if (data?.onDateFormData && data?.onDateStatus) {
                newString = data?.onDateFormData.join(', ') + ', ';
            }
            if (data?.onDayStatus) {
                value?.forEach((item: any) => {
                    if (item.order && item.type && item.type !== 'WEEK') {
                        newString = newString + ' ' + toTitleCase(item.order) + ' ' + removeUnderScoreConvertTitleCase(item.type) + ', ';
                    } else if (item.order && item.type && item.type === 'WEEK') {
                        newString = newString + ' ' + toTitleCase(item.order) + ' ' + convertToTitleCaseAndShowFirstNCharactersForAnArray(item.week, 3) + ', ';
                    }
                })
                newString = newString.substring(0, newString.length - 2);
            }
            if (data?.repeatEveryFormData?.repeatEverySelect === 'YEAR') {
                newString = newString + ' of ' + convertToTitleCaseAndShowFirstNCharactersForAnArray(data?.yearFormData, 3)
            } else if (data?.repeatEveryFormData?.repeatEverySelect === 'WEEK' && data?.weekFormData) {
                newString = convertToTitleCaseAndShowFirstNCharactersForAnArray(data?.weekFormData, 3)
            }
            return newString;
        } else {
            return '';
        }
    } catch (e) {
        Logger.error("CommonBaseClass.tsx", "convertToOnDayFormat error", e); 
    }
}


/**
 * converts to on end format in recurring
 * @param value: value to be formatted
*  @returns : returns the formatted value
 */
export function convertEndData(value: any) {
    try {
        if (value) {
            if (value?.endRecurring === 'AFTER') {
                return value?.afterDate;
            } else if (value?.endRecurring === 'THE') {
                return 'After ' + value?.transactions + ' Occurrences';
            } else {
                return 'Never';
            }
        } else {
            return '';
        }
    }
    catch(e){
        Logger.error("CommonBaseClass.tsx", "convertEndData error", e); 
    }
}
/**
 * Show custom error message
 * @param data :error message
 * @returns
 */
export function customErrorMessage(data: any) {
    let error_message = data?.message
    try {
        if (data?.message[0].indexOf('Processingdetail.companydescription') !== -1) {
            error_message = 'Processingdetail.memo: The length should not exceed 10 characters.'
        }
    }
    catch (e) { 
        Logger.error("CommonBaseClass.tsx", "customErrorMessage error", e); 
    }
    return error_message
}

/**
 * checking permission of a customer
 */
export function checkPermission(data: any) {
    try {
        let hasPermission = false;
        if(getLocalStorage('permission') ==='ADMIN'){
            hasPermission = true
        }
        else if (data && JSON.parse(getLocalStorage('permission')!) !== 'ADMIN') {
            JSON.parse(getLocalStorage('permission')!)?.forEach((item: any) => {
                if (item.entity.toLowerCase() === data.entity.toLowerCase()) {
                    item?.data?.forEach((val: any) => {
                        if (val.name.toLowerCase() === data.name.toLowerCase()) {
                            val?.permissions?.forEach((obj: any) => {
                                if (obj.operation.toLowerCase() === data.operation.toLowerCase()) {
                                    hasPermission = true;
                                }
                            })
                        }
                    });
                }
            })
        }
        else {
            hasPermission = true
        }
        return hasPermission
    }
    catch (e) {
        Logger.error("CommonBaseClass.tsx", "checkPermission error", e); 
     }
}

/**
 * Method updates the param
 */
export function updateComponentParams(dispatch:any,id: any, params: any) {
    const payload: any = {id: id,...params};
    dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: payload });
}
/**
 * This function updates component data by dispatching an action with the provided ID and data.
 * @param dispatch - The dispatch function from a Redux store to dispatch actions.
 * @param id - The identifier for the component data being updated.
 * @param data - The new data to be assigned to the component.
 */
export function updateComponentData(dispatch: any, id: any, data: any) {
    const payload = { id, data };
    dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload });
}
/**
 * Method fetches data from state
 * @param state : redux state
 * @param id : id of the state object
 * @returns : data from state
 */
export const fetchDataFromState = (state: any, id: any) => {
    return state.data[id] || { config: { params: {} } }
}

/**
 * method to get edit schedule path
 * @param schedule :data
 */
export function getEditSchedulePath(schedule:any){
    let editPath: any;
        if(schedule?.source?.account?.id?.toString() === getKey('selectedAccount')?.id?.toString()){
            if(schedule?.destination?.externalAccount && schedule?.destination?.externalAccount?.holderName){
                editPath = '/transaction/send/edit/onetime/' + schedule?.method.toLowerCase() + '/' + schedule.id;
            }
            else if(schedule?.destination?.externalAccount || schedule?.destination?.address || schedule?.destination?.account){
                editPath = '/transaction/send/edit/account/' + schedule?.method.toLowerCase() + '/' + schedule.id;
            }
            else if(schedule?.destination?.contact?.internationalExternalAccount){
                editPath = '/transaction/send/edit/contact/internationalWire/' + schedule.id;
            }
            else if(schedule?.destination?.contact){
                editPath = '/transaction/send/edit/contact/' + schedule?.method.toLowerCase() + '/' + schedule.id;
            }
            else if(schedule?.destination?.wallet){
                editPath = '/transaction/send/edit/wallet/' + schedule?.method.toLowerCase() + '/' + schedule.id;
            }
            else if(schedule?.destination?.internationalExternalAccount?.holderName){
                editPath = '/transaction/send/edit/onetime/internationalWire/' + schedule.id;
            }
            else if(schedule?.destination?.internationalExternalAccount){
                editPath = '/transaction/send/edit/account/internationalWire/' + schedule.id;
            }
            if(schedule?.destination?.ppi){
                editPath = '/transaction/send/edit/onetime/' + schedule?.method.toLowerCase() + '/' + schedule.id;
            }
        }
        else{
            if(schedule?.source?.externalAccount && schedule?.source?.externalAccount?.url){
                editPath = '/transaction/collect/edit/my-account-ach/' + schedule.id;
            }
            else if(schedule?.source?.card && schedule?.source?.card?.cardNumberLast4){
                editPath = '/transaction/collect/edit/onetime-card/' + schedule.id;
            }
            else if(schedule?.source?.externalAccount && schedule?.source?.externalAccount?.accountNumberLast4){
                editPath = '/transaction/collect/edit/onetime-ach/' + schedule.id;
            } 
            // Edit path for (ACH and Card Collect) via contact
            else if(schedule?.source?.contact?.externalAccount?.id) {
                editPath = '/transaction/collect/contact/edit/ach/' + schedule.id;
            } else if(schedule?.source?.contact?.card?.id) {
                editPath = '/transaction/collect/contact/edit/card/' + schedule.id;
            }
        }


    return editPath
}

export enum PermissionAccess {
    FullAccess,
    ViewAccess,
    EditAccess,
    NoAccess,
    CustomObjectAccess
}

/**
 * Method to set the permission access for FXMultiSelectDataTable
 * @param data
 * @param accessType
 * @returns
 */
export function setPermissionAccess(data: any, accessType: any) {
    const dataArray: any = parseJsonData(data)
    dataArray.forEach((element: any) => {
        element.permissions.forEach((row: any) => {
            switch (accessType) {
                case PermissionAccess.FullAccess:
                    element.checked = true;
                    setCheckedOrUnchecked(row, true)
                    break;
                case PermissionAccess.ViewAccess:
                    if (row.operation.toLowerCase() === 'view') {
                        setCheckedOrUnchecked(row, true)
                    }
                    else {
                        setCheckedOrUnchecked(row, false)
                    }
                    break;
                case PermissionAccess.EditAccess:
                    if (row.operation.toLowerCase() === 'view' || row.operation.toLowerCase() === 'edit') {
                        setCheckedOrUnchecked(row, true)
                    }
                    else {
                        setCheckedOrUnchecked(row, false)
                    }
                    break;
                case PermissionAccess.NoAccess:
                    element.checked = false;
                    setCheckedOrUnchecked(row, false)
                    break;

            }
        })
    })
    return CheckAllElementChecked(dataArray);
}

/**
 * Method to select the checkbox based in the permission
 * @param row
 * @param checked
 * @returns
 */
function setCheckedOrUnchecked(row: any, checked: boolean) {
    row.value = checked.toString();
    row.checked = checked
    if (row.checkbox === false) {
        row.checked = false
        row.value = false.toString()
    }
    return row;
}

/**
 * Method for check all element checked
 * @param data
 * @returns
 */
function CheckAllElementChecked(data: any) {
    let index: any;
    data.forEach((element: any) => {
        index = element.permissions.findIndex((item: any) => item.checked === false)
        element.checked = index < 0;
    })
    return data;
}

/**
 * Method for check all element checked
 * @param data
 * @returns
 */
export function transformData(data: any) {
    return data.map((item: any) => {
       const dataArr = item.data.map((i: any) => {
           const name = i.name;

           const permissionData = i.permissions.map((permission: any) => {
               return {
                   operation: permission.operation,
                   id: permission.permissionId
               };
           });

           return { name, permissions: permissionData };
       });

       return {
           entity: item.entity,
           permission_list: item.permission_list,
           data: dataArr,
       };
   });
}


/**
 * Method to parse the Json Data
 * @param data
 * @returns
 */
export function parseJsonData(data: any) {
    return JSON.parse(JSON.stringify(data))
}
/**
 * Disabling view when any of the item selected in the same row
 * @param selectedRow
 * @param col
 * @returns
 */
export function isDisableView(selectedRow: any, col: any) {
    let isDisable = false;
    selectedRow[0].permissions.forEach((item: any) => {
        if (item.operation.toLowerCase() !== 'view' && item.checked === true && col.operation.toLowerCase() !== 'view' ) {
            isDisable = true;
        }
    }
    )
    return isDisable;
}

/**
 * Function to remove an element from an array
 * @param data
 * @param removeId
 * @returns
 */
export function removeElementFromArray(data: Array<any>, removeId: any) {
    const index = data.findIndex((item: any) => item.id?.toString() === removeId.toString())
    if (index > -1) { // only splice array when item is found
        data.splice(index, 1); // 2nd parameter means remove one item only
    }
return data;
}

/***
 * Get api call by passing the url and returning the data
 * @param url : api url
 */
export async function getApiDataByUrl(url: any) {
    try {
        const data: any = await httpClient.get(url?.split('v1/')[1]).then(response => {
            return response?.data
        })
            .catch((error) => {
                return { ...error };
            })
        return data;
    } catch (err) {return err;}
}

/**
* Method formats the string and return only last n characters
* @param data: string
* @param n: number of characters to be returned
*/
export const showLastNCharacters = (data:any, n: number) => {
    return data.substr(data.length-n);
}

/**
* Method to get the payment mode
* @param data: object
*/
export const getPaymentMethods = (data: any) => {
    const methods: any = [];
    if (data?.externalAccount?.find((x:any)=> x.routingNumber)) {
        methods.push('ACH');
      }
      if (data?.externalAccount?.find((x:any)=> x.wireRoutingNumber)) {
        methods.push('WIRE');
      }

    if (data?.mailingAddress && data?.mailingAddress.length > 0) {
        methods.push('CHECK');
    }
    if (data?.ppi) {
        methods.push('PPI');
    }
    if (data?.email) {
        methods.push('VIRTUAL CARD');
    }
    if (data?.internationalExternalAccount && data?.internationalExternalAccount?.length >0) {
        methods.push('INTERNATIONAL WIRE');
    }

    return commaSeperatedValueFromArray(methods);
}

/**
* Method to get the payment mode
* @param data: object
* @param customerType: string
*/
export const getPaymentMethodsByCustomerType = (data: any, businessCustomer:boolean) => {
    const methods: any = [];
    if (data?.externalAccount?.find((x:any)=> x.routingNumber)) {
        methods.push('ACH');
      }
      if (data?.externalAccount?.find((x:any)=> x.wireRoutingNumber)) {
        methods.push('WIRE');
      }

    if (data?.mailingAddress && data?.mailingAddress.length > 0) {
        methods.push('CHECK');
    }
    if (data?.ppi) {
        methods.push('PPI');
    }
    if (businessCustomer && !data?.ppi && data?.email) {
        methods.push('VIRTUAL CARD');
    }
    if (data?.internationalExternalAccount && data?.internationalExternalAccount?.length >0) {
        methods.push('INTERNATIONAL WIRE');
    }
    if (data?.card && data?.card?.length >0) {
        methods.push('CARD');
    }

    return commaSeperatedValueFromArray(methods);
}

/**
 * Method to get the PPI supported payment methods
 * @param defaultMethods: object
 * @param scheduleType
 * @returns
 */
export const getPPISupportedPaymentMethods = (defaultMethods: any, scheduleType: string) => {
    const ppiSupportedMethods = getKey("ppiSupportedMethods")?.split(",");
    let updatedMethods = defaultMethods;
    if(ppiSupportedMethods?.length > 0) {
        updatedMethods = defaultMethods?.filter((item: any) => {
            if((item?.title?.toLowerCase() === 'ACH'.toLowerCase() || item?.title?.toLowerCase() === 'WIRE'.toLowerCase() || item?.title?.toLowerCase() === 'VIRTUAL CARD'.toLowerCase() || item?.title?.toLowerCase() === 'INTERNATIONAL WIRE'.toLowerCase() || item?.title?.toLowerCase() === 'CARD'.toLowerCase()) && scheduleType === 'onetime') {
                return item;
            } else {
                let method = ppiSupportedMethods?.find((element: string)=> element?.toLowerCase() === item?.title?.toLowerCase())
                if(method) {
                    return method
                }
                return null
            }
      })
    }
    return updatedMethods;
}

/**
 * Method to check if method is a supported PPI method
 * @returns
 * @param methodToBeChecked
 */
export const isMethodSupported = (methodToBeChecked: string) => {
    let ppiSupportedMethods = getKey("ppiSupportedMethods")?.split(",");
    let method = ppiSupportedMethods?.find(
      (element: string) =>
        element?.toLowerCase() === methodToBeChecked?.toLowerCase()
    );
    return !!method;
  };
/**
   * Method checks whether view should be disabled or not
   * @param data : permission array of items
   * @returns : true or false
   */
export const checkViewShouldBeDisabled = (data: any) => {
        let flag = false;
        data?.forEach((item: any) => {
            if (item.operation !== 'VIEW' && item.value === 'true') {
                flag = true;
            }
        })
        return flag;
}

/**
* Method used to truncate long characters to specified length
* @param chars : string data
* @param count
* @returns : truncated data with ...
*/
export const truncateChars = (chars: string, count: number): string => {
    if (!chars || chars.length <= count) {
      return chars;
    }

    return chars.substring(0, count) + '...';
  };

/**
 * Function handles setting the state field based on country section
 * @param data : country data string
 */
 export function setStateFieldOnCountry(data: any) {
    let stateField = "text";

    if (data && data?.target?.value) {
        stateField = data?.target?.value === "US" ? "dropdown" : "text";
    }

    return { stateField };
}

// Method to State length error
export const renderStateError = (err: InputErrType): string => {
    if (err.type === 'maxLength') {
        return AUTH_STRINGS.ERRORS.STATE_INVALID_LENGTH;
    }
    return err.message;
};

/**
 * Method handles the country value
 * @returns : country with name
 * @param countryCode
 */
 export function countryValueFormatter(countryCode: any) {
    return countries?.find(el => el.value === countryCode)?.label;
 }

 /**
  * Method checks whether a string is a base64 encoded one or not
  * @param str : a string
  * @returns : false if the string is not base64 encoded one
  */
 export function checkIsBase64(str: any) {
    if (str ==='' || str.trim() ===''){ return false; }
    try {
        return window.btoa(window.atob(str)) === str;
    } catch (err) {
        return false;
    }
}
/**
 * Method returns the operating system from the user agent data
 * @param data : user agent data
 * @returns : operating system
 */
export function getOperatingSystem(data: string) {
    const lowercaseData = data.toLowerCase();
    if (lowercaseData.includes("windows")) {
        return 'WINDOWS';
    } else if (lowercaseData.includes("mac")) {
        return 'MAC';
    } else if (lowercaseData.includes("x11")) {
        return 'LINUX';
    } else if (lowercaseData.includes("android")) {
        return 'ANDROID';
    } else if (lowercaseData.includes("iphone")) {
        return 'IPHONE';
    } else {
        return 'UNKNOWN';
    }
}
/**
 * Method returns the browser name from the user agent data
 * @param data : user agent data
 * @returns : browser
 */
export function getBrowserType(data: string) {
    const lowercaseData = data.toLowerCase();
    const test = (regexp: RegExp) => regexp.test(lowercaseData);
    if (test(/opr\//i)) {
        return 'Opera';
    } else if (test(/edg/i)) {
        return 'Microsoft Edge';
    } else if (test(/chrome|chromium|crios/i)) {
        return 'Google Chrome';
    } else if (test(/firefox|fxios/i)) {
        return 'Mozilla Firefox';
    } else if (test(/safari/i)) {
        return 'Apple Safari';
    } else if (test(/trident/i)) {
        return 'Microsoft Internet Explorer';
    } else if (test(/ucbrowser/i)) {
        return 'UC Browser';
    } else if (test(/samsungbrowser/i)) {
        return 'Samsung Browser';
    } else {
        return 'Unknown browser';
    }
}
/**
 * Method to check value is empty or nill
 * @param variable 
 * @returns 
 */
export function hasValue(variable:any) {
    return !_.isEmpty(variable) && !_.isNil(variable);
}
/**
 * Checks if an object is empty.
 * @param obj - The object to check.
 * @returns A boolean indicating whether the object is empty.
 */
export function isEmpty(obj: any) {
    return (obj === undefined || obj === null ||
        (typeof obj === 'object' && Object.entries(obj).length === 0));
}
/**
 * Recursively cleans an object by removing empty objects or arrays within it.
 * @param obj - The object to clean.
 * @returns A cleaned version of the object.
 */
export function deeplyClean(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(deeplyClean).filter(v => !isEmpty(v));
    } else if (obj !== null && typeof obj === 'object') {
        let cleaned = Object.entries(clean(obj)).map(([k, v]) => [k, deeplyClean(v)])
        cleaned = cleaned.filter(([k, v]) => v !== undefined && !isEmpty(v));
        return Object.fromEntries(cleaned);
    }
    return obj;
}

/**
 * function to call appi for alerts count
 * @returns data
 */
export async function getAlerts() {
    try {
        const data: any = await httpClient.get('/customer/id/' + getKey('customerId') + '/authorizedUser/id/' + getKey('authUserId') + '/alert/unreadCount')
        return data;
    }
    catch(e){ 
        Logger.error("CommonBaseClass.tsx", "error", e);  
    }
}

/**
 * method to validate Beneficial Owner data
 * @param item 
 */
export const validateData = (item: any) => {
    return !!(
      item?.firstName &&
      item?.email &&
      item?.jobtitle &&
      item?.dob &&
      item?.mobilePhone &&
      item?.usCitizen &&
      item?.secondaryIdentificationType &&
      item?.addressLine1 &&
      item.city &&
      item?.state &&
      item?.zip
    );
  };

  /**
 * method to validate Co-Owner data
 * @param item 
 */
export const validateCoOwnerData = (item: any) => {
    return !!(
      item?.firstName &&
      item?.email &&     
      item?.dob &&
      item?.mobilePhone &&      
      item?.addressLine1 &&
      item.city &&
      item?.state &&
      item?.zip
    );
  };
 

/**
 * API Method enum
 */
export const enum Method {
    POST, GET, DELETE, PUT
}

/**
 * Method to check wheather the logged in user is partner or customer
 * @returns :boolean
 */
export const isPartner = () => {
    return getKey('loginType') === 'PARTNER'
}
/*
* Common Method to call api
* @param payloadData : request payload
*/
export async function callAPI(url: any, method: Method, payloadData: any = {}): Promise<any> {
    try {
        let data: any = {}
        switch (method) {
            case Method.POST:
                data = await httpClient.post(url, payloadData);
                break;
            case Method.GET:
                data = await httpClient.get(url);
                break;
            case Method.PUT:
                data = await httpClient.put(url);
                break;
            case Method.DELETE:
                data = await httpClient.delete(url);
                break;
        }
        return data;
    } catch (err) {
        Logger.error("callAPI", "error", err);
        return err;
    }
}

/**
 * Function to convert input into a formatted phone number string.
 * @param input - The input string containing a phone number.
 * @returns A formatted phone number string or an 'Invalid phone number format' message.
 */
export function convertPhoneNumber(input: any) {
    try {
      // Remove any non-digit characters from the input string
      const cleanNumber = input.replace(/\D/g, '');

      // Check if the cleaned number has a length of 10 digits
      if (cleanNumber.length === 10) {
        const areaCode = cleanNumber.slice(0, 3);
        const middleDigits = 'xxx-xxx'; // Replace middle digits with 'xxx'
        const lastDigits = cleanNumber.slice(8); // Keep the last 3 digits

        return `(${areaCode}) ${middleDigits}${lastDigits}`;
      } else {
        // Handle cases where the number might not have the expected length
        return 'Invalid phone number format';
      }
    }
    catch (e) { 
        Logger.error("commonBaseClass.tsx", "convertPhoneNumber error", e);
    }

  }


  /**
 * Function to display tooltip with an info icon with any custom description
 */
  export const ShowInfoToolTip = (className: string,description:string) =>{
    return <FxInfoTooltip  arrow placement={'right-start'} className={className + ' fx-amount-info-tooltip'} title={description}>
    <DarkInfoIcon className="fx-permissions-tooltip"></DarkInfoIcon>
  </FxInfoTooltip>
  }
  
/**
 * Function to convert an email address into a modified format.
 * @param input - The input string representing an email address.
 * @returns A modified version of the email address or an 'Invalid email address' message.
 */
  export function convertEmailAddress(input: any) {
    try {
      const atIndex = input.indexOf('@'); // Find the index of '@' symbol
      if (atIndex !== -1) {
        const username = input.slice(0, atIndex); // Extract the username part before '@'
        const domain = input.slice(atIndex); // Extract the domain part including '@'

        // Shorten the username to first character 'a', then dots, and the last character 'b'
        const shortenedUsername = `${username.charAt(0)}${'....'}${username.charAt(username.length - 1)}`;

        return `${shortenedUsername}${domain}`;
      } else {
        // Handle cases where '@' symbol is not found
        return 'Invalid email address';
      }
    }
    catch (e) {
        Logger.error("commonBaseClass.tsx", "convertEmailAddress error", e);
     }

  }

/**
 * Function to set values for verify props common variables
 * @param req - request
 * @param status - response status
 * @param repeatStatus - repeat status
 * @param amount - amount
 * @param verifyMessage - verify message
 * @returns 
 */
export const createCommonVerifyProps = (req: any, status: any, repeatStatus: boolean, amount: any, verifyMessage: string, schedule: string) => {
    return {
        open: true,
        channelTitle: `To verify ${repeatStatus ? 'recurring ' + schedule : schedule} for $${amount}, Passport will send you a one-time code. Choose how to receive your code.`,
        subTitle: `A verification code has been sent to your ${verifyMessage}. Please enter the code to complete verification.`,
        initiationDetails: status.responseData,
        resendOtpUrl: `/user/id/${getKey('id')}/initiateMFA`,
        req: req,
        url: createTransactionUrl(req)
    };
}

/**
 * Method for handling the cvv validation
 */
export const handleCvvChange = (event: any, setValue: any, setError: any, clearErrors: any) => {
    try {
        let val = event.target.value.replace(/\D/g, '');
        if(val === '000' || val === '999') {
            setError(event.target.name, {
                type: "manual",
                message: "Value should not be 000 or 999",
            });
        } else if(val === '0000' || val === '9999') {
            setError(event.target.name, {
                type: "manual",
                message: "Value should not be 0000 or 9999",
            });
        } else if ((val.length === 3 || val.length === 4 || val.length === 0)) {
            clearErrors(event.target.name);
        } else {
            setError(event.target.name, {
                type: "manual",
                message: "CVV value should be 3 or 4 digits.",
            });
        }
        setValue(event.target.name, val);
    }
    catch (e) {
        Logger.error("commonBaseClass.tsx", "handleCvvChange error", e);
     }
}

/**
 * Method to get login redirect info 
 */
export const getRedirectInfo = ( accountList: any, dispatch: any, baseUrl: string, accountBasedMenu?: any, isUserRefreshed?: boolean) => {
    const isSSOLoggedIn = uiFeat.getSSOUserFeatures().ssoUser;
    const isMerchantLoggedIn = isSSOLoggedIn && getKey('merchantMID') && getKey('customerId') === getKey('midEnabledCustomerID');
    if (accountList?.resources?.length > 1) {
        isMerchantLoggedIn &&  defValueFormatter(accountList?.resources, dispatch);
        const path = isMerchantLoggedIn ? baseUrl : (isUserRefreshed ? baseUrl : (baseUrl === '/dashboard' ? '/home' : baseUrl));
        updateComponentParams(dispatch, 'layout-passport', { 'accountBasedMenu': isMerchantLoggedIn || !!accountBasedMenu })
        return path
    }
    else {
        updateComponentParams(dispatch, 'layout-passport', { 'accountBasedMenu': true })
        defValueFormatter(accountList?.resources, dispatch)
        return baseUrl
    }
}

/**
 * Method used to set unique values
 * @param data 
 */
export function makeArrayUnique<T>(data: T[]): T[] {
    return Array.from(new Set(data));
}

/**
 * * Method to set account details if there is only one account
 * @param options : array of account details data
 */
export const defValueFormatter = (options: any, dispatch: any) => {
    const isSSOLoggedIn = uiFeat.getSSOUserFeatures().ssoUser;
    const isMerchantLoggedIn = isSSOLoggedIn && getKey('merchantMID') && getKey('customerId') === getKey('midEnabledCustomerID')
    try {
        let selectedOption;
        if (options) {
            if (isMerchantLoggedIn) {
                selectedOption = options.find((item: any) => item.id === getKey('merchantMIDAccount'))
            }
            else {
                options?.forEach((option: any) => {
                    if (option.status === 'ACTIVE' || option.status === 'DEBIT_BLOCKED') {
                        selectedOption = option
                    }
                });
            }
            applyTransactionFeatures()
            dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'customer-home-passport' } });
            dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'account-list-sub-menu' } });
            setKey("selectedAccount", selectedOption);
            updateComponentData(dispatch, 'switch-customer-data', { selectedAccount: selectedOption })
            updateComponentParams(dispatch, 'account-list-sub-menu', { 'selectedOption': selectedOption })
            updateComponentParams(dispatch, 'customer-home-passport', { 'accountUpdated': true })
            dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'home-quick-transfer-card' } });
            updateComponentParams(dispatch, 'home-quick-transfer-card', { accordionOpen: true, isDestination: true });
        }
    } catch (e) {
        Logger.error("commonBaseClass.tsx", "defValueFormatter error", e);
     }
    return (<></>)
}

/**
* method to get customer Id based on user login
*/
export const getDefaultCustomer = (list: any, mid?: any) => {
    const customer = list?.map((item: any) => {
        const merchant = item?.merchant?.find((obj: any) => Number(obj?.mid) === mid);
        if (merchant) {
            setKey('midEnabledCustomerID', item?.id);
            setKey('merchantMID', mid);
            setKey('merchantMIDAccount', merchant?.merchantAccount?.id);
            return { ...item, merchant };
        }
        return null;
    }).filter((customer: any) => customer !== null);
    return findItem(customer, list)
}
/**
 * Method used to get Customer
 * @param customer 
 * @param list 
 * @returns 
 */
const findItem = (customer: any[], list: any[]) => {
    const customerId = getKey('customerId');
    if (customerId) {
        const foundCustomer = customer?.find(item => item?.id === customerId);
        if (foundCustomer) return foundCustomer;
        return list.find(item => item?.id === customerId) || null;
    }

    return customer?.length ? customer?.[0] : (list?.length ? list?.[0] : null);
}

/**
 * Method to set verification status
 * @param verificationData
 */
export function setVerificationStatus(verificationData: any) {
  const isCIPVerified = verificationData.cipStatus === "VERIFIED" || verificationData.cipStatus === "IGNORED";
  const isOFACVerified = verificationData.ofacStatus === "VERIFIED" || verificationData.ofacStatus === "IGNORED";
  const isCIPFailedOrRejected = verificationData.cipStatus === "FAILED" || verificationData.cipStatus === "REJECTED";
  const isOFACFailedOrRejected = verificationData.ofacStatus === "FAILED" || verificationData.ofacStatus === "REJECTED";

  if (isCIPVerified && isOFACVerified) {
    setKey("verificationStatus", 'true');
  } else if (isCIPFailedOrRejected || isOFACFailedOrRejected) {
    setKey("verificationStatus", 'failed');
  } else {
    setKey("verificationStatus", 'false');
  }
}

/**
 * Method to get data from session storage when refreshed
 * @returns :data
 */
export const loadStateFromSessionStorage = () => {
    try {
        const serializedState = getSessionStorageCustom('storeDetails');
        if (serializedState === null) {
            return undefined;
        }
        if (serializedState?.data) {
            for (let key in serializedState.data) {
                if (serializedState.data.hasOwnProperty(key) && serializedState.data[key].hasOwnProperty('data_state')) {
                    serializedState.data[key].data_state = 1;
                    serializedState.data[key].data ={}
                }
            }
        }
        return serializedState;
    } catch (err) {
        Logger.error("commonBaseClass.tsx", "loadStateFromSessionStorage error", err);
        return undefined;
    }
};

export const updateSessionStorageMiddleware = (store:any) => (next:any) => (action:any) => {
    const result = next(action);
  
    // Update session storage with the relevant part of the state after each action
    const stateToStore = store.getState();
    try {
        setSessionStorageCustom('storeDetails', stateToStore);
    } catch (err) {
      console.error("Could not save state to local storage", err);
    }
  
    return result;
  };
/**
 * Method used to set refresh properties
 */
export const getUserRefreshProperties = (accountDetails: any, dispatch:any) => {
    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'keycloak-post-login' } });
    setKey('selectedAccount', accountDetails)
    setKey('customerId', getSessionStorageCustom("customerId"))
    setKey('CustomerName', getSessionStorageCustom("CustomerName"))
    setKey('doingBusinessAs', getSessionStorageCustom("doingBusinessAs"))
    setKey('mxmMerchant', getSessionStorageCustom("mxmMerchant"))
    setKey('mxmFastFunding', getSessionStorageCustom("mxmFastFunding"))
    setKey('authUserId', getSessionStorageCustom("authUserId"))
}

/*
 * Method to calculate age from date.
 * @param date
 */
export const calculateAge = (date:any) => {
    const birthDate = new Date(date);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
    }
    return age;
};

type TextCase = 'title' | 'lowercase' | 'uppercase';

interface ScheduleType {
    title: string;
    lowercase: string;
    uppercase: string;
}

const collect: ScheduleType = {
    title: 'Collect',
    lowercase: 'collect',
    uppercase: 'COLLECT',
};

const deposit: ScheduleType = {
    title: 'Deposit',
    lowercase: 'deposit',
    uppercase: 'DEPOSIT',
};

/**
 * Method to check Schedule Type
 */
export const getScheduleType = (isBusinessCustomer: boolean, textCase: TextCase): string => {
    const scheduleType = isBusinessCustomer ? collect : deposit;
    return scheduleType[textCase];
};

/**
 * Method to get the last four digits of an SSN
 * @param ssn
 */
export const getLastFourDigits = (ssn: string | number) => {
  if (ssn === null || ssn === undefined) {
    return '';
  }
  // Ensure the input is treated as a string
  ssn = ssn.toString();

  // If the SSN is less than 4 digits, return the entire SSN
  if (ssn.length <= 4) {
    return ssn;
  }

  // Otherwise, return the last 4 digits
  return ssn.slice(-4);
}
/**
 * Method checks if any of the address fields (address line1, city, state, or zip) are entered.
 * If any of these fields are filled, it returns true, indicating that the validation is successful.
 * Otherwise, it returns false.
 * @param watch : hook form watch parameter
 * @returns : boolean value
 */
export const findAddressValidations = (watch: any) => {
    if(watch('addressLine1') || watch('city') || watch('state') || watch('zip'))
    {
        return true;
    }
    else{
        return false;
    }
}
/**
 * Method filters the properties of the object with null, undefined, or empty string values,
 * and replaces empty strings with null.
 * @param obj - Object to filter
 * @returns - Filtered object
 */
export function cleanWithEmptyValue(obj: any): any {
    for (const propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        } else if (obj[propName] === '') {
            obj[propName] = null;
        }
    }
    return obj;
}

/**
 * This function returns an array containing an object with the label and value 
 * for either 'SSN' or 'ITIN', depending on the provided node name and value.
 *
 * @param {string} nodeName - The name of the field, either 'ssn' or 'itin'.
 * @param {any} nodeValue - The value associated with the field.
 * @param {boolean} last4Node - A flag indicating whether to return 'last4ssn' instead of 'ssn' (default is false).
 * @returns {Array} An array containing an object with label and value for either 'SSN' or 'ITIN'.
 */
export function viewSsnItinNode(nodeName: string, nodeValue: string, last4Node: any = false) {
    // Check if the nodeName is 'itin' and nodeValue is truthy (not empty or false)
    if (nodeName === 'itin' && nodeValue) {
        // Return an array with an object indicating 'ITIN' as the label and 'itin' as the value
        return [{
            label: 'ITIN',
            value: 'itin'
        }];
    }
    
    // If nodeName is not 'itin' or nodeValue is falsy, return an array with 'SSN' as the label
    // If last4Node is true, use 'last4ssn' as the value, otherwise use 'ssn'
    return [{
        label: 'SSN',
        value: last4Node ? 'last4ssn' : 'ssn'
    }];
}


/**
 * This method helps in getting the SubType Value from the subType Name for the Transaction Summary
 * @param formNumber 
 * @returns 
 */
export function getSubtypeDescriptionByName(subtypeName: string, data: any): string | null {
    if (!data) {
      return null; // Return null if data or irsTaxType is missing
    }
    // Use find to search for the matching subtype
    for (const taxType of data) {
      const subtype = taxType.validSubType?.find((subtype: any) => subtype.name === subtypeName);
      if (subtype) {
        return `${subtype.name} - ${subtype.description}`; // Return formatted name and description
      }
    }
    // If no match is found, return null
    return null;
  }

  /**
   * Month Option available for Tax Month
   */
  export const month_options = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
];

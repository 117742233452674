/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { FxDelete } from '../../Data/FxDelete';
import { FxButton } from '../../Action/FxButton';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { ExternalAccountCard } from './ExternalAccountCard';
import { AddressListingCard } from './AddressListingCard';
import { checkPermission, getPaymentMethodsByCustomerType, updateComponentParams } from '../../Utils/CommonBaseClass';
import { getKey } from '../../../libs/utils/storageManager';
import { InternationalExternalAccountCard } from './InternationalExternalAccountCard';
import FxSnackBar from '../../Utils/fx-snack-bar';
import { useMediaQuery } from 'react-responsive';
import { ContactCardsListing } from './ContactCardsListing';
import UIFeatureImpl from '../../../libs/services/uiFeatures';


Logger.debug("ContactInfoCard.tsx", "Contact InfoCard initializing");
/**
 * Component: Contact Info Card
 * Usage: To display contact information
 */

export const ContactInfoCard: React.FC<any> = React.memo((props) => {
        const dispatch = useDispatch()
        const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
        const isEnableBillPaymentVisible = getKey('isEnableBillPaymentVisible') === 'true';
        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
        let context: any;
        ({ context, props } = RegisterComponent(props));
        let methods: any='';
        let contact_information_data: any = {};
        if (context && context.data && context.data.body) {
            contact_information_data = { ...context.data.body }
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: contact_information_data.name, backButton: '/payees' } } });
            methods = getPaymentMethodsByCustomerType(contact_information_data, businessCustomer);
            if(isEnableBillPaymentVisible && businessCustomer) {
                methods = methods ? methods + ', MONEYGRAM' :'MONEYGRAM';
            }
            contact_information_data.methods = methods;
            contact_information_data.fullname =   contact_information_data?.firstName ? contact_information_data?.firstName + ' ' + contact_information_data?.lastName : contact_information_data?.legalName
            contact_information_data.created = contact_information_data.createdBy.username + ' on ' + moment(contact_information_data.createdOn).format('MM/DD/YYYY')
            contact_information_data.lastUpdated = contact_information_data.lastUpdatedBy.username + ' on ' + moment(contact_information_data.lastUpdatedOn).format('MM/DD/YYYY')
        }

        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        
        /**
         * Params for Address list
         */
        const defaultParams={
            anchorEl:null,
            deleteShippingOpen:false,
            addressId:'',
        }
        const history = useHistory();

        /**
         * success event of delete
         */
        const deleteSuccess = () => {
            FxSnackBar.show({
                autoHideDuration: 1000,
                severity: 'success',
                text: 'Contact Deleted Successfully',
            });
            updateComponentParams(dispatch, props?.id, { 'isloading': false, deleteContactOpen: false });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'payee-details-info-card'} });
        }
        const contact_information_section_left = [
            {
                label: 'ID',
                value: 'id'
            },
            {
                label: 'Display Label',
                value: 'name'
            },
            {
                label: 'Payment Mode',
                value: 'methods',
                type: 'fri://fieldtype/paymentmodestatus'
            }
        ];
        const contact_information_section_ppi_left = [
            {
                label: 'ID',
                value: 'id'
            },
            {
                label: 'Display Label',
                value: 'name'
            },
            {
                label: 'Payment Mode',
                value: 'methods',
                type: 'fri://fieldtype/paymentmodestatus'
            }
        ];
        // declare contact information right section columns
        const contact_information_section_right = [
            {
                label: contact_information_data['contactType']==='BUSINESS'?'Legal Name':'Name',
                value: 'fullname'
            },
            {
                label: 'Type',
                value: 'contactType'
            }

        ];

        // showing audit field left column
        const audit_fields_left_section = [
            {
                label: 'Created',
                value: 'created'
            }
        ]
        // showing audit field right column
        const audit_fields_right_section = [
            {
                label: 'Last Updated',
                value: 'lastUpdated'
            }
        ]

    const editPermission = {
        entity:"Other Entities",
        name:"Contact",
        operation:"EDIT"
      }

    const deletePermission = {
        entity:"Other Entities",
        name:"Contact",
        operation:"DELETE"
      }

        //check api call completed or not
        const contextState = context?.data_state;
        const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;

        if(getKey("isPPIEnabled")?.toLowerCase() === 'true' && contact_information_data.ppi){
            contact_information_section_right.splice(1,1, {
                label: 'PPI',value: 'ppi' })
        }

        return (
            <>
                <Grid id="payees-info-Grid " className="fx-margin-bottom-16" item xs={12}  >
                        <FxCard id="payees-info-card" className="fx-theme-passport">
                            {dataLoadStatus ?
                                <>
                            <FxCardHeader id={'payees-info-card#card-header'}>
                                <Grid id="payees-Grid2" item container justifyContent="space-between" xs={12}  alignItems={'center'}>
                                    <Grid id="payees-Grid3">
                                        <Typography id="title-payees-info" variant="h4">Contact Details</Typography>
                                        <div id="div-grow-title-payees-list" className="fx-flex-grow" />
                                    </Grid>
                                    <Grid id="filter-Grid" item container className="fx-view-action fx-inline-flex">
                                        <Grid item><FxButton id="payee-edit-button" permissions={editPermission}
                                                             variant="contained"
                                                             className="fx-button fx-margin-left-10 fx-button-action fx-svg-theme"
                                                             startIcon={<EditIcon />}
                                                             onClick={() => history.push("/payee/edit/" + contact_information_data?.id)}>{isMobile ? '' : 'Edit'}</FxButton></Grid>
                                        <Grid item><FxDelete id={contact_information_data.id}
                                                             permissions={deletePermission} buttonId={'payee'}
                                                             entity={'contact'} buttonTitle={isMobile ? '' : 'Delete'}
                                                             header={"Are you sure want to delete this Contact?"}
                                                             description="You can only take this action if there are no transactions scheduled on any external account or address of this contact. You cannot undo this action."
                                                             redirectUrl={'/payees'} onSuccess={deleteSuccess} /></Grid>
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="payees-info-card#card-body" className="fx-info-card fx-margin-top-reducer">
                                <Grid container item xs={12} direction="row">
                                    <><Grid item xs={12} direction="column">
                                        <FxInfoCard id={"payees-information-info-card" + contact_information_data['id']}
                                                    title="CONTACT INFORMATION"
                                                    leftcolumn={context?.data?.body?.ppi ? contact_information_section_ppi_left : contact_information_section_left}
                                                    rightcolumn={contact_information_section_right}
                                                    data={contact_information_data} />
                                        <Grid item xs={12}>
                                            {contact_information_data && <FxInfoCard
                                                id="company-profile-company-information-card-audit-one-section-info-card"
                                                title="AUDIT FIELDS" leftcolumn={audit_fields_left_section}
                                                rightcolumn={audit_fields_right_section}
                                                data={contact_information_data}></FxInfoCard>}
                                        </Grid>
                                   </Grid>
                                    </>
                                </Grid>
                            </FxCardBody>
                                </>
                                :
                                <FxSkeltonList height="15rem" />
                            }
                        </FxCard>
                       {dataLoadStatus ?
                        (!context?.data?.body?.ppi && <ExternalAccountCard data={contact_information_data} id="payee-external-account-details-list-card"></ExternalAccountCard>)
                        :
                        <div className={'fx-margin-bottom-2'}>
                            <FxSkeltonList height="8rem" />
                        </div>
                       }
                       {dataLoadStatus ?
                        (!context?.data?.body?.ppi && <InternationalExternalAccountCard data={contact_information_data} id="payee-internationalexternal-account-details-list-card"></InternationalExternalAccountCard>)
                        :
                        <div className={'fx-margin-bottom-2'}>
                            <FxSkeltonList height="8rem" />
                        </div>
                       }
                       {dataLoadStatus ?
                        ((!context?.data?.body?.ppi && businessCustomer && uiFeat.getContactCardMethod().available && checkPermission({entity: "Other Entities",name: "Contact",operation: "View"})) && <ContactCardsListing data={contact_information_data} id="contact-cards-details-list-card"></ContactCardsListing>)
                        :
                        <div className={'fx-margin-bottom-2'}>
                            <FxSkeltonList height="8rem" />
                        </div>
                       }
                       {dataLoadStatus ?
                           ((!context?.data?.body?.ppi && checkPermission({entity: "Other Entities",name: "Mailing Address",operation: "View"})) && <AddressListingCard data={contact_information_data} {...defaultParams} id="payee-address-details-list-card"></AddressListingCard>)
                           :
                           <FxSkeltonList height="8rem" />
                       }
                </Grid >
            </>
        )
    })

/*
*@sarath
Fx popover component 
extends from the Material popover and 
enhances for the airbase requirements
*/
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import { MenuProps, ListItemIcon, MenuItem, ListItemText, Popover, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router';
import { InitializeComponent } from "../Utils/ComponentInitializer";
import handleAction from '../../libs/utils/actionHandler';
import { getIcon } from "../../libs/dictionary/icon";
import { Logger } from "../../libs/utils/logger";

Logger.debug("FxPopOver.tsx", "Fx Pop Over initializing")

declare interface IFxPopOver extends MenuProps {
  id: string | undefined;
  data?: any;
  open: any;
  row?: any;
  className?: string;
  refElement?: any;
  onClose?: any;
  anchorOrigin?: any;
  transformOrigin?: any;
  onChange?: any;
}

export const FxPopOver: React.FC<IFxPopOver> =
  (props) => {
    let context: any;
    const dispatch = useDispatch();
    const history = useHistory();
    ({ context, props } = InitializeComponent(props));

    let events: any;
    if (context && context.config && context.config.events) {
      events = context.config.events;
    }

    const anchorEl = typeof props.refElement === 'string' ? document.getElementById(props.refElement) : props.refElement;
    const handleClick = (event: React.ChangeEvent<HTMLInputElement>, item: any) => {
      event.stopPropagation();
      if (item.url || item.link) {
        const events = {
          "function": "redirect",
          "args": {
            "path": item.url ? item.url : item.link,
            "target": item.target
          }
        };
        handleAction(events, dispatch, event, history);
        props?.onClose(event, item);
      } else if (item.onClick) {
        handleAction(item.onClick, dispatch, props, null, null, item);
        handleClose(event);
      }else if(props.onClick){
        props.onClick(item)
        props.onClose?.(event, item);
      }
    }
    const handleClose = (event: any) => {
      handleAction(events.onClose, dispatch, event);
    }

    let popoverData: any = [];
    if (props.data) {
      popoverData = props.data;
    } else if (context && context.data && context.data.source && context.data.source.body && context.data.source.body.data) {
      popoverData = context.data.source.body.data;
    }
    else if (context && context.data && context.data.source && context.data.source.body) {
      popoverData = context.data.source.body;
    }

    return (
      <>
        <Popover
          id={props.id}
          open={props.open}
          anchorEl={anchorEl}
          className={props.className ? props.className :''}
          onClose={props.onClose ? props.onClose : handleClose}
          anchorOrigin={
            props.anchorOrigin ? props.anchorOrigin : {
              horizontal: 'left',
              vertical: 'bottom',
            }}
          transformOrigin={
            props.transformOrigin ? props.transformOrigin : {
              vertical: 'top',
              horizontal: 'left',
            }}
          PaperProps={{
            className: (props.className ? props.className : "fx-popover")
          }}
        >
          {popoverData && popoverData.map((item: any) => {
            return (
              <MenuItem className={item.className ? item.className : ''} onClick={(event: any) => { handleClick(event, item) }}>
                {item.icon &&
                  <ListItemIcon>{getIcon(item.icon)}</ListItemIcon>
                }
                <div>
                  <ListItemText id={props.id + '#export-' + item.label} className='fx-popover-label'>{item.label}</ListItemText >
                
                  {item.subLabel &&
                    // <ListItemText id={props.id + '#export-sub-label-' + item.subLabel}>{item.subLabel}</ListItemText >}
                    <Typography id={props.id + '#export-sub-label-' + item.subLabel} title={item.title} className='fx-popover-sub-label'>{item.subLabel}</Typography >}
                </div>
                {item?.iconSufix &&
                  <ListItemIcon>{getIcon(item.iconSufix)}</ListItemIcon>
                }
              </MenuItem>
            )
          })
          }
        </Popover>
      </>
    )
  }

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getImageSrc } from "../Utils/getImage";
import { InitializeComponent } from "../Utils/ComponentInitializer";
import { Logger } from "../../libs/utils/logger";

Logger.debug("FxImage.tsx", "Fx Image initializing")

interface IFxImage {
  value?: any;
  className?: any;
  id?: any;
  src?: any;
  fileId?: any;
  height?: any;
  width?: any;
}

/**
 * fx image component
 * @param props IFxImage
 */

const FxImage: React.FC<IFxImage> = (props) => {
  const dispatch = useDispatch();
  let context: any;
  ({ context, props } = InitializeComponent(props));

  useEffect(() => {

    //for logo icon calling from fxlogo
    if (props.fileId && context && !props.src) {
      getImageSrc(props.fileId, dispatch, "", function (src: any) {
        const payload = { data: { src: src }, id: props.id }
        dispatch({ type: "CONTEXT_COMPONENT_UPDATE_PARAM", payload })
      })
    }

    //for generic image calling from a datagrid
    if (props.value && context && !props.src) {
      getImageSrc(props.value, dispatch, "", function (src: any) {
        const payload = { data: { src: src }, id: props.id }
        dispatch({ type: "CONTEXT_COMPONENT_UPDATE_PARAM", payload })
      })
    }
  // eslint-disable
  }, []);
  return (
    <img id={props.id ? props.id : ''}
      src={props.src ? props.src : "/svg/no-photo.svg"}
      height={props.height ? props.height : ''}
      width={props.width ? props.width : ''}
      className={props.className ? props.className : ''}
      alt="">
    </img>
  )

}
export default FxImage;
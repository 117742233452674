/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ReactComponent as GreenDebitCardCongratsIcon } from '../../assets/svg/debit-card-congratulation.svg';
import { ReactComponent as PassportDebitCardCongratsIcon } from '../../assets/svg/debit-card-congratulations-passport.svg';
import { ReactComponent as AccountActivationCongratsIcon } from '../../assets/svg/account-activation-congratulation.svg';
import { ReactComponent as FastFundingCongratsIcon } from '../../assets/svg/fast-funding-congratulation.svg';
import { ReactComponent as SelectIcon } from '../../assets/svg/select-icon.svg';
import { FxDialogCard } from '../Utils/FxDialogCard';
import { Grid } from '@mui/material';
import UIFeatureImpl from '../../libs/services/uiFeatures';

interface IFxCongratulation {
    message: string;
    onButtonClick?: (event: any) => void;
    iconName: string;
    backButtonText?:string;
    noteContent?:string;
}

export const FxCongratulationCard: React.FC<IFxCongratulation> = React.memo(({ message, onButtonClick, iconName, backButtonText, noteContent }) => {
    const uiFeat = UIFeatureImpl.getInstance();
    const fastFundAvailable = uiFeat.getFasterFunding().available;
    
    return (
        <Grid xs={12} className={'fx-congratulation-card'}>
            <FxDialogCard
                messageContent={
                    <div>
                        {iconName === 'fastFunding' && <SelectIcon />}
                        <p>{message}</p>
                    </div>
                }
                Icon={iconName === 'debitCard' ? GreenDebitCardCongratsIcon : iconName === 'fastFunding' ? FastFundingCongratsIcon : iconName === 'merchant' ? PassportDebitCardCongratsIcon : AccountActivationCongratsIcon}
                title={'Congratulations!'}
                buttonText={backButtonText}
                onButtonClick={onButtonClick}
                noteContent={
                    <div>
                        <p>{noteContent}</p>
                    </div>
                }
            />
        </Grid>
    )
})
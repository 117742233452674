/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useCallback, useEffect, useMemo } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useDispatch } from 'react-redux';
import { FxButton } from '../../Action/FxButton';
import { DataGrid } from '../../Data/DataGrid';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../Data/FxGridList';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { maskAccountNumber, truncateChars } from '../../Utils/CommonBaseClass';
import moment from 'moment';

Logger.debug("ExternalAccountCard.tsx", "External Account Card initializing");

/**
 * Component: External Account Card Info Card
 * Usage: To display EA information
 */

export const ExternalAccountCard: React.FC<any> = React.memo((props) => {
        const dispatch = useDispatch();
        let cur_date: any = '';
         /**
         * rest the component on unmount, so that when redirect back page it will call the api again
         */
        useEffect(() => () => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        interface RouteParams {
            id: string
        }
        const params = useParams<RouteParams>();
        const contactId = params.id        
        const history = useHistory();
        const isMobile = useMediaQuery({ query: '(max-width: 999px)' });

        /**
         *  To generate the mobile row.
         * @param item :row item object
         */
        const generateMobileRow = (item: any) => {
            let title: any;
            if (cur_date === '' || cur_date !== moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')) {
                cur_date = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
                title = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
            }
            return {
                header: (
                    title ? <Grid item className='fx-grid-list fx-padding-top-header'><Typography variant='h6'>{title}</Typography></Grid> : <></>
                ),
                description: (
                    <Grid item container xs={12} className='fx-grid-list-content'>
                        <Grid item container xs={12} alignItems='center'>
                            <Grid item container xs={12}>
                                <Grid item>
                                    <Grid className='fx-info-label-value'>
                                        <Typography >ID: {item?.id}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid className='fx-info-label-value'>
                                        <Typography>{maskAccountNumber(item?.accountNumberLast4, false)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container xs>
                                    <Grid item xs>
                                        <Grid item container justifyContent="flex-end">
                                            <FxStatus id="international-external-account-status" value={item?.status} fill={true} className="fx-status"></FxStatus>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item className='fx-info-label-value'>
                                    <Typography>{truncateChars(item?.holderName, 25)}</Typography>
                                </Grid>
                                <Grid item container xs>
                                    <Grid item xs>
                                        <Grid item container justifyContent="flex-end" className='fx-info-label-value'>
                                            <Typography className='fx-customer-info-label'>{truncateChars(item?.bankInfo?.name, 25)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ),
                divider: (
                    !title ? true : false
                ),
            };
        } 

        /**
         *  To generate the desktop row.
         * @param item :row item object
         * @param contactId :contact id
         * @param contactName :contact name
         */
        const generateDesktopRow = useCallback((item:any, contactId:any, contactName:any) => {              
            return {
                  actions: <FxButton variant="contained" className="fx-button fx-button-passport-border" id="payee-ea-view-button" onClick={() => history.push(`/payee/id/${contactId}/externalaccount/view/${item?.id}`)}> View </FxButton>,
                  bankName: item?.bankInfo?.name,
                  holderName: item?.holderName,
                  status: item?.status,
                  accountNumberLast4: '**' + item?.accountNumberLast4,
                  id: item?.id,
                  lastUpdatedOn: item?.lastUpdatedOn,
                  lastUpdatedBy: item?.lastUpdatedBy?.username
              };
          },
          [history]
        );

        /**
         *  To get the list data as per device.
         */
        const externalAccountList = useMemo(() => {
            const {id, name, externalAccount }  = props?.data;
            const externalAccountData = externalAccount || [];
            if(isMobile){                 
                return externalAccountData.map((item: any, i: any) => {   
                    return generateMobileRow(item);                   
                });
            }
            else{ 
                return externalAccountData.map((item: any, i: any) => {   
                    return generateDesktopRow(item, id, name);                    
                });                      
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[isMobile, props?.data, generateDesktopRow])

        function redirectToCreatePage() {            
            const holderName = props?.data?.contactType === 'BUSINESS' ? props?.data?.legalName: props?.data?.firstName + ' ' + props?.data?.lastName;
            history.push('/payee/externalaccount/add/' + contactId,{contact:{name:props?.data?.name,id:props?.data?.id,holderName:holderName}});
        }
       
        // To get the mobile grid columns.
        const gridColumn: any = {
            "title": "Select Columns to Show",
            "columns": [
              {
                "enable": true,
                "fieldName": "divider",
                "type": "fri://fieldtype/divider",
                "xs": "12",
              },
              {
                "enable": true,
                "fieldName": "header",
                "type": "fri://fieldtype/header",
                "xs": "12"
              },
              {
                "enable": true,
                "fieldName": "description",
                "type": "fri://fieldtype/custom",
                "xs": "12",
                "alignItems": 'center'
              },        
            ]
          }

        const column: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "HOLDER NAME",
                    "enable": true,
                    "fieldName": "holderName",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "STATUS",
                    "enable": true,
                    "fieldName": "status",
                    "type": "fri://fieldtype/status",
                    "default": true
                },
                {
                    "label": "BANK",
                    "enable": true,
                    "fieldName": "bankName",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
                {
                    "label": "ACCOUNT NUMBER",
                    "enable": true,
                    "fieldName": "accountNumberLast4",
                    "type": "fri://fieldtype/default",
                    "default": true
                },

                {
                    "label": "Last Updated On",
                    "enable": true,
                    "fieldName": "lastUpdatedOn",
                    "type": "fri://fieldtype/date",
                    "default": true
                },
                {
                    "label": "Last Updated By",
                    "enable": true,
                    "fieldName": "lastUpdatedBy",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
            ]
        };
        
        /**
           * Method to redirect to the external account details page
           * @param event :event object
           * @param data : external account data
           */
        function redirectDetailsPage(event: any, data: any) {
             history.push(`/payee/id/${contactId}/externalaccount/view/${data.id}`);
        }

        const editPermission = {
            entity:"Other Entities",
            name:"Contact",
            operation:"EDIT"
          }

        return (
            <>
                <Grid id="payees-ea-info-Grid " className="fx-margin-bottom-16" item xs={12}  >
                    <FxCard id="payees-ea-info-card" className="fx-theme-passport">
                        <FxCardHeader id={'payees-ea-info-card#card-header'}>
                            <Grid id="payees-ea-Grid2" item container justifyContent="space-between" xs={12} >
                                <Grid id="payees-ea-Grid3"  >
                                    <Typography id="title-payees-ea-info" variant="h4" >External Account Details </Typography>
                                    <div id="div-grow-title-payees-ea-list" className="fx-flex-grow" />
                                </Grid>
                                <Grid id="filter-Grid" item container className="fx-view-action fx-inline-flex" >
                                    <Grid item><FxButton id="payees-ea-add-button" permissions={editPermission}  variant="contained" className="fx-button fx-button-passport-border fx-svg-theme" onClick={redirectToCreatePage} startIcon={<AddIcon />} > Add</FxButton></Grid>
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody id="home-recent-ea-card-body" className="fx-card-general">
                        {!isMobile ? <DataGrid id="home-recent-transactions-card-list" rowClick={redirectDetailsPage}  tableClass="fx-table-passport" data={externalAccountList} column={column} />
                         : <FxGridList id="home-recent-transactions-card-list" rowClick={redirectDetailsPage} data={externalAccountList} column={gridColumn}/>}
                       </FxCardBody>
                    </FxCard>
                </Grid >
            </>
        )
    })

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { ReactComponent as DebitCardCongratsPassportIcon } from '../../assets/svg/debit-card-congratulations-passport.svg';
import { FxDialogCard } from '../Utils/FxDialogCard';
import { Grid, Typography } from '@mui/material';

interface IAcceptReviewAgreement {
    primaryMessage?: string;
    onButtonClick?: (event: any) => void;
    secondaryMessage?: string;
}

export const FxDebitCardCongratulationsCard: React.FC<IAcceptReviewAgreement> = React.memo(({ primaryMessage, onButtonClick, secondaryMessage }) => {
    return (
        <Grid xs={12} className={'fx-congratulation-card'}>
            <FxDialogCard
                messageContent={
                    <Grid container justifyContent={'center'} >
                    <p className='fx-congratulation-card-text1'>{primaryMessage}</p>
                </Grid>
                }
                Icon={DebitCardCongratsPassportIcon}
                title={'Congratulations!'}
                buttonText={'View Debit Card'}
                onButtonClick={onButtonClick}
            />
            <Grid container justifyContent={'center'}>
                <Typography className='fx-congratulation-card-text2'>{secondaryMessage}</Typography>
            </Grid>
        </Grid>
    )
})
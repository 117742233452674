/* eslint-disable  @typescript-eslint/no-explicit-any */
import React  from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { EditExternalAccountCard } from './EditEAContact';
/**
 * This component loads the Edit EA component
 */

export const EditEAHome: React.FC<any> = React.memo(() => {
        //#region Variable Declaration
        const params = useParams<any>();

        //#region Assigning Values to Variable
    const { contactId, id } = params;
        // get contact api
        const edit_ea_source = {
            url:  "/contact/id/" + contactId,
            method: "GET"
        }
        const rand = Math.floor(Math.random() * 100) + 1;

        return (
            <Grid id="payee-ea-account-info-grid" container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container">
                <Grid item xs={12} lg={12} >
                    <EditExternalAccountCard eadetails={{ eaId: id,contactId:contactId }} key="editeacontact" id={rand + "-edit-ea-payees-component-" + id} source={edit_ea_source}></EditExternalAccountCard>
                </Grid>
            </Grid>
        )
    })

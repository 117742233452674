/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";
import { getAlerts, updateComponentParams } from "../../Utils/CommonBaseClass";
import { FxBadge } from "../../Utils/FxBadge";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as AlertIcon } from '../../../assets/svg/bell-icon.svg';
import { useLocation } from "react-router";

/**
 * Component: Alert Button Component
 * Usage: To display bell icon in Top Bar to show alerts count
 */

Logger.debug("AlertButtonComponent.tsx", "Alert Button Component initializing")

export const AlertsButtonComponent: React.FC<any> = props => {

    const dispatch = useDispatch();
    const location = useLocation()
    /**
     * useEffect to init
     */
    useEffect(() => {
        dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'alerts-info' } });
    }, [dispatch])

    /**
     * UseEffect to call alert count API when path changes
     */
    useEffect(()=>{
        location?.pathname !== "/switch-customer" &&  getAlertsCount();
    },[location?.pathname]);

    /**
     * funtion to update state for alerts count
     */
    async function getAlertsCount(){
        const data = await getAlerts();
        if(data){
            updateComponentParams(dispatch, "alerts-info", { 'count': data.data.totalCount});
        }
    }
    /**
    * to get alerts count
    */
    const alertsCount = useSelector((state: any) => {
        if (state.data['alerts-info']) {
            return state.data['alerts-info']?.params?.count
        } 
    });

    return (
        <Grid item>
            <FxBadge id='alert-badge' value={alertsCount}>
                <IconButton
                    component="a"
                    className="fx-profile-icon fx-logout"
                    id="menu-top-alerts-button"
                    onClick={props?.handleAlertsOpen}
                    title={'Alerts'}
                    aria-label={'Alerts'}
                    size="large"> 
                        <AlertIcon />
                </IconButton>
            </FxBadge>
        </Grid> 
    );
};

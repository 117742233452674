/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import FxLabelView from '../../../../Input/FxLabel/FxLabelView';
import FxCardBody from '../../../../Container/FxCardBody';
import { countryValueFormatter, toTitleCase } from '../../../../Utils/CommonBaseClass';
import { convertToCapitalLetters, maskAccountNumber, removeUnderScoreConvertTitleCase, stateValueFormatter, validateData } from '../../../../Utils/CommonBaseClass';
import { FxDelete } from '../../../../Data/FxDelete';
import { BusinessOwnerEdit } from './BusinessOwnerEdit';
import FxCard from '../../../../Container/FxCard';
import { FxSectionInfoCard } from '../../../Cards/FxSectionInfoCard';
import FxCardHeader from '../../../../Container/FxCardHeader';

//icons
import { ReactComponent as VerifiedIcon } from '../../../../../assets/svg/verified-new-icon.svg';
import { ReactComponent as TriangleDown } from '../../../../../assets/svg/triangle-down-icon.svg';
import { ReactComponent as TriangleUp } from '../../../../../assets/svg/triangle-up-icon.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/edit-new.svg';
import { useMediaQuery } from 'react-responsive';
import { getKey } from '../../../../../libs/utils/storageManager';
import { Logger } from '../../../../../libs/utils/logger';

//component used to view business owner details

export const BusinessOwnerCardView: React.FC<any> = React.memo((props) => {

        const [showDetails, setShowData] = useState(false);
        const [deleteBusinessOwner, setDeleteBusinessOwnerOpen] = useState(false);
        const [editView, setEditView] = useState(false);
        const [editDetails, setEditDetails] = useState<any>();
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
        const ownerProfileDetails = [
            {
                label: 'Date of Birth',
                value: 'dob'
            },
            {
                label: 'Social Security Number',
                value: 'ssn'
            },
            {
                label: 'Phone Number',
                value: 'mobilePhone'
            },
            {
                label: 'US Citizenship Status',
                value: 'usCitizen'
            }
        ]

        // Update owner profile details if 'itin' is present in the item props
        // Replace the SSN field with an ITIN label and value if 'itin' exists
        if (props?.item?.itin) {
            ownerProfileDetails[1] = {
                label: 'Individual Taxpayer Identification Number',
                value: 'itin'
            }
        }

        const ownerProfileAddress = [
            {
                label: 'Address Line 1',
                value: 'addressLine1'
            },
            {
                label: 'Address Line 2',
                value: 'addressLine2'
            },
            {
                label: 'City',
                value: 'city'
            },
            {
                label: 'State',
                value: 'state'
            },
            {
                label: 'Zip',
                value: 'zip'
            }
        ]
        /**
         * handle business owner view
         */
        const showHandleDetails = () => {
            setShowData(!showDetails)
        }

        const isAuthUser = props?.item?.email === getKey('email')
        const { item, editOpen } = props;

        //To open Auth User Card in Edit Mode
        useEffect(()=>{
            if(isAuthUser && !validateData(item)){
                setEditView(true);
                setEditDetails(item);
                if (editOpen) {
                    editOpen()
                }
            }
              // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])

        //set business owner details
        const ownerDetails: any = useMemo(() => {
            const data: any = { ...props?.item }
            data['ssn'] = maskAccountNumber(data.ssn, false)
            data['itin'] = maskAccountNumber(data.itin, false)
            data['state']=stateValueFormatter(data?.state)
            data['usCitizen'] = data?.usCitizen  ? toTitleCase(data.usCitizen): '' ;
            data['secondaryIdentificationIssuanceAlien'] = data['secondaryIdentificationIssuanceAlien'] ? countryValueFormatter(data['secondaryIdentificationIssuanceAlien']) : ''
            data['secondaryIdentificationIssuance'] = data['secondaryIdentificationIssuance'] ? countryValueFormatter(data['secondaryIdentificationIssuance']) : ''
            data['secondaryIdentificationIssuanceState'] = data['secondaryIdentificationIssuanceState'] ? stateValueFormatter(data['secondaryIdentificationIssuanceState']) : ''
            data['convertedSecondaryIdentificationType'] = data['secondaryIdentificationType'] ? removeUnderScoreConvertTitleCase(data['secondaryIdentificationType']) : ''
            return data
        }, [props?.item]);

  const secondaryIdentificationType = ownerDetails?.secondaryIdentificationType || 'DRIVER_LICENSE';

  const issuanceLocationsByType = {
    ALIEN_REGISTRATION_CARD: {
      label: 'Country of Issuance',
      value: 'secondaryIdentificationIssuanceAlien'
    },
    PASSPORT: {
      label: 'Country of Issuance',
      value: 'secondaryIdentificationIssuance'
    },
    DRIVER_LICENSE: {
      label: 'State of Issuance',
      value: 'secondaryIdentificationIssuanceState'
    }
  }

  const issuanceLocations = issuanceLocationsByType[secondaryIdentificationType as keyof typeof issuanceLocationsByType];

  const secondaryIdentification = [
    {
      label: 'Type',
      value: 'convertedSecondaryIdentificationType'
    },
    {
      label: 'ID',
      value: 'secondaryIdentificationId'
    },
    ...(issuanceLocations ? [issuanceLocations] : [])
  ]


        //remove business owner details
        const remove = (index: number) => {
            setDeleteBusinessOwnerOpen(false);
            const myArray: any = [...props?.data];
            myArray.splice(index, 1);
            if (props?.updatedData) {
                props?.updatedData(index, myArray)
            }
        };
      
        /**
        * Method handles the close event of the FxDelete component
        */
        const deleteBusinessOwnerClose = () => {
            setDeleteBusinessOwnerOpen(false);
        };
        /**
         * method to handle edit data and edit page
         * @param data :data
         */
        const editBusinessOwner = (data: any) => {
            setShowData(false)
            setEditView(true)
            setEditDetails(data)
            if (props?.editOpen) {
                props?.editOpen()
            }
        }
        /**
         * method to handle cancel edit
         */
        const cancelEdit = () => {
            setEditView(false)
            if (props?.editOpen) {
                props?.editOpen()
            }
        }
        /**
         * method to submit data od edit form
         */
        const submit = (data: any, req: any) => {
            if (props.onSubmit) {
                props.onSubmit(data, req, 'edit', props?.index)
            }
        }

        /**
         * Method converts string to float format
         * @param data 
         * @returns 
         */
        const convertToFloat = (data: any) => {
            try{
                const number = parseFloat(data);
                if (!isNaN(number)) {
                    return number.toFixed(2);
                }
            }
            catch(e){
                Logger.error('BusinessOwnerCardView.tsx',"error",e)
            }
            return '';
        }

        return (
            <Grid container id='business-owner-card' xs={12} className='fx-business-owner-view'>
                <FxCard id="signUp-business-owner-form-card-view" className={isSmallScreen?"fx-theme-passport fx-business-owner-view-border":"fx-theme-passport"}>
                <FxCardHeader>
                  <Typography className='fx-new-invite-owner-profile-label'>{props?.index + 1 + ". "} Owner Profile</Typography>
                    <Grid item xs >
                        <Grid container justifyContent="flex-end">
                           {!editView && <Grid item className="fx-info-kyc-view-topbutton">
                                <IconButton
                                    id={"btn-show-hide-ico"}
                                    className="fx-button fx-border-gray"
                                    onClick={showHandleDetails}
                                    size="large">{showDetails ? <TriangleUp /> : <TriangleDown />}</IconButton>
                            </Grid>}
                        </Grid>
                    </Grid>
                </FxCardHeader>
                    <div className="fx-form-edit-profile">
                        <FxCardBody id="signUp-beneficialOwner-form-card-body" className="fx-info-card fx-info-kyc-new-view" >
                            <Grid container direction="row" className="fx-form-edit-profile " xs={12}>
                                <Grid item container xs={12}>
                                    {!editView && <><Grid item xs={12} >
                                        {props?.item.ownerShipPercentage !== '' ? <Grid item sm={12} xs={12}>
                                            <Typography id="business-owner-name-label" variant="h5" >{convertToCapitalLetters(props?.item.firstName + ' ' + props?.item.lastName) + ' (Ownership - ' + convertToFloat(props?.item.ownerShipPercentage) + '%)'}</Typography>
                                        </Grid> :
                                            <Grid item sm={12} xs={12}>
                                                <Typography id="business-owner-name-label" variant="h5" >{convertToCapitalLetters(props?.item.firstName + ' ' + props?.item.lastName)}</Typography>
                                            </Grid>}
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography id="business-owner-name-label" variant='h6' className="" >{props?.item.jobtitle}</Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography id="business-owner-name-label" variant='h6' className="" >{props?.item.email}</Typography>
                                    </Grid>
                                    <Grid item xs={12} >&nbsp;</Grid>
                                    <Grid item container xs={12} alignItems="center" >
                                        <Grid item container xs={6}>
                                            {(props?.item.authSignatory?.[0] === 'authorized' ? true : props?.item.authSignatory === true) &&
                                                <>
                                                    <Grid item>
                                                        <VerifiedIcon />
                                                    </Grid>
                                                    <Grid item>
                                                        <FxLabelView id="business-owner-name-label" className="" >&nbsp;AUTHORIZED SIGNATORY</FxLabelView>
                                                    </Grid>
                                                </>}
                                        </Grid>
                                        {!editView &&
                                            <Grid item container justifyContent='flex-end' spacing={2} xs={6}>
                                                <Grid item>
                                                    <IconButton
                                                        id={"edit-business-owner"}
                                                        className="fx-button fx-button-action-no-border fx-svg-theme"
                                                        onClick={() => { editBusinessOwner(props?.item) }}
                                                        size="large"><EditIcon /> &nbsp; EDIT</IconButton>
                                                </Grid>
                                                { !isAuthUser && <Grid item>
                                                   {!props?.item?.id && <FxDelete entity="businessowner" className={'fx-button fx-button-action-no-border fx-svg-theme'} id={'delete-business-owner'} buttonId={'delete-business-owner-submit'} buttonTitle="REMOVE" header={"Are you sure want to delete this Business Owner?"} description="You cannot undo this action." successEntity="Business Owner" noEntity={true} open={deleteBusinessOwner} close={deleteBusinessOwnerClose} onSuccess={() => { remove(props?.index) }} />}
                                                    {props?.item?.id && <FxDelete entity="beneficialOwner" className={'fx-button fx-button-action-no-border fx-svg-theme'} noRedirection={true}  buttonId={'delete-business-owner-submit'} buttonTitle="REMOVE" header={"Are you sure want to delete this Business Owner?"} description="You cannot undo this action." successEntity="Business Owner" id={props?.item?.id} open={deleteBusinessOwner} close={deleteBusinessOwnerClose} onSuccess={() => { remove(props?.index) }} />}
                                                </Grid>}
                                            </Grid>}
                                    </Grid>

                                    {showDetails && !editView && <Grid item xs={12}>
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <FxSectionInfoCard id={"business-owner-info-card"} title={props?.index + 1 + '. OWNER PROFILE'} column={ownerProfileDetails} data={ownerDetails} />
                                        <Grid item xs={12}>&nbsp; </Grid>
                                        <FxSectionInfoCard id={"business-owner-secondary-identification-info-card"} title={"SECONDARY IDENTIFICATION"} column={secondaryIdentification} data={ownerDetails} />
                                        <Grid item xs={12} >&nbsp; </Grid>
                                        <FxSectionInfoCard id={"business-owner-address-info-card"} title={"PRIMARY ADDRESS"} column={ownerProfileAddress} data={ownerDetails} />
                                    </Grid>}
                                    </>}
                                    {editView && editDetails && <Grid item xs={12}>
                                        <BusinessOwnerEdit data={editDetails} index={props?.index} cancel={cancelEdit} businessDetails={props?.businessDetails} onSubmit={submit} />
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </FxCardBody>
                    </div>
                </FxCard>
            </Grid>
        );
    }
)

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import { Divider, Grid, Tooltip, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { AUTH_STRINGS } from '../../../../../constants/strings';
import FxMaterialSelect from '../../../../Input/FxSelect/FxMaterialSelect';
import FxCard from '../../../../Container/FxCard';
import FxCardBody from '../../../../Container/FxCardBody';
import FxCardFooter from '../../../../Container/FxCardFooter';
import { FxTextEdit } from '../../../../Input/FxText/FxTextEdit';
import FxLabelView from '../../../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../../../Action/FxButton';
import { ReactComponent as InternalIcon } from '../../../../../assets/svg/Internal-circle.svg';
import usa_state from '../../../../Utils/usa_states.json'
import {
  ageLimit,
  deleteUnwantedRequest,
  handleKeyDownChange,
  handleZipChange,
  handleAddressChange,
  renderError,
  setZipValidationOptions,
  renderOwnershipPercentageError,
  setPhoneValidationOptions,
  convertToPhoneRequestFormat,
  renderPhoneError,
  handleSecIdChange,
  secondaryIdentificationIdValidation,
  setAddressLine1Validation,
  setSsnValidationOptions,
  setItinValidationOptions
} from '../../../../Utils/CommonBaseClass';
import issuance_countries from '../../../../../libs/utils/country/country.json'
import { FxDateEdit } from '../../../../Input/FxDate/FxDateEdit';
import FxCheckEdit from '../../../../Input/FxCheck/FxCheckEdit';
import FxPhoneEdit from '../../../../Input/FxPhone/FxPhoneEdit';
import FxCardHeader from '../../../../Container/FxCardHeader';
import { FxTextEditSSN } from '../../../../Input/FxSSN/FxTextEditSSN';
import { FxSelectAutoSearch } from '../../../../Input/FxSelect/FxSelectAutoSearch';
import { useMediaQuery } from 'react-responsive';

//component used to add business owner
export const BusinessOwnerAdd: React.FC<any> = React.memo(
    (props) => {
        const { register, formState: { errors }, handleSubmit, setValue, control, clearErrors, setError, watch } = useForm();
        const [secondaryIdType, setSecondaryIdType] = useState('');
        const [checkBoxValue, setCheckBoxValues] = useState<any>({ authorized: false, creditReport: false })
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' });
        const taxIdentifier = watch('taxIdentifier') || 'ssn';
        const taxIdentifierData = [
            { label: "SSN", value: "ssn" },
            { label: "ITIN", value: "itin" },
        ];

        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            setCheckBoxValues({ authorized: false, creditReport: false })
        }, []);

       useEffect(() => {
            // Reset the value of the selected taxIdentifier (SSN or ITIN) field when the taxIdentifier changes
            setValue(taxIdentifier, "");
            
            // Clear any errors for the field that is not selected
            clearErrors(taxIdentifier === 'ssn' ? 'itin' : 'ssn');
        }, [taxIdentifier]);

        const secondaryIDTypeData: any = [
            { value: 'DRIVER_LICENSE', label: 'Driver License' },
            { value: 'PASSPORT', label: 'Passport' },
            { value: 'ALIEN_REGISTRATION_CARD', label: 'Alien registration Card' }
        ];

        const usCitizenDropdown:any =[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
        ]

        //Set Validation condition for Ownership Percentage
        const authSignDate: any = useMemo(() => {
            let requiredField: boolean;
            if (checkBoxValue?.authorized === true && (props.businessDetails?.['businessCategory'] === 'GOVERNMENT_ORGANISATION' || props.businessDetails?.['businessCategory'] === 'NON_PROFIT' || props.businessDetails?.['businessCategory'] === 'TAX_EXEMPTION' || props.businessDetails?.['businessCategory'] === 'LLC' || props.businessDetails?.['businessCategory'] === 'LLP' || props.businessDetails?.['businessCategory'] === 'S-CORP ' || props.businessDetails?.['businessCategory'] === 'C-CORP')) {
                delete errors['ownerShipPercentage']
                requiredField = true
            }
            else {
                requiredField = false
            }
            return requiredField
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [checkBoxValue])

        const authorizedCheckBoxData = [
            { label: 'Is an authorised signatory?', value: 'authorized', checked: checkBoxValue?.authorized }
        ]

        // let creditReportCheckBoxData = [
        //     { label: 'Pull Credit Report', value: 'creditReport', checked: checkBoxValue?.creditReport }
        // ]

        // Email validation constant
        const setEmailValidationOptions = {
            required: true,
            maxLength: 250,
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i,
                message: AUTH_STRINGS.ERRORS.EMAIL_INVALID_REGEXP,
            },
        };

        // Ownership percentage validation constant
        const setOwnerShipPercentageRule = {
            required: authSignDate === true ? false : true,
            min: 0,
            max: 100
        };

        // Method handles the handle change of ssn and mobile number fields
        const handleChange = (event: any) => {
            if (event.target.name === 'secondaryIdentificationType') {
                setSecondaryIdType(event.target.value)
                clearErrors('secondaryIdentificationId')
            }
            if (event.target.name === 'ssn') {
                let val = event.target.value.replace(/\D/g, '');
                val = val.replace(/^(\d{3})/, '$1-');
                val = val.replace(/-(\d{2})/, '-$1-');
                val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
                if (val.length === 11) {
                    clearErrors('ssn');
                }
                else {
                    setError("ssn", {
                        type: "manual",
                        message: "Invalid SSN",
                    });
                }
                setValue('ssn', val)
            }
        }
        // Method handles the submit for add beneficial owner form
        function onSubmit(data: any) {
            // If 'ssn' is being used, remove 'itin' from the data.
            // Otherwise, if 'itin' is being used, remove 'ssn' from the data
            if (taxIdentifier === 'ssn') {
                delete data.itin;
            } else {
                delete data.ssn;
            }
            delete data.taxIdentifier;
            const req: any = { ...data };
            if( data['workPhone'] === "" &&  data['homePhone'] === "" && data['mobilePhone'] === ""){
                setError("homePhone", {
                    type: "required",
                    message: "At least one of home phone, work phone or mobile phone is required.",
                  });
                return;
            }
            if (data['dob']) {
                req['dob'] = moment(data['dob']).format('MM/DD/YYYY');
            }
            req['mailingAddress'] = [{
                "addressLine1": data['addressLine1'],
                "addressLine2": data['addressLine2'],
                "city": data['city'],
                "state": data['state'],
                "zip": data['zip'],
                "isPrimary": true
            }]
            req['businessDetails'] = {
                'ownershipPercentage': data['ownerShipPercentage'],
                'title': data['jobtitle']
            }
            req["secondaryIdentification"] = data['secondaryIdentificationType'] === 'DRIVER_LICENSE' ? {
                'type': data['secondaryIdentificationType'],
                'id': data['secondaryIdentificationId'],
                'stateOfIssuance': data['secondaryIdentificationIssuanceState']
            } : {
                'type': data['secondaryIdentificationType'],
                'id': data['secondaryIdentificationId'],
                'countryOfIssuance': data['secondaryIdentificationType'] === 'ALIEN_REGISTRATION_CARD' ? 'US' : data['secondaryIdentificationIssuance']
            }
            req['mobilePhone'] = convertToPhoneRequestFormat(req.mobilePhone)
            if(req['mobilePhone'] === ""){
                delete req['mobilePhone'];
            }
            req['homePhone'] = convertToPhoneRequestFormat(req.homePhone)
            if(req['homePhone'] === ""){
                delete req['homePhone'];
            }
            req['workPhone'] = convertToPhoneRequestFormat(req.workPhone)
            if(req['workPhone'] === ""){
                delete req['workPhone'];
            }
            req['isUSCitizen'] = data['usCitizen']==='yes' ? true : false;
            req['actAsAuthorizedSignatory'] = data.authSignatory === undefined ? false : data.authSignatory[0] === 'authorized'

            if (!req['businessDetails']['ownershipPercentage']) {
                delete req['businessDetails']['ownershipPercentage'];
            }

            const unwantedRequestArray = ['state', 'addressLine1', 'city', 'zip', 'addressLine2', 'jobtitle', 'authSignatory', 'ownerShipPercentage', 'creditReport', 'secondaryIdentificationType', 'secondaryIdentificationId', 'secondaryIdentificationIssuance', 'secondaryIdentificationIssuanceState', 'usCitizen','mobilePhone-select','homePhone-select','workPhone-select'];
            deleteUnwantedRequest(req, unwantedRequestArray)
            if (props.onSubmit) {
                props.onSubmit(data, req, 'add', undefined)
            }

        }

        // Method handles the click operation for going back to business owner list screen
        const handleGoBackClick = () => {
            if (props?.cancel) {
                props.cancel()
            }
        }

        /**
         * handle change for check box
         * @param event :click event
         */
        const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement> | any) => {
            if (event.target?.type === 'checkbox') {
                if (event.target.value === 'authorized') {
                    setCheckBoxValues({ authorized: event.target.checked, creditReport: checkBoxValue?.creditReport === undefined ? false : checkBoxValue?.creditReport })
                }
                else {
                    setCheckBoxValues({ authorized: checkBoxValue?.authorized === undefined ? false : checkBoxValue?.authorized, creditReport: event.target.checked })
                }
            }
        }
       

        return (
            <Grid container id='signUp-business-owner' xs={12} className='fx-business-owner-add'>
                <FxCard id="signUp-business-owner-form-card" className={isSmallScreen?"fx-theme-passport fx-business-owner-add-border":"fx-theme-passport"}>
                    <FxCardHeader>
                      <Typography className='fx-new-invite-owner-profile-label'>{props?.data ? props?.data?.length + 1 + ". " : 1 + ". "}Owner Profile</Typography>
                    </FxCardHeader>
                    <div className="fx-form-edit-profile">
                        <form id="signUp-business-owner-form" onSubmit={handleSubmit(onSubmit)}>
                            <FxCardBody id="signUp-business-owner-form-card-body" className="fx-info-card" >
                                <Grid container direction="row" spacing={2} className="fx-info-card-form" >
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("firstName") }} className={errors.firstName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-form-card-first-name" label="Legal First Name *" name="firstName" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("lastName") }} className={errors.lastname ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-form-card-last-name" label="Legal Last Name *" name="lastName" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("email") }} type="email" className={errors.email ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setEmailValidationOptions} id="signUp-business-owner-form-card-email" label="Email *" name="email" variant="outlined" />
                                        <div className={'error-message'}>
                                            {errors.email && renderError(errors.email)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("jobtitle") }} className={errors.jobtitle ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-form-card-jobTitle" label="Job Title *" name="jobtitle" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                    <FxTextEdit register={{ ...register("ownerShipPercentage") }} className={errors.ownerShipPercentage ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setOwnerShipPercentageRule} id="signUp-business-owner-form-card-ownerShipPercentage" type="number" label={authSignDate === true ? "Ownership" : "Ownership*"} suffix="%" name="ownerShipPercentage" variant="outlined" showDecimal={true} setValue={setValue} min='0' defaultValue={0.00} />
                                        <div className={'error-message'}>
                                            {errors.ownerShipPercentage && renderOwnershipPercentageError(errors.ownerShipPercentage)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxLabelView id="business-owner-details-label" className="" >ABOUT OWNER</FxLabelView>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <FxDateEdit register={{ ...register("dob") }} className={errors.dob ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-form-card-dob" label="Date of Birth *" name="dob" variant="outlined" maxDate={ageLimit(18)} setValue={setValue} defaultCalendarMonth={ageLimit(18)}/>
                                    </Grid>
                                    <Grid item xs ={12} sm={6}>
                                        <FxMaterialSelect
                                            register={{ ...register("taxIdentifier") }}
                                            control={control}
                                            rules={{ required: false }}
                                            id="signUp-indivudal-form-card-tax-identifier"
                                            name="taxIdentifier"
                                            data={taxIdentifierData}
                                            value="ssn"
                                            label="Tax Identifier"
                                            setValue={setValue}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEditSSN
                                            key={taxIdentifier}
                                            register={{ ...register(taxIdentifier) }}
                                            className={errors.ssn || errors.itin ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                            error={errors?.ssn || errors?.itin}
                                            control={control}
                                            rules={taxIdentifier === 'ssn' ? {...setSsnValidationOptions(true)} : {...setItinValidationOptions(true)}}
                                            id="signUp-indivudal-form-card-ssn-itin"
                                            label={`${taxIdentifier === 'ssn' ? 'Social Security Number' : 'Individual Taxpayer Identification Number'}*`}
                                            placeholder="XXX-XX-XXXX"
                                            name={taxIdentifier}
                                            variant="outlined"
                                            setValue={setValue}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                        />                                   
                                        <div className={'error-message'}>
                                            {(taxIdentifier === 'ssn' && errors.ssn) ? renderError(errors.ssn) : (errors.itin) ? renderError(errors.itin) : ''}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxPhoneEdit register={register} className={errors.mobilePhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(true)} id="signUp-business-owner-form-card-mobilePhone" label="Mobile Phone *" name="mobilePhone" placeholder={'XXX-XXX-XXXX'} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                        <div className={'error-message'}>
                                            {errors.mobilePhone && renderPhoneError(errors.mobilePhone)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxPhoneEdit register={register} className={errors.homePhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(false)} id="signUp-business-owner-form-card-homePhone" label="Home Phone" name="homePhone" placeholder={'XXX-XXX-XXXX'} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                        <div className={'error-message'}>
                                            {errors.homePhone && renderPhoneError(errors.homePhone)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxPhoneEdit register={register} className={errors.workPhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setPhoneValidationOptions(false)} id="signUp-business-owner-form-card-workPhone" label="Work Phone" name="workPhone" placeholder={'XXX-XXX-XXXX'} setValue={setValue} setError={setError} clearErrors={clearErrors} />
                                        <div className={'error-message'}>
                                            {errors.workPhone && renderPhoneError(errors.workPhone)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxMaterialSelect register={{ ...register("usCitizen") }} rules={{ required: true }} className={errors.usCitizen ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="signUp-business-owner-select-usCitizen" name="usCitizen" data={usCitizenDropdown} label="Is US Citizen*" setValue={setValue} value={'US_CITIZEN'} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxLabelView id="business-owner-details-label" className="" >SECONDARY IDENTIFICATION</FxLabelView>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FxMaterialSelect register={{ ...register('secondaryIdentificationType') }} control={control} className={errors['secondaryIdentificationType'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={'secondaryIdentificationType'} name={'secondaryIdentificationType'} data={secondaryIDTypeData}
                                            onChange={handleChange} label="Type *" setValue={setValue} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FxTextEdit register={{ ...register("secondaryIdentificationId") }} className={errors.secondaryIdentificationId ? "border-error-input fx-input-edit login-email" : "fx-input-edit login-email"} control={control} rules={secondaryIdentificationIdValidation(secondaryIdType)} id="signup-secondaryIdentificationId" label="ID *" name="secondaryIdentificationId" onChange={(e: any) => { handleSecIdChange(e, setValue, setError, clearErrors, secondaryIdType) }} />
                                        <div className={'error-message'}>
                                            {errors.secondaryIdentificationId && renderError(errors.secondaryIdentificationId)}
                                        </div>
                                    </Grid>
                                    {secondaryIdType === 'DRIVER_LICENSE' &&
                                        <Grid item xs={12} sm={4}>
                                            <FxMaterialSelect register={{ ...register('secondaryIdentificationIssuanceState') }} control={control} className={errors['secondaryIdentificationIssuanceState'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={'secondaryIdentificationIssuanceState'} name={'secondaryIdentificationIssuanceState'} data={usa_state}
                                                onChange={handleChange} label="State of Issuance *" setValue={setValue} />
                                        </Grid>}
                                    {secondaryIdType !== 'DRIVER_LICENSE' && secondaryIdType !== 'ALIEN_REGISTRATION_CARD' && <Grid item xs={12} sm={4}>
                                        <FxMaterialSelect register={{ ...register('secondaryIdentificationIssuance') }} control={control} className={errors['secondaryIdentificationIssuance'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: secondaryIdType !== '' ? true : false }} id={'secondaryIdentificationIssuance'} name={'secondaryIdentificationIssuance'} data={issuance_countries}
                                            onChange={handleChange} readOnly={secondaryIdType === 'ALIEN_REGISTRATION_CARD' ? true : false} label="Country of Issuance *" setValue={setValue} />
                                    </Grid>}
                                    {secondaryIdType === 'ALIEN_REGISTRATION_CARD' &&
                                        <Grid item xs={12} sm={4}>
                                            <FxTextEdit register={{ ...register("secondaryIdentificationIssuanceAlien") }} className={errors.secondaryIdentificationIssuanceAlien ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                                control={control} id="signUp-business-owner-secondaryIdentificationIssuanceAlien" label="Country of Issuance *" name="secondaryIdentificationIssuanceAlien" defaultValue="US" isEditable={false} />
                                            <div className={'error-message'}>
                                                {errors.secondaryIdentificationIssuanceAlien && renderError(errors.secondaryIdentificationIssuanceAlien)}
                                            </div>
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={12}>
                                        <FxLabelView id="business-owner-details-label" className="" >PRIMARY ADDRESS</FxLabelView>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("addressLine1") }} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"}  rules={setAddressLine1Validation(true)} control={control} id="signUp-business-owner-addressLine1" label="Address Line 1 *" name="addressLine1"  onChange={(e: any) => { handleAddressChange(e, setValue, setError, clearErrors) }} />
                                        <div className={'error-message'}>
                                            {errors.addressLine1 && renderError(errors.addressLine1)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("addressLine2") }} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="signUp-business-owner-addressLine2" label="Address Line 2" name="addressLine2" />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FxTextEdit register={{ ...register("city") }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true }} id="signUp-business-owner-city" label="City *" name="city" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: true }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="signUp-business-owner-select-state" name="state" data={usa_state} label="State *" setValue={setValue} value={''} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FxTextEdit register={{ ...register("zip") }} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setZipValidationOptions(true)} id="signUp-business-owner-zip" label="ZIP *" name="zip" onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors) }} onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }} />
                                        <div className={'error-message'}>
                                            {errors.zip && renderError(errors.zip)}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container alignItems="center" >
                                            <Grid item >
                                                <FxCheckEdit register={{ ...register("authSignatory") }} onChange={handleChangeCheckBox} control={control} data={authorizedCheckBoxData} id="signUp-business-owner-form-card-authSignatory" name="authSignatory" row="vertical" setValue={setValue} />
                                            </Grid>
                                            <Grid item >
                                                <Tooltip title="Authorised Signatory will have authority to appoint the authorized signers on your Passport Account, to add or delete authorized signers on your Passport Account, to open and manage accounts, and to give us any other instructions on your behalf with respect to your Passport Accounts"><InternalIcon /></Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </FxCardBody>
                            <FxCardFooter id="signUp-business-owner-Card-Footer" className="fx-footer">
                                <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                    <FxButton
                                        disableRipple={false}
                                        variant="contained"
                                        className="fx-button fx-button-cancel"
                                        id="cancel-add-business-owner-btn"
                                        onClick={handleGoBackClick}
                                    > CANCEL
                                    </FxButton>
                                    <Grid className='fx-padding-right-15'></Grid>
                                    <FxButton
                                        disableRipple={false}
                                        className="fx-button fx-button-theme"
                                        id="save-add-business-owner-btn"
                                        type="submit"
                                    > Save
                                    </FxButton>
                                </Grid>
                            </FxCardFooter>
                        </form>
                    </div>
                </FxCard>
            </Grid>
        )
    }
)

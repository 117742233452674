/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'; // we need this to make JSX compile
import { Grid, ListItemIcon } from '@mui/material';
import { useHistory, useLocation } from 'react-router';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import { FxLink } from '../../Action/FxLink';
import { checkMenuDrawerPermission, checkTransactionApprovalPermission, getSelectedPath } from '../Passport';
import { LogoMenu } from '../../Utils/Logo';
import FxAvatar from '../../Input/FxAvatar/FxAvatar';

//icons
import { ReactComponent as DashboardIcon } from '../../../assets/svg/leftmenu-dashboard.svg';
import { ReactComponent as AuthorisedUsersIcon } from '../../../assets/svg/leftmenu-authorised-users.svg';
import { ReactComponent as TransactionsIcon } from '../../../assets/svg/leftmenu-transactions.svg';
import { ReactComponent as RolesPermissionIcon } from '../../../assets/svg/roles-permission-menu-icon.svg';
import { ReactComponent as RecurringIcon } from '../../../assets/svg/recurring-menu-icon.svg';
import { ReactComponent as LogoRightIcon } from '../../../assets/svg/leftmenu-logo-right.svg';
import { ReactComponent as ShoppingBagIcon } from '../../../assets/svg/shopping-bag.svg';
import { ReactComponent as AccountLedger } from '../../../assets/svg/account-ledger.svg';
import { ReactComponent as InvestmentInstruction } from '../../../assets/svg/portfolio-instruction-icon.svg';
import { ReactComponent as DebitCardIcon } from '../../../assets/svg/debit_card_icon.svg';
import { ReactComponent as ApprovalsIcon } from '../../../assets/svg/approval-queue-icon.svg';
import { ReactComponent as HomeIcon } from '../../../assets/svg/account-home-icon.svg';
import { useIsMobileOrTabletScreen } from '../../../libs/utils/utils';
import { FxWelcomeListMenu } from '../../Page/Index/Passport/WelcomeMenuList';
import { useDispatch, useSelector } from 'react-redux';
import { drawerCollapseTitle, swtichPortalSecondMenu } from '../../Utils/ThemeCheckerPartner';
import { ManageMenu } from '../ManageMenu';
import { handleHomeClick, handleLogoClick } from './DrawerHelper';
import { SettingsMenu } from '../SettingsMenu';
import { switchCustomerSecondMenu } from '../../Utils/ThemeChecker';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
/**
 * This Component handles the drawer collapse state
 */
export const DrawerCollapse: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const isMobileOrTabScreen = useIsMobileOrTabletScreen();
    const location = useLocation();
    const dispatch = useDispatch()
    const history = useHistory();
    const uiFeat = UIFeatureImpl.getInstance();
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
    const jointTennancy=uiFeat.getJointTenancyFeature().available;

  const accountBasedMenu = props?.accountBasedMenu;
  const accountList = useSelector((state: any) => state.data['customer-basic-info']?.params?.accountList || null);
  const selectedAccount: any = props?.selectedOption;

    return (
          <Grid item container className={"fx-menu-passport-collapse"} justifyContent="center" alignItems='flex-start' >
            <Grid item xs={12}>
              <Grid item container>
                <Grid item className="fx-menu-passport-collapse-logo" xs={12}>
                  <FxLink onClick={() => handleLogoClick(history, selectedAccount, accountList, dispatch)}><LogoMenu DrawerCollapse /></FxLink>
                </Grid>
                {drawerCollapseTitle(props)}
                <Grid item xs={12} className="fx-menu-passport-collapse-expandicon" id="expand-menu" >
                  <LogoRightIcon onClick={props.toggleDrawer} />
                </Grid>
                <Grid item xs={12} className="fx-menu-passport-collapse-menu">
                  <ul>
                    {accountList?.resources?.length > 1 && checkMenuDrawerPermission('accounts') && <li className={getSelectedPath(location) === 12 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-account-home" onClick={() => handleHomeClick(dispatch)} redirect={"/home"} title="Home" tooltip={true}><ListItemIcon> <HomeIcon /></ListItemIcon></FxLink></li>}
                    {accountBasedMenu && <>
                      <li className={getSelectedPath(location) === 1 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-dashboard" redirect={selectedAccount?.type === 'CASH_BUILDER_PLUS' ? "/cashbuilder" : selectedAccount?.type === 'CASH_BUILDER' ? "/portfolio" : "/"} title="Dashboard" tooltip={true}><ListItemIcon> <DashboardIcon /></ListItemIcon></FxLink></li>
                      {businessCustomer && (isMobileOrTabScreen) && checkMenuDrawerPermission('authorisedusers') && selectedAccount?.type !== 'DEPOSIT' && <li className={getSelectedPath(location) === 2 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-authorised-user" redirect="/authorizedusers" title="Authorized Users" tooltip={true}><ListItemIcon> <AuthorisedUsersIcon /></ListItemIcon></FxLink></li>}
                      {checkMenuDrawerPermission('accountledgers') && <li className={getSelectedPath(location) === 3 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-ledger" redirect={"/accountledgers"} title="Account Activity" tooltip={true}><ListItemIcon> <AccountLedger /></ListItemIcon></FxLink></li>}
                      {(checkMenuDrawerPermission('transactions_card_collect') || checkMenuDrawerPermission('transactions_check_collect') || checkMenuDrawerPermission('transactions_ach_collect') || checkMenuDrawerPermission('transactions_wire_send') || checkMenuDrawerPermission('transactions_book_send') || checkMenuDrawerPermission('transactions_check_send') || checkMenuDrawerPermission('transactions_ach_send') || checkMenuDrawerPermission('transactions_internationalWire_send') || checkMenuDrawerPermission('transactions_virtual_card_send')) && <li className={getSelectedPath(location) === 4 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-transaction" redirect="/transactions" title={isMobileOrTabScreen ? "Transactions" : "Transaction Activity"} tooltip={true}><ListItemIcon> <TransactionsIcon /></ListItemIcon></FxLink></li>}
                      {(checkMenuDrawerPermission('recurring_card_collect') || checkMenuDrawerPermission('recurring_ach_collect') || checkMenuDrawerPermission('recurring_wire_send') || checkMenuDrawerPermission('recurring_ach_send') || checkMenuDrawerPermission('recurring_check_send') || checkMenuDrawerPermission('recurring_book_send') || checkMenuDrawerPermission('recurring_virtual_card_send') || checkMenuDrawerPermission('recurring_internationalWire_send')) && selectedAccount?.type !== 'DEPOSIT' && <li className={getSelectedPath(location) === 5 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-transaction" redirect="/recurring" title={isMobileOrTabScreen ? 'Recurring Transactions' : "Recurring Txn. Activity"} tooltip={true}><ListItemIcon> <RecurringIcon /></ListItemIcon></FxLink></li>}
                      {checkMenuDrawerPermission('debitcards') && selectedAccount?.type !== 'DEPOSIT' && <li className={getSelectedPath(location) === 11 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-debit-cards" redirect="/debitcards" title="Debit Cards" tooltip={true}><ListItemIcon> <DebitCardIcon /></ListItemIcon></FxLink></li>}
                      {businessCustomer && (isMobileOrTabScreen) && checkMenuDrawerPermission('roles') && selectedAccount?.type !== 'DEPOSIT' && <li className={getSelectedPath(location) === 8 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-roles" redirect="/roles" title="Roles & Permissions" tooltip={true}><ListItemIcon> <RolesPermissionIcon /></ListItemIcon></FxLink></li>}
                      {businessCustomer && (isMobileOrTabScreen) && checkMenuDrawerPermission('company') && selectedAccount?.type !== 'DEPOSIT' && <li className={getSelectedPath(location) === 9 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-company-information" redirect="/company" title="Company" tooltip={true}><ListItemIcon> <ShoppingBagIcon /></ListItemIcon></FxLink></li>}
                      {checkTransactionApprovalPermission() && <li className={getSelectedPath(location) === 10 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-approvals" redirect="/pendingApprovals" title="Approvals" tooltip={true}><ListItemIcon> <ApprovalsIcon /></ListItemIcon></FxLink></li>}
                      {selectedAccount?.type === 'CASH_BUILDER' && <li className={getSelectedPath(location) === 5 ? 'fx-menu-passport-collapse-menu-sel' : ''}><FxLink id="menu-left-company-information" redirect="/investmentInstructions" title="Investment Instructions" tooltip={true}><ListItemIcon> <InvestmentInstruction /></ListItemIcon></FxLink></li>}
                    </>}
                      <ManageMenu id="manage-external-menu" drawerExpand={false} selectedAccount={selectedAccount}/>
                  </ul>
                </Grid>
                <Grid className='fx-sidenav-collapse' justifyContent='flex-end' alignItems='center' flexDirection='column' display='flex'>
                {(businessCustomer || jointTennancy) && <SettingsMenu id="manage-settings-menu-collapse"  drawerExpand={false} />}
                {switchCustomerSecondMenu(props)}
                  {getKey('isProxyLogin') !== true && (isMobileOrTabScreen) ?
                    <Grid item xs={12} className="fx-menu-passport-collapse-bottommenu">
                      <Grid item xs={12} container justifyContent='center'>
                        <FxLink id="menu-left-user-profile" redirect="/profile"><FxAvatar id="avtar-collapse" value={props?.userName} /></FxLink>
                      </Grid>
                    </Grid> : <FxWelcomeListMenu drawerExpand={props.drawerExpand} userName={props.userName}></FxWelcomeListMenu>
                  }
                {swtichPortalSecondMenu(props)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
    )
  })
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import {
    Grid,
    Typography,
    IconButton,
    Button,
    Divider,
    Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { Logger } from "../../../libs/utils/logger";
import FxCardHeader from "../../Container/FxCardHeader";
import FxCard from "../../Container/FxCard";
import FxCardBody from "../../Container/FxCardBody";
import { FxButton } from "../../Action/FxButton";
import { ReactComponent as LockIcon } from "../../../assets/svg/lock-image.svg";
import { ReactComponent as CrossIcon } from "../../../assets/svg/cross-icon.svg";
import { clean, toTitleCase, truncateChars, updateComponentParams } from "../../Utils/CommonBaseClass";
import HttpClient from "../../../libs/utils/httpClient";
import { getCustomerUrl, processAPIResponse } from "../../../libs/utils/utils";
import FxSnackBar from "../../Utils/fx-snack-bar";
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { useDispatch } from "react-redux";
import moment from "moment";
const httpClient = HttpClient.getClient();

Logger.debug("CustomerAPITokenDetails.tsx", "Customer api Token Details initializing");
/**
 * This component handles the customer api details
 */
export const CustomerAPITokenDetails: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const dispatch = useDispatch()
    const { handleSubmit, formState: { isSubmitting } } = useForm();
    console.log(props,'prpps')
    useEffect(() => {
        const processTokenDetails = (tokendata: any) => {
            const createdOn = moment(tokendata.createdOn).format('MMM DD, yyyy') + ' By';
            const createdBy = tokendata.createdBy;
            const roles = tokendata?.roleList?.map((item: any) => toTitleCase(item.name));
            const customerApiKeyData = [
                {
                    name: 'Status',
                    value: [toTitleCase(tokendata.status)],
                },
                {
                    name: 'Created',
                    value: [createdOn, createdBy],
                },
                {
                    name: 'Assigned Role(s)',
                    value: [''],
                    type: 'role'
                },
            ];
            updateComponentParams(dispatch, props.id, { apiTokenDetails: customerApiKeyData, tokendata: tokendata, roles: roles ? roles?.toString() : '' });
        };

        (async () => {
            try {
                const apiTokenDetails = await getDetails(props?.tokenId);
                const tokendata = apiTokenDetails?.data;
                processTokenDetails(tokendata);
            } catch (err) {
                Logger.error("CustomerAPITokenDetails.tsx", "error", err);
            }
        })();
    }, [props?.tokenId,dispatch,props?.id]);

    useEffect(() => {
        updateComponentParams(dispatch, props?.id, { showDeactivateButton: false })
    }, [dispatch,props?.id]);
    /**
     * Getting api token details
     * @param id 
     * @returns 
     */
    async function getDetails(id: any) {
        try {
            const details: any = await httpClient.get(getCustomerUrl('/token/id/' + id))
            return details;

        }
        catch (err) {
            Logger.error("CustomerAPITokenDetails.tsx", "error", err);
            return false;
        }


    }
    /**
     * Async function handles the form submission
     */
    async function onSubmit(data: any) {
        let status: any;
        const request = {
            keyName: [
                props?.tokendata?.keyName
            ]
        };
        status = await deActivate(clean(request));
        status = processAPIResponse(status)

        if (status.status) {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'customer-api-token-list-card-data' } });
            FxSnackBar.show({
                autoHideDuration: 1000,
                severity: 'success',
                text: 'Token Deactivated Successfully!',
            });

            props?.close()
        } else {
            //api  failed
            FxSnackBar.show({
                text: status.message,
            });
        }
        props.close();
    }

    /**
     * Method to call api for set Usage limit
     * @param payloadData : request payload
     */
    async function deActivate(payloadData: any) {
        try {
            const url = 'token/revoke'
            const data: any = await httpClient
                .post(getCustomerUrl(url, false), payloadData);
            return data;
        } catch (err) {
            Logger.error("deActivate.tsx", "error", err);
            return err;
        }
    }
    /**
     * handle deactivate event
     * @param e 
     */
    const handleDeactivate = (e: any) => {
        updateComponentParams(dispatch, props?.id, { showDeactivateButton: true })
    }
    return (
        <>
            {props?.apiTokenDetails && <div className="modal-content fx-customer-api-token-details-card-modal">
                <div className="modal-body">
                    {
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FxCard className="fx-theme-passport">
                                <FxCardHeader
                                    id="customer-api-details-card-header"
                                    className="fx-card-header"
                                >
                                    <Grid container item xs={12} justifyContent={'flex-end'} className="fx-modal-close-icon">
                                        <IconButton onClick={props.close} id="customer-api-details-card-modal-close-icon" size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </FxCardHeader>
                                <FxCardBody
                                    id="customer-api-details-card-modal-body"
                                    className="fx-info-card"
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                        className="fx-modal-form flex column"
                                        paddingLeft={"1rem"}
                                    >
                                        <Grid item container justifyContent="center" xs={12}>
                                            <IconButton
                                                id="customer-api-details-card-select-icon"
                                                size="large"
                                                disabled={true}
                                            >
                                                {!props?.showDeactivateButton ? <LockIcon /> : <CrossIcon />}
                                            </IconButton>
                                        </Grid>
                                        {!props?.showDeactivateButton && <><Grid
                                            container
                                            justifyContent="center"
                                            item
                                            xs={12}
                                            className="fx-delete-title"
                                            marginBottom={'1.5rem'}
                                        >
                                            <Typography id="customer-api-details-card-actions-popup-modal-title-text" className={'fx-modal-title-h1'}>
                                                {props?.tokendata ? props?.tokendata.keyName : ''}
                                            </Typography>

                                        </Grid>
                                            <Grid container xs={12} item justifyContent="start" className={'fx-padding-left-0'}>
                                                <Typography id="customer-api-details-card-title" className={'fx-modal-title-h3'}>
                                                    API Key Details
                                                </Typography>
                                            </Grid>

                                            {props?.apiTokenDetails?.map((item: any, index: any) => {
                                                return <Grid container alignItems={'center'} key={index} className={`fx-customer-token-row ${index % 2 === 0 ? 'fx-customer-token-row-gray' : ''} `} >
                                                    <Grid xs={6} sm={6} item>
                                                        <Typography id="customer-api-details-item-name" >
                                                            {item?.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container xs={6}>
                                                        {item?.value?.map((value: any, index: any) => {
                                                            return <Grid item xs={12} container justifyContent="flex-end">
                                                                <Typography id="customer-api-details-item-value" variant="body1" key={index}>
                                                                    {item?.name === 'Created' ? (
                                                                        <Tooltip title={value}>
                                                                            <Typography>{truncateChars(value, 25)}</Typography>
                                                                        </Tooltip>
                                                                    ) : value}
                                                                </Typography>
                                                                {item?.type === 'role' && <Typography id="customer-api-details-item-value-role" variant="body1" key={index}>
                                                                    {props?.roles}
                                                                </Typography>}
                                                                {/* {item?.type==='date' && <FxDateTimeView format={"MMM DD, yyyy"} value={value}></FxDateTimeView>
                                                                {}
                                                           } */}
                                                            </Grid>
                                                        })}
                                                    </Grid>
                                                    {/* {props?.roles && <Grid container sm={12} xs={12} justifyContent="flex-end">
                                                    <Typography id="customer-api-details-item-value2" variant="body1" key={index}>
                                                                    {props?.roles}
                                                                </Typography>
                                                    </Grid>} */}
                                                </Grid>
                                            })}
                                        </>
                                        }
                                        {
                                            props?.showDeactivateButton && <> <Grid
                                                container
                                                justifyContent="center"
                                                item
                                                xs={12}
                                                className="fx-delete-title"

                                            >
                                                <Typography id="customer-api-details-card-deactivate-popup-modal-title-text" className={'fx-modal-title-md'} variant={'h3'}>
                                                    Are you sure you want to Deactivate
                                                </Typography>
                                            </Grid>
                                                <Grid container
                                                    justifyContent="center"
                                                    item
                                                    xs={12}
                                                    className="fx-delete-title"
                                                >
                                                    <Typography id="customer-api-details-card-deactivate-popup-modal-info-text" className={'fx-modal-title-h4'}>
                                                        You will not be able to recover it
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }

                                        {!props?.showDeactivateButton && <Grid xs={12} className={'fx-modal-divider'}>
                                            <Divider />
                                        </Grid>}
                                        {props?.tokendata?.status==='ACTIVE' && <Grid
                                            container
                                            xs={12}
                                            justifyContent="space-between"
                                            className="fx-modal-footer"
                                        >
                                            <Grid item xs={6} paddingRight={1}>
                                                <Button fullWidth
                                                    variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="customer-api-details-card-deactivate-popup-cancel-btn"
                                                    onClick={props.close}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} paddingLeft={1}>
                                                {!props?.showDeactivateButton && <FxButton fullWidth
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme"
                                                    id="customer-api-details-card-deactivate-popup-deactivate-btn"
                                                    onClick={handleDeactivate}
                                                    isSubmitting={isSubmitting}
                                                > DEACTIVATE
                                                </FxButton>}
                                                {props?.showDeactivateButton && <FxButton fullWidth
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme"
                                                    id="customer-api-details-card-deactivate-popup-deactivate-btn"
                                                    type="submit"
                                                    isSubmitting={isSubmitting}
                                                > DEACTIVATE
                                                </FxButton>}
                                            </Grid>
                                        </Grid>}
                                    </Grid>
                                </FxCardBody>
                            </FxCard>
                        </form>
                    }
                </div>
            </div>}
        </>
    );
});

/*
 **
 ** Status Component
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { FxSecondary } from "../../Action/FxSecondary";
import { InitializeComponent } from "../../Utils/ComponentInitializer";
import { Logger } from "../../../libs/utils/logger";
import { toTitleCase } from "../../../libs/utils/string";

Logger.debug("FxStatus.tsx", "Fx Status initializing")

/**
 * Component used to show status
 */
declare interface IFxStatus {
  id?: string;
  value?: any;
  entity?: any;
  className?: any;
  fill: boolean;
  hyperLink?: any;
  modal?: any;
  staticWord?: any;
  mark?: boolean;
  appendText?: any;
  prefixText?: any;
  tooltip?: any;
}
// export FxStatus
export const FxStatus: React.FC<IFxStatus> = (props) => {
  //Pre-defined Status for entities

  const statusData = {
    "Validation":{
      values: ["not initiated"],
      color: "#000000",
      textcolor: "#000000",
      bgcolor: "rgba(0, 0, 0, 0.2)",
      bordercolor: "#000000",
     },
    "Good": {
      values: ["active", "completed", "verified", "accepted", "settled", "approved", "transaction","captured","enabled"],
      color: "#00b05c",
      textcolor: "#00b05c",
      bgcolor: "#d9f3e7",
      bordercolor: "#00b05c",
    },
    "Bad": {
      values: ["failed", "blocked", "expired", "rejected","cancelled","security", "debit blocked"],
      color: "#FF4E4E",
      textcolor: "#FF4E4E",
      bgcolor: "#F9E0E2",
      bordercolor: "#FF4E4E",
    },
    "Primary": {
      values: ["primary"],
      color: "#F48220",
      textcolor: "#F48220",
      bgcolor: "#F4822033",
      bordercolor: '#F48220',
    },
    "InProcess":{
      values: ["processing","closed", "pending","debit card","partially captured"],
      color: "#F48220",
      textcolor: "#D9B034",
      bgcolor: "rgba(217, 176, 52, 0.2)",
      bordercolor: '#F48220',
    },
    "Okay": {
      values: ["scheduled","approvals","uncaptured"],
      color: "#00AEEF",
      textcolor: "#00AEEF",
      bgcolor: "rgba(0, 174, 239, 0.2)",
      bordercolor: "#00AEEF",
    },
    "Ignore": {
      values: ["ignored", "voided"],
      color: "#727272",
      textcolor: "#727272",
      bgcolor: "#F0F0F0",
      bordercolor: "#727272",
    },
    "Inactive":{
      values: ["inactive"],
      color: "#4F4F4F",
      textcolor: "#4F4F4F",
      bgcolor: "rgba(242, 242, 242, 1)",
      bordercolor: "#4F4F4F"
    },
    "Transitional": {
      values: ["pending verification", "pending", "closure initiated","disabled","sent",'authorized','partially_authorized', 'account',"pending activation"],
      color: "#f48220",
      textcolor: "#f48220",
      bgcolor: "rgba(244,130,32,0.2)",
      bordercolor: "#f48220",
    },
    "alerts" : {
      values: ["external account"],
      color: "#9358B9",
      textcolor: "#9358B9",
      bgcolor: "#F4EEF8",
      bordercolor: "#9358B9",
    }
  };


  let context: any;
  ({ context, props } = InitializeComponent(props));
  if (context && context.config && context.config.params && context.config.events) {
    props = {
      ...props,
      ...context.config.params,
      events: { ...context.config.events },
    };
  }
  let setStatus: any
  //for ofac cip value coming as object
  if (typeof props.value === 'object' && props.value !== null) {
    setStatus = props.value?.name;
  }
  else {
    setStatus = props.value;
  }
  setStatus = getStatusText(setStatus);

  let selNod = statusData.Okay;

  try {
    Object.entries(statusData).forEach(([node, nodprops]) => {
      if (nodprops.values.indexOf(setStatus.toLowerCase()) > -1) {
        selNod = nodprops;
        return;
      }
    });
  } catch (e) { 
    Logger.error("FxStatus.tsx", "Error in FxStatus");
  }

  const setTextColor = selNod?.textcolor;
  const setBgColor = selNod?.bgcolor;
  const setBorderColor = selNod?.bordercolor;
  const fill_class = 'fx-status-filled ';
  const status_value: any = (props.prefixText ? props.prefixText : '') + setStatus
  return (
    <span id={props.id} className={'fx-status-mark'}>
      {props.fill === true ?
        (<><mark title={props.tooltip ? status_value : ''} className={fill_class} style={{ backgroundColor: setBgColor, color: setTextColor }}>{status_value}</mark>{<FxSecondary value='' hyperLink={props.hyperLink} modal={props.modal} staticWord={props.staticWord} mark={true}></FxSecondary>}{props.appendText}</>
        )
        :
        (<>
          <mark title={props.tooltip ? status_value : ''} className={fill_class} style={{ backgroundColor: "transparent", border: "1px solid " + setBorderColor, color: setTextColor }}>{status_value}</mark>
        </>
        )
      }
    </span>
  );
};


//get the status text after format
function getStatusText(statusText: any) {
  if (!statusText) return "";
  let retText = statusText;
  try {
    // eslint-disable-next-line no-useless-escape
    retText = retText.replace(/\_/g, " ");
    retText = toTitleCase(retText)

  } catch (e) { 
    Logger.error("FxStatus.tsx", "Error in FxStatus");
  }

  return retText;

}
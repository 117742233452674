/* eslint-disable */
import React from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form"
import { useHistory } from "react-router"
import { IConfig } from '../../types/common.interfaces';
import { getComponent } from "../../libs/dictionary";
import { InitializeComponent } from "../Utils/ComponentInitializer";
import { SagaHelper } from "../../libs/utils/sagaHelper";
import handleAction from "../../libs/utils/actionHandler";
import { FxSkeltonRightInfoCard } from "../Page/Cards/FxSkelton";
import { IsJsonString } from "../../libs/utils/conditionalHelpers";
import { Logger } from "../../libs/utils/logger";

Logger.debug("FxForm.tsx", "Fx Form initializing")

declare interface IFxForm {
    id: string;
    entity?: string;
    source?: string;
    mode?: string;
    formComponents?: any;
    request?: any;
    parentId?: any;
    customUrl?: any;
    isRestricted?: boolean;
}
let currentData: string;
let formHelper: any;

/**
 * Using only for dynamic rendering project to create form
 * @param props 
 */

export const FxForm: React.FC<IFxForm> = (props) => {

    // wire the state to component if any
    let context: any;
    ({ context, props } = InitializeComponent(props));
    let errorData: any;
    const dispatch = useDispatch();
    const history = useHistory();
    function prepareAPIEndpoint(apiData: string): any {

        if (apiData.trim().match(/^function\(/)) {
            const functionName = "api";
            let api = function (id: any, data: any) { };
            eval(functionName + " = " + apiData);
            return api;
        }
        return apiData;
    }
    function prepareTransformer(code: string): any {
        const functionName = "transformed";
        let transformed = function (id: any, data: any) { };
        eval(functionName + " = " + code);
        return transformed;
    }
    const methods = useForm();
    const { register, formState: { errors }, control, getValues, setError, clearErrors } = methods;

    function getFormData(formData: any) {
        for (const key in formData) {
            if (Object.prototype.hasOwnProperty.call(formData, key)) {
                if (typeof formData[key] == "string" && IsJsonString(formData[key])) {
                    const tempval = JSON.parse(formData[key]);
                    if (typeof tempval != "number") {
                        formData[key] = tempval;
                    }
                }
                else if (typeof formData[key] == "object") {
                    //check sub nodes
                    formData[key] = getFormData(formData[key]);
                }
            }
        }
        for (const key in formData) {
            if (typeof formData[key] == "string") {
                formData[key] = formData[key].trim();
            }
        }
        return formData
    }

    function getAlias(filters: any, alias: any = []) {
        if (filters.params && filters.params.fields) {
            filters.params.fields.forEach((f: any) => {
                if (f['alias']) {
                    alias[f['alias']] = f['fieldname'] ? f['fieldname'] : f['fieldName']
                }
            })
        }

        if (filters.components && typeof filters.components != 'string') {
            filters.components.forEach((c: any) => {
                getAlias(c, alias)
            })
        }
        return alias;
    }
    
    function compileFunction(code: any) {
        let functionName = function (data: any, event: any) { };
        eval("functionName = " + code);
        return functionName;
    }

    formHelper = () => {
        return {
            getData: getValues(),
            sagaHelper: new SagaHelper(dispatch),
            clearErrors: clearErrors,
            setError: setError,
            modalData: context.config && context.config.params && context.config.params.args,
            executeEvents: executeEvents,
            validateCustom: (type: string, value: any) => {
                switch (type) {
                    case 'email':
                        // eslint-disable-next-line no-useless-escape
                        const re = /^(([^<>()\[\]\\,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return re.test(value);
                    default: return false;
                }
            }
        };
    }
    const onBlur = (e: any) => {
        if (typeof context.config.events.onBlur == "string") {
            const actionF = compileFunction(context.config.events.onBlur);
            const formHelp = formHelper();
            /**Checking the old data and new data is same for preventing unwanted api call */
            if (JSON.stringify(formHelp.getData) !== currentData) {
                currentData = JSON.stringify(formHelp.getData);
                return actionF(formHelp, e);
            } else {
                return;
            }
        }
    }

    if (context && context.config && context.config.formComponents) {
        getWatchFields(props, context.config);
    }

    //get the fields to watch for blur event
    function getWatchFields(props: any, config: any) {
        if (config.events.onBlur) {
            return;
        }
    }

    const onSubmit = (formDataOrg: any) => {
        formData = getFormData(formDataOrg);

        if (typeof context.config.events.onSubmit !== "string") {
            const source = JSON.parse(JSON.stringify(context.config.events.onSubmit.args.source));
            source.method = source.api.method ? source.api.method : source.method;
            source.header = source.api.header ? source.api.header : source.header;
            const onSubmit = JSON.parse(JSON.stringify(context.config.events.onSubmit));
            const api = prepareAPIEndpoint(source.api.url);
            if (source.api.transformer) {
                const transformer = prepareTransformer(source.api.transformer);
                formData = transformer(props.id, formData);
            }

            //this is usefull with dynamic link based on form values
            source.formData = formData;
            source.api = typeof api == "function" ? api(source, props) : api;
            onSubmit.args.source = source;

            onSubmit['mode'] = props.mode;
            handleAction(onSubmit, dispatch, formData, null, (cb: any) => {
                executeEvents(cb);
            });

        } else {
            handleAction(context.config.events.onSubmit, dispatch, formData, null, null, formHelper(), props.request);
        }
    };

    //execute chained events after submit like 
    //check errors close,reload card,reload etc
    const executeEvents = (cb: any) => {
        const formComponents = context.config.formComponents
        const alias = getAlias(formComponents)
        if (cb != null) {
            /**Checking Field type Error from Api */
            if (cb.errorData && cb.errorData != null) {
                errorData = cb.errorData;
                if (errorData) {
                    let flag = 0;
                    const errorArray: any = [];
                    /**Loop through the Error and set that error in a array */
                    errorData.forEach((element: any) => {
                        for (const key in formData) {
                            if (element.field.indexOf(".") !== -1) {
                                const arrayFieldName = element.field.split('.');
                                if (arrayFieldName[0] === key) {
                                    if (Object.prototype.hasOwnProperty.call(alias, key)) {
                                        errorArray.push({
                                            name: alias[key],
                                            type: "required",
                                            message: element.message
                                        })
                                        flag = 1;
                                    } else {
                                        errorArray.push({
                                            name: element.field,
                                            type: "required",
                                            message: element.message
                                        })
                                        flag = 1;
                                    }
                                }
                            } else {
                                if (element.field === key) {
                                    if (Object.prototype.hasOwnProperty.call(alias, key)) {
                                        errorArray.push({
                                            name: alias[key],
                                            type: "required",
                                            message: element.message
                                        })
                                        flag = 1;
                                    } else {
                                        if (typeof formData[key] == 'object') {
                                            flag = 0;
                                        } else {
                                            errorArray.push({
                                                name: element.field,
                                                type: "required",
                                                message: element.message
                                            })
                                            flag = 1;
                                        }
                                    }
                                }
                            }
                        }
                        if (flag === 0) {
                            context.config.events.onError.args.payload.data.text = element.message;
                            handleAction(context.config.events.onError, dispatch, null);
                        }
                    })
                    /**Check Error srrsy is empty and Call react hook for setError function */
                    if (errorArray.length > 0) {
                        errorArray.forEach((error: any) =>
                            setError(error.name, { type: error.type, message: error.message })
                        );
                    }
                }
                else if (cb.error) {
                    context.config.events.onError.args.payload.data.text = cb.error;
                    handleAction(context.config.events.onError, dispatch, null);
                }
            } else if (cb.isError || cb.status > 404) {
                let error_message = "";
                if (cb.error) {
                    error_message = cb.error;
                } else {
                    error_message = "Some error occured! Please try after some time";
                }
                context.config.events.onError.args.payload.data.text = error_message;
                handleAction(context.config.events.onError, dispatch, null);
            } else if (cb.error) {
                context.config.events.onError.args.payload.data.text = cb.error;
                handleAction(context.config.events.onError, dispatch, null);
            } else {
                if (context.config.events.onClose) {
                    handleAction(context.config.events.onClose, dispatch, null);
                    handleAction(context.config.events.onSuccess, dispatch, null);
                    window.location.reload();
                }
                else if (context.config.events.onCloseNotReload) {
                    handleAction(context.config.events.onCloseNotReload, dispatch, null);
                    handleAction(context.config.events.onSuccess, dispatch, null);
                }
                else if (context.config.events.onCloseCardReload) {
                    handleAction(context.config.events.onCloseCardReload, dispatch, null);
                    handleAction(context.config.events.onSuccess, dispatch, null);

                    let payload;
                    //reset form
                    if (context.config.source) {
                        //source won't be available for add form
                        const datasource = JSON.parse(JSON.stringify(context.config.source))
                        datasource.status = 0
                        payload = { source: datasource, id: props.id }
                        dispatch({ type: "COMPONENT_UPDATE_CONFIG_SOURCE", payload: payload })
                    }
                    //reset card
                    const cardid = context.config.events.onCloseCardReload.cardid
                    if (cardid) {
                        if (cardid.constructor === Array) {
                            for (const cid of cardid) {
                                payload = { source: { status: 0 }, id: cid }
                                dispatch({ type: "COMPONENT_UPDATE_CONFIG_SOURCE", payload: payload })
                            }
                        }
                        else {
                            payload = { source: { status: 0 }, id: cardid }
                            dispatch({ type: "COMPONENT_UPDATE_CONFIG_SOURCE", payload: payload })
                        }

                    }
                }
                if (context.config.events.onSubmitNext) {
                    if (props.customUrl) {
                        setTimeout(() => {
                            handleAction(context.config.events.onSubmitNext, dispatch, cb, history, formData, formHelper(), props.request);
                        }, 300)
                    } else {
                        window.location.reload();
                    }
                }
            }
        }
    };
    let formData = context.config ? context.config.formComponents : {};
    const data = context.data && context.data.source ? context.data.source.body : null;
    const request = context && context.request ? context.request : null;

    if (props.isRestricted === true) {
        return <></>
    }
    else {
        return (
            <>
                {props.mode === "update" && context.config && !data && <FxSkeltonRightInfoCard className="fx-skelton fx-skelton-details-modal" />}
                <FormProvider {...methods}><form id={props.id && props.id} onSubmit={methods.handleSubmit(onSubmit)} onBlur={onBlur}>
                    {props.mode === "create" && context.config && createForm(formData, dispatch, register, errors, data, request, control)}
                    {props.mode === "update" && data && context.config && createForm(formData, dispatch, register, errors, data, request, control)}
                </form>
                </FormProvider>
            </>
        )
    }
}


export function createForm(json: any, dispatch: any, register: any, errors: any, data: any = null, request: any, control: any, formState?: any) {
    return render(json, dispatch, errors, register, data, request, control, formState);
}

export function getFormHelper() {
    if (!formHelper) {
        return;
    }
    return formHelper();
}

function isValidComponent(comp: any) {
    return React.isValidElement(React.createElement(comp, {}));
}
export function render(config: IConfig, dispatch: any, errors: any, register: any, data: any, request: any, control: any, formState?: any): any {
    const component = config.id;

    const element = component ? getComponent(component) : null;
    if (component && element && isValidComponent(element)) {
        dispatch({ type: "SET_CONTEXT", payload: config })

        return React.createElement(
            element,
            {
                key: config.id,
                id: config.id,
                request: request,
                data: data,
                formState: formState
            },
            config.components &&
            (typeof config.components === "string"
                ? config.components
                : config.components.map((c: IConfig) => render(c, dispatch, errors, register, data, request, control, formState)))
        );
    }
}
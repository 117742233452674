/* eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Controller, FieldValues, UseFormClearErrors, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import { Logger } from "../../../libs/utils/logger";
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-new.svg';
import { handleKeyDownChange } from "../../Utils/CommonBaseClass";

/**
 * Component used for edit text
 */
Logger.debug("FxTextEditSSN.tsx", "Fx Text Edit initializing")

// Define the types for functions to handle setting errors, clearing errors, and updating form values
type SetErrorFn = (name: string, error: { type: string; message: string }) => void;
type ClearErrorsFn = (name: string) => void;
type SetValueFn = (name: string, value: string) => void;

interface FxTextEditSSNProps {
  id: string;
  name: string;
  value?: string;
  defaultValue?: String;
  className?: string;
  error?: boolean;
  label?: React.ReactNode;
  placeholder?: string;
  rules?: any,
  register: any,
  control: any,
  variant?: "standard" | "filled" | "outlined" | undefined;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

/**
 * @param props FxTextEditSSNProps
 * Component can be used for edit ssn.
 */
export const FxTextEditSSN: React.FC<FxTextEditSSNProps> =
  (props) => {
    const { id, name, error, setValue, clearErrors, setError, control, rules, register, defaultValue, placeholder, label, variant } = props;

    const className = (props.className ? props.className : 'fx-input-edit')

    const [editing, setEditing] = useState(false);
    const [showIcon, setShowIcon] = useState(false);
    const [last4DigitSSN, setLast4DigitSSN] = useState<any>(defaultValue ? '*****' + defaultValue.substr(defaultValue?.length - 4) : '');

    const textBoxRef = useRef<any>();

    const ssnDefaultValue = defaultValue?.length === 4 ? '' : defaultValue;

    function onMouseOver() {
      if(!rules?.disabled){
        setShowIcon(true)
      }
    }

    function onMouseOut() {
      setShowIcon(false)
    }

    useEffect(() => {
      if (setValue) {
        setValue(name, ssnDefaultValue);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue])

    function onBlur(e: any) {
      try {
        const ssn = e.target.value;
        if (!error) {
          setEditing(false);
          setValue(name, ssn);
          if (ssn) {
            const last4DigitSSN = '*****' + ssn.substr(ssn.length - 4);
            setLast4DigitSSN(last4DigitSSN);
          } else {
            setLast4DigitSSN('');
          }
        }
      }
      catch (e) { }
    }

    /**
    * On edit click handler
    */
    const handleClickEditIcon = (value: any) => {
      setEditing(true);

      name === 'itin' && setValue(name, '');
      setTimeout(() => {
        textBoxRef?.current?.focus();
      }, 300)

    };

    /**
     * A generic onChange handler that validates input fields for ITIN and SSN.
     * 
     * @param event - The change event triggered by the input field.
     * @param setError - Function to set an error message when validation fails.
     * @param clearErrors - Function to clear any existing errors if validation passes.
     * @param setValue - Function to update the value of the form input.
     */
    const onChangeHandler = (
      event: React.ChangeEvent<HTMLInputElement>,
      setError: SetErrorFn,
      clearErrors: ClearErrorsFn,
      setValue: SetValueFn
    ) => {
      try {
        const { name: targetName, value: targetValue } = event.target;
        if (targetName !== name) return;
        if (targetValue.includes('*')) {
          setValue(name, '');
          return;
        }

        const isPatternSatisfied = rules?.pattern?.value;
        // Sanitize the input value by removing non-digits and applying formatting (XXX-XX-XXXX)
        const sanitizedValue = targetValue.replace(/\D/g, '')
          .replace(/^(\d{3})/, '$1-')
          .replace(/-(\d{2})/, '-$1-')
          .replace(/(\d)-(\d{4}).*/, '$1-$2');

        // Check if the input is valid based on the name and pattern
        const isITIN = name === 'itin' && isPatternSatisfied?.test(targetValue); // Validate ITIN based on the regex pattern
        const isSSN = name === 'ssn' && sanitizedValue.length === 11; // Validate SSN by ensuring it matches the XXX-XX-XXXX format
        
        if (isITIN || isSSN) {
          clearErrors(name);
        } else if (targetValue) {
          if (targetValue?.length < 11 && name === 'itin') {
            setError(name, { type: 'manual', message: 'Invalid ITIN format' });
          }
          else {
            const errorMessage = rules?.pattern?.message || `Invalid ${name.toUpperCase().includes('ITIN') ? 'ITIN' : 'SSN'}`;
            setError(name, { type: 'manual', message: errorMessage });
          }
        }
        setValue(name, sanitizedValue);
      } catch (error) {
        Logger.error('Error handling input change:', error);
      }
    };

    return <>
      {!editing && last4DigitSSN !== '' ?
        <TextField
          variant={variant}
          id={`${id}-view`}
          name={`${name}-input`}
          disabled={true}
          defaultValue={last4DigitSSN}
          placeholder={placeholder ? placeholder : ""}
          className="fx-input-edit"
          type={'text'}
          onMouseEnter={onMouseOver}
          onMouseLeave={onMouseOut}
          label={label ? label : ''}
          InputProps={
            {
              endAdornment: showIcon && (
                <InputAdornment position="end">
                  <IconButton
                    className="fx-icon-button"
                    onClick={handleClickEditIcon}
                    id={`${id}-view-icon`}
                    size="large">
                    {<EditIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }
          }
        /> :
        <Controller
          name={name}
          control={control ? control : ''}
          rules={rules ? rules : {}}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              {...register}
              variant={variant}
              id={id}
              name={name}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={ssnDefaultValue}
              placeholder={placeholder ? placeholder : ""}
              className={className}
              type={'text'}
              onBlur={onBlur}
              label={label ? label : ''}
              value={(props.value === '' ? props.value : (props.value ? props.value : value))}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(
                e,
                setError, 
                clearErrors, 
                setValue
              )}
            
              onKeyDown={(e: any) => { handleKeyDownChange(e, setValue) }}
              inputRef={textBoxRef}
              error={error ? error : false}
            />
          )}
        />
      }
      <TextField
            className="fx-hidden"
            id={"react-" + props.id + "-ssn-input"}
            name={props.name}
            value={defaultValue ? defaultValue : ""}
            inputRef={textBoxRef}
            type={'hidden'} />
    </>;
  }
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid } from '@mui/material';
import React from 'react';
import { ReactComponent as MXMLogo } from '../../assets/svg/mxm-logo.svg';
import {ReactComponent as BackArrow} from '../../assets/svg/BackArrow.svg'
import { FxButton } from '../Action/FxButton';
import { getKey } from '../../libs/utils/storageManager';
import { useIsMobileScreen } from '../../libs/utils/utils';

declare global {
    interface Window {
        MXM_PORTAL_URL: any;
    }
}


export const FxBackToMXM: React.FC<any> = (props: any) => {

    let goBackUrl: any = getKey('goBackUrl');
    goBackUrl = props?.ssoUser ? goBackUrl : window.MXM_PORTAL_URL ;
    const isSmallScreen = useIsMobileScreen();

    return (
            <FxButton id="back-to-mxm-button" className="fx-arrow-button fx-mxm-back-button"
                      aria-label="more"
                      aria-haspopup="true"
                      onClick={()=> props?.ssoUser ? window.location.href=goBackUrl : !isSmallScreen ? window.open(goBackUrl, '_blank') : window.location.href=goBackUrl }>
                <Grid container alignItems="center" justifyContent='space-between' xs={12}>
                {props?.ssoUser && <Grid container item xs={1} justifyContent='flex-end'>
                       <BackArrow className="fx-back-arrow-icon" />
                    </Grid>}
                    <Grid container item xs={2} justifyContent='flex-end'>
                        <MXMLogo className="fx-mxm-logo" />
                    </Grid>
                    <Grid container item xs={8} justifyContent='start'>
                        <p>
                            {!props?.ssoUser && <span>Go to MX Merchant</span>}
                            {props?.ssoUser && <span>Back to MX Merchant</span>}
                            {!isSmallScreen && <span>{getKey('doingBusinessAs') || getKey('CustomerName')}</span>}
                        </p>
                    </Grid>
                    {!props?.ssoUser && !isSmallScreen && <Grid container item xs={1} justifyContent='flex-end'>
                       <BackArrow className="fx-right-arrow-icon " />
                    </Grid>}
                   
                </Grid>
            </FxButton>
    )
}
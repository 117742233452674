/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { TextField } from "@mui/material";

/**
 * Component used to show password values 
 */
interface FxPasswordFieldProps {
  id?: string;
  value?: string;
  name?: string;
  className?: string;
  error?: boolean;
  readonly?: boolean;
  label?: React.ReactNode;
  placeholder?: string;
  labelColor?: string;
  inputRef?: any;
  rounded?: boolean;
  borderColor?: string;
  searchIcon?: boolean;
  noGrow?: boolean;
  helperText?: string;
  viewType?: "standard" | "filled" | "outlined" | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
}
export const FxPassword: React.FC<FxPasswordFieldProps> =
  (props) => {

    return (
      <TextField className={props.className} id={props.id} type="password"
        inputRef={props?.inputRef}
        error={props?.error}
        variant={props?.viewType}
        name={props?.name}
        value={props.value}
        onChange={props.onChange} />
    )
  };

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';

Logger.debug("FxAcceptedCurrencyView.tsx", "Fx Accepted Currency View initializing");

/**
 * Component used to display Address
 */
interface IAcceptedCurrencyView {
    value?: any;
    className?: any;
    tooltip: any;
    id?: any;
}

export const FxAcceptedCurrencyView: React.FC<IAcceptedCurrencyView> = ({ tooltip, value, className }) => {
    /**
     * Grouping all accepted currency values into one single string and formating them
     */
    let acceptedCurrencyValue: any = '';
    let acceptedCurrencyLongValue: any = '';
    if (value && value.length > 0) {
        acceptedCurrencyLongValue = Array.isArray(value) ? value.join(','): value;
        if (acceptedCurrencyLongValue?.length >= 20) {
            acceptedCurrencyValue = acceptedCurrencyLongValue.substring(0, 20) + "...";
        } else {
            acceptedCurrencyValue = acceptedCurrencyLongValue;
        }
    }
    else {
        acceptedCurrencyValue = "";
    }

    return (
        <Typography title={tooltip ? acceptedCurrencyLongValue : acceptedCurrencyValue} className={className ? className : ''}>{acceptedCurrencyValue}</Typography>
    )
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { CircularProgress, Divider, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import HttpClient from '../../../libs/utils/httpClient';
import { Logger } from '../../../libs/utils/logger';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { FxButton } from '../../Action/FxButton';
import { IData } from '../../Input/FxSelect/FXMaterialMultipleSelect';
import FxMaterialMultipleSelect from '../../Input/FxSelect/FXMaterialMultipleSelect';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import FxSnackBar from '../../Utils/fx-snack-bar';

const httpClient = HttpClient.getClient();


Logger.debug("EditAssignedRoles.tsx", "edit assigned roles initializing");

/**
 * Component: EditAssignedRoles
 * Usage: to edit assigned roles
 */

interface IRole {
  id: string;
  name: string;
}

interface IEditAssignedRoles {
  id: string;
  metadata: {
    roles: any[];
    portalAccess: any;
    userId: string;
  };
  onClose: () => void;
  rolesData: IData[];
  roleList: IData[];
  isLoading: boolean;
}

export const EditAssignedRoles: React.FC<IEditAssignedRoles> = React.memo((props) => {
  const role_src = {
    url: "role/list",
    method: "POST",
    data: {
      "pageSize": 25,
      "pageNumber": 1,
      "criteria": {
        "filters":
          [{
            "key": "name",
            "operator": "like",
            "values": [
              ""
            ]
          }]
      }
    }
  };
  ({ props } = RegisterComponent(props));
  const { register, handleSubmit, setValue, control } = useForm();
  const dispatch = useDispatch();

  /**
   * Method called before component loading
   * */
  useEffect(() => {
    if(props.metadata?.roles?.length){
        const roles =  customerDataTransformation(props.metadata.roles);
        updateComponentParams(dispatch, props.id, { roleList: roles || [] });
    }
    getRoles(role_src.data, true);
  }, [props.metadata?.roles]);


  /**
   * Method to handle on submit request
   * */
  async function onSubmit(data: any) {
    let status: any;
    const role = data.role.map((role: any) => String(role.value));
    const portalAccess: any = {
      grantAccess: role?.length > 0 ? true : false,
      role: role?.length > 0 ? role : undefined
    }
    status = await updateAssignedRoles({portalAccess});
    status = processAPIResponse(status);
    if (status.status) {
      // api success
      dispatch({
        type: 'DATA_API_PARAM_CHANGED', payload: {
          id: `user-details-config-card${props.metadata?.userId}`,
          source: {
            url: `/authorizedUser/id/${props.metadata?.userId}`,
            method: "GET"
          }
        } });
      FxSnackBar.show({
        autoHideDuration: 1000,
        severity: 'success',
        text: 'Assigned Roles Updated Successfully!',
      });
      onModalClose();
    }
    else {
      //api  failed
      FxSnackBar.show({
        text: status.message,
      });
    }
  }

  /**
   * Method handles the closing of the modal
   */
  const onModalClose = () => {
    props.onClose && props.onClose();
  }

  /**
   * Method to call api  for update user
   * */
  async function updateAssignedRoles(reqBody: any) {
    try {
      updateComponentParams(dispatch, props.id, { isLoading: true });
      return await httpClient.post(getCustomerUrl(`authorizedUser/id/${props.metadata?.userId}`, false), reqBody).then(response => {
        return response;
      })
        .catch((error) => {
          return { ...error };
        });
    } catch (err) {
      Logger.error("EditAssignedRoles.tsx", "error", err);
      return err;
    } finally {
      updateComponentParams(dispatch, props.id, { isLoading: false });
    }
  }

  /**
   * To transform the data
   * @param data: Data received from api
   */
  function customerDataTransformation(data: IRole[]) {
    return data?.map((role: any) => {
      return { value: role.id, label: `${role.name} ID: ${role.id}` };
    });
  }

  /**
   * To do the api call based on the user search
   * @param searchValue: user entered value
   */
  async function onRoleSearch(searchValue: any) {
    if(searchValue){
      const payloadData =  {
        "pageSize": 25,
        "pageNumber": 1,
        "criteria": {
          "filters": [
            {
              "key": "name",
              "operator": "like",
              "values": [
                searchValue
              ]
            }
          ]
        }
      }

      return getRoles(payloadData);
    }
  }

  /**
   * Method used to call request roles
   * @param payloadData :form data
   * @param isOnload : to check onload
   * @returns
   */
  async function getRoles (payloadData: any, isOnload = false) {
    try {
      await httpClient.post(getCustomerUrl(role_src.url, false), payloadData).then(response => {
        if (response.data && response.data.resources && response.data.resources.length > 0) {
          const transformedData = customerDataTransformation(response.data.resources);
          isOnload &&  updateComponentParams(dispatch, props.id, { rolesData: transformedData || [] });
          return transformedData;
        }
        return response.data.resources;
      })
    } catch (err) {
      Logger.error("EditAssignedRoles.tsx", "error", err);
      return err;
    }
  }


  return (
    <Grid item container xs={12} className='fx-form-grid'>
      <Grid item xs={12}>
        <Grid marginY={1}>
          <Divider className={'fx-solid-divider'}/>
        </Grid>
        <Grid marginTop={3}>
          <form id="create-user-form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="row" spacing={2} className="fx-form-edit-profile">
              <Grid item xs={12} sm={12} className='fx-authorised-user-card'>
                <Grid item className='fx-autosearch-height'>
                  <FxMaterialMultipleSelect
                    register={{ ...register("role") }}
                    id="edit-auth-user-role"
                    name='role'
                    data={props.rolesData}
                    control={control}
                    canSearch={true}
                    isEdit={true}
                    rules={{ required: props.metadata?.portalAccess }}
                    dataTransformation={customerDataTransformation}
                    onChange={onRoleSearch}
                    value={props.roleList}
                    selectAllLabel={"All Roles"}
                    label={`Role ${props.metadata?.portalAccess ? '*' : ''}`}
                    setValue={setValue} />
                </Grid>
              </Grid>
            </Grid>
            <Grid marginBottom={1} marginTop={3}>
              <Divider className={'fx-solid-divider'}/>
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
              <FxButton variant="contained"
                        className="fx-button fx-button-cancel"
                        id={"edit-assigned-roles-cancel-button"}
                        onClick={onModalClose}>
                Cancel
              </FxButton>
              <span className="fx-padding-right-16" />
              <FxButton
                disableRipple={false}
                className="fx-button fx-button-theme"
                id={"edit-assigned-roles-submit-button"}
                type="submit"
              >
                {props.isLoading ? (
                  <CircularProgress
                    size={20}
                    style={{
                      color: 'white',
                    }}
                  />
                ) : (
                  "Save"
                )}
              </FxButton>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  )
});

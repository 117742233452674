/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { Logger } from "../../../libs/utils/logger";

Logger.debug("FxCurrencyView.tsx", "Fx Currency View initializing")

/**
 * Component used to view Amount
 */
declare interface IFxCurrencyView {
  id?: string;
  value: any;
  prefix?: string;
  className?: string | string[];
  defValue?: string,
  tooltip?: any;
  showPlus?:boolean;
  showMinus?:boolean;
  justifyContent?:any;
  hideDecimal?: boolean;
}

export const FxCurrencyView: React.FC<IFxCurrencyView> =
  (props) => {
    let value = props.value;
    let valueOrg = value;
    if ((value && !isNaN(value)) || value === 0) {
      valueOrg = value
      value = numberWithCommas(value);
      const arrSplitVal = getWholeAndDecimalPart(value);
        if (valueOrg < 0) {
          value =<Grid item container justifyContent={props?.justifyContent} alignItems="baseline"><Grid item>- {props.prefix}</Grid> <Grid item className="fx-currency-value">{arrSplitVal[0]}</Grid>{!props.hideDecimal ? `.${arrSplitVal[1]}` : ''}</Grid> ;
        }
        else {
          value = <Grid item container justifyContent={props?.justifyContent} alignItems="baseline"><Grid item>{props.showPlus ? "+ ": props.showMinus ? "- " :""}{props.prefix}</Grid> <Grid item className="fx-currency-value"> {arrSplitVal[0]}</Grid>{!props.hideDecimal ? `.${arrSplitVal[1]}` : ''}</Grid>
        }
    }
    else {
      //if no value can show a def value like 0 , - etc
      value = props.defValue ? props.defValue : '$0.00';
    }
    return (
      <Grid item title={props.tooltip ? value : ''} id={props.id} className={clsx("fx-currency",props.className, valueOrg < 0 ? 'fx-color-red' : '')}>
        {value}
      </Grid>
    );
  }
/**
 * Function used to add commas in amount
 * @param x :value
 * @returns
 */
function numberWithCommas(x: any) {
  const y = (Math.round(Math.abs(x) * 100) / 100).toFixed(2);
  return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * return whole number and decimal part
 * @param x
 */
function getWholeAndDecimalPart(x:any){
  let retArr = []
  try{
    retArr = x.split(".")
  }catch(e){
    Logger.error("FxCurrencyView.tsx", "getWholeAndDecimalPart", "error", e);
  }
  if (retArr.length < 1 ){
    retArr = [0,"00"]
  }
  return retArr;
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import { Logger } from '../../../libs/utils/logger';
import { ContactValidation } from './ContactValidation';
import { EADetailsCard } from './EADetailsCard';
import { getEAOrAddressDetailsFromContact } from '../../Utils/CommonBaseClass';


Logger.debug("ExternalAccountDetailsCard.tsx", "EA initializing");

/**
 * Component: External Account Details Card
 * Usage: To display External Account Details
 */
export const ExternalAccountDetailsCard: React.FC<any> = React.memo((props) => {

        //#region Variable Declaration
        const dispatch = useDispatch()
        const [defaultP, setDefaultP] = React.useState(false);
        const isSmallScreen = useMediaQuery({ query: '(max-width: 999px)' })
        interface RouteParams {
            id: string
            contactId:any
        }
        const params = useParams<RouteParams>();

        //#region Assigning Values to Variable

        //get ID from page url
        const id = params.id
        const componentStatePayee = useSelector((state: any) => {
            if (state.data['payee-details-info-card']) {
                return state.data['payee-details-info-card']
            } else {
                return { config: { params: {} } };
            }
        });
        const compContactId= componentStatePayee?.source?.url?.split("/")[3]
        const contactId = params? params?.contactId :compContactId;
        const ea_source = {
            url: "/contact/id/" + contactId,
            method: "GET"
        }
        const componentState = useSelector((state: any) => {
            if (state.data['ea-details-info-card']) {
                return state.data['ea-details-info-card']
            } else {
                return { config: { params: {} } };
            }
        });

        /**
        * Method to rest the component on unmount.so that when redirect back page it will call the api again
        */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        if (componentState?.data?.body && !defaultP) {
            const external_account_data:any = { ...getEAOrAddressDetailsFromContact(componentState.data.body, "EA", id, false) };
            if (external_account_data?.accountNumberLast4) {
                external_account_data.accountNumber = '** ' + external_account_data?.accountNumberLast4;
            }
            setDefaultP(true);
        }

        return (
            <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container"  >
                <Grid container xs={12} sm={8} className="fx-container-left" >
                    <EADetailsCard id="ea-details-info-card" source={ea_source} />

                </Grid>
                <Grid container xs={12} sm={4} className={isSmallScreen? "": "fx-container-right"}>
                    <ContactValidation source={ea_source} id="payees-ea-external-account-validation-info-card" />
                </Grid>
            </Grid>
        );
    })
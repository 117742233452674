/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useMemo, useState, useEffect } from 'react';
import { Grid, FormHelperText } from '@mui/material';
import { useForm } from 'react-hook-form';
import HttpClient from '../../libs/utils/httpClient';
import { Logger } from '../../libs/utils/logger';
import { getCustomerUrl } from '../../libs/utils/utils';
import { IAccountOptions } from '../../types/common.interfaces';
import { FxTextEdit } from '../Input';
import { FxCurrencyView } from '../Input/FxCurrency/FxCurrencyView';
import { sourceAccountDataTransformation } from '../Page/Schedules/ScheduleGlobalFunctions';
import { destinationAccountDataTransformation } from '../Page/Schedules/ScheduleGlobalFunctions';
import { setAmountValidationOptions } from '../Page/Schedules/ScheduleGlobalFunctions';
import { LogoAccount } from '../Utils/Logo';
import FxMaterialSelect from '../Input/FxSelect/FxMaterialSelect';
const httpClient = HttpClient.getClient();

Logger.debug("FxAccountTransferForm.tsx", "Account Transfer Form initializing");

export type FxAccountTransferFormProps = {
  id:string;
  accounts: any[];
  formId: string;
  onSubmit(data: any): void;
  setIsSubmitDisabled?: (isDisabled: boolean) => void;
  onReset?: (reset: any) => void;
  checkIsWithdrawal: (isWithdrawal: boolean) =>  void;
  setDestinationAccountData: (accountData: any) => void;
}

/**
 * This component used to render the account transfer form
 */
export const FxAccountTransferForm: React.FC<FxAccountTransferFormProps> = React.memo((props) => {
  const { accounts, onSubmit, formId, setIsSubmitDisabled, checkIsWithdrawal, setDestinationAccountData } = props;
  const { register, formState: { errors }, handleSubmit, setValue, control, watch, reset, getValues} = useForm({ mode: 'onChange' });
  const [fromAccountDetails, setFromAccountDetails] = useState<null | {
    availableBalance: {
      amount: number;
    }
  }>(null);
  /**
   * State to check if form is submitting
   */
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Watch for form fields
   */
  const amount = getValues('amount');
  const fromAccount = getValues('from');
  const toAccount = getValues('to');

  /**
   * useEffect to check if form is filled
   */
  useEffect(() => {
    if (isSubmitting) {
      return;
    }
    const isFilled = !Object.keys(errors).length && amount && fromAccount && toAccount;
    if (setIsSubmitDisabled) {
      setIsSubmitDisabled(!isFilled);
    }
    // eslint-disable
  }, [amount, fromAccount, toAccount, errors, isSubmitting]);

  /**
   * useMemo to get account options
   */
  const accountOptions:IAccountOptions[] = useMemo(() => {
    //Active Accounts excluding accounts with type INVESTMENT and subType DEPOSIT
    return sourceAccountDataTransformation(accounts, true) || [];
  }, [accounts]);

  /**
   * useMemo to get options for to account
   */
  const toAccountOptions = useMemo(() => {
    if (!fromAccount) {
      return [];
    }
    return destinationAccountDataTransformation(fromAccount, accountOptions, true);
    // eslint-disable
  }, [accountOptions, fromAccount]);

  /**
   * useEffect to reset form
   */
  useEffect(() => {
    if (props.onReset) {
      props.onReset(reset);
      setIsSubmitting(false);
    }
    // eslint-disable
  }, [props.onReset, reset]);

  /**
   * function to handle from account change
   * @param event :event
   */
  const onToAccountChangeHandler = async (event: any) => {
    const selectedAccount = event.target.value;
    await getDestinationAccountDetails(selectedAccount);
  }

  /**
   * function to get destination account data
   * @param id :account id
   */
  async function getDestinationAccountDetails(id: number) {
    try {
      const res = await httpClient.get(getCustomerUrl("account/id/" + id));
      setDestinationAccountData(res?.data);
    } catch (e) {
      Logger.error("FxAccountTransferForm.tsx", "function getDestinationAccountDetails", "Error while fetching account details", e);
    }
  }

  /**
   * function to handle from account change
   * @param event :event
   */
  const onFromAccountChangeHandler = async (event: any) => {
    const selectedAccount = event.target.value;
    setValue('to', '');
    await getAccountDetails(selectedAccount);
  }

  /**
   * function to get account data
   * @param id :account id
   */
  async function getAccountDetails(id: number) {
    try {
      const res = await httpClient.get(getCustomerUrl("account/id/" + id));
      //Checking whether the transaction is of type Withdrawal or not based upon Account Selection
      if(res?.data?.type === 'CASH_BUILDER_PLUS'){
        checkIsWithdrawal(true);
      }else{
        checkIsWithdrawal(false);
      }

      setFromAccountDetails(res.data);
    } catch (e) {
      Logger.error("FxAccountTransferForm.tsx", "Error while fetching account details", e);
    }
  }

  /**
   * Get helper text for from account balance.
   */
  const fromAccountHelpText = useMemo(()=>{
    return watch('from') ? (
      <FormHelperText className={'fx-balance-info'}>
        <span>Available Balance</span>&nbsp;
        <FxCurrencyView value={fromAccountDetails?.availableBalance?.amount} prefix={"$"} />
      </FormHelperText>
    ) : null;
    // eslint-disable
  },[fromAccountDetails, watch('from')]);

  return (
    <form
      id={formId}
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
        setIsSubmitting(true);
      })}
    >
      <Grid container spacing={2} className="fx-modal-form flex column fx-account-transfer-form">
        <Grid item container xs={12}>
          <FxTextEdit
            register={{ ...register('amount') }}
            className={errors.amount ? 'border-error-input fx-input-edit clear-number-field-arrow' : 'fx-input-edit clear-number-field-arrow'}
            control={control} rules={setAmountValidationOptions} prefix="$"
            id={`${formId}-amount-textbox`} label="Amount" name="amount"
            type="number" variant="outlined" valuePattern={/-|\+|e|E/}
            showDecimal={true} setValue={setValue} placeholder="0.00"
          />
        </Grid>
        <Grid item container xs={12}>
          <FxMaterialSelect
            name={'from'}
            control={control}
            rules={{ required: true }} register={{ ...register('from') }}
            id={`${formId}-account-from-select`}
            className={'fx-account-select'}
            label="From"
            data={accountOptions}
            setValue={setValue}
            onChange={onFromAccountChangeHandler}
            value={''}
            placeholder={'Select Passport A/c'}
            hidePlaceholderInOptions={true}
            prefix={<LogoAccount />}
          />
          {fromAccountHelpText}
        </Grid>
        <Grid item container xs={12}>
          <FxMaterialSelect
            name={'to'}
            control={control}
            rules={{ required: true }} register={{ ...register('to') }}
            id={`${formId}-account-to-select`}
            className={'fx-account-select'}
            label="To"
            data={fromAccount ? toAccountOptions : []}
            setValue={setValue}
            onChange={onToAccountChangeHandler}
            value={''}
            placeholder={'Select Passport A/c'}
            hidePlaceholderInOptions={true}
            prefix={<LogoAccount />}
          />
        </Grid>
      </Grid>
    </form>
  );
})
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FxButton } from '../../Action/FxButton';
import { DataGrid } from '../../Data/DataGrid';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { useMediaQuery } from 'react-responsive';
import { FxGridList } from '../../Data/FxGridList';

Logger.debug("ContactCardsListing.tsx", "Contact Cards Listing initializing");

interface RouteParams {
    id: string
}

/**
 * Component: Contact Cards Card
 * Usage: To display Contact Cards information
 */
export const ContactCardsListing: React.FC<any> = React.memo(
    (props) => {
        const dispatch = useDispatch();
        const isMobile = useMediaQuery({ query: '(max-width: 999px)' });
        ({ props } = RegisterComponent(props));

        const contactList: any[] = props.data?.card?.length ? props.data?.card.map((cardDetail: any) => generateDesktopRow(cardDetail)) : [];
        
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            updateComponentParams(dispatch, props?.id, { 'anchorEl': null })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        
        const params = useParams<RouteParams>();
        const contactId = params.id
        const history = useHistory();

        const createPermission = {
            entity:"Other Entities",
            name:"Contact",
            operation:"EDIT"
        }

        const column: any = {
            "title": "Select Columns to Show",
            "columns": [
                {
                    "label": "ID",
                    "enable": true,
                    "fieldName": "id",
                    "type": "fri://fieldtype/default",
                    "default": true,
                    "style": { "width": "7rem" }
                },
                {
                    "label": "STATUS",
                    "enable": true,
                    "fieldName": "status",
                    "type": "fri://fieldtype/status",
                    "default": true,
                    
                },
                {
                    "label": "Card Holder",
                    "enable": true,
                    "fieldName": "cardHolder",
                    "type": "fri://fieldtype/custom",
                    "default": true
                },
                {
                    "label": "Card Number",
                    "enable": true,
                    "fieldName": "cardNumber",
                    "type": "fri://fieldtype/custom",
                    "default": true,
                    "style": { "width": "10rem" }
                },
                {
                    "label": "Last Updated On",
                    "enable": true,
                    "fieldName": "lastUpdatedOn",
                    "type": "fri://fieldtype/date",
                    "default": true
                },
                {
                    "label": "Last Updated By",
                    "enable": true,
                    "fieldName": "lastUpdatedBy",
                    "type": "fri://fieldtype/default",
                    "default": true
                },
            ]
        };

        /**
         *  To generate the desktop row.
         *  @param item :row item object
         */
        function generateDesktopRow(item: any) {
            return {
                id: item.id,
                status: item.status,
                cardHolder: item.holderName,
                cardNumber: '**' + item.cardNumberLast4,
                lastUpdatedOn: item.lastUpdatedOn,
                lastUpdatedBy: item.lastUpdatedBy.username
            };
        }

        // to redirect to add card page from contact page
        function redirectToCreatePage() {
            const holderName = props?.data?.contactType === 'BUSINESS' ? props?.data?.legalName: props?.data?.firstName + ' ' + props?.data?.lastName;
            history.push('/payee/card/add/' + contactId,{contact:{name:props?.data?.name,id:props?.data?.id,holderName:holderName}});
        }

        /**
         * Method to redirect to the card details page
         * @param event :event object
         * @param data : card data
         */
        function redirectDetailsPage(event: any, data: any) {
            history.push(`/payee/card/view/${data.id}`, { contact: { id: contactId } });
        }

        return (
            <>
                <Grid id="payees-cards-info-grid " className="fx-margin-bottom-16" item xs={12}  >
                    <FxCard id="payees-info-card-component" className="fx-theme-passport">
                        <FxCardHeader id='payees-info-cards-component-header'>
                            <Grid id="payees-card-Grid2" item container justifyContent="space-between" xs={12} >
                                <Grid id="payees-card-Grid3"  >
                                    <Typography id="card-title-payees-info" variant="h4" >Cards</Typography>
                                    <div id="div-grow-title-payees-card-list" className="fx-flex-grow" />
                                </Grid>
                                <Grid id="filter-Grid" item container className="fx-view-action fx-inline-flex" >
                                    <Grid item>
                                        <FxButton id="payees-add-card-button" permissions={createPermission} variant="contained" className="fx-button fx-button-passport-border fx-svg-theme" onClick={redirectToCreatePage} startIcon={<AddIcon />} >Add</FxButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FxCardHeader>
                        <FxCardBody id="payees-card-component-body" className="fx-card-general">
                        {! isMobile ? <DataGrid id="payee-card-list" rowClick={redirectDetailsPage} tableClass="fx-table-passport" data={contactList} column={column} />
                            : <FxGridList id="payee-card-list" rowClick={redirectDetailsPage} data={contactList} column={column}/>    
                        }
                        </FxCardBody>
                    </FxCard>
                </Grid >
            </>
        )
    })

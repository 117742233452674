/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'
import { Grid, Typography, CircularProgress, Divider } from '@mui/material';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ISource } from '../../../types/common.interfaces';
import { clean, cleanWithEmptyValue, convertToPhoneRequestFormat, findAddressValidations, handleKeyDownChange, handleZipChange, renderCityNameError, renderEAAddressLine1Error, renderEAAddressLine2Error, renderError, renderHolderNameError, renderNameError, setCityNameValidation, setPhoneValidationOptions, setZipValidationOptions } from '../../Utils/CommonBaseClass';
import { AUTH_STRINGS } from '../../../constants/strings';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxAutoComplete } from '../../Input/FxAutoComplete/FxAutoComplete';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../Action/FxButton';
import FxSnackBar from '../../Utils/fx-snack-bar';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import usa_state from '../../Utils/usa_states.json';
const httpClient = HttpClient.getClient();

interface InputErrType {
    type: string;
    message: string;
}

Logger.debug("CreateExternalAccount.tsx", "create external account new initializing");

// Interface: errors
interface InputErrType {
    type: string;
    message: string;
}

/**
 * Component: CreateExternalAccountCard
 * Usage: create multiple external account
 */
export const CreateExternalAccountCard: React.FC<any> = React.memo(
    (props) => {
        //#region Variables Declarations
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, setValue, clearErrors, setError, control, resetField, watch } = useForm();
        const [isLoading, setIsLoading] = React.useState(false);
        const submitButton = 'Add External Account';
        const history = useHistory();
        const params = useParams<any>();

        const location:any=useLocation()
        const holderName=location.state?.contact?.holderName;

        //#region Assigning values to variable
        const contactId = params.id
        useEffect(() => {
            if(!holderName){
                history.push('/payee/view/'+ contactId)
            }
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Add External Account', backButton: '/payee/view/'+contactId} } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        // account type data
        const accountTypeData = [
            { label: 'Checking', value: 'CHECKING' },
            { label: 'Savings', value: 'SAVINGS' }
        ];
        // account purpose data
        const accountPurposeData = [
            { label: 'Consumer', value: 'CONSUMER' },
            { label: 'Corporate', value: 'CORPORATE' }
        ];
         // external validation
        const EAValidation = [
            { label: 'Instant Verification', value: "EWS" },
            { label: 'Prenote', value: "PRENOTE" }
        ];


        //#region API

        // API request for bank list
        const bank_src: ISource = {
            url: "list/bank",
            method: "POST",
            expiry: 300,
            baseUrl: true,
            data: {

                "pageSize": 25,
                "pageNumber": 1,
                "criteria": {
                    "filters": [
                    ]
                }
            }
        };


        //#region Handle Functions
        /**
         * Method to render account number error
         * @param err
         */
        const renderAccountNumberError = (err: InputErrType): string => {
            if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.ACCOUNT_NUMBER_INVALID_LENGTH;
            }
            return err.message;
        };

        // routing number validation error messages
        const renderRoutingNumberError = (err: InputErrType): string => {
            if (err.type === 'minLength') {
                return AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_LENGTH;
            }
            else if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_LENGTH;
            }
            return err.message;
        };
        //wire routing  number validation error messages
        const renderWireRoutingNumberError = (err: InputErrType): string => {
            if (err.type === 'minLength') {
                return AUTH_STRINGS.ERRORS.WIRE_ROUTING_NUMBER_INVALID_LENGTH;
            }
            else if (err.type === 'maxLength') {
                return AUTH_STRINGS.ERRORS.WIRE_ROUTING_NUMBER_INVALID_LENGTH;
            }
            return err.message;
        };

        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleClose = () => {
            history.push('/payee/view/' + contactId)

        };

        //#region Validations
        // routing number validation constant
        const setRoutingNumberValidation = {
            required: true,
            minLength: 9,
            maxLength: 9,
            pattern: {
                value: /^[0-9-_&,.]*$/,
                message: AUTH_STRINGS.ERRORS.ROUTING_NUMBER_INVALID_REGEXP,
            },
        }
        // wire routing number validation constant
        const setWireRoutingNumberValidation = {
            required: false,
            minLength: 9,
            maxLength: 9,
            pattern: {
                value: /^[0-9-_&,.]*$/,
                message: AUTH_STRINGS.ERRORS.WIRE_ROUTING_NUMBER_INVALID_REGEXP,
            },
        }
        // holder name validation constant
        const setholderNameValidation = {
            required: true,
            maxLength: 128
        }
        // account number validation constant
        const setAccountNumberValidation = {
            required: true,
            maxLength: 40
        }


        //#region Functions

        /**
        * Method to transform routing number data as dropdown options
        * @param data
         */
        function routingNumberTransformer(data: any) {
            return data?.map((item: any) => {
                return  { label: item.routingNumber + ' (' + item.name + ')', value: item.routingNumber };
            }) || [];
        }
        /**
         * Method to handle on submit request
         * @param data : form data
         */
        async function onSubmit(data: any) {
            let req: any = {};
            let status: any;
            req = createEARequest(data)
            const successMessage = 'External Account Created Successfully!';
            status = await createExternalAccount(clean(req));
            status = processAPIResponse(status)
            if (status.status) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push('/payee/view/' + contactId);
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }
            setIsLoading(false);
        }

        /**
        * Method to Create the External Account Request
        * @param data
        * @returns
        */
        function createEARequest(data: any) {
            const object: any = {};
            const holderAddress = {
                "addressLine1": data?.addressLine1,
                "addressLine2": data?.addressLine2,
                "city": data?.city,
                "state": data?.state,
                "zip": data?.zip
            }
            cleanWithEmptyValue(holderAddress);
            const externalAccountRequest: any = {
                accountNumber: data?.accountNumber,
                routingNumber: data?.routingNumber,
                holderType: data?.accountPurpose,
                type: data?.accountType,
                holderName: data?.holderName,
                holderPhone:data?.holderPhone&&convertToPhoneRequestFormat(data.holderPhone)
            }
            if (holderAddress && Object.keys(holderAddress).length > 0 && Object.values(holderAddress).some(value => value !== null)) {
                externalAccountRequest['holderAddress'] = holderAddress;
            }
            clean(externalAccountRequest);
            if (data.wireRoutingNumber && data.wireRoutingNumber !== '') {
                externalAccountRequest.wireRoutingNumber = data.wireRoutingNumber;
            }
            if (data.eaPurpose && data.eaPurpose !== '') {
                externalAccountRequest.purpose = data.eaPurpose;
            }
            if (data.accountValidation) {
                const validationObject: any = {}
                const preNotObject: any = {}
                if (data.accountValidation === "PRENOTE") {
                    preNotObject.prenote = "ALWAYS";
                }
                else {
                    validationObject.ews = true;
                }
                externalAccountRequest.validateAccount = [];
                externalAccountRequest.validateAccount.push(validationObject)
                externalAccountRequest.prenote = preNotObject.prenote
            }
            object.externalAccount = [externalAccountRequest];
            return object
        }

        /**
         * Method to call api for create externalAccount
         * @param payloadData : request payload
         */
        async function createExternalAccount(payloadData: any) {
            try {
                const url =  '/contact/id/' + contactId
                const data: any = await httpClient.post(getCustomerUrl(url,false), payloadData).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("CreateContact.tsx", "error", err);
                return err;
            }
        }
        return (
            <Grid container id="create-payees-ea-main-grid" xs={12} className='fx-form-grid'>
                <Grid id="create-payees-ea-first-grid" item xs={12} sm={8}>
                    <Grid id="create-payees-ea-sub-grid" container spacing={1} >
                        <Grid id="create-payees-ea-second-grid" item xs={12}>
                            <div className="fx-form-edit-profile flex column">
                                {<form id="create-payees-ea-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard id="create-payees-ea-form-card" className="fx-theme-passport">
                                        <FxCardHeader id="create-payees-ea-form-card-header">
                                        </FxCardHeader>
                                        <FxCardBody id="create-payees-ea-form-card-body" className="fx-info-card fx-margin-top-reducer" >
                                            <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                                {<>
                                                    <Grid item xs={12} >
                                                        <Typography className="filter-popover-label">EXTERNAL ACCOUNT DETAILS</Typography>
                                                    </Grid>
                                                    {<Grid item xs={12} sm={6}>
                                                        <FxMaterialSelect register={{ ...register("accountPurpose") }} control={control} rules={{ required: true }} className={errors.accountPurpose ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-ea-add-account-purpose" data={accountPurposeData} name="accountPurpose" readOnly={false} label="Holder Type" setValue={setValue} value="CORPORATE" />
                                                    </Grid>}
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("holderName") }} className={errors.holderName ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setholderNameValidation} id="payee-ea-add-holdername-textbox" label="Holder Name*" name="holderName" variant="outlined"  defaultValue={holderName} />
                                                        <div className={'error-message'}>
                                                            {errors.holderName && renderHolderNameError(errors.holderName)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxPhoneEdit register={register } className={errors.holderPhone ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-external-account-form-card-holderphone-textbox" label="Holder Phone" name="holderPhone" variant="outlined" 
                                                        rules={setPhoneValidationOptions(false)} disableFlag={true}
                                                        setValue={setValue} defaultValue={context?.data?.body?.holderPhone ? context.data.body.holderPhone : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.holderPhone && renderNameError(errors.holderPhone)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item  xs={12} sm={12} spacing={2}>
                                                        <fieldset className='fx-create-contact-address-container'>
                                                        <legend>Address Details</legend>
                                                        <Grid item container xs={12} sm={12} spacing={2}>
                                                        <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("addressLine1") }} className={errors.addressLine1 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: findAddressValidations(watch),maxLength:35 }} id="create-contact-form-card-addressline1-textbox" label="Address Line 1" name="addressLine1" variant="outlined" defaultValue={context?.data?.body?.holderAddress?.addressLine1 ? context.data.body.holderAddress.addressLine1 : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.addressLine1 && renderEAAddressLine1Error(errors.addressLine1)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxTextEdit register={{ ...register("addressLine2") }} className={errors.addressLine2 ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false,maxLength:35 }} id="create-contact-form-card-addressline2-textbox" label="Address Line 2" name="addressLine2" variant="outlined" defaultValue={context?.data?.body?.holderAddress?.addressLine2 ? context.data.body.holderAddress.addressLine2 : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.addressLine2 && renderEAAddressLine2Error(errors.addressLine2)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("city") }} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setCityNameValidation(findAddressValidations(watch))} id="create-contact-form-card-city-textbox" label="City" name="city" variant="outlined" defaultValue={context?.data?.body?.holderAddress?.city ? context.data.body.holderAddress.city : ''} />
                                                        <div className={'error-message'}>
                                                            {errors.city && renderCityNameError(errors.city)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: findAddressValidations(watch) }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="create-contact-form-card-state-textbox" name="state" data={usa_state} label="State" value={context?.data?.body?.holderAddress?.state ? context.data.body.holderAddress.state : ''}
                                                            setValue={setValue} readOnly={false} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(findAddressValidations(watch))} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-contact-form-card-state-textbox" name="zip" label="Zip" defaultValue={context?.data?.body?.holderAddress?.zip ? context.data.body.holderAddress.zip : ''} isEditable={true} onChange={(e: any) => { handleZipChange(e, setValue, setError, clearErrors); }} onKeyDown={(e: any) => {  handleKeyDownChange(e, setValue);}}/>
                                                        <div className={'error-message'}>
                                                            {errors.zip && renderError(errors.zip)}
                                                        </div>
                                                    </Grid>
                                                    </Grid>
                                                        </fieldset>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant='h5'>Holder’s address and phone number are mandatory to send a wire payment to this account.</Typography>
                                                   </Grid>
                                                   <Grid xs={12} sm={12}>
                                                      <Typography className='fx-create-contact-sub-title'>BANK ACCOUNT INFORMATION</Typography>
                                                  </Grid>
                                                    {<Grid item xs={12} sm={6}>
                                                        <FxMaterialSelect register={{ ...register("accountType") }} rules={{ required: true }} className={errors.accountType ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-ea-add-account-type" name="accountType" data={accountTypeData} label="Account Type" value="SAVINGS" readOnly={false} setValue={setValue} />
                                                    </Grid>}
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("accountNumber") }} className={errors.accountNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={setAccountNumberValidation} control={control} id="payee-ea-add-account-number-textbox" label="Account Number*" name="accountNumber" variant="outlined" defaultValue={context?.data?.body?.accountNumber ? context.data.body.accountNumber : ''} isEditable={true} />
                                                        <div className={'error-message'}>
                                                            {errors.accountNumber && renderAccountNumberError(errors.accountNumber)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxLabelView className="fx-label-top" id="routing-number-label">Routing Number*</FxLabelView>
                                                        <FxAutoComplete register={{ ...register("routingNumber") }} control={control} rules={setRoutingNumberValidation} id="payee-ea-add-routing-number" name="routingNumber" source={bank_src} searchBy={'searchPattern'} className={errors.routingNumber ? "border-error-input fx-input-edit" : "fx-input-edit"} value={context?.data?.body?.routingNumber ? { label: context.data.body.routingNumber, value: context.data.body.routingNumber } : null} dataTransformer={routingNumberTransformer} setValue={setValue} resetField={resetField} setError={setError} clearError={clearErrors} defaultOperator={'eq'} isEditable={true} />
                                                        <div className={'error-message'}>
                                                            {errors.routingNumber && renderRoutingNumberError(errors.routingNumber)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Typography className="fx-label-top" id="wire-routing-number-label">Wire Routing Number</Typography>
                                                        <FxAutoComplete register={{ ...register("wireRoutingNumber") }} control={control} rules={setWireRoutingNumberValidation} id="payee-ea-add-wire-routing-number" name="wireRoutingNumber" source={bank_src} searchBy={'searchPattern'} value={context?.data?.body?.wireRoutingNumber ? { label: context.data.body.wireRoutingNumber, value: context.data.body.wireRoutingNumber } : null} dataTransformer={routingNumberTransformer} setValue={setValue} resetField={resetField} setError={setError} clearError={clearErrors} defaultOperator={'eq'} isEditable={true} />
                                                        <div className={'error-message'}>
                                                            {errors.wireRoutingNumber && renderWireRoutingNumberError(errors.wireRoutingNumber)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxMaterialSelect register={{ ...register("accountValidation") }} rules={{ required: true }} className={errors.accountValidation ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-ea-add-account-validation" name="accountValidation" data={EAValidation} label="Validate Account *" value="EWS" setValue={setValue} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("eaPurpose") }} className={errors.eaPurpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: false }} id="payee-ea-add-purpose-textbox" label="Purpose" name="eaPurpose" variant="outlined" />
                                                    </Grid>
                                                </>
                                                }
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </FxCardBody>
                                        <FxCardFooter id="create-payees-ea-form-card-footer" className="fx-footer">
                                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                                <FxButton variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="payees-ea-cancel-button"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </FxButton>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme"
                                                    id={"payees-add-ea-submit-button"}
                                                    type="submit"
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    ) : (
                                                        submitButton
                                                    )}
                                                </FxButton>
                                            </Grid>
                                        </FxCardFooter>
                                    </FxCard>
                                </form>}
                            </div>
                        </Grid >
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} ></Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    });
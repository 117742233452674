/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import {Divider, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FxSwitch } from '../../Action/FxSwitch';
import {
  clean,
  convertToPhoneRequestFormat,
  createCommonVerifyProps,
  formatDate,
  updateComponentParams,
  renderCardNumberError,
  setCVVValidation,
  setCardNumberValidation
} from '../../Utils/CommonBaseClass';
import { useForm } from 'react-hook-form';
import FxMaterialSelect from '../../Input/FxSelect/FxMaterialSelect';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { Logger } from '../../../libs/utils/logger';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { getKey } from '../../../libs/utils/storageManager';
import { accountLabelTransformation, createUpdateCollect,getDestinationValue, getLocation, getMerchantData, getTransactionStatus } from './ScheduleGlobalFunctions';
import { FxCardNumberEdit } from '../../Input/FxCardNumber/FxCardNumberEdit';
import { FxDateEdit } from '../../Input/FxDate/FxDateEdit';
import { ScheduleCreateMethodTab } from './ScheduleCreateMethodTab';
import { TransactionSummaryFieldType } from './TransactionSummaryTemplate';
import FxLabel from "../../Input/FxLabel/FxLabelView";
import { ScheduleTopSection } from './ScheduleTopSection';
import FxSnackBar from '../../Utils/fx-snack-bar';
import ScheduleLayout from '../../Layout/ScheduleLayout';
import { FxSummaryCurrency } from '../../Input/FxCurrency/FxSummaryCurrency';
import { isAchControlAllowed } from '../../Utils/ThemeChecker';
import { CardCollectAdvancedOptions } from './CardCollectAdvancedOptions';
import { FxButton } from '../../Action/FxButton';
import { ReactComponent as AddIcon } from "../../../assets/svg/add-new.svg";
import FxModalGeneric from '../../Utils/FxModalGeneric';
import { AdditionalAVSInfo } from './AdditionalAVSInfo';
import { ReactComponent as EditIcon } from '../../../assets/svg/transaction-edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete-icon.svg';
import { ReactComponent as TickIcon } from '../../../assets/svg/green-outline-tick.svg';
import HttpClient from '../../../libs/utils/httpClient';

import FxLocationSelectComponent from '../Location/FxLocationSelectComponent';
import UIFeatureImpl from '../../../libs/services/uiFeatures';
import { TransactionCaptureStatus } from './TransactionCaptureStatus';

Logger.debug("CreateOnetimeCollectCard.tsx", "create onetime collect card initializing")

interface IPaymentMethodType {
    label: string;
    value: string;
    disabled?: boolean;
}

/**
 * This component handles the collect creation through payer for card method
 */
export const CreateOnetimeCollectCard: React.FC<any> = React.memo(
    (props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const { register, formState: { errors }, getValues, handleSubmit, setValue, clearErrors, control, setError, watch, resetField } = useForm();
        const params = useParams<any>();
        let scheduleInfo: any = {};
        let collectId: any = '';
        const scheduleId = params.id;

        const [invoiceData, setInvoiceData] = useState(null); 
        const [additionalAvsData, setAdditionalAvsData] = useState({});
        const [avsDataAvailable, setAvsDataAvailable] = useState(false);
        const [invoiceDataAvailable, setInvoiceDataAvailable] = useState(false);
        const [merchantList, setMerchantList] = useState<any>([]);
        const httpClient = HttpClient.getClient();
        const [location, setLocation] = useState<any>();
        const [terminalData, setTerminalData] = useState<[{label: string, value: string}]>();
        const paymentMethodType = watch("paymentMethodType");

        const uiFeat = UIFeatureImpl.getInstance();
        const businessCustomer = uiFeat.getBusinessCustomerFeature().available
        /**
         * Account list API source
         */
        const acc_src = {
            url: "account/list",
            method: "POST",
            data: {

                "pageSize": 25,
                "pageNumber": 1,
                "sortOptions": {
                    "sortOrder": "DESC",
                    "sortBy": "createdOn"
                },
                "criteria": {
                    "filters": [
                        {
                            "key": "status",
                            "operator": "nin",
                            "values": [
                                "INACTIVE"
                            ]
                        }
                    ]
                }
            }
        };
        /**
         * Merchant list API source
         */
        const merchant_src = {
            url: "merchant/list",
            method: "POST",
            data: {
                "criteria": {
                    "filters": [
                        {
                            "operator": "eq",
                            "key": "account.id",
                            "values": [getKey("selectedAccount")?.id]
                        }
                    ]
                }
            }
        }

    /**
     * Method to get the merchant List
     * @returns data - Merchant List Data
     */
    async function getMerchantList() {
        return await httpClient.post(getCustomerUrl(`/${merchant_src?.url}`, false), merchant_src?.data);
    }

    /**
     * Fetches terminal data for a given merchant ID
     * @param {string} merchantId - The ID of the merchant to fetch terminal data for.
     */
    const getTerminalList = async (merchantId: string) => {
        return await httpClient.get(getCustomerUrl(`/merchant/id/${merchantId}/terminal`, false));
    }

    /**
     * Fetches terminal data for a given merchant ID and updates the state with formatted data and set default value.
     * @param {string} merchantId - The ID of the merchant to fetch terminal data for.
     */
    const fetchAndSetTerminalData = async (merchantId: string) => {
        if (!merchantId) return;
    
        try {
            const { data } = await getTerminalList(merchantId);
            const updatedPaymentMethodData = [
                { label: "Card not Present", value: "CARD_NOT_PRESENT" },
                { label: "Card Present", value: "CARD_PRESENT" },
            ]
            if (!data) {
                updatedPaymentMethodData.pop();
                setValue("paymentMethodType", "CARD_NOT_PRESENT");
            }
            setPaymentMethodTypeData(updatedPaymentMethodData);
    
            if (data?.length) {
                setTerminalData(data.map(({ name, tid }: any) => ({
                    label: name,
                    value: tid,
                })));
    
                if (data.length === 1) {
                    setValue('terminal', data[0].tid);
                }
            }
        } catch (error) {
            Logger.error('CreateOneTimeCollectCard.tsx', 'fetching terminal data', error);
        }
    };
    
    
    /**
     * Method to transform Merchant List into label and value object
     * Setting the transformed merchant list into merchantList state object
     */
     async function handleMerchantListData() {
        const data = await getMerchantList();
        if (data?.data?.resources) {
            const transformedMerchant = data?.data?.resources?.map((mList: any) => {
                return ({  value: mList.id.toString(),
                    label: `${'ID: ' + mList.id || ''}${customerName ? ', (' + customerName + ')' : ''}` })
            })
            setMerchantList(transformedMerchant);

            /**
             * Sets the `merchantId` to the only available merchant if there is a single item in `transformedMerchant` 
             * and `context?.data?.body` is falsy. Then, fetches and updates terminal data based on the current `merchantId`.
             */
            if (transformedMerchant?.length === 1) {
                !context?.data?.body && setValue('merchantId', transformedMerchant?.[0]?.['value']);
            }
            fetchAndSetTerminalData(getValues('merchantId'));
        }
    }

        /**
         *  Declaring Constants
         */
        const [isLoading, setIsLoading] = useState(false);
        const [instantFunding, setInstantFunding] = useState(false);
        const [captureFundsLater, setCaptureFundsLater] = useState(false);
        const [advancedOptions, setAdvancedOptions] = useState(false);
        const history = useHistory()
        const pmLevelQuickSettle = getKey('isCardQuickSettleEnabled');
        const showInstantFunding =  (watch('merchantId') && pmLevelQuickSettle) || context?.data?.body?.processingDetail?.quickSettle;
        const [openAdditionalInfoModal, setOpenAdditionalInfoModal] = useState(false);
        const customerName = getKey("CustomerName") || getKey('CustomerIndividualFullName');


        const dispatch = useDispatch();


        /**
         * Function to be called before loading the component
         */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'create-collect-onetime-card-form-card-merchant-id' } });
            handleMerchantListData();
            // setValue('method', 'CARD')
            if(scheduleId){
                const src={
                    url: "/transaction/id/"+ scheduleId,
                    method: "GET"
                }
                dispatch({ type: "DATA_API_PARAM_CHANGED", payload: { id:'edit-collect-from-onetime-card', source: src } });
            }
            else{
                dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title:'Collect Money',backButton:'/dashboard' } }});
              }

              const verifyProps = {
                open: false
            }
            updateComponentParams(dispatch, props?.id, { verifyProps, isCaptureModal: false, transactionId: collectId });

        }, []);

        /**
         * Resets the `terminal` field to `null` and fetches updated terminal data
         * whenever the `merchantId` value changes.
         */
        useEffect(() => {
            setValue("terminal", null);
            fetchAndSetTerminalData(getValues("merchantId"));
        }, [getValues("merchantId")])
        
        scheduleInfo = context?.data?.body;

        /**
         * Function handles transformation of destination data
         * @param data : destination data array
         * @returns : array
         */
        const dataTransformation = useCallback((data: any) => data?.map((item: any) => ({
            value: item.id,
            label: accountLabelTransformation(item),
        })) || [], []);

        /**
         * Displays a success message in a Snackbar and navigates to a specified URL.
         * @param successMessage - The message text to be displayed in the Snackbar.
         * @param backurl - The URL to navigate to if `scheduleId` is present.
         * @param authCode - The authorization code passed in the navigation state.
         */
        const showSnackBarAndNavigate = (successMessage: string, backurl: string, authCode: string) => {
            FxSnackBar.show({
                autoHideDuration: 1000,
                severity: 'success',
                text: successMessage,
            });
        
            history.push(scheduleId ? backurl : `/thanks/transactions/onetime-card/${collectId}`, { authCode });
        };
        

        /**
         * Method handles the onchange event
         * @param event : onchange event parameter
         */
        const handleChange = async (event: any) => {
            setLocation(null);
            try{
                const merchantData = await getMerchantData(event.target.value).then(res => res?.data);
                const merchantLocationUrl = merchantData?.location?.url?.split('/v1/')[1];
                if(merchantLocationUrl){
                    const locationData = await getLocation(merchantLocationUrl).then(res => res.data);
                    if(locationData){
                        setLocation(locationData[0])
                        setValue('location',locationData[0]?.id);
                    }
                }
            }catch(e){
                Logger.error('CreateOnetimeCollectCard.tsx','habdle merchant change', e)
            }
        }

        /**
         * Async function handles the create collect form submission
         * @param data : form request
         */
        async function onSubmit(data: any) {
            let req: any = {};
            let authCode: any = '';
            const expiryDate = new Date(data.expiryDate);
            const month = expiryDate.getMonth() + 1;
            const year = expiryDate.getFullYear();
            clearErrors("cardNumber");
            if(data['cardNumber']){
               const cardObj = JSON.parse(data['cardNumber'])
                if(!cardObj.success){
                    setError("cardNumber", {
                        type: "manual",
                        message: cardObj.message,
                    });
                    return;
                }
                data['cardNumber'] = cardObj?.value;
                data['brand'] = cardObj?.type;
            }
            if (paymentMethodType === 'CARD_NOT_PRESENT') {
                req["type"] = "REGULAR"
                req["isAutoCapture"] = true
                req.source = {
                    "card": {
                        "cardNumber": data.cardNumber,
                        "expiryMonth": month,
                        "expiryYear": year,
                        "cvv": data.cvv,
                        "brand": data.brand,
                        "holderName": data?.firstName + ' ' + data?.lastName,
                    }
                }
            }

       /**
        * Checking if avs data is available in the form, then creating the request nodes
        */
            if(avsDataAvailable){
                const avsNodes = drawAvsNodes(additionalAvsData);
                const billingNodes = drawHolderBillingAddressNodes(additionalAvsData);

                if (Object.keys(avsNodes)?.length > 0) {
                    req.source.card.avs = avsNodes;
                }
                if (Object.keys(billingNodes)?.length > 1) {
                    req.source.card.billingAddress = billingNodes;
                }
                req.source.card.cardHolder = drawHolderNodes(additionalAvsData, data);

            }

            req.method = 'CARD';

            req.processingDetail = {
                "merchant": {
                    "id": data['merchantId'] ? data['merchantId'] : ''
                }
            };

            if (paymentMethodType === 'CARD_PRESENT') {
                req.processingDetail.terminal = {
                    id: data.terminal
                }
            }

            if (paymentMethodType === 'CARD_NOT_PRESENT') {
                req.processingDetail.quickSettle = instantFunding
            }
            if (data['statementDescriptor']) {
                req['processingDetail']['statementDescriptor'] = data['statementDescriptor'];
            }
            if(data['location']){
                req['processingDetail']['location'] = {
                    'id' : data['location']
                }
            }

            // req.method = data.method;
            if (data.externalId) {
                req.externalId = data.externalId;
            }
            // req.scheduleDate = moment(data.scheduleDate).format('MM/DD/YYYY');
            req.amount = data.amount; 
            req.isAutoCapture = !captureFundsLater;
            if (paymentMethodType === 'CARD_NOT_PRESENT') {
                req.allowDuplicate = data.allowDuplicate ? (data.allowDuplicate === "true") : false;
                req.destination = {
                    account: {
                        id: data['depositAccount']
                    }
                };
            }

            if(advancedOptions && data?.addInvoice?.[0] === 'yes' && invoiceData && Object.keys(invoiceData).length > 0){
                req.processingDetail.order = drawInvoiceNodes(invoiceData);
             } 

             if(advancedOptions){
                req.amountDetails = {};
                if(data?.addSurcharge?.[0] === 'yes' && data?.surchargeAmount){
                    req.amountDetails.surchargeAmount = data?.surchargeAmount;
                }
                if(data?.addTip?.[0] === 'yes' && data?.tipAmount){
                    req.amountDetails.tipAmount = data?.tipAmount;
                }
                if(data?.addTag?.[0] === 'yes' && data?.tagValue && !captureFundsLater){
                    req.funding ={
                        tags:  
                            {
                                "type": data?.tagValue
                            }
                    };
                }
             } 

            req.purpose = data.purpose;
            if (scheduleId) {
                req.id = scheduleId;
                delete req.source;
                delete req.destination;
                delete req.method;
              }
             
            req = clean(req);
            setIsLoading(true);
            let status = await createUpdateCollect(req);
            if (status?.headers?.url) {
                collectId = (status.headers.url).split('/').pop();
            }
            if (status?.headers?.['auth-code']) {
                authCode = status.headers['auth-code']
            }
            setIsLoading(false);
            status = processAPIResponse(status)

            let backurl = '/transactions/collect/';
            let successMessage = 'Created Successfully';
            if (data.id && data.id !== undefined) {
                backurl = '/transactions/collect/' + data['id'];
                successMessage = 'Updated Successfully';
            }
          
            if (status.status) {
                //method for terminal transaction status
                const postSuccessActions = (response: any) => {
                    const collectId = response?.headers?.url?.split('/').pop() || null; 
                    if (collectId) {
                        updateComponentParams(dispatch, props?.id, { isCaptureModal: true, transactionId: collectId , authCode: authCode});
                    }
                }

                //api success
                if(status.responseData?.sessionId){
                    const verifyMessage =  status.responseData?.email? `email ${status.responseData?.email}`: status.responseData?.phone? `phone ${status.responseData?.phone}`: '';
                    const verifyProps = {
                        ...createCommonVerifyProps(req, status, false, data?.amount, verifyMessage, 'collect'),
                        ...(paymentMethodType !== 'CARD_PRESENT' ? {
                            successMessage : successMessage,
                            redirectUrl: scheduleId? backurl: '/thanks/transactions/onetime-card/',
                            redirectUrlFromHeader: scheduleId?false: true
                    }:{postSuccessEvent: postSuccessActions}),
                    }
                    updateComponentParams(dispatch, props.id, { verifyProps })
                }
                else{
                    if (paymentMethodType === 'CARD_PRESENT') {
                        updateComponentParams(dispatch, props?.id, { isCaptureModal: true, transactionId: collectId , authCode: authCode});
                    } else {
                        showSnackBarAndNavigate(successMessage, backurl, authCode);
                    }
                }
            }
            else {
                //api  failed
                    FxSnackBar.show({
                        text: status.message
                    });
            }
        };

        /**
         * Method handles the validation for schedule date
         */
        const scheduleDateValidation = {
            required: scheduleInfo?.scheduleDate ? false : true,
            minDate: (new Date())
        };

        // Set the initial payment methods outside the component if it’s constant
        const initialPaymentMethodTypeData: IPaymentMethodType[] = [
            { label: "Card not Present", value: "CARD_NOT_PRESENT" },
            { label: "Card Present", value: "CARD_PRESENT" },
        ];

        const [paymentMethodTypeData, setPaymentMethodTypeData]: any = useState(initialPaymentMethodTypeData);

        const method_Tab = [
            {
                title: 'Check',
                disabled: scheduleId ? true : false,
                link: '/transaction/collect/add/mobile-check-deposit',
                permission:{
                    entity: "Accounts",
                    name: "Check Collect",
                    operation: "CREATE"
                  }
            },
            {
                className: 'fx-link-sel',
                title: 'Card',
                disabled: scheduleId ? true : false,
                link: '/transaction/collect/add/onetime-card',
                permission: {
                    entity: "Accounts",
                    name: "ACH Collect",
                    operation: "CREATE"
                  }
            }
        ];
        if(businessCustomer && isAchControlAllowed(getKey('collectAchControl'))){
            method_Tab.unshift( {
                title: 'ACH',
                link: '/transaction/collect/add/onetime-ach',
                disabled: scheduleId ? true : false,
                permission:{
                    entity: "Accounts",
                    name: "ACH Collect",
                    operation: "CREATE"
                  }
            },)
          }

        /**
         * Method setting the default values for the text edit component from the context data
         */
        function setFormValues(data:any) {
            if(context?.data?.body && context?.data?.body?.amount != null){
            const expDate = (data?.source?.card?.expiryMonth && (data?.source?.card?.expiryMonth+'/'+data?.source?.card?.expiryYear))
            setValue("amount", data?.amount)
            setValue("purpose", data?.purpose)
            setValue("holderName", data?.source?.card && data?.source?.card?.holderName)
            setValue("externalAccount", data?.source?.externalAccount?.id)
            setValue("cardNumber", data?.source?.card && data?.source?.card?.cardNumberLast4)
            setValue("expiryDate",formatDate(expDate))
            setValue("cvv", '***')
            setValue("addressLine1", data?.source?.card && data?.source?.card?.billingAddress?.addressLine1)
            setValue("addressLine2", data?.source?.card && data?.source?.card?.billingAddress?.addressLine2)
            setValue("city", data?.source?.card && data?.source?.card?.billingAddress?.city)
            setValue("state", data?.source?.card && data?.source?.card?.billingAddress?.state)
            setValue("zip", data?.source?.card && data?.source?.card?.billingAddress?.zip)
            setValue('merchantId',data?.processingDetail?.merchant?.id?.toString());
            setValue('statementDescriptor',data?.processingDetail?.statementDescriptor);
            setInstantFunding(data?.processingDetail?.quickSettle);
            }
        }

        /**
         * useEffect to set edit data details
         */
        useEffect(()=>{
            setFormValues(context?.data?.body);
            getDestinationData(context?.data?.body)
        },[context?.data?.body])

         /**
         * function to get destination details
         * @param collectInfo :data
         */
         async function getDestinationData(collectInfo:any){
            if(collectInfo){
                const destinationValue:any = await getDestinationValue(collectInfo?.destination);
                setValue('destination', destinationValue);
            }
        }

        /**
         * Handle closing of the model
        */
        const handleClose = () => {
            if (context?.data?.body) {
                history.push('/transactions/collect/' + context.data.body.id)
            } else {
                history.push('/dashboard')
            }
        };

        /**
         * The main summary data
         */
        const amount = watch('amount');
        const summaryData: Record<string, TransactionSummaryFieldType | string> = {
            'Collect Amount': {
                id: 'amount',
                ValueComponent: FxSummaryCurrency,
                value: amount && !isNaN(amount) ? amount : '',
            },
            'To': {
                id: 'to',
                value: accountLabelTransformation(getKey('selectedAccount')),
            },
            'Purpose': {
                id: 'purpose',
                value: watch('purpose'),
            },
            'Via': {
                id: 'method',
                value: 'CARD',
            },
        };
        if (paymentMethodType === 'CARD_NOT_PRESENT') {
            summaryData['Statement Descriptor'] = {
                id: 'statementDescriptor',
                value: watch('statementDescriptor')
            }
        }
        if(paymentMethodType === 'CARD_NOT_PRESENT' && showInstantFunding) {
            summaryData['Faster Funding'] = {
                id: 'quickSettle',
                value: instantFunding ? 'Yes' : 'No',
            }
        }

        const topSectionDefaultValues = {
            amount: context?.data?.body?.amount,
            depositAccount: context?.data?.body?.destination?.url? context.data.body.destination.url.split("/").pop(): '',
            purpose: context?.data?.body?.purpose
        }
        const tabClickFunction = () => {
            try{
            const data:any = {
                schedule: 'collect',
                type: 'one-time',
                formData: { amount: watch('amount'), purpose: watch('purpose')}
            }
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'schedule-top-section', data: data } });
            }
            catch(e){
                Logger.error("CreateOnetimeCollectCard.tsx", "error", e);
            }
        }

        /**
         * This method checks for the value from the component and if it is equal to '' then changes it to null for supported fields
         * @param value 
         * @returns 
         */
        const populateValue = (value: any) => (value === '' ? null : value);

        /**
         * This method draws the Order node containing the Invoice details in the processing Detail Node in request creation
         * @param data 
         * @returns 
         */
        const drawInvoiceNodes = (data: any) => {
            const {
                invoiceNumber,
                'invoice-amounts': { discountAmount, taxAmount, freightAmount, dutyAmount },
                taxExempt,
                addressLine1,
                addressLine2,
                city,
                state,
                zip,
                country,
                'invoice-items': invoiceItems
            } = data;
        
            let address = {
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                state: state,
                zip: zip,
                country: country,
            };

            address = clean(address);

            const shipmentDetail: any = {
              freightAmount: populateValue(freightAmount),
              dutyAmount: populateValue(dutyAmount),
              ...(Object.values(address).some((value) => value) && Object.values(address).length >1 && { address }),
            };

            let returnData: any = {
                invoice : 
                    {
                        number: invoiceNumber,
                        discountAmount : populateValue(discountAmount),
                        taxAmount : populateValue(taxAmount),
                        taxExempt,
                        shipmentDetail: shipmentDetail
                    }
            };

            if(formatInvoiceItemsData(invoiceItems).length > 0){
                returnData.invoice.lineItem = formatInvoiceItemsData(invoiceItems);
            }

            return returnData;
        };

        /**
         * THis method formats the invoice item data in to api friendly data,
         */
        const formatInvoiceItemsData = (data: any) => {
            //check for an empty array
            const hasNonEmptyObject = data.some((obj:any) => Object.keys(obj).length > 0);
            if (!hasNonEmptyObject) {
              return [];
            } else {
              return data.map((obj: any) => {
                obj = clean(obj);
                delete obj.totalAmount;
                return obj;
              });
            }
          };

        /**
         * This method draws the AVS nodes containing the address details in the source Node in request creation
         * @param data 
         * @returns 
         */

        const drawAvsNodes = (data:any) => {
            const {
                avsZip =  data.addressSame? data.holderZip :  data?.avsZip ?? '',
                avsPhone = data.addressSame? data?.holderPhone : data?.avsPhone ?? '',
                avsEmail = data.addressSame? data?.holdermail : data?.avsEmail ?? '',
                avsFirstName = data.addressSame? data?.holderFirstName  : data?.avsFirstName ?? '',
                avsLastName = data.addressSame? data?.holderLastName : data?.avsLastName ?? '',
                avsAddressLine1 = data.addressSame? data.holderAddressLine1 : data?.avsAddressLine1 ?? '',

            } = {};
        
            return {
                ...(avsZip && { zip: avsZip }),
                ...(avsPhone && { phone: convertToPhoneRequestFormat(avsPhone) }),
                ...(avsEmail && { email: avsEmail }),
                ...(avsFirstName && { firstName: avsFirstName }),
                ...(avsLastName && { lastName: avsLastName }),
                ...(avsAddressLine1 && { addressLine1: avsAddressLine1 })
            };
        }

        /**
         * This method draws the Holder Detail nodes containing the Holder details in the source Node in request creation
         * @param data 
         * @returns 
         */
        const drawHolderNodes = (data:any, cardData:any) => {
            const {
                holderFirstName = cardData?.firstName ?? '',
                holderLastName = cardData?.lastName ?? '',
                holderPhone = data?.holderPhone ?? '',
                holderEmail = data?.holderEmail ?? ''
            } = data;
        
            return {
                    name: holderFirstName+' '+holderLastName,
                    firstName: holderFirstName,
                    lastName: holderLastName,
                    ...(holderPhone && { phone: holderPhone ? convertToPhoneRequestFormat(holderPhone) : '' }),
                    ...(holderEmail && { email: holderEmail }),
            }
        }

        /**
         * This method draws the Holder Address nodes for request creation
         * @param data 
         * @returns 
         */
        const drawHolderBillingAddressNodes = (data:any) => {
            const {
                addressLine1 = data?.holderAddressLine1 ?? '',
                addressLine2 = data?.holderAddressLine2 ?? '',
                city = data?.holderCity ?? '',
                state = data?.holderState ?? '',
                zip = data?.holderZip ?? '',
                country = data?.holderCountry ?? ''
            } = data;
            
            return {
                ...(addressLine1 && { addressLine1 }),
                ...(addressLine2 && { addressLine2 }),
                ...(city && { city }),
                ...(state && { state }),
                ...(zip && { zip }),
                ...(country && { country }) 
            };
        }
        
        /**
         * Method handles the opening of the Additional AVS Info Details Modal
         *  */ 
        const handleAddAdditionalInfo = (e:any) => {
            setOpenAdditionalInfoModal(true);
        };

        /**
         * Method handles the closing of the Additional AVS Info Details Modal
         */
        const handleAddAdditionalInfoModalClose = () => {
            setOpenAdditionalInfoModal(false);
        };

        /**
         * useEffect to reset instant funding value after change of merchant,
         * fetch merchant data based on the 'merchantId' and set "quick settle" configuration to enable or disable instant funding
         */
        useEffect(() => {
            !scheduleId && setInstantFunding(false);
        }, [scheduleId]);

            /**
                     * Function to handle the closing of the modal.
                     */
                
            const handleCloseModal = () => {
                const verifyProps = {...props.verifyProps}
                verifyProps['open'] = false;
                updateComponentParams(dispatch, props.id, { verifyProps });
            };

            /**
             * Method saves the data of the AVS detail form in a state
             * @param data 
             */
              const saveAdditionalAvsData = (data:any) => {
                setAdditionalAvsData(data);
                setAvsDataAvailable(true);
              }
              
            /**
             * Method saves the data of the invoice detail form in a state
             * @param data 
             */
              const saveInvoiceFormData = (data:any) => {
                setInvoiceData(data);
                setInvoiceDataAvailable(true);
              }

              const [isEdit, setIsEdit] = useState(false);


              /**
               * Handling edit function for detail of collect
               */
              const handleEdit = () => {
                setIsEdit(true);
                setOpenAdditionalInfoModal(true);
              }


              /**
               *  Handling data removing from the collect options
               */
              const handleDataRemoval = () => {
                saveAdditionalAvsData({});
                setAvsDataAvailable(false);
              }

        return(
          <>
            <ScheduleLayout
                id='create-collect-onetime-ach'
                type='collect'
                loading={isLoading}
                submitTitle={scheduleId ? 'Save Changes' : 'Collect Money'}
                summaryData={summaryData}
                onSubmit={handleSubmit(onSubmit)}
                onCancel={handleClose}
                saveEnabled={true}
                verifyProps={props?.verifyProps}
                handleCloseModal={handleCloseModal}
            >
                {/* <Grid item xs={12} sm={12} >
                    <FxTextEdit register={{ ...register("amount") }} className={errors.amount ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={setAmountValidationOptions} prefix="$" id="create-collect-onetime-card-amount-textbox" label="Amount*" name="amount" type="number" variant="outlined"  showDecimal={true} setValue={setValue} />
                </Grid>
                <Grid item xs={12} sm={12} >
                    <FxMaterialSelect register={{ ...register("depositAccount") }} control={control} rules={{ required: true }} className={errors.depositAccount ? "border-error-input fx-input-edit" : "fx-input-edit"} id="create-collect-from-my-account-form-card-destination-account" name="depositAccount" dataTransformation={dataTransformation} readOnly={true} value={scheduleInfo?.destination?.url ? scheduleInfo.destination.url.split("/").pop() : getKey("selectedAccount")?.id} source={acc_src} label="Destination Account *" setValue={setValue} />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FxTextEdit register={{ ...register("purpose") }} className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} rules={{ required: true, maxLength: 128 }} id="create-collect-onetime-card-purpose-textbox" label="Purpose*" name="purpose" variant="outlined" defaultValue={context?.data?.body?.purpose ? context.data.body['purpose'] : ''} />
                </Grid>
                */}
                <Grid item xs={12} sm={12}>
                    <ScheduleCreateMethodTab tabItems={method_Tab} id="create-collect-onetime-card-tab" tabClick={tabClickFunction}/>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <FxMaterialSelect  register={{ ...register("merchantId") }} value={''} rules={{ required: true }} control={control} key={`${context?.data?.body?.processingDetail?.merchant?.id}`} id="create-collect-onetime-card-form-card-merchant-id" name="merchantId" data={merchantList} readOnly={scheduleId?true:false} onChange={handleChange}  label="Merchant*" className={errors.merchantId ? "border-error-input fx-input-edit" : "fx-input-edit"} setValue={setValue} placeholder='Select Merchant' />
                   
                    {getValues('merchantId') && location && <Grid item xs={12} sm={12}>
                    <FxLocationSelectComponent
                        label='Location *'
                        register={{ ...register("location") }}
                        control={control}
                        rules={{ required: false }}
                        id="add-one-time-collect-card-form-location"
                        name="location"
                        className="fx-input-edit"
                        setError={setError}
                        clearError={clearErrors}
                        setValue={setValue}
                        resetField={resetField}
                        isEditable={false}
                        value={location ? {
                            label: location?.doingBusinessAs + " (" + location?.address?.addressLine1 + ', ' + (location?.address?.addressLine2 ? location?.address?.addressLine2 + ', ' : '') + location?.address?.city + ', ' + location?.address?.state + ', ' + location?.address?.zip + ")",                            
                            value: location?.id,
                        }: null}
                    />
                </Grid>}
                </Grid>
               
                
                <Grid item xs={12} sm={12}>
                    <FxMaterialSelect
                        register={{ ...register("paymentMethodType") }}
                        control={control}
                        rules={{ required: true }}
                        id="create-collect-onetime-card-payment-method-type"
                        name="paymentMethodType"
                        data={paymentMethodTypeData}
                        value="CARD_NOT_PRESENT"
                        label="Payment Method Type*"
                        setValue={setValue}
                    />
                </Grid>
                
                {paymentMethodType === "CARD_NOT_PRESENT" ? (
                    <Grid item xs={12} container>
                        <Grid item container xs={12} sm={12}>
                        <fieldset className="fx-container-create-schdule-summery">
                            <legend>Card Details*</legend>
                            <Grid item container xs={12} sm={12} spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography>BASIC DETAILS</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxTextEdit
                                register={{ ...register("firstName") }}
                                className={
                                    errors.firstName
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                                }
                                control={control}
                                rules={{ required: true }}
                                id="create-collect-onetime-card-form-card-holder-first-name-textbox"
                                label="First Name*"
                                name="firstName"
                                variant="outlined"
                                defaultValue={
                                    scheduleInfo?.cardNumber
                                    ? scheduleInfo["firstName"]
                                    : ""
                                }
                                isEditable={scheduleId ? false : true}
                                setValue={setValue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxTextEdit
                                register={{ ...register("lastName") }}
                                className={
                                    errors.firstName
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                                }
                                control={control}
                                rules={{ required: true }}
                                id="create-collect-onetime-card-form-card-holder-last-name-textbox"
                                label="Last Name*"
                                name="lastName"
                                isEditable={scheduleId ? false : true}
                                variant="outlined"
                                defaultValue={
                                    scheduleInfo?.cardNumber ? scheduleInfo["lastName"] : ""
                                }
                                setValue={setValue}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FxCardNumberEdit
                                register={{ ...register("cardNumber") }}
                                className={
                                    errors.cardNumber
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                                }
                                type="number"
                                control={control}
                                setValue={setValue}
                                setError={setError}
                                value={scheduleInfo?.source?.card?.cardNumberLast4}
                                clearErrors={clearErrors}
                                isEditable={scheduleId ? false : true}
                                rules={setCardNumberValidation(false)}
                                id="create-one-time-collect-card-card-number-textbox"
                                label="Card Number*"
                                placeholder="Card Number"
                                name="cardNumber"
                                variant="outlined"
                                maxLength={20}
                                />
                                <div className={"error-message"}>
                                {errors.cardNumber &&
                                    renderCardNumberError(errors.cardNumber)}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxDateEdit
                                register={{ ...register("expiryDate") }}
                                className={
                                    errors.expiryDate
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                                }
                                control={control}
                                rules={scheduleDateValidation}
                                id="create-collect-onetime-card-form-card-expiry-date-textbox"
                                label="Expiry Date*"
                                name="expiryDate"
                                readOnly={scheduleId ? true : false}
                                type="date"
                                variant="outlined"
                                defaultValue={
                                    scheduleInfo?.expiryDate
                                    ? formatDate(scheduleInfo.expiryDate)
                                    : ""
                                }
                                setValue={setValue}
                                month={true}
                                disablePast={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FxTextEdit
                                register={{ ...register("cvv") }}
                                className={
                                    errors.cvv
                                    ? "border-error-input fx-input-edit"
                                    : "fx-input-edit"
                                }
                                type="password"
                                control={control}
                                rules={setCVVValidation(true)}
                                id="create-collect-onetime-card-form-card-cvv-textbox"
                                label="CVV*"
                                name="cvv"
                                variant="outlined"
                                defaultValue={
                                    scheduleInfo?.purpose ? scheduleInfo["cvv"] : ""
                                }
                                maxLength={3}
                                isEditable={scheduleId ? false : true}
                                />
                            </Grid>

                            <Grid item xs>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Typography>ADDITIONAL INFORMATION</Typography>
                            </Grid>

                            {!avsDataAvailable && (
                                <Grid item xs={12} sm={12}>
                                <FxButton
                                    id={"add-card-holder-avs-details-info-button"}
                                    title="Add Card Holder & AVS Information"
                                    className="fx-button fx-button-lite"
                                    variant="contained"
                                    onClick={(e: any) => handleAddAdditionalInfo(e)}
                                    startIcon={<AddIcon />}
                                >
                                    Add Card Holder & AVS Information
                                </FxButton>
                                </Grid>
                            )}

                            {/**
                             * When data is present
                             */}
                            {avsDataAvailable && additionalAvsData && (
                                <Grid
                                item
                                xs={12}
                                sm={12}
                                marginTop={"0.5rem"}
                                marginBottom={"0.5rem"}
                                display={"flex"}
                                alignItems={"center"}
                                >
                                <Grid item xs={6} justifyContent={"flex-end"}>
                                    <Grid className="fx-data-saved-label">
                                    <Grid className="fx-data-saved-label-icon">
                                        <TickIcon />
                                    </Grid>
                                    <Grid>
                                        <Typography>
                                        CARD HOLDER AND AVS INFORMATION ADDED
                                        </Typography>
                                    </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} justifyContent={"flex-end"}>
                                    <Grid
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                    gap={"1rem"}
                                    >
                                    <Grid>
                                        <FxButton
                                        id={"details-card-edit-button"}
                                        variant="contained"
                                        className="fx-button  fx-button-action "
                                        startIcon={<EditIcon />}
                                        onClick={handleEdit}
                                        >
                                        Edit
                                        </FxButton>
                                    </Grid>
                                    <Grid>
                                        <FxButton
                                        id={"details-card-remove-button"}
                                        variant="contained"
                                        className="fx-button  fx-button-action "
                                        startIcon={<DeleteIcon />}
                                        onClick={handleDataRemoval}
                                        >
                                        Remove
                                        </FxButton>
                                    </Grid>
                                    </Grid>
                                </Grid>
                                </Grid>
                            )}

                            <FxModalGeneric
                                id="card-collect-addition-avs-details-modal"
                                open={openAdditionalInfoModal}
                                onClose={handleAddAdditionalInfoModalClose}
                                componentProps={{
                                amount: props?.amount,
                                register: register,
                                control: control,
                                setValue: setValue,
                                setError: setError,
                                clearErrors: clearErrors,
                                errors: errors,
                                cardNumber: watch("cardNumber"),
                                expiry: watch("expiryDate"),
                                cvv: watch("cvv"),
                                firstName: watch("firstName"),
                                lastName: watch("lastName"),
                                saveAdditionalAvsData: saveAdditionalAvsData,
                                setAvsDataAvailable: setAvsDataAvailable,
                                data: isEdit ? additionalAvsData : {},
                                watch: watch,
                                isEdit: isEdit,
                                }}
                                className={"fx-modal-submedium"}
                                component={AdditionalAVSInfo}
                            ></FxModalGeneric>
                            </Grid>
                        </fieldset>
                        </Grid>
                        
                        <ScheduleTopSection id="create-collect-one-time-card-schedule-top-section" schedule="collect" type="one-time" register={register} control={control} errors={errors} setValue={setValue} defaultValue={topSectionDefaultValues} acc_src={acc_src} dataTransformation={dataTransformation} amountId="create-collect-onetime-card-amount-textbox" destinationId="create-collect-from-my-account-form-card-destination-account" purposeId="create-collect-onetime-card-purpose-textbox" hasMarginBottom="true" />
                        <Grid item sm={12} marginTop={"2rem"}>
                        <FxTextEdit
                            register={{ ...register("statementDescriptor") }}
                            className={
                            errors.statementDescriptor
                                ? "border-error-input fx-input-edit"
                                : "fx-input-edit"
                            }
                            control={control}
                            rules={{ required: false }}
                            id="create-collect-onetime-card-form-card-statement-descriptor-textbox"
                            label="Statement Descriptor"
                            name="statementDescriptor"
                            variant="outlined"
                            setValue={setValue}
                        />
                        </Grid>

                        {/** Advanced Options Toggle Button */}
                        <Grid item xs={12} sm={12} marginTop={"1rem"}>
                        <FxSwitch
                            id="advanced-options"
                            value={advancedOptions}
                            onClick={setAdvancedOptions}
                            disabled={false}
                        ></FxSwitch>{" "}
                        <FxLabel value="Advanced Options"></FxLabel>
                        </Grid>

                        {/** Rendering the Advanced Options Form */}
                        {advancedOptions && (
                        <CardCollectAdvancedOptions
                            id={"card-collect-advanced-options-form"}
                            amount={amount}
                            saveInvoiceFormData={saveInvoiceFormData}
                            invoiceDataAvailable={invoiceDataAvailable}
                            control={control}
                            setValue={setValue}
                            register={register}
                            errors={errors}
                            watch={watch}
                            getValues={getValues}
                            setInvoiceDataAvailable={setInvoiceDataAvailable}
                            invoiceData={invoiceData}
                            billingAddressData={additionalAvsData ?? {}}
                            isAddTagDisabled={captureFundsLater}
                        />
                        )}
                    </Grid>
                    ) : 
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={12} sm={12}>
                            <Grid className="fx-container-create-schdule-summery">
                                <Grid item xs={12} sm={12} className='fx-pb-4'>
                                    <FxMaterialSelect
                                        register={{ ...register("terminal") }}
                                        control={control}
                                        rules={{ required: true }}
                                        id="create-collect-onetime-card-terminal"
                                        name="terminal"
                                        placeholder='Select Terminal'
                                        data={terminalData}
                                        label="Terminal*"
                                        value={terminalData?.length === 1 ? terminalData[0].value : ""}
                                        setValue={setValue}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} className='fx-pb-4'>
                                    <FxTextEdit
                                        register={{ ...register("amount") }}
                                        className={errors.amount ? "border-error-input fx-input-edit clear-number-field-arrow" : "fx-input-edit clear-number-field-arrow"}
                                        control={control}
                                        rules={{required: true}}
                                        valuePattern={/-|\+|e|E/}
                                        onWheel={(e:any)=>e.target.blur()}
                                        id="fx-redeem-card-gross-revenue"
                                        label="Amount*"
                                        type="number" 
                                        name="amount"
                                        variant="outlined"
                                        placeholder='0.00'
                                        prefix={"$"}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} className='fx-pb-4'>
                                    <FxTextEdit
                                        register={{ ...register("purpose") }}
                                        className={errors.purpose ? "border-error-input fx-input-edit" : "fx-input-edit"}
                                        control={control}
                                        rules={{ required: true }}
                                        id="create-purpose"
                                        label="Purpose*"
                                        placeholder='Purpose'
                                        name="purpose"
                                        variant="outlined" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {/** Capture Funds Later Toggle Button */}
                <Grid item xs={12} sm={12}>
                    <FxSwitch
                        id="capture-funds-later"
                        value={captureFundsLater}
                        onClick={setCaptureFundsLater}
                        disabled={false}
                    ></FxSwitch>{" "}
                    <FxLabel value="Capture Funds Later"></FxLabel>
                </Grid>
        {/** Capture Modal */}
        <FxModalGeneric
            id={"capture-modal-popup"}
            open={props?.isCaptureModal}
            hideClose={true}
            disableEscapeKeyDown={true}
            onClose={(event: any, item: any) => {
            // Prevent closing when clicking on backdrop
            if (item === 'backdropClick') {
                event.stopPropagation();
             } else {
                updateComponentParams(dispatch, props?.id, { isCaptureModal: false });
            }
            }}
            componentProps={{ transactionId: props?.transactionId, authCode: props?.authCode, backUrl: '/thanks/transactions/onetime-card/', isCollect: true}}
            className={'fx-modal-small2medium'}
            component={TransactionCaptureStatus}
        >
        </FxModalGeneric>
            </ScheduleLayout>
          </>
    )});

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Typography } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';
import { countryValueFormatter, stateValueFormatter } from '../../Utils/CommonBaseClass';

Logger.debug("FxAddressView.tsx", "Fx Address View initializing")

/**
 * Component used to display Address
 */
interface IAddressView {
    value?: any;
    className?: any;
    tooltip: any;
}

export const FxAddressView: React.FC<IAddressView> = ({ tooltip, value, className }) => {
    /**
     * Grouping all address nodes into one single node and formating them
     */
    let addressValue: any = '';
    const countryValue = value['country'] ? countryValueFormatter(value['country']) : "";
    const stateValue = value['state'] ? value['country'] ? value['country'].toLowerCase() === 'us' ? stateValueFormatter(value['state']) : value['state'] : value['state'] : "";
    if (value['address']) {
        addressValue = value['address']
    }
    else if (value['addressLine2'] !== '' && value['addressLine2']) {
        addressValue = value['addressLine1'] + ', ' + value['addressLine2'] + ', ' + (value['city']? value['city'] : "") + (value['state']? ', ' + stateValue : "") + (value['country']? ', ' + countryValue : "") + (value['zip'] ? ', ' + value['zip'] : "");
    }
    else if(value['addressLine1'] !== '' && value['addressLine1'] !== undefined) {
        addressValue = value['addressLine1'] + ', ' + (value['city']? value['city']  : "") + (value['state']? ', ' + stateValue  : "") + (value['country']? ', ' + countryValue : "") + (value['zip'] ? ', ' + value['zip'] : "");
    }
    else if(value['addressLine1'] === '' || value['addressLine2'] === '') {
        addressValue = (value['city']? value['city']  : "") + (value['state']? ', ' + stateValue  : "") + (value['country']? ', ' + countryValue : "") + (value['zip'] ? ', ' + value['zip'] : "");
    }
    else{
        addressValue = ""
    }

    return (
        <Typography title={tooltip ? addressValue : ''} className={className ? className : ''}>{addressValue}</Typography>
    )
}
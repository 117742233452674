/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, TextField, InputAdornment, IconButton } from '@mui/material';
import { Control, RegisterOptions, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { FxTextEdit } from '../Input';
import { FxButton } from '../Action/FxButton';
import { ReactComponent as VerifiedIcon } from '../../assets/svg/check-verified.svg';
import { ReactComponent as NotVerifiedIcon } from '../../assets/svg/close.svg';
import { renderError } from '../Utils/CommonBaseClass';
import { ReactComponent as EditIcon } from '../../assets/svg/edit-new.svg';

type FieldValues = Record<string, string>;

export type FxVerifyPropsType = {
    id: string;
    fieldName: string;
    inputLabel?: string;
    buttonLabelNormal: string;
    buttonLabelSucceeded?: string;
    buttonLabelFailed?: string;
    suffix?: string;
    rules?: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    register: UseFormRegister<FieldValues>;
    watch: UseFormWatch<FieldValues>;
    control: Control<FieldValues>;
    errors: Record<string, any>;
    verify(value: string): Promise<boolean> | boolean;
    onError?(e: any): void;
    onVerificationFinished?(succeeded?: boolean): void;
}

/**
 * @param props FxVerifyPropsType
 * Component can be used for verification any text value
 */
const FxVerify: React.FC<FxVerifyPropsType> = (props) => {
    const {
        id,
        fieldName,
        inputLabel,
        buttonLabelNormal,
        buttonLabelSucceeded,
        buttonLabelFailed,
        suffix,
        rules,
        register,
        control,
        errors,
        verify,
        watch,
        onError,
        onVerificationFinished,
    } = props;
    const [verifying, setVerifying] = useState(false);
    const [verified, setVerified] = useState<boolean>();
    const [editing, setEditing] = useState(true);
    const [ppiValue, setPpiValue] = useState("");
    const textBoxRef = useRef<any>();

    const fieldValue = watch(fieldName);
    const onVerificationFinishedRef = useRef(onVerificationFinished);
    onVerificationFinishedRef.current = onVerificationFinished;

    useEffect(() => {
        setVerified(undefined);
        onVerificationFinishedRef.current?.();
    }, [fieldValue]);

    const onVerify = useCallback(async () => {
        try {
            setVerifying(true);
            const result: any = await verify(`${fieldValue.trim()}${suffix || ''}`);
            if (typeof result === "object" && result !== null) {
              setVerified(result?.status === "VERIFIED");
              setEditing(false);
              setPpiValue(result?.name + " (" + result?.ppi + ")");
              onVerificationFinished?.(result?.status === "VERIFIED");
            } else {
              setVerified(result);
              onVerificationFinished?.(result);
            }
        } catch (e) {
            onVerificationFinished?.(false);
            onError?.(e);
        } finally {
            setVerifying(false);
        }
    }, [fieldValue, suffix, verify, onError, onVerificationFinished]);

    const buttonDisabled = verifying || !fieldValue?.trim();
    const buttonLabel = useMemo(() => {
        if(verified === undefined) {
            return buttonLabelNormal;
        }
        return verified ? (buttonLabelSucceeded || buttonLabelNormal)
            : (buttonLabelFailed || buttonLabelNormal);
    }, [verified, buttonLabelNormal, buttonLabelSucceeded, buttonLabelFailed]);

  /**
   * On edit click handler
   */
  const handleClickEditIcon = (value: any) => {
    setEditing(true);
    setVerified(undefined);
    onVerificationFinished?.(false);
    setPpiValue("");
    setTimeout(() => {
      textBoxRef?.current?.focus();
    }, 300);
  };

  return (
    <Box>
      <Box className="fx-verify">
        <Box sx={{ flexGrow: 1, marginRight: '8px' }}>
          {!editing ? (
            <TextField
              variant="outlined"
              id={`${id}-view`}
              name={`${props?.fieldName}-input`}
              disabled={true}
              InputLabelProps={{
                shrink: true,
              }}
              value={ppiValue}
              className="fx-input-edit"
              type={"text"}
              label="Payment ID"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="fx-icon-button"
                      onClick={handleClickEditIcon}
                      id={`${props?.id}-edit-icon`}
                      size="large">
                      {<EditIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <FxTextEdit
              id={`${id}-input`}
              register={register(fieldName)}
              className={`${
                errors[fieldName] ? "border-error-input " : ""
              }fx-input-edit`}
              control={control}
              isEditable={!verifying}
              rules={rules}
              label={inputLabel}
              name={fieldName}
              suffix={suffix}
              variant="outlined"
            />
          )}
        </Box>
        <Box>
          <FxButton
            id={`${id}-verify-button`}
            disabled={buttonDisabled || verified !== undefined}
            isSubmitting={verifying}
            className={
              buttonDisabled ? "fx-button fx-button-disabled" : "fx-button"
            }
            startIcon={
              verified === undefined ? (
                verified
              ) : verified ? (
                <VerifiedIcon />
              ) : (
                <NotVerifiedIcon />
              )
            }
            onClick={onVerify}
          >
            {buttonLabel}
          </FxButton>
        </Box>
      </Box>
      {!!errors[fieldName] && (
        <div className={"error-message"}>{renderError(errors[fieldName])}</div>
      )}
    </Box>
  );
};

export default FxVerify;

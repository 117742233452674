/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/**
 * Component: CoOwnerDetailsInfo
 * Usage: To display Co-Owner information
 */
import React, { useEffect } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { DATA_STATE } from '../../../libs/reducer/dataReducer';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { FxInfoCard } from '../Cards/FxInfoCard';
import { FxSkeltonList } from '../Cards/FxSkelton';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { CoOwnerCustomerAddress } from './CoOwnerMailingAddressDetails';
import { viewSsnItinNode } from '../../Utils/CommonBaseClass';


Logger.debug("CoOwnerDetailsInfo.tsx", "Co-owner InfoCard initializing");


export const CoOwnerDetailsInfo: React.FC<any> = React.memo((props) => {
    const dispatch = useDispatch()
    let context: any;
    ({ context, props } = RegisterComponent(props));
    let co_owner_information_data: any = {};
    let ssnItinNode = [{}];

    /**
     * Details page field is setting
     */
    if (context && context.data && context.data.body) {
        co_owner_information_data = { ...context.data.body }
        const itin = co_owner_information_data?.taxIdentification?.id || co_owner_information_data?.taxIdentification?.idLast4;
        co_owner_information_data.isPrimary = co_owner_information_data?.isPrimaryOwner ? 'Yes' : 'No';
        ssnItinNode = viewSsnItinNode('itin', co_owner_information_data?.taxIdentification?.idLast4 || co_owner_information_data?.taxIdentification?.id)
        co_owner_information_data['itin'] =  itin ? ("****") + itin : '';
        co_owner_information_data.ssn = co_owner_information_data?.last4ssn ? '****' + co_owner_information_data?.last4ssn : ''
        co_owner_information_data.fullname = co_owner_information_data?.firstName ? co_owner_information_data?.firstName + ' ' + co_owner_information_data?.lastName : co_owner_information_data?.legalName
        const createdBy = co_owner_information_data?.createdBy?.username || '';
        const createdOn = co_owner_information_data?.createdOn ? moment(co_owner_information_data.createdOn).format('MM/DD/YYYY') : '';
        const lastUpdatedBy = co_owner_information_data?.lastUpdatedBy?.username || '';
        const lastUpdatedOn = co_owner_information_data?.lastUpdatedOn ? moment(co_owner_information_data.lastUpdatedOn).format('MM/DD/YYYY') : '';
        co_owner_information_data.created = `${createdBy} ${createdOn ? 'on' : ''} ${createdOn}`;
        co_owner_information_data.lastUpdated = `${lastUpdatedBy} ${lastUpdatedOn ? 'on' : ''} ${lastUpdatedOn}`;
        co_owner_information_data.userName = co_owner_information_data?.portalAccess?.username;
        dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: co_owner_information_data?.fullname, backButton: '/co-owner/profile' } } });
    }
    useEffect(() => {
        return ()=>{
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        }
      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const co_owner_information_section_left = [
        {
            label: 'Name',
            value: 'fullname'
        },
        {
            label: 'Username',
            value: 'userName'
        },
        {
            label: 'DOB',
            value: 'dob'
        },
        ...ssnItinNode
    ];


    const co_owner_information_section_right = [

        {
            label: 'Mobile',
            value: 'mobilePhone'
        },
        {
            label: 'EMAIL',
            value: 'email',
            truncateChars: 34,
            type: 'fri://fieldtype/default'
        },
        {
            label: 'Is Primary Owner',
            value: 'isPrimary'
        },

    ];

    const audit_fields_left_section = [
        {
            label: 'Created',
            value: 'created'
        }
    ]
    const audit_fields_right_section = [
        {
            label: 'Last Updated',
            value: 'lastUpdated'
        }
    ]

    const contextState = context?.data_state;
    const dataLoadStatus = contextState === DATA_STATE.STATUS_DONE;
    return (
        <>
            <Grid id="co-owner-info-Grid " className="fx-margin-bottom-16" item xs={12}  >
                <FxCard id="co-owner-info-card" className="fx-theme-passport">
                    {dataLoadStatus ?
                        <>
                            <FxCardHeader id={'co-owner-info-card#card-header'}>
                                <Grid id="co-owner-details-Grid2" item container justifyContent="space-between" xs={12} alignItems={'center'}>
                                    <Grid id="co-owner-details-Grid3">
                                        <Typography id="title-co-owner-info" variant="h4">Owner Information</Typography>
                                        <div id="div-grow-title-co-owner-details" className="fx-flex-grow" />
                                    </Grid>
                                </Grid>
                            </FxCardHeader>
                            <FxCardBody id="co-owner-info-card#card-body" className="fx-info-card fx-margin-top-reducer">
                                <Grid container item xs={12} direction="row">
                                    <><Grid item xs={12} direction="column">
                                        <FxInfoCard id={"co-owner-information-info-card" + co_owner_information_data['id']}
                                            title="BASIC INFORMATION"
                                            leftcolumn={co_owner_information_section_left}
                                            rightcolumn={co_owner_information_section_right}
                                            data={co_owner_information_data} />
                                        <Grid item xs={12}>
                                            {co_owner_information_data && <FxInfoCard
                                                id="co-owner-profile-information-card-audit-one-section-info-card"
                                                title="AUDIT FIELDS" leftcolumn={audit_fields_left_section}
                                                rightcolumn={audit_fields_right_section}
                                                data={co_owner_information_data}></FxInfoCard>}
                                        </Grid>
                                    </Grid>
                                    </>
                                </Grid>

                            </FxCardBody>
                        </>
                        :
                        <FxSkeltonList height="15rem" />
                    }
                </FxCard>


            </Grid >
            <Grid container item xs={12} direction="row">
                <CoOwnerCustomerAddress id="coowneraddresslist" data={co_owner_information_data?.mailingAddress} ></CoOwnerCustomerAddress>
            </Grid>
        </>
    )
})

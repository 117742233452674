/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import Slider from 'react-slick';

interface IFxCarousel {
    data: any;
    settings: any;
    getSliderRef(ref: Slider | null): any;
    
}

/**
 * This Component handles the Rendering of the Carousel Component
 * @param props 
 * @returns 
 */
export const FxCarousel: React.FC<IFxCarousel> = (props) => {

    return (
        <Slider {...props?.settings} ref={(ref) => (props.getSliderRef(ref))}>
        {props?.data()}
      </Slider>
    );
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { TextField, OutlinedTextFieldProps } from "@mui/material";
import clsx from "clsx";
import { Logger } from '../../../libs/utils/logger';

Logger.debug("FxPhoneEditWithoutCountryCode.tsx", "Fx Phone Edit initializing")

/**
 * Component used to edit phone number without country code
 * @param props :props data
 * @returns 
 */
type FxPhoneEditWithoutCountryCodeType = ControllerProps & OutlinedTextFieldProps;

interface FxPhoneEditWithoutCountryCodeProps extends Partial<FxPhoneEditWithoutCountryCodeType>{
    value?: string,
    format?: string,
    onChangePhoneNumber?: any,
    className?: string;
    name: string;
    id?: string;
    onChange?: any;
    inputRef?: any;
}
const FxPhoneEditWithoutCountryCode: React.FC<FxPhoneEditWithoutCountryCodeProps> = (props) => {
    return (
        <Controller
            name={props.name}
            control={props.control ? props.control : undefined}
            rules={props.rules ? props.rules : { required: false }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (<NumberFormat id={props.id} displayType="input" name={props.name}
                variant={"outlined"}
                inputRef={props.inputRef} customInput={TextField}
                label={props.label ? props.label : ''}
                defaultValue={props.value ? props.value : value}
                value={(props.value === '' ? props.value : (props.value ? props.value : value))}
                onValueChange={props.onChange ? props.onChange : onChange}
                format={props.format ? props.format : "(###)-###-####"}
                error={props.error ? props.error : error as unknown as boolean }
                className={clsx(props.className ? props.className : 'fx-input-edit')} />
            )}
        />
    )
}

export default FxPhoneEditWithoutCountryCode;
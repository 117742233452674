/*
 **
 ** Status Component
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { InitializeComponent } from "../../Utils/ComponentInitializer";
import { Logger } from "../../../libs/utils/logger";

Logger.debug("FxStatus.tsx", "Fx Status initializing")
/**
 * Component used to show status
 */
declare interface IFxBadgeStatus {
    id?: string;
    value?: any;
    entity?: any;
    className?: any;
    fill: boolean;
    hyperLink?: any;
    modal?: any;
    staticWord?: any;
    mark?: boolean;
    appendText?: any;
    prefixText?: any;
    tooltip?: any;
    noColor?:any;
}
// export FxStatus
export const FxPaymentModeStatus: React.FC<IFxBadgeStatus> = (props) => {
    //Pre-defined Status for entities

    const statusData = {
        "SKY_BLUE": {
            values: ["wire","benf. owner"],
            color: "#00AEEF",
            textcolor: "#00AEEF",
            bgcolor: "rgba(0, 174, 239, 0.15)",
            bordercolor: "#B3E7FA",
        },
        "RED": {
            values: ["check",'auth signatory'],
            color: "#FF4E4E",
            textcolor: "#E96383",
            bgcolor: "rgba(255, 78, 78, 0.15)",
            bordercolor: "#F8D0DA",
        },
        "GREEN": {
            values: ["check"],
            color: "#0DA8A8",
            textcolor: "#00B05C",
            bgcolor: "rgba(0, 176, 92, 0.15)",
            bordercolor: '#B7E5E5',
        },
        "VIOLET": {
            values: ["mobile check"],
            color: "#8D53EB",
            textcolor: "#8D53EB",
            bgcolor: "#DCE7FD",
            bordercolor: "#DDCBF9",
        },
        "YELLOW": {
            values: ["book"],
            color: "#D9B034",
            textcolor: "#F58220",
            bgcolor: "rgba(244, 130, 32, 0.15)",
            bordercolor: "#F4E7C2",
        }
    };

    let context: any;
    ({ context, props } = InitializeComponent(props));
    if (context && context.config && context.config.params && context.config.events) {
        props = {
            ...props,
            ...context.config.params,
            events: { ...context.config.events },
        };
    }
    let setStatus: any
    if (typeof props.value === 'object' && props.value !== null) {
        setStatus = props.value?.name;
    }
    else {
        setStatus = props.value;
    }

    return (
        <span id={props.id} className={'fx-mode-status'}>
            {setStatus && setStatus.split(',').map((item: any) => {
                let selNod = statusData.SKY_BLUE;

                try {
                    Object.entries(statusData).forEach(([node, nodprops]) => {
                        if (nodprops.values.indexOf(item.toLowerCase()) > -1) {
                            selNod = nodprops;
                        }
                    });
                } catch (e) { 
                    Logger.error("FxStatus.tsx", "Fx Status initializing", e);
                }

                const setTextColor = selNod?.textcolor;
                const setBorderColor = selNod?.bordercolor;
                const fill_class = 'fx-status-filled ';
                return (
                    <>
                        {!props?.noColor && <mark title={props.tooltip ? item : ''} className={fill_class} style={{ backgroundColor: "transparent", border: "1px solid " + setBorderColor, color: setTextColor }}>{item}</mark>}
                        {props?.noColor && <mark title={props.tooltip ? item : ''} className={'fx-status-filled fx-status-filled-no-color'} style={{ backgroundColor: "transparent"}}>{item}</mark>}
                    </>
                )
            })}
        </span>
    );
};


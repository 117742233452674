/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
import React, { useEffect } from 'react'
import { Grid, CircularProgress, Divider } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { clean, convertToPhoneRequestFormat, getEAOrAddressDetailsFromContact, renderPhoneError, setPhoneValidationOptions, setZipValidationOptions, renderCityNameError, setCityNameValidation } from '../../Utils/CommonBaseClass';
import { Logger } from '../../../libs/utils/logger';
import HttpClient from '../../../libs/utils/httpClient';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxTextEdit } from '../../Input/FxText/FxTextEdit';
import { getCustomerUrl, processAPIResponse } from '../../../libs/utils/utils';
import usa_state from '../../Utils/usa_states.json';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import FxLabelView from '../../Input/FxLabel/FxLabelView';
import { FxButton } from '../../Action/FxButton';
import FxPhoneEdit from '../../Input/FxPhone/FxPhoneEdit';
import { FxSelectAutoSearch } from '../../Input/FxSelect/FxSelectAutoSearch';
import FxSnackBar from '../../Utils/fx-snack-bar';

const httpClient = HttpClient.getClient();

Logger.debug("UpdateAddressCard.tsx", "update address new initializing");

// Interface: errors
interface InputErrType {
    type: string;
    message: string;
}

/**
 * Component: Update Address Card
 * Usage: Update Address
 */
export const UpdateAddressCard: React.FC<any> = React.memo((props) => {
        let context: any;
        ({ context, props } = RegisterComponent(props));
        const dispatch = useDispatch()
        const { register, formState: { errors }, handleSubmit, setValue, clearErrors, setError, control } = useForm();
        const params = useParams<any>();
        let edit_address_data: any;
        let contact_data: any
        let contactId = ''
        const addressId = params.id

        if (context && context?.data && context?.data?.body) {
            contact_data = { ...context.data.body }
            contactId = contact_data?.id;
            edit_address_data = { ...getEAOrAddressDetailsFromContact(contact_data, "ADDRESS", addressId, false) };
            dispatch({ type: "DATA_UPDATE_COMPONENT_REDUCER", payload: { id: 'page-title-details', data: { title: 'Update Shipping Address', backButton: '/payee/view/'+contactId } } });
        }

        /**
       * reset the component on unmount.so that when redirect back page it will call the api again
       * */
        useEffect(() => {
            dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const [isLoading, setIsLoading] = React.useState(false);
        const submitButton = 'Save Changes';
        const history = useHistory();

        /**
         * Method to redirect to previous page on clicking cancel button
         */
        const handleClose = () => {

            history.push('/payee/view/' + props?.eadetails?.contactId, { contact: { id: props?.eadetails?.contactId } })

        };

        /**
           * Name validation
           */
        const nameValidation = {
            required: true,
            maxLength: 45
        }

        /**
         * Method to handle error
         * @param err
         * @returns
         */
        const renderZipError = (err: InputErrType): string => {
            return err.message;
        };


        //#region Functions

        /**
           * Method to handle on submit request
           * @param data : form data
           */
        async function onSubmit(data: any) {
            let req: any = {};
            let status: any;
            req = createAddressRequest(data)
            const successMessage = 'Address Updated Successfully!';
            status = await updateAddress(clean(req));
            status = processAPIResponse(status)
            if (status.status) {
                FxSnackBar.show({
                    autoHideDuration: 1000,
                    severity: 'success',
                    text: successMessage,
                });
                history.push('/payee/view/' + contactId);
            }
            else {
                FxSnackBar.show({
                    text: status.message,
                });
            }
            setIsLoading(false);

        }

        /**
        * Method to create address request
        * @param data
        * @returns
        */
        function createAddressRequest(data: any) {
            const object: any = {};
            const address: any = {
                id: addressId,
                addressLine1: data.addressLine1,
                city: data.city,
                state: data.state,
                zip: data.zip,
                phone: data.recipientPhone ? convertToPhoneRequestFormat(data.recipientPhone) : '',
                countryCode: data['recipientPhone-select'],
                name: data.recipientName,
            }
            if (data.addressLine2 && data.addressLine2 !== '') {
                address.addressLine2 = data.addressLine2;
            }
            object.mailingAddress = [address];
            return object
        }
        /**
         * Method to call api  for create address
         * @param paylaoddata : request payload
         *
         */
        async function updateAddress(paylaoddata: any) {
            try {
                const data: any = await httpClient.post( getCustomerUrl('/contact/id/' + contactId,false), paylaoddata).then(response => {
                    return response
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("UpdateAddressCard.tsx", "error", err);
                return err;
            }
        }

        return (
            <Grid container id="update-address-main-grid" xs={12} className='fx-form-grid'>
                <Grid id="update-address-first-grid" item xs={12} sm={8}>
                    <Grid id="update-address-sub-grid" container spacing={1} >
                        <Grid id="update-address-second-grid" item xs={12}>
                            <div className="fx-form-edit-profile flex column">
                                {edit_address_data && <form id="update-address-form" onSubmit={handleSubmit(onSubmit)}>
                                    <FxCard id="create-address-form-card" className="fx-theme-passport">
                                        <FxCardHeader id="create-addrsss-form-card-header">
                                        </FxCardHeader>
                                        <FxCardBody id="create-address-form-card-body" className="fx-info-card fx-margin-top-reducer" >
                                            <Grid container direction="row" spacing={1} className="fx-info-card-form" >
                                                {<>
                                                    <Grid item xs={12} sm={12}>
                                                        <FxLabelView id="customer-signup-individual-info-label-primary-address" className="" >SHIPPING ADDRESS DETAILS</FxLabelView>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register("recipientName") }} className={errors.recipientName ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={nameValidation} control={control} id="payee-address-edit-recipientname-textbox" label="Shipped To *" placeholder="Shipped To" setValue={setValue} name="recipientName"
                                                        defaultValue={edit_address_data?.name}
                                                        variant="outlined" />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxPhoneEdit register={register} control={control} rules={setPhoneValidationOptions(true)} setValue={setValue} readOnly={false} isEditable={true}
                                                            setError={setError} clearErrors={clearErrors}
                                                            defaultValue={edit_address_data?.phone}
                                                            defaultCountryCode={edit_address_data?.countryCode}
                                                            className={errors.recipientPhone ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-address-edit-recipientphone-textbox" name="recipientPhone" label="Phone *" />
                                                        <div className={'error-message'}>
                                                            {errors.recipientPhone && renderPhoneError(errors.recipientPhone)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register('addressLine1') }} control={control} className={errors['addressLine1'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: true }} id={'payee-address-edit-addressline1-textbox'} label="Address Line 1*" name={'addressLine1'} variant="outlined" setValue={setValue}
                                                         defaultValue={edit_address_data?.addressLine1}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FxTextEdit register={{ ...register('addressLine2') }} control={control} className={errors['addressLine2'] ? "border-error-input fx-input-edit" : "fx-input-edit"} rules={{ required: false }} id={'payee-address-edit-addressline2-textbox'} label="Address Line 2" name={'addressLine2'} variant="outlined" setValue={setValue} defaultValue={edit_address_data?.addressLine2} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("city") }} control={control} rules={setCityNameValidation(true)} className={errors.city ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-address-edit-city-textbox" name="city" label="City *" setValue={setValue} defaultValue={edit_address_data?.city}
                                                        />
                                                        <div className={'error-message'}>
                                                            {errors.city && renderCityNameError(errors.city)}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxSelectAutoSearch register={{ ...register("state") }} rules={{ required: true }} className={errors.state ? "border-error-input fx-input-edit" : "fx-input-edit"} control={control} id="payee-address-edit-state-textbox" value={edit_address_data?.state} name="state" data={usa_state} label="State *" setValue={setValue} readOnly={false} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <FxTextEdit register={{ ...register("zip") }} control={control} rules={setZipValidationOptions(true)} className={errors.zip ? "border-error-input fx-input-edit" : "fx-input-edit"} id="payee-address-edit-zip-textbox" name="zip" label="Zip *" isEditable={true} defaultValue={edit_address_data?.zip} />
                                                        <div className={'error-message'}>
                                                            {errors.zip && renderZipError(errors.zip)}
                                                        </div>
                                                    </Grid>
                                                </>
                                                }
                                                  <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </FxCardBody>
                                        <FxCardFooter id="uppdate-address-form-card-footer" className="fx-footer">
                                            <Grid container direction="row" justifyContent="flex-end" className="fx-modal-footer">
                                                <FxButton variant="contained"
                                                    className="fx-button fx-button-cancel"
                                                    id="payee-edit-address-cancel-button"
                                                    onClick={handleClose}>
                                                    Cancel
                                                </FxButton>
                                                <span className="fx-padding-right-16" />
                                                <FxButton
                                                    disableRipple={false}
                                                    className="fx-button fx-button-theme"
                                                    id={"payee-edit-address-submit-button"}
                                                    type="submit"
                                                >
                                                    {isLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                        />
                                                    ) : (
                                                        submitButton
                                                    )}
                                                </FxButton>
                                            </Grid>
                                        </FxCardFooter>
                                    </FxCard>
                                </form>}
                            </div>
                        </Grid >
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} ></Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    });

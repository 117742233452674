/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useMemo, useState } from 'react'
import { Button, Grid, Modal, Typography, IconButton, CircularProgress, MenuItem } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { FxButton } from '../Action/FxButton';
import { toTitleCase, checkPermission } from '../Utils/CommonBaseClass';
import { Logger } from '../../libs/utils/logger';
import HttpClient from '../../libs/utils/httpClient';
import FxCardHeader from '../Container/FxCardHeader';
import FxCard from '../Container/FxCard';
import FxCardBody from '../Container/FxCardBody';
import FxCardFooter from '../Container/FxCardFooter';
import { getCustomerUrl, processAPIResponse } from '../../libs/utils/utils';
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg';
import FxSnackBar from '../Utils/fx-snack-bar';
const httpClient = HttpClient.getClient();

Logger.debug("FxDelete.tsx", "fx delete initializing")

interface FxDeleteProps {
    permissions?: any;
    header: string;
    description?: string;
    buttonTitle?: string;
    buttonId?: string;
    entity: string;
    id: string;
    noRedirection?: boolean;
    redirectUrl?: string;
    open?: boolean;
    close?: () => void;
    onSuccess?: () => void;
    noEntity?: boolean;
    domId?: string;
    menuType?: boolean;
    successEntity?: string;
    type?:any;
    buttonName?:string;
    className?:string;
}
/**
 * Generic delete modal window for different entity
 */
export const FxDelete: React.FC<FxDeleteProps> = React.memo(
    (props) => {
        const { handleSubmit } = useForm();

        const [isLoading, setIsLoading] = useState(false);
        const [deleteOpen, setDeleteOpen] = useState(false);
        const [propsOpen, setPropsOpen] = useState(props.open);
        const buttonId = props?.buttonId ? props?.buttonId : props.entity;
        const history = useHistory();
        const handleClose = () => {
        };

        // function for closing the modal
        const handleDeleteClose = () => {
            if (props.open && props.close) {
                props.close()
            }
            setDeleteOpen(false);
            setPropsOpen(false);
        };
        // function for opening the modal
        const handleDeleteOpen = () => {
            setDeleteOpen(true);
        };

        /**
        * to set permission
        */
        const permission = useMemo(() => {
          return checkPermission(props?.permissions)
        }, [props?.permissions])

        // onsubmit function for delete form
        async function onSubmit(data: any) {
            const successText: any = props.successEntity? props.successEntity: toTitleCase(props.entity);
            //if condition to check whether need a API call or not
            if(props?.noEntity===true){
                FxSnackBar.show({
                    severity: 'success',
                    text: successText + ' Deleted Successfully!',
                });
                setTimeout(() => {
                    props.onSuccess?.();
                    setDeleteOpen(false);
                    setPropsOpen(false);
                    FxSnackBar.closeForce();
                }, 800);
            }
            else{
                let status: any;
                status = await deleteEntity(props.id);
                status = processAPIResponse(status)
                setIsLoading(false);
                if (status.status) {
                    FxSnackBar.show({
                        severity: 'success',
                        text: successText + ' Deleted Successfully!',
                    });
                    setTimeout(() => {
                        if(props.noRedirection){
                            setDeleteOpen(false);
                            setPropsOpen(false);
                        }else{
                            FxSnackBar.closeForce();
                            props.redirectUrl && history.push(props.redirectUrl)
                        }
                        props.onSuccess?.();
                    }, 1000);

                }
                else {
                    //api  failed
                    FxSnackBar.show({
                        text: status.message,
                    });
                }
            }

        };
        //Api call for delete entity
        async function deleteEntity(id: any) {
            try {
                let url = getCustomerUrl(props.entity + '/id/' + id,false);
                if(props.entity === 'recurring'){
                    url = getCustomerUrl('transaction/' + props.entity + '/id/' + id,false);
                }
                const data: any = await httpClient.delete(url).then(response => {
                    return response;
                })
                    .catch((error) => {
                        return { ...error };
                    })
                return data;
            } catch (err) {
                Logger.error("FxDelete.tsx", "error", err);
                return false;
            }
        }

        /**
         * Component structure
         */
        return <>
            {props.menuType && permission ? (<><MenuItem id={"delete-" + props.entity + "-menu"} onClick={handleDeleteOpen}>
                <DeleteIcon />&nbsp;&nbsp;{props.buttonTitle}
            </MenuItem></>) : permission ? (<FxButton  id={props?.domId ? props?.domId: "delete-" + props.entity + "-button"} variant="contained" className={props.className ? props.className : "fx-button fx-button-action fx-svg-theme fx-button-delete"} startIcon={<DeleteIcon />} onClick={handleDeleteOpen}>{props.buttonTitle}</FxButton>) : <></>}
            <Modal
                style={{ overflow: 'scroll' }}
                open={propsOpen ? propsOpen : deleteOpen}
                onClose={handleClose}
                disableEnforceFocus={true}
                disableAutoFocus={false}
                className="fx-modal fx-modal-small"
            >
                <div className="modal-content" >
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FxCard>
                                <FxCardHeader id="create-del-header" className="fx-card-header fx-modal-header">
                                    <Grid container className="fx-modal-close-icon" justifyContent="flex-end">
                                        <IconButton onClick={handleDeleteClose} id='ea-modal-close-icon' size="large">
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </FxCardHeader>
                                <FxCardBody id="del-create-modal-body" className="fx-info-card fx-delete-info" >
                                    <Grid container direction="row" spacing={1} className="fx-modal-form flex column" alignItems="center">
                                        <Grid item container justifyContent="center" xs={12}>
                                            <IconButton
                                                onClick={handleDeleteClose}
                                                id='del-modal-delete-icon'
                                                className="fx-modal-delete-icon"
                                                size="large">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid container justifyContent="center" item xs={12} className="fx-delete-title">
                                            <Typography id="title-delete-modal" variant="h3" >
                                                {props.header}
                                                {/* Are you sure you want to delete this external account {externalAccountData.accountNumber} */}
                                            </Typography>
                                        </Grid>
                                        <Grid container justifyContent="center" item xs={12} className="fx-delete-description">
                                            <Typography id="title-delete-grid-two" variant="h5" >
                                                {props.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </FxCardBody>
                                <FxCardFooter id="ea-modal-Footer" className="fx-footer">
                                    <Grid container direction="row" justifyContent="center" className="fx-modal-footer">
                                        <Button variant="contained"
                                            className="fx-button fx-button-cancel"
                                            id={"cancel-" + buttonId + "-button"}
                                            onClick={handleDeleteClose}>
                                            Cancel
                                </Button>
                                        <span className="fx-padding-right-16" />
                                        <Button
                                            disableRipple={false}
                                            className="fx-button fx-button-theme fx-button-delete"
                                            id={"delete-" + buttonId + "-button"}
                                            type={props?.type || "submit"}
                                            onClick={() => {
                                                if (props?.type === 'button') {
                                                  props.onSuccess?.();
                                                }
                                              }}
                                        >
                                            {isLoading ? (
                                                <CircularProgress
                                                    size={20}
                                                    style={{
                                                        color: 'white',
                                                    }}
                                                />
                                            ) : (
                                                    props&& props.buttonName? props.buttonName :'Yes, Delete It'
                                                )}
                                        </Button>
                                    </Grid>
                                </FxCardFooter>
                            </FxCard>
                        </form>
                    </div>
                </div>
            </Modal>
        </>;
    });

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Chip, Grid } from '@mui/material';

interface FxChipProps {
    id: string;
    data: any[];
    handleDelete: (data: any) => void;
    className?:any
    deleteIcon?:any
}

export const FxChip: React.FC<FxChipProps> = (props) => {

    return (
        <Grid container direction="row" id={props.id} justifyContent="space-between" >
            <Grid item container>
                {props?.data.map((date: any, index: any) => (
                    <Chip
                        className={props?.className}
                        key={index}
                        label={date}
                        onDelete={() => props.handleDelete(date)}
                        deleteIcon={props?.deleteIcon}
                    />
                ))}
            </Grid>
        </Grid>
    );
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import { useDispatch } from "react-redux";
import { SagaHelper } from "../../libs/utils/sagaHelper";
import { FxLink } from "./FxLink";

export enum TextType {
    VALUE,
    STATIC,
    BOTH,
}
interface IHyperLink {
    url: any;
    target?: any;
    type?: TextType;
    title: string;
    separator?: string;
}
interface IModal {
    id: any;
    type?: TextType;
    title: string;
    separator?: string;
}
interface IStatic {
    title: string;
    separator?: string;
    className?: any;
}
export declare interface IFxSecondary {
    value?: any;
    hyperLink?: IHyperLink;
    modal?: IModal;
    staticWord?: IStatic;
    context?: any;
    className?: any;
    mark?: boolean;
}
/**
 * Draws a link that may have a target or
 * a modal attached with this or
 * may be  a simple text
 * @param param0
 */
export const FxSecondary: React.FC<IFxSecondary> = ({ hyperLink = null, modal = null, staticWord = null, className, value, context, mark = false }) => {

    const dispatch = useDispatch();
    if (hyperLink && hyperLink.url) {
        const api = hyperLink.url;
        return (
            <>{!hyperLink ? value : hyperLink.type === 1 ?
                <FxLink href={api ? api : "#"} target={hyperLink.target ? hyperLink.target : ""} className={
                    className ? className : "fx-link"}>{hyperLink.title}
                </FxLink>
                : hyperLink.type === 0 ?
                    <FxLink href={api ? api : "#"} target={hyperLink.target ? hyperLink.target : ""} className={
                        className ? className : "fx-link"}>{value}
                    </FxLink>
                    : <>{!mark ? value : ''}{hyperLink.separator ?
                        <> {hyperLink.separator}</>
                        : <></>} <FxLink href={api ? api : "#"} target={hyperLink.target ? hyperLink.target : ""} className={
                            className ? className : "fx-link"}>{hyperLink.title}</FxLink></>}
            </>
        )
    } else if (modal && modal.id) {
        return (
            <>{!modal ? value : modal.type === 1 ?
                <FxLink className={
                    className ? className : "fx-link"} onClick={() => { new SagaHelper(dispatch).openPopup(modal.id, context); }}>{modal.title}
                </FxLink>
                : modal.type === 0 ?
                    <FxLink className={
                        className ? className : "fx-link"} onClick={() => { new SagaHelper(dispatch).openPopup(modal.id, context); }}>{value}
                    </FxLink>
                    : <>{value}{modal.separator ?
                        <> {modal.separator}</>
                        : <></>} <FxLink className={
                            className ? className : "fx-link"} onClick={() => { new SagaHelper(dispatch).openPopup(modal.id, context); }}>{modal.title}</FxLink></>}
            </>
        )
    } else if (staticWord && staticWord.title) {
        return (
            <>{value}{staticWord.separator ?
                <> {staticWord.separator}</>
                : <span className={staticWord.className ? staticWord.className : ''}>{staticWord.title}</span>}</>
        )
    }
    else {
        return (
            <>{value}</>
        )
    }
}


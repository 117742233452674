/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, CircularProgress, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { FxButton } from '../Action/FxButton';
import { FxSkeltonList } from "../Page/Cards/FxSkelton";
import { getIcon } from "../../libs/dictionary/icon";
import { Logger } from "../../libs/utils/logger";
import { RegisterComponent } from "../../libs/saga/dataSaga";
import { FxDateTimeView } from "../Input/FxDate/FxDateView";
import { FxCurrencyView } from "../Input/FxCurrency/FxCurrencyView";
import { FxSSNView } from "../Input/FxSSN/FxSSNView";
import { FxAccountNumberView } from "../Input/FxAccountNumber/FxAccountNumberView";
import { FxStatus } from "../Input/FxStatus/FxStatus";
import { ReactComponent as LeftIcon } from '../../assets/svg/page-left.svg';
import { ReactComponent as RightIcon } from '../../assets/svg/page-right.svg';
import { idConvert } from "../../libs/utils/string";
import { FxCustomComponent } from "../Input/FxCurrency/FxCustomComponent";
import { DATA_STATE } from "../../libs/reducer/dataReducer";
import { FxPaymentModeStatus } from "../Input/FxPaymentModeStatus/FxPaymentModeStatus";
import { checkPermission } from "../Utils/CommonBaseClass";
import { FxAccessDenied } from "../Page/Index/Passport/FxAccessDenied";
import FxTextView from "../Input/FxText/FxTextView";
import FxMaterialSelect from "../Input/FxSelect/FxMaterialSelect";
import { FxAcceptedCurrencyView } from "../Input/FxAcceptedCurrency/FxAcceptedCurrencyView";
import FxNoGridDataImage from "./FxNoGridDataImage";

Logger.debug("FxDataGrid.tsx", "Fx Data Grid initializing")

const resultsPerPageData = [
    { label: 25, value: 25},
    { label: 50, value: 50},
    { label: 75, value: 75},
    { label: 100, value: 100}
]
interface DataGridProps {
  id?: string;
  source?: any;
  pagination?: any;
  pageSize?: any;
  pageNumber?: any;
  rowClick?: any;
  iconClick?: any;
  tableClass?: any;
  dataTransformer?: any;
  multiplePermission?: boolean;
  permissions?: any;
  data?: any;
  noResources?: boolean;
  title?: string;
  column?: any;
  noRowCursor?: any;
  isloading?: boolean;
  accessDeniedTitle?: string;
  resultPerPage?: any;
  randomKey?:any;
}

/**
 * Data grid component
 * to display data in table grid format
 */
export const DataGrid: React.FC<DataGridProps> =
  (props) => {
    const dispatch = useDispatch()
    const { register, setValue, control } = useForm();
    let context: any;
    ({ context, props } = RegisterComponent(props));

    let totalCount: any;
    let hasMore: any; //for timeline api
    
    //check api call completed or not
    const contextState = context?.data_state;
    const dataLoadStatus = (contextState && contextState === DATA_STATE.STATUS_DONE) ? true : (props.data ? true : false);
    let resultsPerPage = context?.source?.data?.pageSize ? context.source.data.pageSize : 25;
    const returnedCount: any = context?.data?.body?.returnedCount || context?.data?.body?.length;
    let pageSize: any = props.pageSize;

    const transformationFormatter = (data: any) => {
      try {
        return props.dataTransformer(data);
      } catch (e) {
        console.log(e)
      }
    }

    //rest the component on unmount.so that when redirect back page it will call the api again
    useEffect(() => () => {
      dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
    }, [])

    /**
    * to set permission
    */
    const permission = useMemo(() => {
      let flag: any = false;
      if (props?.multiplePermission) {
        props?.permissions?.forEach((item:any) => {
          if (!flag) {
             flag = checkPermission(item)
          }
          if (flag === true) {
            return flag
          }
        })
      } else {
        flag = checkPermission(props?.permissions)
      }
      return flag
    }, [])

    let rows: any;
    if (props.noResources) {
      rows = props.data ? props.data : props.dataTransformer ? transformationFormatter(context?.data?.body) : context?.data?.body;
    } else {
      rows = props.data ? props.data : props.dataTransformer ? transformationFormatter(context?.data?.body?.resources) : context?.data?.body?.resources;
    }

    if (context?.data?.body?.totalCount || context?.data?.body?.length) {
      totalCount = context?.data?.body?.totalCount || context?.data?.body?.length;
    }

    const updateNewPageSource = (pageSourceData: any) => {
      const pageSource: any = { ...context.source };
      pageSource.data = pageSourceData;
      const newProps = { ...props };
      newProps.source = pageSource;
      dispatch({ type: "DATA_API_PARAM_CHANGED", payload: newProps });
    }

    const nextPage = (event: any ) => {
      const newPage = parseInt(context?.source?.data?.pageNumber) + 1;
      const pageSourceData: any = { ...context.source.data };
      pageSourceData.pageNumber = newPage;
      updateNewPageSource(pageSourceData);
    }

    const prevPage = (event: any ) => {
      const newPage = parseInt(context?.source?.data?.pageNumber) - 1;
      const pageSourceData: any = { ...context.source.data };
      pageSourceData.pageNumber = newPage;
      updateNewPageSource(pageSourceData);
    }

    const handlePaginationChange = (event: any ) => {
      resultsPerPage = event.target.value;
      pageSize = resultsPerPage
      if (pageSize > totalCount) {
        pageSize = totalCount
      }
      const pageSourceData: any = { ...context.source.data };
      pageSourceData.pageSize = resultsPerPage;
      pageSourceData.pageNumber = 1;
      updateNewPageSource(pageSourceData);
    }

    const handleClick = (event: any, row: any) => {
      if (props?.rowClick) {
        props.rowClick(event, row);
      }
    };

    const handleIconClick = (event: any, row: any) => {
      if(props?.iconClick){
        props.iconClick(event, row);
      }
    };

    // checking the status of the left pagination button whether disabled or not
    const checkPaginationLeftButton = () => {
      if (parseInt(context?.source?.data?.pageNumber) <= 1) {
          return true;
      }
      return false;
    }

    // checking the status of the right pagination button whether disabled or not
    const checkPaginationRightButton = (totalCount: any, resultsPerPage: any) => {
      if ((parseInt(context?.source?.data?.pageNumber) > (totalCount / parseInt(resultsPerPage))) || ((parseInt(context?.source?.data?.pageNumber) >= (totalCount / parseInt(resultsPerPage))) && ((totalCount % parseInt(resultsPerPage)) === 0))) {
          return true;
      }
      return false;
    }

    return (
      <div>
        {rows && dataLoadStatus && permission ?
          (<Paper >
            <TableContainer className={"fx-grid-container"}>
              <Table
                className={clsx('fx-table-generic', props.tableClass)}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
                stickyHeader
              >
                <TableHead>
                  <TableRow >
                    {props?.column?.columns?.map((item: any) => {
                      if (item.enable && item.default) {
                        return <TableCell id={idConvert(props.id) + '-' + item.fieldName} style={item.style ? item.style : {}}>{item.label}</TableCell>
                      }
                      return null;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((item: any, index: number) => (
                    /* eslint-disable no-extra-boolean-cast */
                    <TableRow key={!!props?.randomKey ? Math.random() + item.id : item.id} onClick={(event) => handleClick(event, item)} className={clsx(props?.rowClick && !props.noRowCursor? "fx-cursor-pointer" : "")}
                    >
                      {props && props.column && props.column.columns && props.column.columns.map((dataColumn: any) => {
                        if (dataColumn.type === 'fri://fieldtype/date') {
                          return dataColumn.enable && dataColumn.default && <TableCell id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName}><FxDateTimeView tooltip={true} id={dataColumn.fieldName + '-' + index} value={item[dataColumn.fieldName]} format={dataColumn.format ? dataColumn.format : "MMM DD, yyyy"}></FxDateTimeView></TableCell>
                        }
                        else if (dataColumn.type === 'fri://fieldtype/currency') {
                          return dataColumn.enable && dataColumn.default && <TableCell style={dataColumn.dataStyle ? dataColumn.dataStyle : {}} id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName}><FxCurrencyView tooltip={true} id={dataColumn.fieldName + '-' + index} value={item[dataColumn.fieldName]} prefix={dataColumn.prefix ? dataColumn.prefix : "$"}></FxCurrencyView></TableCell>
                        }
                        else if (dataColumn.type === 'fri://fieldtype/custom') {
                          return dataColumn.enable && dataColumn.default && <TableCell style={dataColumn.dataStyle ? dataColumn.dataStyle : {}} id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName}><FxCustomComponent id={dataColumn.fieldName + '-' + index} className={dataColumn.className ? dataColumn.className : item.className}>{item[dataColumn.fieldName]}</FxCustomComponent></TableCell>
                        }
                        else if (dataColumn.type === 'fri://fieldtype/paymentmodestatus') {
                          return dataColumn.enable && dataColumn.default && <TableCell style={dataColumn.dataStyle ? dataColumn.dataStyle : {}} id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName}><FxPaymentModeStatus tooltip={true} id={dataColumn.fieldName + '-' + index} value={item[dataColumn.fieldName]} noColor={item[dataColumn.color]?item[dataColumn.color]:true} fill={false} className="fx-status"></FxPaymentModeStatus></TableCell>
                        }
                        else if (dataColumn.type === 'fri://fieldtype/ssn') {
                          return dataColumn.enable && dataColumn.default && <TableCell id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName}><FxSSNView tooltip={true} id={dataColumn.fieldName + '-' + index} value={item[dataColumn.fieldName]} unMask={dataColumn.unMask ? dataColumn.unMask : false}></FxSSNView></TableCell>
                        }
                        else if (dataColumn.type === 'fri://fieldtype/accountnumber') {
                          return dataColumn.enable && dataColumn.default && <TableCell id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName}><FxAccountNumberView tooltip={true} id={dataColumn.fieldName + '-' + index} value={item[dataColumn.fieldName]} unMask={dataColumn.unMask ? dataColumn.unMask : false}></FxAccountNumberView></TableCell>
                        }
                        else if (dataColumn.type === 'fri://fieldtype/acceptedCurrency') {
                          return dataColumn.enable && dataColumn.default && <TableCell id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName}><FxAcceptedCurrencyView tooltip={true} id={dataColumn.fieldName + '-' + index} value={item[dataColumn.fieldName]} ></FxAcceptedCurrencyView></TableCell>
                        }
                        else if (dataColumn.type === 'fri://fieldtype/status') {
                          return dataColumn.enable && dataColumn.default && <TableCell style={dataColumn.dataStyle ? dataColumn.dataStyle : {}} id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName}><FxStatus tooltip={true} id={dataColumn.fieldName + '-' + index} value={item[dataColumn.fieldName]} fill={true} className="fx-status"></FxStatus></TableCell>
                        }
                        else if (dataColumn.type === 'fri://fieldtype/image') {
                          if (dataColumn.onIconClick) {
                            return dataColumn.enable && dataColumn.default && <TableCell id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName} onClick={(event) => handleIconClick(event, item)} className={dataColumn.className ? dataColumn.className: false} >{<span>{getIcon(dataColumn.icon ? dataColumn.icon : item.icon)}</span>}</TableCell>
                          } else {
                            return dataColumn.enable && dataColumn.default && <TableCell id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName} className={dataColumn.className ? dataColumn.className : false} >{<span>{getIcon(dataColumn.icon ? dataColumn.icon : item.icon)}</span>}</TableCell>
                          }
                        } 
                        else if (dataColumn.type === 'fri://fieldtype/button') {
                          return dataColumn.enable && dataColumn.default && <TableCell id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName}><FxButton id={dataColumn.fieldName + '-' + index} title={dataColumn.title} className="fx-button fx-button-passport-border fx-svg-theme" variant="contained" >{dataColumn.title}</FxButton></TableCell>
                        }
                        else {
                          return dataColumn.enable && dataColumn.default && <TableCell id={idConvert(props.id) + '-' + item.id + '-' + dataColumn.fieldName}><FxTextView id={dataColumn.fieldName + '-' + index} value={item[dataColumn.fieldName]} tooltip={true} truncateChars={dataColumn.truncateChars} valueTransformer={dataColumn.valueTransformer}></FxTextView></TableCell>
                        }
                      })}
                    </TableRow>
                  ))}
                  {(rows && !rows.length &&
                    <TableCell colSpan={props.column.columns.length} className="fx-no-data" > 
                      <Grid item container justifyContent={'center'}>
                        <Grid item>
                          <FxNoGridDataImage /> 
                          <Typography>No data to show</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  )}
                </TableBody>
              </Table>
              {(Array.isArray(rows) && rows !== undefined && rows.length > 0) && props.pagination && <table className="fx-table-pagination">
                <TableBody>
                  <TableRow>
                    <TableCell>
                     <FxMaterialSelect register={{ ...register('resultsPerPage') }} className={"fx-pagination-select"} value={resultsPerPage} control={control} onChange={handlePaginationChange} id={'resultsPerPage'} name={'resultsPerPage'} setValue={setValue} data={resultsPerPageData} />
                      &nbsp; <span className="pagination-text">Results per page</span>
                    </TableCell>
                    <TableCell align="right" >
                      <Grid item container justifyContent="flex-end" alignItems="center">
                        <Grid item className="page-count-label">{((parseInt(context?.source?.data?.pageNumber) - 1) * resultsPerPage) + 1} to {parseInt(returnedCount) < parseInt(resultsPerPage) ? ((parseInt(context?.source?.data?.pageNumber) - 1) * resultsPerPage) + parseInt(returnedCount) : parseInt(context?.source?.data?.pageNumber) * parseInt(resultsPerPage)} of {hasMore === undefined ? totalCount : hasMore === 1 ? "Many" : rows.length} &nbsp;&nbsp; </Grid>
                        <Grid item>
                          <FxButton
                            id="btn-loadprev" onClick={prevPage}
                            className={checkPaginationLeftButton() ? "fx-button fx-pagination-button fx-button-disabled" : "fx-button fx-pagination-button"}
                            disabled={checkPaginationLeftButton()? true: false}>
                            {props.isloading ? (
                              <CircularProgress
                                size={20}
                                style={{
                                  color: 'white',
                                }}
                              />
                            ) : (
                                <LeftIcon />
                              )}</FxButton>
                          <FxButton
                            id="btn-loadmore" onClick={nextPage}
                            className={checkPaginationRightButton(totalCount, resultsPerPage) ? "fx-button  fx-pagination-button fx-button-disabled" : "fx-button  fx-pagination-button"}
                            disabled={checkPaginationRightButton(totalCount, resultsPerPage)? true: false}>
                            {props.isloading ? (
                              <CircularProgress
                                size={20}
                                style={{
                                  color: 'white',
                                }}
                              />
                            ) : (
                                <RightIcon />
                            )}
                          </FxButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </table>}
            </TableContainer>
          </Paper >) : !permission ? <Paper>
            <TableContainer className={"fx-grid-container"}>
              <FxAccessDenied id={props?.id + "-access-denied"} title={props?.accessDeniedTitle ? props?.accessDeniedTitle : 'You are not authorized to access this page'} />
            </TableContainer>
          </Paper> : (
            <Paper>
              <TableContainer className={"fx-grid-container"}>
                <FxSkeltonList width="100%" height="12.5rem" />
              </TableContainer>
            </Paper>
          )}
      </div >
    );
  }

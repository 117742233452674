/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint react-hooks/exhaustive-deps: "off" */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from 'react'; // we need this to make JSX compile
import { Grid, Typography } from '@mui/material';
import { ReactComponent as AddIcon } from '../../../assets/svg/add-new-icon.svg';
import { Logger } from '../../../libs/utils/logger';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FxButton } from '../../Action/FxButton';
import { DataGrid } from '../../Data/DataGrid';
import { useMediaQuery } from 'react-responsive';
import { maskAccountNumber, truncateChars } from '../../Utils/CommonBaseClass';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { FxGridList } from '../../Data/FxGridList';
import moment from 'moment';
Logger.debug("InternationalExternalAccountCard.tsx", "External Account Card initializing");
/**
 * Component: International External Account Card Info Card
 * Usage: To display EIA information
 */

interface RouteParams {
    id: string
}

export const InternationalExternalAccountCard: React.FC<any> = React.memo((props) => {
    const dispatch = useDispatch();
    let cur_date: any = '';
    const history = useHistory();
    const params = useParams<RouteParams>();
    const isMobile = useMediaQuery({ query: '(max-width: 999px)' });
    const contactId = params.id;
    const internationalExternalAccountData = props?.data?.internationalExternalAccount || [];
    const editPermission = {
        entity: "Other Entities",
        name: "Contact",
        operation: "EDIT"
    };
    const column: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "label": "HOLDER NAME",
                "enable": true,
                "fieldName": "holderName",
                "type": "fri://fieldtype/acceptedCurrency",
                "default": true
            },
            {
                "label": "STATUS",
                "enable": true,
                "fieldName": "status",
                "type": "fri://fieldtype/status",
                "default": true
            },
            {
                "label": "ACCOUNT NUMBER",
                "enable": true,
                "fieldName": "accountNumberLast4",
                "type": "fri://fieldtype/default",
                "default": true
            },

            {
                "label": "Last Updated On",
                "enable": true,
                "fieldName": "lastUpdatedOn",
                "type": "fri://fieldtype/date",
                "default": true
            },
            {
                "label": "Last Updated By",
                "enable": true,
                "fieldName": "lastUpdatedBy",
                "type": "fri://fieldtype/default",
                "default": true
            },
        ]
    };

    // To get the mobile grid columns.
    const gridColumn: any = {
        "title": "Select Columns to Show",
        "columns": [
            {
                "enable": true,
                "fieldName": "divider",
                "type": "fri://fieldtype/divider",
                "xs": "12",
            },
            {
                "enable": true,
                "fieldName": "header",
                "type": "fri://fieldtype/header",
                "xs": "12"
            },
            {
                "enable": true,
                "fieldName": "description",
                "type": "fri://fieldtype/custom",
                "xs": "12",
                "alignItems": 'center'
            },
        ]
      }

    /**
     * rest the component on unmount, so that when redirect back page it will call the api again
     */
    useEffect(() => () => {
        dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: props.id } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
      *  To generate the mobile row.
      * @param item :row item object
     */
      const generateMobileRow = (item: any) => {
        let title: any;
        if (cur_date === '' || cur_date !== moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')) {
            cur_date = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
            title = moment(new Date(item?.lastUpdatedOn)).format('MMM DD, YYYY')
        }
        return {
            header: (
                title ? <Grid item className='fx-grid-list fx-padding-top-header'><Typography variant='h6'>{title}</Typography></Grid> : <></>
            ),
            description: (
                <Grid item container xs={12} className='fx-grid-list-content'>
                    <Grid item container xs={12} alignItems='center'>
                        <Grid item container xs={12}>
                            <Grid item>                                            
                                <Grid className='fx-info-label-value'>
                                    <Typography >ID: {item?.id}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>                                            
                                <Grid className='fx-info-label-value'>
                                    <Typography>{maskAccountNumber(item?.accountNumberLast4, false)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container xs>
                                <Grid item xs>
                                    <Grid item container justifyContent="flex-end">
                                        <FxStatus id="international-external-account-status" value={item?.status} fill={true} className="fx-status"></FxStatus>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item className='fx-info-label-value'>
                                <Typography>{truncateChars(item?.holderName, 25)}</Typography>
                            </Grid>                                                  
                        </Grid>
                    </Grid>
                </Grid> 
            ),
            divider: (
                !title ? true : false
            ),  
        };
    }

    /**
     *  To generate the desktop row.
     * @param item :row item object
    */
    const generateDesktopRow = (item: any) => {
        return {
            actions: <FxButton variant="contained" className="fx-button fx-button-passport-border" id="payee-eia-view-button" onClick={() => history.push(`/payee/id/${contactId}internationalExternalAccount/view/${item?.id}`, { contact: { name: props?.data?.name, id: props?.data?.id } })}> View </FxButton>,
            holderName: item?.holderName,
            status: item?.status,
            country: item?.holderAddress?.country,
            accountNumberLast4: '**' + item?.accountNumberLast4,
            id: item?.id,
            acceptedCurrency: item?.acceptedCurrency,
            lastUpdatedOn: item?.lastUpdatedOn,
            lastUpdatedBy: item?.lastUpdatedBy?.username
        };
    }

    /**
     *  To get the list data as per device.
     */
    const internationalExternalAccountList = useMemo(() => {
        if(isMobile){
            return internationalExternalAccountData.map((item: any, i: any) => {   
                return generateMobileRow(item);                    
            })           
        }else{
            return internationalExternalAccountData.map((item: any) => {
                return generateDesktopRow(item);   
            });
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile, internationalExternalAccountData])   

    /**
     * Method to redirect to the create international external account
     */
    function redirectToCreatePage() {
        const holderName = props?.data?.internationalExternalAccount?.holderName;
        history.push('/payee/internationalExternalAccount/add/' + contactId, { contact: { name: props?.data?.name, id: props?.data?.id, holderName: holderName } });
    }

    /**
     * Method to redirect to the international external account details page
     * @param event :event object
     * @param data : international external account data
     */
    function redirectDetailsPage(event: any, data: any) {
        history.push(`/payee/id/${contactId}/internationalExternalAccount/view/${data.id}`, { contact: { id: contactId } });
    }

    return (
        <>
            <Grid id="payees-eia-info-Grid " className="fx-margin-bottom-16" item xs={12}  >
                <FxCard id="payees-eia-info-card" className="fx-theme-passport">
                    <FxCardHeader id={'payees-eia-info-card#card-header'}>
                        <Grid id="payees-eia-Grid2" item container justifyContent="space-between" xs={12} >
                            <Grid id="payees-eia-Grid3"  >
                                <Typography id="title-payees-eia-info" className='fx-full-width' variant="h4" >International External Account Details </Typography>
                                <div id="div-grow-title-payees-eia-list" className="fx-flex-grow" />
                            </Grid>
                            <Grid id="filter-Grid" item container className="fx-view-action fx-inline-flex" >
                                <Grid item><FxButton id="payees-eia-add-button" permissions={editPermission} variant="contained" className="fx-button fx-button-passport-border fx-svg-theme" onClick={redirectToCreatePage} startIcon={<AddIcon />} > Add</FxButton></Grid>
                            </Grid>
                        </Grid>
                    </FxCardHeader>
                    <FxCardBody id="home-recent-eia-card-body" className="fx-card-general">
                       {!isMobile ? <DataGrid id="eia-home-recent-transactions-card-list" rowClick={redirectDetailsPage} tableClass="fx-table-passport" data={internationalExternalAccountList} column={column} />
                        : <FxGridList id="eia-home-recent-transactions-card-list" rowClick={redirectDetailsPage} data={internationalExternalAccountList} column={gridColumn} />
                       } 
                    </FxCardBody>
                </FxCard>
            </Grid >
        </>
    )
})

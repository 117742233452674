/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { Grid, Popover, PopoverProps, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useMediaQuery } from 'react-responsive'
import { RegisterComponent } from "../../libs/saga/dataSaga";
import { ReactComponent as FilterIcon } from '../../assets/svg/filter-icon.svg';
import { IDataGridProps, IFilterFields } from '../../types/common.interfaces';
import FxSelect from "../Input/FxSelect/FxSelect";
import FxRadioEdit from "../Input/FxRadio/FxRadioEdit";
import { FxTextEdit } from "../Input/FxText/FxTextEdit";
import FxCheckEdit from "../Input/FxCheck/FxCheckEdit";
import { ReactComponent as ResetIcon } from '../../assets/svg/reset-icon.svg';
import { FxButton } from "../Action/FxButton";
import { FxDateEdit } from "../Input/FxDate/FxDateEdit";
import { checkPermission } from "../Utils/CommonBaseClass";
import FxPhoneEditWithoutCountryCode from "../Input/FxPhone/FxPhoneEditWithoutCountryCode";

interface FilterProps extends Partial<PopoverProps>{
    id?: string;
    field: IFilterFields;
    dataGridProps: IDataGridProps;
    hiddenParameter?: string[];
    permissions?: any;
    multiplePermission?: any;
    transformFilter?: any;
    resetFilter?: boolean;
}

/**
 * filter component using for data advanced filter search
 * @param props :any
 */

export const Filter: React.FC<FilterProps> =
    (props) => {

        const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
        ({ props } = RegisterComponent(props));
        const { resetField, setValue, control } = useForm();

        const dispatch = useDispatch()
        //popover
        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        /**
         * Method will reset the filter when the resetFilter parameter is true
         */
        useEffect(() => {
            if(props.resetFilter){
                handleReset();
            }
        }, [props.resetFilter])

        const handleClose = () => {
            setAnchorEl(null);
        };

        /**
        * to set permission
        */
        const permission = useMemo(() => {
            let flag: any = false;
            if(props?.multiplePermission){
                 props?.permissions.forEach((item:any)=>{
                    if (checkPermission(item)) {
                        flag = true
                       return true
                    }
                })
              }
              else{
                flag = checkPermission(props?.permissions)
              }
            return flag
        }, [])

        const open = Boolean(anchorEl);
        const popoverId = open ? 'simple-popover' : undefined;

        let defFilterData: any = {};
        let defCheckOptionsData: any = {};

        /**
         * Render fields
         */
        props?.field?.fields?.forEach((item: any, index: number) => {
            if (item.type === 'fri://fieldtype/daterange') {
                if (item['defaultValue']) {
                    defFilterData[item.fieldName + '#from'] = item['defaultValue']['from'] ? formatDate(item['defaultValue']['from']) : "";
                    defFilterData[item.fieldName + '#to'] = item['defaultValue']['to'] ? formatDate(item['defaultValue']['to']) : "";
                }
                else {
                    defFilterData[item.fieldName + '#from'] = ""
                    defFilterData[item.fieldName + '#to'] = ""
                }
            }
            else if (item.type === 'fri://fieldtype/amountrange') {
                defFilterData[item.fieldName + '#fromAmount'] = ""
                defFilterData[item.fieldName + '#toAmount'] = ""
            }
            else if (item.type === 'fri://fieldtype/checkbox') {
                defFilterData[item.fieldName] = [""];
                let checkData = [...item.data];
                checkData?.map((checkItem: any, index: number) => {
                    let temp = { ...checkItem }
                    if (!checkItem['checked']) {
                        temp['checked'] = false;
                    }
                    return temp;
                });
                defCheckOptionsData[item.fieldName] = checkData;
            }
            else {
                defFilterData[item.fieldName] = "";
            }
        });
        const [filterData, setFilterData] = useState(defFilterData);
        const [checkOptionsData, setCheckOptionsData] = useState(defCheckOptionsData);
        const [defData, setDefData] = useState(false);
        const componentState = useSelector((state: any) => {
            if (state.data[props.dataGridProps.id]) {
                return state.data[props.dataGridProps.id]
            } else {
                return { config: { params: {} } };
            }
        });

        if (!defData) {
            if (componentState && componentState.source && componentState.source.data && componentState.source.data && defFilterData) {
                componentState.source.data?.criteria?.filters?.forEach((filter: any, i: number) => {
                    if (filter.operator === 'gte') {
                        if (String(filter.values).replace(/[]/gi, "").includes("/")) {
                            defFilterData[filter.key + '#from'] = String(filter.values).replace(/[]/gi, "").replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
                        }
                        else {
                            defFilterData[filter.key + '#fromAmount'] = String(filter.values).replace(/[]/gi, "");
                        }
                    }
                    else if (filter.operator === 'lte') {
                        if (String(filter.values).replace(/[]/gi, "").includes("/")) {
                            defFilterData[filter.key + '#to'] = String(filter.values).replace(/[]/gi, "").replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
                        }
                        else {
                            defFilterData[filter.key + '#toAmount'] = String(filter.values).replace(/[]/gi, "");
                        }
                    }
                    else if (filter.operator === 'in') {
                        defFilterData[filter.key] = filter.values;
                        if(checkOptionsData && checkOptionsData[filter.key]){
                            let defOptions = [...checkOptionsData[filter.key]];
                            defOptions?.forEach((option: any, i: number) => {
                                for (let property in filter.values) {
                                    if (filter.values[property] === option.value) {
                                        option.checked = true;
                                    }
                                }
                            });
                            defCheckOptionsData[filter.key] = defOptions;
                        }
                        else{
                            defFilterData[filter.key] = filter.values;
                        }

                    }
                    else if (filter.operator === 'eq') {
                        defFilterData[filter.key] = String(filter.values).replace(/[]/gi, "");
                    }
                });
                if (defCheckOptionsData) {
                    setCheckOptionsData(defCheckOptionsData);
                }
                setFilterData(defFilterData);
                setDefData(true);
            }
        }

        const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement> | any, name: any) => {
            setFilterData({
                ...filterData,
                [name]: event?.value
            });
        }

        function formatDate(date: any) {
            let d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }

        /**
         * reset form
         */
        const handleReset = () => {
            let defFilterData: any = {};
            let defCheckOptionsData: any = {};
            props?.field?.fields?.forEach((item: any, index: number) => {
                if (item.type === 'fri://fieldtype/daterange') {
                    if (item['defaultValue']) {
                        defFilterData[item.fieldName + '#from'] = item['defaultValue']['from'] ? formatDate(item['defaultValue']['from']) : "";
                        defFilterData[item.fieldName + '#to'] = item['defaultValue']['to'] ? formatDate(item['defaultValue']['to']) : "";
                    }
                    else {
                        defFilterData[item.fieldName + '#from'] = ""
                        defFilterData[item.fieldName + '#to'] = ""
                    }

                }
                else if (item.type === 'fri://fieldtype/amountrange') {
                    defFilterData[item.fieldName + '#fromAmount'] = ""
                    defFilterData[item.fieldName + '#toAmount'] = ""
                }
                else if (item.type === 'fri://fieldtype/checkbox') {
                    let checkData = [...item.data];
                    if (item['defaultValue']) {
                        defFilterData[item.fieldName] = item['defaultValue'];
                        checkData?.forEach((checkItem: any) => {
                            item['defaultValue']?.forEach((defaultItem: any) => {
                                if (defaultItem === checkItem.value) {
                                    checkItem.checked = true;
                                }
                            })
                            if (!checkItem['checked']) {
                                checkItem['checked'] = false;
                            }
                        });
                    }
                    else {
                        defFilterData[item.fieldName] = [""];
                        checkData?.forEach((checkItem: any) => {
                            checkItem.checked = false;
                        });
                    }
                    defCheckOptionsData[item.fieldName] = checkData;
                }
                else {
                    if (item['defaultValue']) {
                        defFilterData[item.fieldName] = item['defaultValue'];
                    }
                    else {
                        defFilterData[item.fieldName] = "";
                    }

                }
            });
            // looping the check options for resetting the checkbox data
            const tempCheckOptionsData: any = {...checkOptionsData};
            for (let property in tempCheckOptionsData) {
                let defaultValue: any = [];
                // checking any default value
                props.field.fields?.forEach((checkItem: any, count: number)=>{
                    if(checkItem.fieldName === property && checkItem.defaultValue && checkItem.defaultValue.length>0){
                        defaultValue = checkItem.defaultValue;
                    }
                });
                tempCheckOptionsData[property]?.forEach((item: any, index: number) => {
                    if (item['checked'] && !defaultValue.includes(item['value'])) {
                        item['checked'] = false;
                    }
                })
            }
            setCheckOptionsData(tempCheckOptionsData)
            setFilterData(defFilterData);
        };



        /** onchange handler */
        const handleChange = (event: React.ChangeEvent<HTMLInputElement> | any,name:any) => {
            if (event.target?.type === 'checkbox') {
                const checkState = [...filterData[event.target.name]];
                const checkNewState: any = [];
                let checkFlag: boolean = false;
                const elementsIndex = checkOptionsData[event.target.name].findIndex((element: { value: string; }) => element.value === event.target.value)
                const newArray = [...checkOptionsData[event.target.name]]
                newArray[elementsIndex] = { ...newArray[elementsIndex], checked: event.target.checked }
                setCheckOptionsData({
                    ...checkOptionsData,
                    [event.target.name]: newArray
                });
                checkState?.forEach((option: any, i: number) => {
                    if ((option !== event.target.value) || (option === event.target.value && event.target.checked)) {
                        checkNewState.push(option)
                    }
                    if (option === event.target.value) {
                        checkFlag = true;
                    }
                });
                if (!checkFlag) {
                    checkNewState.push(event.target.value)
                    setFilterData({
                        ...filterData,
                        [event.target.name]: checkNewState
                    });
                }
                setFilterData({
                    ...filterData,
                    [event.target.name]: checkNewState
                });
            }
            //for setting filter values in date field component
            else if(!event?.target && name){
                setFilterData({
                    ...filterData,
                    [name]: event
                });
            }
            else {
                setFilterData({
                    ...filterData,
                    [event.target?.name]: event.target?.value
                });
            }
        };

        const valueFormatter = (selectOption: any, options: any) => {
            return options.filter((option: any) => selectOption === option.value).map((option:any)=>{
                return { label: option.label, value: option.value }
            })
        }

       /**
        * Method to clean phone number which is accepted by API
        * @param phone : Phone number with brackets
        * @returns Phone number with no brackets
        */
        const formatPhoneForAPIRequest = (phone:any) =>{
            if(phone){
                return phone.replace(/[()/]/gi, '');
            }
            return  ;
        }


        /**
         *
         * Apply filter and update data data grid data
         * based on the applied filters
         */
        const applyFilter = (event: any) => {
            const filters: any = [];
            const componentsStateFilters = componentState?.source?.data?.criteria?.filters || [];
            const filterAccData: any = [...componentsStateFilters];
            if (props.hiddenParameter) {
                props.hiddenParameter.forEach((hidPar:any)=>{
                    let filteredValue = filterAccData?.find((filter: any) => filter.key === hidPar);
                    if (filteredValue) {
                        filters.push({
                            "operator": filteredValue.operator || 'eq',
                            "key": hidPar,
                            "values": filteredValue.values
                        })
                    }
                });
            }
            setTimeout(() => {
                for (const property in filterData) {
                    if (filterData[property] && Array.isArray(filterData[property])) {
                        if (filterData[property].filter(Boolean).length > 0) {
                            filters.push(
                                {
                                    "operator": "in",
                                    "key": property,
                                    "values": filterData[property].filter(Boolean)
                                })
                        }
                    }
                    else if (filterData[property] && property.includes("#fromAmount")) {
                        filters.push(
                            {
                                "operator": "gte",
                                "key": property.replace(/#fromAmount/gi, ""),
                                "values": [
                                    filterData[property]
                                ]
                            })
                    }

                    else if (filterData[property] && property.includes("mobilePhone") && filterData[property]['formattedValue']) {
                        filters.push(
                            {
                                "operator": "eq",
                                "key": property,
                                "values": [
                                    filterData[property] && filterData[property]['formattedValue'] && formatPhoneForAPIRequest(filterData[property]['formattedValue'])
                                ]
                            })
                    }
                    else if (filterData[property] && property.includes("#toAmount")) {
                        filters.push(
                            {
                                "operator": "lte",
                                "key": property.replace(/#toAmount/gi, ""),
                                "values": [
                                    filterData[property]
                                ]
                            })
                    }
                    else if (filterData[property] && property.includes("#from") && filterData[property].indexOf("Invalid date")===-1) {
                        filters.push(
                            {
                                "operator": "gte",
                                "key": property.replace(/#from/gi, ""),
                                "values": [
                                    moment(filterData[property]).format('MM/DD/YYYY')
                                ]
                            })
                    }
                    else if (filterData[property] && property.includes("#to") && filterData[property].indexOf("Invalid date")===-1) {
                        filters.push(
                            {
                                "operator": "lte",
                                "key": property.replace(/#to/gi, ""),
                                "values": [
                                    moment(filterData[property]).format('MM/DD/YYYY')
                                ]
                            })
                    }
                    else if (typeof filterData[property] != "object" && filterData[property] && !props.hiddenParameter?.includes(property) && filterData[property].indexOf("Invalid date")===-1) {
                        filters.push(
                            {
                                "operator": "eq",
                                "key": property,
                                "values": [
                                    filterData[property]
                                ]
                            })
                    }
                }

                const filterSourceDataCriteria: any = { ...componentState?.source?.data?.criteria }
                filterSourceDataCriteria.filters = filters;
                const filterSourceData: any = { ...componentState?.source?.data }
                filterSourceData.criteria = filterSourceDataCriteria;
                filterSourceData.pageNumber = 1; //reset page number
                let filterSource: any = { ...componentState?.source };
                filterSource.data = filterSourceData;
                if(props.transformFilter){
                    filterSource = props.transformFilter(filterSource)
                }
                const newProps: any = { id: props.dataGridProps?.id, source: filterSource, column: props.dataGridProps?.column, pagination: props.dataGridProps?.pagination, pageNumber: props.dataGridProps?.pageNumber, pageSize: props.dataGridProps?.pageSize };
                dispatch({ type: "DATA_API_PARAM_CHANGED", payload: newProps });
                handleClose();
            }, 1);


        }

        /**
         * component UI
         */

        return (
            <>
                {permission ? <>
                    <FxButton id={props.id} variant="contained" className="fx-button fx-button-filter" startIcon={<FilterIcon />} onClick={handleClick}>{isMobile ? '' : 'Filters'}</FxButton>
                    <Popover
                        id={popoverId}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        className="fx-filter-popover"
                        anchorOrigin={
                            props.anchorOrigin ? props.anchorOrigin : {
                                horizontal: 'left',
                                vertical: 'bottom',
                            }}
                        transformOrigin={
                            props.transformOrigin ? props.transformOrigin : {
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        PaperProps={{
                            style: { width: isMobile ? '90%' : '40rem' }
                        }}
                    >
                        <Grid id="filter-popover-Grid" item container xs={12} className="fx-filter-popover-content" >
                            {props?.field?.fields?.map((item: any, index: number) => (
                                <Grid item container xs={12} className={item.className ? item.className : "filter-divider"}>
                                    <Grid item xs={isMobile ? 12 : undefined} >
                                        <Typography className="filter-popover-label">{item.label}</Typography>
                                    </Grid>
                                    {item.type === 'fri://fieldtype/checkbox' && <Grid item xs>
                                        <FxCheckEdit className={'check-edit'} name={item.fieldName} control={control} id={item.fieldName} data={checkOptionsData[item.fieldName]} onChange={handleChange} />
                                    </Grid>}
                                    {item.type === 'fri://fieldtype/daterange' && <Grid container item justifyContent="flex-start" alignItems="center" xs>
                                        <FxDateEdit control={control} className="fx-input-edit" id={item.fieldName + '#from'} name={item.fieldName + '#from'} onChange={handleChange} value={filterData[item.fieldName + '#from']} maxDate={filterData[item.fieldName + '#to']} disableFuture={item.notAllowFutureDate ? item.notAllowFutureDate : false} setValue={setValue} resetField={resetField} />
                                        <Typography className="filter-popover-label-second">&nbsp;</Typography>
                                        <FxDateEdit control={control} className="fx-input-edit" id={item.fieldName + '#to'} name={item.fieldName + '#to'} onChange={handleChange} value={filterData[item.fieldName + '#to']} minDate={filterData[item.fieldName + '#from']} disableFuture={item.notAllowFutureDate ? item.notAllowFutureDate : false} setValue={setValue} resetField={resetField} />
                                    </Grid>

                                    }
                                    {item.type === 'fri://fieldtype/amountrange' && <Grid container item justifyContent="flex-start" alignItems="center" xs>
                                        <FxTextEdit type="text" control={control} className="fx-input-edit" id={item.fieldName + '#fromAmount'} name={item.fieldName + '#fromAmount'} onChange={handleChange} value={filterData[item.fieldName + '#fromAmount']} prefix="$" />
                                        <Typography className="filter-popover-label-second">&nbsp;</Typography>
                                        <FxTextEdit type="text" control={control} className="fx-input-edit" id={item.fieldName + '#toAmount'} name={item.fieldName + '#toAmount'} onChange={handleChange} value={filterData[item.fieldName + '#toAmount']} prefix="$" />
                                    </Grid>

                                    }
                                    {item.type === 'fri://fieldtype/radio' && <Grid item xs>
                                        <FxRadioEdit className={'radio-edit'} name={item.fieldName} control={control} id={item.fieldName} data={item.data} onChange={handleChange} value={filterData[item.fieldName]} />
                                    </Grid>
                                    }
                                    {item.type === 'fri://fieldtype/select' && <Grid item xs >
                                        <FxSelect id={item.fieldName} name={item.fieldName}
                                            data={item?.data} className="fx-dropdown-input-edit" onChange={(e: any) => handleChangeSelect(e, item.fieldName)} resetEnable={true} value={valueFormatter(filterData[item.fieldName], item?.data)} />
                                    </Grid>
                                    }
                                    {item.type === 'fri://fieldtype/textfield' && <Grid item xs >
                                        <FxTextEdit type="text" control={control} className="fx-input-edit" id={item.fieldName} name={item.fieldName} onChange={handleChange} value={filterData[item.fieldName]} />
                                    </Grid>
                                    }
                                    {item.type === 'fri://fieldtype/hidden' && <Grid item xs >
                                        <FxTextEdit type="hidden" control={control} className="fx-hidden" id={item.fieldName} name={item.fieldName} onChange={handleChange} value={filterData[item.fieldName]} />
                                    </Grid>
                                    }
                                    {item.type === 'fri://fieldtype/phoneeditwithoutcountrycode' && <Grid item xs >
                                        <FxPhoneEditWithoutCountryCode control={control} className="fx-input-edit" id={item.fieldName} name={item.fieldName} onChange={(e: any) => handleChange(e, item.fieldName)} value={filterData[item.fieldName] && filterData[item.fieldName].value  ? filterData[item.fieldName].value : '' } />
                                    </Grid>
                                    }

                                </Grid>
                            ))}
                            <Grid container item justifyContent="space-between" alignItems="center" className="fx-popover-control-grid" xs={12} >
                                <Grid item>
                                    <FxButton className="fx-button popover-button-teritiary fx-button-reset" id={props.id + '-reset'} variant="contained" onClick={handleReset} startIcon={<ResetIcon />}>{isMobile ? '' : 'Reset All'}</FxButton>
                                </Grid>

                                <Grid item >
                                    <FxButton className="fx-button fx-button-cancel" variant="contained" id={props.id + '-cancel'} onClick={handleClose}>Cancel</FxButton>
                                    <span className="fx-padding-right-16" />
                                    <FxButton className="fx-button fx-button-theme" variant="contained" id={props.id + '-apply'} onClick={applyFilter}>Apply Filters</FxButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Popover>
                </> : <></>}
            </>
        );
    }

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
  } from "@mui/material";
import { toTitleCase, updateComponentParams } from '../../Utils/CommonBaseClass';
import { useDispatch } from 'react-redux';
import FxCheckEdit from '../FxCheck/FxCheckEdit';

/**
 * Componet used to draw FxCheckboxTree
 */
export const FxCheckboxTree: React.FC<any> = React.memo((props) => {
    ({ props } = RegisterComponent(props));
    const dispatch:any = useDispatch();

    /**
     * useEffect to be called at the time of component rendering
     */
    useEffect(()=>{
        entityNameData();
        eventNameData();
        updateComponentParams(dispatch,  props.id, { entityGridOpen: false, event_count: 0 })
        // eslint-disable  
    },[])

    /**
     * useEffect to handle the radio button action of either 'all_events' or 'select_events'
     */
    useEffect(()=>{ 
        if(props?.triggerType === 'all_events'){
            handleSubscribeAllEvents(true);
        }else{
            handleSubscribeAllEvents(false);
        }
    // eslint-disable
    },[props?.triggerType])

    

    /**
     * Method compares the EventList Data along with the API retrieved data to render the appropriate checkboxes
     * @param eventList 
     * @param data 
     * @returns 
     */
    function compareEvents(eventList:any, data:any) {
        const newList = [];
        const allEvents = data["eventName"];
      
        for (const event of allEvents) {
          newList.push({ label: event, value: event, checked: eventList.includes(event) });
          if(eventList.includes(event)){
            selectParentEntityCheckbox(true)
          }else{
            selectParentEntityCheckbox(false)
          }
        }

        return newList;
      }

      /**
       * Method recalculates the selected event count in case of edit
       * @param options 
       * @returns 
       */
      const calculateEventCount = (options:any) => {
        let count = 0;
        for (let i = 0; i < options.length; i++) {
            const item = options[i];
            if (item?.checked) {
                count++;
            }
        }
        return count;
      }

    /**
     * useEffect to set the api data into the component in case of edit
     */
    useEffect(()=>{
        if(props?.treeData.length > 0){
            const newOptions: any = compareEvents(props?.treeData, props?.data);
            const newCount:any = calculateEventCount(newOptions)
            updateComponentParams(dispatch,  props.id, { 'eventCheckboxData': newOptions, 'event_count': newCount })
            setNodeData(`webhook-events.${props?.index}.eventName`, newOptions );
        }
    // eslint-disable
    },[props?.treeData])


    /**
     * Method handles the selection of the parent entity checkbox when any one of its event checkbox is selected
     * @param checked 
     */
    const selectParentEntityCheckbox = (checked: boolean) => {
            if(checked || (!checked && (props?.event_count - 1) === 0)){
                const updatedData = props?.entityCheckboxData?.map((item:any) => {
                    return { ...item, checked: checked };
                });

                updateComponentParams(dispatch,  props.id, { 'entityCheckboxData': updatedData })
                setNodeData(`webhook-events.${props.index}.entityName`, updatedData );
            }
    }

    
    /**
     * Method handles the onChange event of the Event Checkbox
     * @param event 
     */
    const handleEventCheckboxChange = (event: any) => {
        if(event.target.value){
            let count = props?.event_count;
            const newArr = props?.eventCheckboxData?.map((item: any) => {
                if (item.value === event.target.value) {
                    if(event.target.checked){
                        count++;
                        updateComponentParams(dispatch,  props.id, { 'event_count': count })
                        selectParentEntityCheckbox(true)
                    }else{
                        count--;
                        updateComponentParams(dispatch,  props.id, { 'event_count': count })
                        selectParentEntityCheckbox(false)
                    }
                  return { ...item, checked: event.target.checked };
                }
                return item;
              });
            updateComponentParams(dispatch,  props.id, { 'eventCheckboxData': newArr })    
            setNodeData(`webhook-events.${props?.index}.eventName`, newArr);   
        }
    }



    /**
     *  Handles the open close of the accordians
     */
    const toggleAvsDetail = () => {
        updateComponentParams(dispatch,  props.id, { entityGridOpen: !props?.entityGridOpen })    
    }

    /**
     * Method constructs the dropdwon data for the Entity to be displayed in form of checkboxes
     */
    const entityNameData = () => {
        const dataArray:any = [{ label: '', value: props?.data?.entity, checked: false }];
        updateComponentParams(dispatch,  props.id, { 'entityCheckboxData': dataArray })    
    }

    /**
     * Method constructs the dropdwon data for the Events to be displayed in form of checkboxes
     */
    const eventNameData = () => {
        const dataArray: any = props?.data?.eventName?.map((data:any)=>{
            return { label: data, value: data, checked: false }
        })
        updateComponentParams(dispatch,  props.id, { 'eventCheckboxData': dataArray })    
    }

    

    /**
     * Method handles the onChange event of the EntityName Checkbox on clicking that entityName
     * @param event 
     * @param checkboxName 
     */
    const handleMyCheck = (event:any, checkboxName:any) => {
        if(event.target.checked){
            selectAllSubNodeCheckboxes(true);
        }else{
            selectAllSubNodeCheckboxes(false);
        }
    }

    /**
     * Method to set the values in the eventName/entityName array for form submission
     * @param name 
     * @param data 
     */
    const setNodeData = (name: any, data:any) => {
        const entityArray:any = data?.map((item: any)=>{
            return item?.checked ? item?.value : undefined;
        }).filter((value:any) => value !== undefined);
        props?.setValue(name, entityArray);
    }

    /**
     * Method handles the selecting/deselecting all logic of the checkboxes along with their event count
     * @param isChecked 
     */
    const selectAllSubNodeCheckboxes = (isChecked: boolean) => {

        const prevEntityName:any = props?.entityCheckboxData;
        const newEntityData:any = prevEntityName?.map((item:any)=>{
            const myitem:any = { ...item, checked: isChecked}
            return myitem;
        })
        

        let selected_event_count = 0;
        const newArr:any = props?.eventCheckboxData?.map((item:any)=>{
            const myitem:any = { ...item, checked: isChecked}
            if(isChecked)
                selected_event_count++;
            return myitem;
        })
        updateComponentParams(dispatch,  props.id, { 'entityCheckboxData': newEntityData ,'eventCheckboxData': newArr, 'event_count': selected_event_count })  
        setNodeData(`webhook-events.${props?.index}.eventName`, newArr);
        setNodeData(`webhook-events.${props.index}.entityName`, newEntityData);
        
    }

    /**
     * Method used to either subscribe to all_events or to select_events trigger point
     * @param doSubscribe 
     */
    const handleSubscribeAllEvents = ( doSubscribe: boolean ) => {

            if(!props?.entityCheckboxData || !props?.eventCheckboxData) return ;

            //setting the Entity Checkbox Value as true/false
            const entityNameDataArray:any = props?.entityCheckboxData

            const updatedData = entityNameDataArray?.map((item:any) => {
                return { ...item, checked: doSubscribe };
              });

            updateComponentParams(dispatch,  props.id, { 'entityCheckboxData': updatedData })
            setNodeData(`webhook-events.${props.index}.entityName`, updatedData );

            //setting the Event Checkbox Value as true/false
            selectAllSubNodeCheckboxes(doSubscribe);

    }


    return (
        <Grid container xs={12} sm={12} id={props.index} key={props.index}>
        <Grid item xs={12} sm={12}>
            <fieldset className="fx-container-create-schdule-summery fx-checkbox-tree">
                <Grid xs={12}>
                    <Accordion className="" expanded={props?.entityGridOpen}>
                        <AccordionSummary className='fx-checkbox-tree-title'>
                            <Grid item xs={12} sm={12} display={'flex'}>
                                <Grid>

                                    <FxCheckEdit 
                                        className={`fx-input-edit fx-permissions-checkbox ${props?.triggerType === 'all_events' ? 'disabled-check-box':''}`}
                                        disabled={ props?.triggerType === 'all_events' ? true : false } 
                                        id={`webhook-events.${props.index}.entityName`} 
                                        name={`webhook-events.${props.index}.entityName`} 
                                        data={ props?.entityCheckboxData }
                                        register={{ ...props?.register(`webhook-events.${props.index}.entityName`) }} 
                                        onChange={(event: any) => handleMyCheck(event, `webhook-events.${props.index}.entityName`)}
                                        control={props?.control} 
                                        setValue={props.setValue}
                                        updateOptions={true} 
                                    />

                                </Grid>
                                <Grid alignContent={'center'} onClick={toggleAvsDetail} className={'fx-checkbox-tree-title-label'}>
                                    {toTitleCase(props?.data?.entity)} ({props?.event_count}/{props?.data?.eventName?.length})
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container item xs={12} sm={12} marginLeft={'2.25rem'} className='fx-checkbox-tree-content' marginBottom={'1rem'} padding={0}>
                                <Grid item display={'flex'}>
                                <Grid padding={0} className={''}>

                                    <FxCheckEdit 
                                        className={`fx-input-edit ${props?.triggerType === 'all_events' ? 'disabled-check-box':''}`} 
                                        disabled={ props?.triggerType === 'all_events' ? true : false } 
                                        updateOptions={true} 
                                        onChange={handleEventCheckboxChange}
                                        register={{ ...props?.register(`webhook-events.${props?.index}.eventName`) }} 
                                        control={props?.control} 
                                        data={ props?.eventCheckboxData } 
                                        id={`webhook-events.${props?.index}.eventName`} 
                                        name={`webhook-events.${props?.index}.eventName`} 
                                        setValue={props?.setValue} 
                                    /> 

                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </fieldset>
        </Grid>
    </Grid>
    );
});

import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as TreasureBatch } from '../../../assets/svg/TreasureBanner.svg';
import { getKey } from '../../../libs/utils/storageManager';

/**
 * Component used inside the right drawer as a left layout 
 */
interface IFxBaseLayout {
    type?: string;
    layoutHeader: React.ReactElement;
}

export const FxTreasureStartedLayout: React.FC<IFxBaseLayout> = React.memo(({ type, layoutHeader }) => {
    return (
        <Grid container className={'fx-treasure-drawer-left-layout'}>
            <Grid item className='fx-treasure-left-layout-header-icon'>
                <Grid item xs={12} className="fx-treasure-passport-icon">
                    {layoutHeader}
                </Grid>

                <Grid className="fx-treasure-banner"><TreasureBatch /></Grid>
                <Grid item xs={12} >
                    <Typography className='fx-treasure-earn-text'>Earn up to {type === 'CASH_BUILDER_PLUS' ? getKey('cashBuilderPlusInterestRate') : getKey('cashBuilderInterestRate')} yield*.</Typography>
                </Grid>
                <Grid item xs={12} >
                    <Typography className='fx-treasure-banner-text'>Simple Pricing, No Hidden Charges</Typography>
                </Grid>
                <hr />
            </Grid>

        </Grid>
    )
})
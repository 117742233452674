/* eslint-disable  @typescript-eslint/no-explicit-any */
/**
 * Component used to display onboarding layout for co-owner
 */
import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ReactComponent as OnboardingImg } from '../../../../assets/svg/onboarding-image.svg';
import { ReactComponent as LogoutIcon } from '../../../../assets/svg/logout-icon.svg';
import { getKey } from '../../../../libs/utils/storageManager';
import { Logo } from '../../../Utils/Logo';
import { LogoAccount } from '../../../Utils/Logo';

export const CoOwnerOnboardingLayout: React.FC<any> = ({ children }) => {
    const history = useHistory();

    return (
        <Grid container className={'fx-partner-onboarding-layout'}>
            <Grid item xs={4} className={'fx-partner-onboarding-layout-left'}>
                <Grid item container xs={12} className={'fx-partner-onboarding-layout-left-header'}>
                    <Grid item className={'fx-logo-circle'}>
                        <LogoAccount blueIcon={true} />
                    </Grid>
                    <Grid item >
                        <Typography className={'fx-partner-onboarding-layout-left-welcome'}>Welcome!</Typography>
                        <Typography className={'fx-partner-onboarding-layout-left-username'}>{getKey('CustomerIndividualFullName')}</Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} className={'fx-partner-onboarding-layout-left-body'}>
                    <Grid item >
                        <Typography className={'fx-partner-onboarding-layout-left-body-title'}>You are just a step away</Typography>
                        <Typography className={'fx-partner-onboarding-layout-left-body-title'}>from onboarding as</Typography>
                        <Grid className={'fx-logo-name'}>
                            <Logo blueIcon={true} />
                        </Grid>
                        <Typography className={'fx-partner-onboarding-layout-left-body-title'}>Joint Account !</Typography>
                    </Grid>
                    <Grid item className={'fx-partner-onboarding-layout-left-body-image'} >
                        <OnboardingImg />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={'fx-partner-onboarding-layout-left-footer'}>
                    <Grid>
                        <p>Your Joint Account Journey Begins Now!</p>
                    </Grid>
                    <hr />
                </Grid>
            </Grid>
            <Grid item container justifyContent={'space-between'} xs={8} className={'fx-partner-onboarding-layout-right'}>
                <Grid item container xs={11} justifyContent={'center'}>
                    {children}
                </Grid>
                <Grid item>
                    <IconButton
                        component="a"
                        className="fx-profile-icon fx-logout"
                        id="logout-button"
                        onClick={() => history.push('/logout')}
                        title={'Logout'}
                        aria-label={'Logout'}
                        size="large">
                        <LogoutIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    )
}
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState} from "react";
import { Logger } from "../../libs/utils/logger";
import { IconButton, Snackbar, IconButtonProps, SnackbarProps } from "@mui/material";
import { ReactComponent as CopyIcon } from '../../assets/svg/copy-to-clipboard.svg';


Logger.debug("FxCopyToClipboard.tsx", "Fx copy to clipboard initializing")

type FxCopyToClipboardType = IconButtonProps & SnackbarProps;

interface FxCopyToClipboardProps extends FxCopyToClipboardType {
    value: string;
    onClick?:any;
    hideSnackbar?:boolean;
}

/**
 * FxCopyToClipboard
 * @param props FxCopyToClipboardProps
 */
export const FxCopyToClipboard: React.FC<FxCopyToClipboardProps> =
    (props) => {
        const [open, setOpen] = useState(false)
        /**
         * Method handles the onclick event of copy icon button
         * */
        const handleClick = (e:any) => {
            try{
              !props.hideSnackbar && setOpen(true)
                navigator.clipboard.writeText(props.value)
                if(props?.onClick){
                props?.onClick(e)
                }
            }
            catch(e){
                Logger.error("FxCopyToClipboard.tsx", "handleClick", "error", e);
            }
        }
        return <>
        <IconButton
            onClick={handleClick}
            id={props.id}
            className="fx-copy-clipboard"
            size="large">
            <CopyIcon />
        </IconButton>
        <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={props.message}
        anchorOrigin={props.anchorOrigin || { vertical: 'bottom', horizontal: 'center' }}
      />
      </>;
    }
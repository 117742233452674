/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import Select, { GroupBase } from 'react-select'
import { RegisterComponent } from "../../../libs/saga/dataSaga";
import { ISource } from '../../../types/common.interfaces';

/**
 * Component used for selection
 */
interface FxSelectProps<Option = unknown, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>{
    id: string;
    fieldName?: string;
    name?: string;
    data: any;
    isSearchable?: boolean;
    isEditable?: boolean;
    closeMenuOnSelect?: boolean;
    placeholder?: string;
    className?: string;
    dataTransformation?: (data: any) => any;
    onChange?: (event: any, options?: any) => void;
    value: any;
    default?: () => { label: string, value: string} | undefined;
    resetEnable?: boolean;
    source?: ISource;
}

const FxSelect: React.FC<FxSelectProps> = React.memo(
  (props) => {

    let context: any;
    ({ context, props } = RegisterComponent(props));

    const [selectVal, setSelectVal] = useState(props.value?.filter(Boolean)[0]?.value || '');
    const [selectOptions, setSelectOptions] = useState(props.data ? props.data : []);
    const [selectDef, setSelectDef] = useState(false);
    const [selectInit, setSelectInit] = useState(false);
    /**
    * Method used to manipulate data from api 
    * @returns :selected option
    */
    function dataTransformation() {
      if (props.dataTransformation && context?.data?.body?.resources) {
        const selectOptions: any = props.dataTransformation(context.data.body.resources);
        if (!selectDef) {
          setSelectOptions(selectOptions);
          setSelectDef(true);
        }
        return selectOptions;
      }
    }
    /**
     * Method used to handle selection options
     * @param selectOption :values
     * @param options :options
     * @returns 
     */
    const valueFormatter = (selectOption: any, options: any) => {
      // eslint-disable
      return options?.map((option: any, i: number) => {
        if (selectOption === option.value) {
          return { label: option.label, value: option.value }
        }
      });
    }

    function onChangeFunction(event: any) {
      setSelectVal(event.value);
      if (event.value && selectOptions && props.onChange) {
        return props.onChange(event, selectOptions)
      }
    }
    /**
     * Method used to set default value in select 
     * @param options :option
     * @returns 
     */
    const defValueFormatter = (options: any) => {
      if (options && !selectInit) {
        setSelectInit(true)
        const defValue: any = props.default && props.default();
        setSelectVal(defValue)
      }
      else {
        return { label: '', value: '' }
      }
    }

    if (selectVal && selectVal !== props.value?.filter(Boolean)[0]?.value && props.resetEnable) {
      setSelectVal('');
    }

    return (<>
      <Select styles={{ menuPortal: (base:any) => ({ ...base, zIndex: 9999 }) }} menuPortalTarget={document.body} menuPosition={'fixed'} id={props.id} className={props.className ? "fx-input-edit fx-select-def " + props.className : "fx-input-edit fx-select-def"} options={props.source ? dataTransformation() : props.data ? props.data : []} onChange={(e: any) => props.onChange ? onChangeFunction(e) : ''}
        name={props.fieldName} isDisabled={props.isEditable === false ? true : false}
        value={valueFormatter(selectVal, selectOptions)}
        defaultValue={props.default && defValueFormatter(context?.data?.body?.resources)} placeholder={props.placeholder ? props.placeholder : 'Select'}
        closeMenuOnSelect={props.closeMenuOnSelect ? props.closeMenuOnSelect : true}
        blurInputOnSelect={true}
        classNamePrefix="fx-react-select-dropdown"
        instanceId={props.id}
        isSearchable={props.isSearchable ? props.isSearchable : false} />
    </>
    )
  })

export default FxSelect;

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useMemo } from "react";
import { Link, LinkProps }  from '@mui/material';
import { useHistory } from 'react-router';
import { Logger } from "../../libs/utils/logger";
import { checkPermission } from "../Utils/CommonBaseClass";

Logger.debug("FxLink.tsx", "Fx Link initializing")
/**
 * link using for redirection. It restrict right click open link
 */

interface FxLinkProps extends LinkProps {
    permissions?: any;
    redirect?: string;
    tooltip?: boolean;
}

export const FxLink: React.FC<FxLinkProps> = React.memo(
    (props) => {

        const history = useHistory();
        const handleRedirect = (event: any) => {
            props.redirect && history.push(props.redirect)
            if (props.onClick && typeof props.onClick == 'function') {
                props.onClick(event);
            }
        };

        /**
         * to set permission
         */
        const permission = useMemo(() => {
            return checkPermission(props?.permissions);
          }, [props?.permissions])


        return (
            <>
                {permission ? <Link href={props.href ? props.href : undefined}
                    id={props.id ? props.id : ""}
                    title={props.tooltip ? props?.title : ''}
                    target={props.target ? props.target : ""}
                    underline={'none'}
                    onClick={props.redirect ? handleRedirect : props.onClick ? props.onClick : undefined}
                    className={
                        props.className ? "fx-link " + props.className : "fx-link"}
                >
                    {props.children}
                </Link> : <></>}
            </>
        )
    })




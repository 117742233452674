import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import clsx from "clsx";
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { useIsMobileScreen } from '../../../libs/utils/utils';
import { updateComponentParams } from '../../Utils/CommonBaseClass';
import FxCardHeader from '../../Container/FxCardHeader';
import { FxButton } from '../../Action/FxButton';
import { getKey } from '../../../libs/utils/storageManager';
import FxCard from '../../Container/FxCard';
import FxCardBody from '../../Container/FxCardBody';
import { AccountStatementDownload } from '../../Page/Index/Passport/AccountStatementDownload';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/download-home.svg';
import { DepositPlusAccountBalanceCard } from './DepositPlusAccountBalanceCard';
import { TotalInterestReceivedChart } from '../../Page/Index/Passport/TotalInterestReceivedChart';

const accountStatementPermission = {
  entity: "Accounts",
  name: "Statement",
  operation: "Download"
}
/**
 * This component manages the rendering of the Cash Builder+ Account Balance card and Total Interest Chart 
 */

export const DepositPlusAccountInfoCard: React.FC<any> = React.memo((props) => {
  let context: any;
  ({ context, props } = RegisterComponent(props));
  let currentAccountId: any;
  let currentAccountNumber: any;
  let accountData: any;
  const dispatch = useDispatch()
  const isSmallScreen = useIsMobileScreen();

  /**
   * checking account number in the context data
   */
  if (context && context.data && context.data.accountNumber) {
    accountData = context.data;
    currentAccountId = context.data.id
    currentAccountNumber = context.data.accountNumber
    dispatch({ type: "DATA_UPDATE_COMPONENT_PARAM", payload: { "id": 'home-ledger-list', 'account': currentAccountId } });
  }

  /**
  * Function to reset the component
  */
  useEffect(() => () => {
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'deposit-plus-account-info-card' } });
    dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'deposit-plus-chart-card' } });
    updateComponentParams(dispatch, 'customer-home-passport', { 'accountUpdated': true })
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'deposit-plus-quick-transfer-card' } });
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'home-ledger-list' } });
    dispatch({ type: "DATA_COMPONENT_RESET", payload: { id: 'home-transaction-activity-list' } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const account: any = currentAccountId ? { id: currentAccountId, accountNumber: currentAccountNumber } : null;

  /**
  * Method handles the onclick event for opening modal
  */
  const handleOpen = () => {
    updateComponentParams(dispatch, props?.id, { 'open': true });
  };

  /**
  * Method handles the closing for account statement
  */
  const handleAccountStatementClose = () => {
    updateComponentParams(dispatch, props?.id, { 'open': false });
  }

  return (
    <>
        <Grid id="deposit-plus-account-info-grid" item xs={12}  container className="fx-account-insight fx-deposit-plus-account-info-card">
            <FxCard className={clsx("fx-theme-passport fx-account-summary-card", !isSmallScreen ? 'fx-account-summary-card-accountgraph':'')}>
                <FxCardHeader>
                    <Grid item container xs={12} justifyContent="space-between" className='fx-account-summary-card-accountgraph-header'>
                        <Grid item className='fx-account-insight-title'>
                            <Typography variant='h3'>Overview</Typography>
                        </Grid>
                        {!isSmallScreen && getKey("selectedAccount")?.id && getKey("selectedAccount")?.status !== 'INACTIVE' &&
                        <Grid item>
                            <FxButton
                                permissions={accountStatementPermission}
                                variant="contained"
                                className="fx-account-insight-ac-statement-btn "
                                title='Download Statement'
                                id="account-statement"
                                onClick={handleOpen}
                            >
                                <DownloadIcon/><span className='fx-account-insight-ac-statement'>A/C STATEMENT</span>
                            </FxButton>
                        </Grid>}
                    </Grid>
                </FxCardHeader>
                <FxCardBody>
                    <Grid item container xs={12} sm={12} spacing={!isSmallScreen ? 0: 4}>
                        <Grid item xs={isSmallScreen ? 12 : 5} className={clsx(!isSmallScreen ? 'fx-account-summary-card-accountgraph-body fx-dashboard-section-one-cards-left' : '')}>
                            <DepositPlusAccountBalanceCard id="deposit-plus-account-info-card" accountData={accountData} />
                        </Grid>
                        <Grid item container xs={isSmallScreen ? 12 : 7} className={clsx(!isSmallScreen ? 'fx-account-summary-card-accountgraph-body fx-dashboard-section-one-cards-right' : '')}>
                            <TotalInterestReceivedChart id="deposit-plus-chart-card" account={account} />
                        </Grid>
                    </Grid>
                </FxCardBody>
            </FxCard>
        </Grid>
        {props?.open && <AccountStatementDownload id="deposit-plus-account-info-card-asd" open={props?.open} onClose={handleAccountStatementClose} />}
    </>
  )
})

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'
import { Grid, Typography } from '@mui/material';
import FxCard from '../../Container/FxCard';
import FxCardHeader from '../../Container/FxCardHeader';
import FxCardBody from '../../Container/FxCardBody';
import FxCardFooter from '../../Container/FxCardFooter';
import { FxStatus } from '../../Input/FxStatus/FxStatus';
import { ReactComponent as ReadAlertIcon } from '../../../assets/svg/read.svg';
import { removeUnderScoreConvertTitleCase } from '../../Utils/CommonBaseClass';
import moment from 'moment';
import { FxInfoTooltip } from '../../Utils/FxInfoTooltip';

/**
 * Component: Alert Component
 * Usage: To display alert card
 */

export const AlertComponent: React.FC<any> = React.memo((props) => {

  const categoryData : any = {
    "ACCOUNT" : "ACCOUNT",
    "DEBIT_CARD" : "DEBIT_CARD",
    "EXTERNAL_ACCOUNT" : "EXTERNAL_ACCOUNT",
    "SECURITY" : "SECURITY",
    "TRANSACTION" : "TRANSACTION",
    "TRANSACTION_APPROVALS" : "APPROVALS",
    "INVESTMENT_ACCOUNT" : "ACCOUNT"
  }

  // method to return message in P tag 
  function displayUpdatedDescription(messageBody: string) {
    if(messageBody) {
      const message: string[] = messageBody.split('\n');
      return <>
        {
          message.map((msg: string) => (
              <Typography>
                {msg}
              </Typography>
          ))
        }
      </>
    }
    return '';
  }

  return (
    <>
      <FxCard className={props?.data?.status === 'READ' ? 'fx-read-alert-component' : 'fx-alert-component'}>
          <FxCardHeader className='fx-alert-component-header'>
              <Grid container item xs direction='row' justifyContent='space-between'>
                <Grid item className='fx-alert-component-title' alignSelf='center'>
                  <Typography>{removeUnderScoreConvertTitleCase(props?.data?.name)}</Typography>
                </Grid>
                {props?.data?.status === 'UNREAD' && 
                <Grid item className='fx-alert-component-action-button' onClick={() => props?.markAsRead(props?.data?.id)}>
                  <FxInfoTooltip title='Mark as read' arrow placement={'left-start'} className={'fx-mark-as-read-tooltip'}>
                    <ReadAlertIcon />
                  </FxInfoTooltip>
                </Grid>}
              </Grid>
              <Grid item xs className='fx-alert-component-title'>
                <FxStatus id="alert-category" value={categoryData[props?.data?.category]} fill={true} className="fx-status" />
              </Grid>
          </FxCardHeader>
          <FxCardBody>
              <Grid item xs className='fx-alert-component-description'>
                  {displayUpdatedDescription(props?.data?.messageBody)}
              </Grid>
          </FxCardBody>
          <FxCardFooter>
              <Grid className='fx-alert-component-date'>
                <Typography>{moment(props?.data?.lastUpdatedOn).format("MMM DD, YYYY HH:mm A")}</Typography>
              </Grid>
          </FxCardFooter>
      </FxCard>
    </>
  )
})
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'
import { Stepper, Step, StepLabel, Box } from '@mui/material';
import { Logger } from '../../../libs/utils/logger';

Logger.debug("FxStepper.tsx", "FxStepper initializing");

/**
 * Component: FxStepper
 * Usage: create steper component
 */
export interface IStep {
    text?: string;
    icon: React.FunctionComponent;
}
interface FxStepperProps {
    steps: IStep[];
    alternativeLabel?:boolean;
    connector?: React.ReactElement<any, any>;
}

export const FxStepper: React.FC<FxStepperProps> = React.memo(
    ({steps, alternativeLabel = true, connector}) => {
        return (
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={3} alternativeLabel={alternativeLabel} connector={connector}>
                    {steps.map((item: any) => (
                        <Step key={item?.text}>
                            <StepLabel StepIconComponent={item.icon ? item.icon : ''}>{item?.text}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        )
    });
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { UseFormSetValue } from "react-hook-form";
import { isEmpty } from "lodash";
import FxVerify, { FxVerifyPropsType } from "./FxVerify";
import HttpClient from "../../libs/utils/httpClient";
import { getCustomerUrl, processAPIResponse } from "../../libs/utils/utils";
import { Logger } from "../../libs/utils/logger";
import { AUTH_STRINGS } from "../../constants/strings";
import { ReactComponent as WarningIcon } from "../../assets/svg/danger-icon.svg";
import { FxLink } from "../Action/FxLink";
import { getKey } from "../../libs/utils/storageManager";
const httpClient = HttpClient.getClient();

type FxVerifyRemainedPropsType = Omit<
  FxVerifyPropsType,
  | "inputLabel"
  | "buttonLabelNormal"
  | "buttonLabelSucceeded"
  | "buttonLabelFailed"
  | "verify"
  | "suffix"
>;

export type PropsType = FxVerifyRemainedPropsType & {
  currentPPI?: string;
  setValue: UseFormSetValue<any>;
};

/**
 * API call function to check ppi availability
 * @param ppi : string
 */
async function checkAvailabilityPPIAPI(ppi: string) {
  try {
    const req = { ppi };
    const data: any = await httpClient
      .post(getCustomerUrl("/ppi/verify", true), req)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { ...error };
      });
    return data;
  } catch (err) {
    Logger.error("FxCheckAvailabilityPPI.tsx", "error", err);
    return err;
  }
}

/**
 * API call function to get available PPI suggestions
 * @param customerId: string
 */
async function getAvailablePPIAPI(customerId: string) {
  try {
    const req = { customerId: customerId };
    const data: any = await httpClient
      .post(getCustomerUrl("/ppi/suggest", true), req)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { ...error };
      });
    return data;
  } catch (err) {
    Logger.error("FxCheckAvailabilityPPI.tsx", "error", err);
    return err;
  }
}

/**
 * rules for PPI input
 */
const rules = {
  required: true,
  maxLength: {
    value: 250,
    message: AUTH_STRINGS.ERRORS.PPI_INVALID_LENGTH,
  },
  pattern: {
    // eslint-disable-next-line no-useless-escape
    value: /[a-zA-Z0-9\t\n ./<>?;:"'`!@#$%^&*()\[\]{}_+=|\\-]/g,
    message: AUTH_STRINGS.ERRORS.PPI_INVALID_REGEXP,
  },
};

/**
 * Component responsible for checking availability PPI
 */
const FxCheckAvailabilityPPI: React.FC<PropsType> = ({
  onVerificationFinished,
  ...props
}) => {
  const [ppiAvailable, setPpiAvailable] = useState<boolean>();
  const [suggestions, setSuggestions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const check = useCallback(
    async (ppi: string) => {
      if (ppi.replace("@ppi", "") === props.currentPPI) {
        return true;
      }
      const response = await checkAvailabilityPPIAPI(ppi);
      const apiResponse = processAPIResponse(response);
      if (!apiResponse?.status) {
        setErrorMessage(apiResponse?.message);
        setSuggestions([]);
        return false;
      } else {
        if (response?.data?.status !== "UNVERIFIED") {
          const suggestionData = await getAvailablePPIAPI(getKey("customerId"));
          setSuggestions(suggestionData?.data?.ppiSuggestions);
          setErrorMessage(
            "That Payment ID is already taken, please try another."
          );
          return false;
        }
        return response?.data?.status === "UNVERIFIED";
      }
    },
    [props.currentPPI]
  );

  const handleVerificationFinished = useCallback(
    (status) => {
      setPpiAvailable(status);
      onVerificationFinished?.(status);
    },
    [onVerificationFinished]
  );

  return (
    <>
      <FxVerify
        buttonLabelNormal="Check Availability"
        buttonLabelSucceeded="Available"
        buttonLabelFailed="Not Available"
        inputLabel="Payment ID"
        verify={check}
        suffix="@ppi"
        rules={rules}
        onVerificationFinished={handleVerificationFinished}
        {...props}
      />
      {ppiAvailable === false && (
        <>
          <Grid item container xs={12} sm={12} alignItems="center">
            <WarningIcon />
            <Typography
              id="manage-ppi-check-availability-error-message"
              className="error-message"
            >
              {errorMessage}
            </Typography>
          </Grid>
          {!isEmpty(suggestions) && (
            <Grid item container xs={12} sm={12} alignItems="center">
              <Grid item xs={2}>
                <Typography>Available:</Typography>
              </Grid>
              <Grid item container xs={10}>
                {suggestions?.map((item: any) => {
                  return (
                    <Grid item className="fx-button-available">
                      <FxLink
                        onClick={() => {
                          props.setValue(
                            props.fieldName,
                            item.replace("@ppi", "")
                          );
                        }}
                      >
                        <Typography>{item}</Typography>
                      </FxLink>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default FxCheckAvailabilityPPI;

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react'; // we need this to make JSX compile
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { UpdateAddressCard } from './UpdateAddress';

/**
 * This component loads the Edit Address component
 */

export const EditAddressHome: React.FC<any> = React.memo(() => {
        //#region Variable Declaration
        const params = useParams<any>();
        //#region Assigning Values to Variable
        const {contactId, id} = params;
        // get contact api
        const edit_ea_source = {
            url: "/contact/id/" + contactId,
            method: "GET"
        }
        const rand = Math.floor(Math.random() * 100) + 1;

        return (
            <Grid id="edit-address-info-grid" container xs={12} alignItems="flex-start" justifyContent="space-between" className="fx-container">
                <Grid item xs={12} lg={12} >
                    <UpdateAddressCard eadetails={{ eaId: id,contactId:contactId }} key="editaddresscontact" id={rand + "-edit-address-payees-component-" + id} source={edit_ea_source}></UpdateAddressCard>
                </Grid>
            </Grid>


        )
    })

/* eslint-disable  @typescript-eslint/no-explicit-any */
import CryptoJS from 'crypto-js';
import md5 from 'crypto-js/md5';
import moment from 'moment';
import { getCustomerUrl } from './utils';
import _ from 'lodash';
import { Logger } from './logger';

//some browser eg: safari have memory size restriction
//the restriction will happen while storing the large portal.json file
export const inMemoryStorage: any = {};

export const createUniqueId = function (source: any) {
    const cloneSource = _.cloneDeep(source)
    cloneSource['url'] = getCustomerUrl(cloneSource['url'], cloneSource['baseUrl'])
    const strObj = JSON.stringify(cloneSource);
    return CryptoJS.MD5(strObj).toString(CryptoJS.enc.Hex);;
}

export const compareUniqueId = function (source: any, uniqueId: any) {
    return CryptoJS.MD5(source) === uniqueId;
}

export const setLocalStorage = function (key: any, data: any) {
    const strObj = JSON.stringify(data);
    try {
        localStorage.setItem(md5(key).toString(), strObj);
    } catch (e) {
        Logger.error("cacheHandler.tsx", "Local Storage Write Error", e);
        inMemoryStorage[key] = strObj
    }
}

export const getLocalStorage = function (key: any) {
    let data: any
    try {
        data = localStorage.getItem(md5(key).toString());
        if (!data) {
            //check in memory 
            if (inMemoryStorage[key]) {
                return inMemoryStorage[key];
            }
            return null;
        }
    } catch (e) { 
        Logger.error("cacheHandler.tsx", "Local Storage Get Error", e);
    }
    return data;
}

export const removeLocalStorage = function (key: any) {
    return localStorage.removeItem(key);
}
export const removeAllLocalStorage = function () {
    return localStorage.clear();
}
export function getDataFromStorage(key: any, expiry: number) {
    const d = moment(new Date());
    const storageTime = getLocalStorage(key + '.createdOn');
    if (storageTime == null) {
        return null;
    }
    const jsonDate:any = JSON.parse(storageTime);
    const created_on = moment(jsonDate).local();
    const timeDiff = d.diff(created_on, 'seconds')
    if (timeDiff < expiry) {
        const apiData = getLocalStorage(key + '.data');
        if (apiData != null) {
            return JSON.parse(apiData)
        }
        return null;
    } else {
        return null;
    }
}

export const saveDataInStorage = function (key: any, data: any) {
    const localCreatedOn = moment(new Date());
    setLocalStorage(key + '.createdOn', localCreatedOn);
    setLocalStorage(key + '.data', data);
}
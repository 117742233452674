/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from "react";
import { Grid, IconButton, InputAdornment, TextField, OutlinedTextFieldProps } from "@mui/material";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive'
import { Logger } from "../../libs/utils/logger";
import { ReactComponent as SearchIcon } from '../../assets/svg/search-icon.svg';
import { ReactComponent as IconCLose } from '../../assets/svg/close-icon.svg';
import FxMaterialSelect from "../Input/FxSelect/FxMaterialSelect";
import { checkPermission } from "../Utils/CommonBaseClass";
import { IDataGridProps } from '../../types/common.interfaces';

Logger.debug("FxPrimaryFilter.tsx", "Fx Primary filter initializing")

export interface IPrimaryFilterData {
    label: string; value: string; operator: string;
}

interface FxPrimaryFilterProps extends Partial<OutlinedTextFieldProps> {
    id: string;
    permissions?: any;
    data: IPrimaryFilterData[];
    dataGridProps: IDataGridProps;
    defaultSelection?: string;
}

/***
 * primary filter component
 * Contain a searchable text area with selection
 * selection normally contains id, name etc
 */
export const FxPrimaryFilter: React.FC<FxPrimaryFilterProps> =
    (props) => {
        const { register, setValue, control } = useForm();
        const [searchValue, setSearchValue] = useState('');
        const [searchParameter, setSearchParameter] = useState(props.defaultSelection ? props.defaultSelection : 'id');
        const componentState = useSelector((state: any) => {
            if (state.data[props?.dataGridProps?.id]) {
                return state.data[props?.dataGridProps?.id]
            } else {
                return { config: { params: {} } };
            }
        });
        /**
        * to set permission
        */
        const permission = useMemo(() => {
           return checkPermission(props?.permissions)
        }, [props?.permissions])

        const dispatch = useDispatch()
        const textChange = (event: any) => {
            setSearchValue(event.target.value);
        }
        //handle change
        const handleChange = (event: any) => {
            setSearchParameter(event.target.value)
            setFilterAndCallDataGridApi(event.target.value)
        }
        const isMobileScreen = useMediaQuery({ query: '(max-width: 600px)' })

        //update grid based on input text
        function setFilterAndCallDataGridApi(key: any) {
            let operator: any;
            props.data?.forEach((item: any) => {
                if (item.value === key) {
                    operator = item.operator;
                }
            });
            const filters: any = [];
            const dataFilters: any = [...componentState?.source?.data?.criteria?.filters];
            let checkFlag: boolean;
            dataFilters?.forEach((item: any, i: number) => {
                checkFlag = false;
                props.data?.forEach((filItem: any) => {
                    if (item.key === filItem.value) {
                        checkFlag = true;
                    }
                })
                if (!checkFlag) {
                    filters.push(item)
                }
            });
            const searchedFilter: any = {
                "operator": operator,
                "key": key,
                "values": [searchValue]
            };
            if (searchValue) {
                filters.push(searchedFilter);
            }
            const filterSourceDataCriteria: any = { ...componentState?.source?.data?.criteria }
            filterSourceDataCriteria.filters = filters;
            const filterSourceData: any = { ...componentState?.source?.data }
            filterSourceData.criteria = filterSourceDataCriteria;
            const filterSource: any = { ...componentState?.source };
            filterSource.data = filterSourceData;
            const newProps: any = { id: props.dataGridProps?.id, source: filterSource, column: props.dataGridProps?.column, pagination: props.dataGridProps?.pagination, pageNumber: props.dataGridProps?.pageNumber, pageSize: props.dataGridProps?.pageSize};
            dispatch({ type: "DATA_API_PARAM_CHANGED", payload: newProps });
        }

        const handleKeyDownChange = (event: any) => {
            if (event.key === 'Enter') {
                setFilterAndCallDataGridApi(searchParameter)
            }
        }

        //clear text
        const handleClear = () => {
            setSearchValue('');
            const filters: any = [];
            const dataFilters: any = [...componentState?.source?.data?.criteria?.filters];
            let checkFlag: boolean;
            dataFilters?.forEach((item: any, i: number) => {
                checkFlag = false;
                props.data?.forEach((filItem: any) => {
                    if (item.key === filItem.value) {
                        checkFlag = true;
                    }
                })
                if (!checkFlag) {
                    filters.push(item)
                }
            });
            const filterSourceDataCriteria: any = { ...componentState?.source?.data?.criteria }
            filterSourceDataCriteria.filters = filters;
            const filterSourceData: any = { ...componentState?.source?.data }
            filterSourceData.criteria = filterSourceDataCriteria;
            const filterSource: any = { ...componentState?.source };
            filterSource.data = filterSourceData;
            const newProps: any = { id: props.dataGridProps?.id, source: filterSource, column: props.dataGridProps?.column, pagination: props.dataGridProps?.pagination, pageNumber: props.dataGridProps?.pageNumber, pageSize: props.dataGridProps?.pageSize };
            dispatch({ type: "DATA_API_PARAM_CHANGED", payload: newProps });
        }

        /**
         * render component
         */
        return <>
            {!isMobileScreen && permission ?
                <Grid item container xs={12} className="fx-primary-filter">
                    <Grid item >
                        <TextField
                            className={"fx-input-edit"}
                            id={props.id}
                            placeholder="Search"
                            name={props.name}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {searchValue ? <IconButton id='filter-clear' onClick={handleClear} size="large">
                                            <IconCLose />
                                        </IconButton> : <SearchIcon />}
                                    </InputAdornment>
                                ),
                            }}
                            value={searchValue}
                            onChange={textChange}
                            onKeyDown={handleKeyDownChange}
                        />
                    </Grid>
                    <Grid item >
                        <FxMaterialSelect register={{ ...register(props.name + '-select') }} className={" fx-primary-select"} value={searchParameter} control={control} onChange={handleChange} id={props.id + '-select'} name={props.name + '-select'} data={props.data} setValue={setValue} />
                    </Grid>
                </Grid> : <></>
            }
        </>;
    }